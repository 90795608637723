import { Button, List, Progress } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import styles from './ImportadorProjecaoVenda.module.scss';
import { toast } from "react-toastify";
import { secondsToMs } from "../../../../utils/dateHelper";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { SvDbActions } from "../../../../store/svDb/SvDb.actions";
import { getOptionsDataFromObject } from "../../../../utils/helpers";
import { UserActions } from "../../../../store/user/User.actions";
import schema from "./ImportadorProjecaoVenda.schema";
import { TipoCombinacaoEnum } from "../..";
import { INewCombinacaoRequest } from "../../../../data/interfaces/projecaoVenda/ICombinacao";
import { ProjecaoVendaActions } from "../../../../store/projecaoVenda/ProjecaoVenda.actions";
import FieldForm from "../../../../components/ui/field";

export interface IImportadorProjecaoVenda {
    tipo: TipoCombinacaoEnum;
    visible: boolean;
    onClose: () => void;
}

interface formValues {
    clientesId?: number[],
    industriasId?: number[],
    tipo?: TipoCombinacaoEnum
}

const tipoOrigemOptions =
    [
        { text: 'Importar de Arquivo', value: 'importar' },
        { text: 'Copiar de Combinação Existente', value: 'copiarCombinacao' },
        { text: 'Referenciar um Modelo', value: 'referenciarModelo' }
    ] as IOptionData[];

const ImportadorProjecaoVenda: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeUpdating, setTimeUpdating] = useState(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [clientesOptions, setClientesOptions] = useState<IOptionData[]>([]);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [combinacoesOptions, setCombinacoesOptions] = useState<IOptionData[]>([]);
    const [modelosOptions, setModelosOptions] = useState<IOptionData[]>([]);
    const [clientesSelected, setClientesSelected] = useState<number[]>([]);
    const [tipoOrigem, setTipoOrigem] = useState<string>('importar');

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
        dispatch(ClienteActions.listClientes());
        dispatch(ProjecaoVendaActions.listModelos());

        if (props.tipo == TipoCombinacaoEnum.CLIENTEXINDUSTRIA)
            dispatch(ClienteActions.listClientesIndustrias());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0 && industriasOptions?.length == 0)
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));

        if (props.clientes && props.clientes.length > 0 && clientesOptions?.length == 0) {
            setClientesOptions(getOptionsDataFromObject(props.clientes, 'clienteId', 'clienteNomeFantasia'));
        }

        if (props.combinacoes && props.combinacoes.length > 0 && combinacoesOptions?.length == 0) {
            setCombinacoesOptions(getOptionsDataFromObject(props.combinacoes, 'combinacaoId', 'nomeAux'));
        }

        if (props.modelos && props.modelos.length > 0 && modelosOptions?.length == 0) {
            setModelosOptions(getOptionsDataFromObject(props.modelos, 'modeloId', 'nome'));
        }
    }, [props.clientes, props.industrias, props.modelos]);

    useEffect(() => {
        setIndustriasOptions(getOptionsDataFromObject(
            props.industrias?.filter((industria) => {
                return props?.clientesIndustrias?.filter(cXi => clientesSelected.includes(cXi?.clieIndustriaClienteId) && cXi?.clieIndustriaIndustriaId == industria?.industriaId)?.length == clientesSelected.length;
            }),
            'industriaId', 'industriaNomeFantasia'));
    }, [clientesSelected]);

    useEffect(() => {
        if (isUpdating) {
            setTimeout(() => {
                setTimeUpdating(timeUpdating + 1);
            }, 1000);
        }
    }, [timeUpdating, isUpdating])

    useEffect(() => {
        if (!props.isImporting && isUpdating) {
            if (!props.errosImportacao || props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsUpdating(false);
        }
    }, [props.isImporting])

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        dispatch(ClienteActions.importarClienteIndustriaClear());
        props.onClose();
    }

    const submit = (values: INewCombinacaoRequest) => {
        if (values) {
            if (tipoOrigem == 'importar' && file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {

                let hasCombinacao: boolean = hasCombinacaoExistente(values);

                if (!hasCombinacao || (hasCombinacao && window.confirm('ATENÇÃO: \r\n \r\nExistem combinações já existentes entre as escolhidas, e elas serão substituidas pela nova importação.\r\nDeseja continuar?'))) {
                    values.file = file;
                    dispatch(ProjecaoVendaActions.importarCombinacao(values));
                    setIsUpdating(true);
                    setShowForm(false);
                    setValues(values);
                }
            }
        }
        setIsSubmit(false);
    }

    const hasCombinacaoExistente = (values: INewCombinacaoRequest): boolean => {

        switch (props.tipo) {
            case TipoCombinacaoEnum.COMBINACAOGERAL:
                return true;
            case TipoCombinacaoEnum.CLIENTE: {
                let clientesIdExistentes = props.combinacoes.filter(c => c.combinacaoClienteId && !c.combinacaoIndustriaId).map(x => x.combinacaoClienteId);
                return values.clientesId.some(x => clientesIdExistentes.includes(x));
            }
            case TipoCombinacaoEnum.INDUSTRIA: {
                let industriasIdExistentes = props.combinacoes.filter(c => !c.combinacaoClienteId && c.combinacaoIndustriaId).map(x => x.combinacaoIndustriaId);
                return values.industriasId.some(x => industriasIdExistentes.includes(x));
            }
            default: {
                return props.combinacoes
                    .filter(c => c.combinacaoClienteId && c.combinacaoIndustriaId)
                    .some(x => values.clientesId.includes(x.combinacaoClienteId) && values.industriasId.includes(x.combinacaoIndustriaId))
            }
        }
    }

    const removeFile = () => {
        dispatch(ClienteActions.importarClienteIndustriaClear());
        setFile(null);
    }

    const buttons =
        [
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosAtualizacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const form =
        <Form onSubmit={submit} isSubmited={isSubmit} initialValues={values} schema={schema} className={styles['form']}>
            <div>
                <FieldForm type='hidden' name='tipo' key='tipo' value={props.tipo.toString()}></FieldForm>
                <SelectForm mode="multiple" hidden={props.tipo == TipoCombinacaoEnum.COMBINACAOGERAL || props.tipo == TipoCombinacaoEnum.INDUSTRIA} placeholder='Selecione um Cliente' onClear={() => setClientesSelected([])} onDeselect={(e) => setClientesSelected([...clientesSelected].filter(x => x != e))} onSelect={(e) => setClientesSelected([...clientesSelected, e])} name='clientesId' label='Cliente' options={clientesOptions} />
                <SelectForm mode="multiple" hidden={props.tipo == TipoCombinacaoEnum.COMBINACAOGERAL || props.tipo == TipoCombinacaoEnum.CLIENTE} placeholder='Selecione uma Indústria' disabled={props.tipo == TipoCombinacaoEnum.CLIENTEXINDUSTRIA && !clientesSelected} name='industriasId' label='Indústria' options={industriasOptions} />
                <SelectForm placeholder='Selecione a Origem' name='tipoOrigem' label='Tipo de Origem' defaultValue={tipoOrigem} options={tipoOrigemOptions} onSelect={(e) => setTipoOrigem(e)} />
                <SelectForm hidden={tipoOrigem != 'copiarCombinacao'} placeholder='Selecione a Combinação' name='combinacaoId' label='Combinações' options={combinacoesOptions} />
                <SelectForm hidden={tipoOrigem != 'referenciarModelo'} placeholder='Selecione o Modelo' name='modeloId' label='Modelos' options={modelosOptions} />
            </div>
            <div className={styles['groupSelect']} style={{ display: "flex" }} hidden={tipoOrigem != 'importar'}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportar')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportar' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    const infoAtualizacao = <div className={styles['infoUpdating']} id='infoUpdating'>
        <p>Importando Projeção de Venda - <span>{secondsToMs(timeUpdating)}</span></p>
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={100}
            showInfo={false}
            status="active"
        />
    </div>;

    return (
        <Modal title={'Importação de Projeção de Venda'} open={props.visible} footer={!isUpdating ? buttons : null} closable={false} destroyOnClose={true} width={!showForm && props.errosImportacao && props.errosImportacao.length > 0 ? 750 : 450}>
            <div className={styles['container']}>
                {
                    isUpdating ?
                        infoAtualizacao :
                        (
                            !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                errosAtualizacao
                                : form
                        )}
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda,
    ...state.cliente,
    industrias: { ...state }.svDb.industrias,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IImportadorProjecaoVenda;

export default connector(ImportadorProjecaoVenda);