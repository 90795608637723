import React from "react";
import { ColumnProps } from "antd/lib/table";
import {
  getFilterSearchProps,
  getDistinctFilterValues,
  sorter,
  URL_PEDIDO_SV
} from "../../../utils/helpers";
import { IPedidosNaoImportado } from "../../../data/interfaces/pedido/IPedidosNaoImportado";
import { Button } from "antd";
import styles from "./PedidosImportados.module.scss";
import { FaPen } from "react-icons/fa";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";

const openPedidoSV = (id64: string) => {
  window.open(URL_PEDIDO_SV + id64);
};

export const getColumns = (
  pedidosNaoImportados: IPedidosNaoImportado[],
  onEdit: (register: IPedidosNaoImportado) => void
) => {

  var dateCurrent = new Date();
  var month = dateCurrent.getMonth() + 1;
  var dateFilterStart = new Date(dateCurrent.setMonth(month - 1));
  var monthFilter = dateFilterStart.getMonth() >= 10 ? dateFilterStart.getMonth() : '0' + dateFilterStart.getMonth();

  var columns = [
    {
      title: "Número",
      dataIndex: "pedidoNumero",
      sorter: (a, b) => sorter(a.pedidoNumero, b.pedidoNumero),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      width: 120,
      ...getFilterSearchProps("pedidoNumero", "Pedido"),
      render: (text: string) => (
        <>
          <Button
            type="link"
            size="small"
            onClick={() => openPedidoSV(btoa(btoa(text)))}
          >
            {text}
          </Button>
        </>
      ),
    },
    {
      title: "Verificado",
      dataIndex: "verificado",
      sortDirections: ["descend", "ascend"],
      width: 120,
      sorter: (a, b) => sorter(a.verificado, b.verificado),
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      onFilter: (value: string, record) => {
        return record.verificado.toString().indexOf(value) === 0;
      },
      render: (value) => {
        return <div>
          {value === true
            ? <span style={{ color: 'green' }}>Sim</span>
            : <span style={{ color: 'red' }}>Não</span>}
        </div>;
      },
    },
    {
      title: 'Data',
      dataIndex: 'dataEmissao',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => sorter(a.dataEmissao, b.dataEmissao),
      showSorterTooltip: false,
      render: (value: any, register: IPedidosNaoImportado) => {
        return `${dateStringUStoPTBR(register.dataEmissao)}`;
      }
    },
    {
      title: "Indústria",
      dataIndex: "industriaNomeFantasia",
      sorter: (a, b) =>
        sorter(a.industriaNomeFantasia, b.industriaNomeFantasia),
      filters:
        getDistinctFilterValues(
          pedidosNaoImportados,
          "industriaNomeFantasia"
        ),
      onFilter: (value: string, record) => {
        return record.industriaNomeFantasia?.indexOf(value) === 0;
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Cliente",
      dataIndex: "clienteNomeFantasia",
      sorter: (a, b) =>
        sorter(a.clienteNomeFantasia, b.clienteNomeFantasia),
      filters: getDistinctFilterValues(
        pedidosNaoImportados,
        "clienteNomeFantasia"
      ),
      onFilter: (value: string, record) => {
        return record.clienteNomeFantasia?.indexOf(value) === 0;
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Usuário",
      dataIndex: "usuarioNomeQuemDigitou",
      sorter: (a, b) =>
        sorter(a.usuarioNomeQuemDigitou, b.usuarioNomeQuemDigitou),
      filters: getDistinctFilterValues(
        pedidosNaoImportados,
        "usuarioNomeQuemDigitou"
      ),
      onFilter: (value: string, record) => {
        return record.usuarioNomeQuemDigitou?.indexOf(value) === 0;
      },
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Observação",
      dataIndex: "observacao",
      sorter: (a, b) => sorter(a.observacao, b.observacao),
      ...getFilterSearchProps("observacao", "Pedido"),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      width: 120,
      className: styles["columnAction"],
      render: (value, register) => {
        return (
          <div>
            <FaPen title="Editar" onClick={() => onEdit(register)} />
          </div>
        );
      },
    },
  ] as Array<ColumnProps<IPedidosNaoImportado>>;

  return columns;
};
