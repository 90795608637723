import { ColumnProps } from "antd/lib/table";
import { sorter } from "../../../utils/helpers";
import { ICamposIntegracao } from "../../../data/interfaces/config/IInfoTabelaIntegracao";
import { Button } from "antd";
import React from "react";
import { history } from "../../../store";

export interface ITabelasIntegracao {
    nome: string,
    alias: string
}

export const TabelasIntegracaoData =
    [
        { nome: 'Pedidos', alias: 'Pedidos' },
        { nome: 'Produtos', alias: 'Produtos' },
        { nome: 'PedidoItens', alias: 'Itens do Pedido' },
        { nome: 'PedidoComissao', alias: 'Pedido Comissão' },
        { nome: 'UsuariosVendedores', alias: 'Usuários/Vendedores' },
        { nome: 'Clientes', alias: 'Clientes' },
        { nome: 'Industrias', alias: 'Indústrias' },
        { nome: 'Transportadoras', alias: 'Transportadoras' },
        { nome: 'Contatos', alias: 'Contatos' },
        { nome: 'Regioes', alias: 'Regiões' },
        { nome: 'TabelasPreco', alias: 'Tabelas de Preço' },
        { nome: 'PedidoStatus', alias: 'Pedido Status' },
        { nome: 'Grupo', alias: 'Grupo dos Produtos' },
        { nome: 'ProdutosCategorias', alias: 'Categorias dos Produtos' },
        { nome: 'UsuarioQueCadastrou', alias: 'Usuário que Cadastrou' },
        { nome: 'UsuarioQueDigitou', alias: 'Usuário que Digitou' },
        { nome: 'VendedoresIndustrias', alias: 'Vendedores x Indústrias' },
        { nome: 'VendedoresRegioes', alias: 'Vendedores x Regiões' },
        { nome: 'ClientesIndustrias', alias: 'Clientes x Indústrias Desconto' },
        { nome: 'ClientesVendedores', alias: 'Clientes x Vendedores' },
        { nome: 'ClientesTransportadoras', alias: 'Clientes x Transportadoras' },
    ] as ITabelasIntegracao[];

export const getColumns = () => {
    return [
        {
            title: 'Tabela',
            dataIndex: 'alias',
            sorter: (a, b) => sorter(a.alias, b.alias),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: '',
            dataIndex: 'nome',
            showSorterTooltip: false,
            render: (value: string) => {
                return value ? <Button type='link' size='small' onClick={() => history.push(`/api/campos-integracao/${value}`)}>Listar Campos</Button> : null;
            }
        },
    ] as Array<ColumnProps<ITabelasIntegracao>>;
}