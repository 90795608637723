import { format } from 'date-fns';
import moment from 'moment';
import { IOptionData } from '../components/ui/select';

export const mesesOptions =
    [
        { text: 'Janeiro', value: 1 },
        { text: 'Fevereiro', value: 2 },
        { text: 'Março', value: 3 },
        { text: 'Abril', value: 4 },
        { text: 'Maio', value: 5 },
        { text: 'Junho', value: 6 },
        { text: 'Julho', value: 7 },
        { text: 'Agosto', value: 8 },
        { text: 'Setembro', value: 9 },
        { text: 'Outubro', value: 10 },
        { text: 'Novembro', value: 11 },
        { text: 'Dezembro', value: 12 }
    ] as IOptionData[];

export const anosOptions =
    [
        { text: '2022', value: 2022 },
        { text: '2023', value: 2023 },
        { text: '2024', value: 2024 }
    ] as IOptionData[];

export const datetimeToString = (date: Date, strFormat: string): string | null => {
    try {
        return format(date, strFormat);
    }
    catch {
        return null;
    }
};

export const dateStringUStoPTBR = (date: any): string => {
    if (date == null) return '';

    var mes = date.toString().substr(5, 2);
    var dia = date.toString().substr(8, 2);
    var ano = date.toString().substr(0, 4);

    var response = `${dia}/${mes}/${ano}`;

    return response == '01/01/0001' ? '' : response;
};

//"2021-03-18T10:50:00.0096572Z"
export const dateTimeStringUStoPTBR = (date: any): string => {
    if (date == null) return '';

    var mes = date.toString().substr(5, 2);
    var dia = date.toString().substr(8, 2);
    var ano = date.toString().substr(0, 4);

    var time = date.toString().substr(11, 8);

    return `${dia}/${mes}/${ano} ${time}`;
};

export const secondsToMs = (d: number) => {
    d = Number(d);
    var m = Math.floor(d / 60).toString().padStart(2, '0');
    var s = Math.floor((d % 3600) % 60).toString().padStart(2, '0');

    return `${m}:${s}`;
};

export const stringDBToDate = (value: string): Date => {

    if (!value || value?.length == 0)
        return new Date();

    var mes = value.toString().substring(5, 7);
    var dia = value.toString().substring(8, 10);
    var ano = value.toString().substring(0, 4);

    return new Date(`${mes} ${dia} ${ano}`);
}

export const stringDBToMoment = (value: string): moment.Moment => {
    var date = stringDBToDate(value);

    return moment(date);
}