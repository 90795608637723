import React, { FC, CSSProperties } from 'react';

import styles from './InputNumber.module.scss';
import { InputNumber as InputNumberAnt } from 'antd';

export interface IInput {
  label?: string | React.ReactNode;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: CSSProperties;
  className?: string;
  type?: string;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  readonly?: boolean;
  onInput?: (value: string) => void;
  onChange?: (value: string) => void;
  value?: string;
  defaultValue?: string | number;
  autoComplete?: 'true' | 'false';
  tagRequired?: boolean,
  precision?: number,
  min?: number
}

const InputNumber: FC<IInput> = ({
  label, style, styleInput, className, defaultValue,
  type = 'text', placeholder, autoComplete,
  onBlur, onInput, disabled, readonly,
  onChange, tagRequired, precision, min
}) => {

  if (!placeholder && typeof (label) == 'string') {
    placeholder = label;
  }

  if (tagRequired && typeof (label) == 'string') {
    label = <>{label} <span style={{ color: 'red' }}>*</span></>;
  }

  return (
    <div className={`${styles['contentInput']} ${className ?? ''}`} style={style}>
      <label className={styles['description']}>{label}</label>
      <label
        hidden={type === 'hidden'}
        className={styles['labelInput']}>
        <InputNumberAnt
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readonly}
          style={{ width: '100%', ...styleInput }}
          onInput={(e: any) => onInput && onInput(e.target.value)}
          onBlur={(e: any) => onBlur && onBlur(e.target.value)}
          onChange={(e: any) => onChange && onChange(e)}
          bordered={false}
          decimalSeparator=','
          precision={precision}
          min={min?.toString()}
          autoComplete={autoComplete ? autoComplete : 'true'}
        />
      </label>
    </div>
  );
};

export default InputNumber;
