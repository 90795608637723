import { ICombinacao } from "../../data/interfaces/projecaoVenda/ICombinacao";
import { IModeloCombinacao } from "../../data/interfaces/projecaoVenda/IModeloCombinacao";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IProjecaoVendaState } from "./intefaces/IProjecaoVendaState";
import { ProjecaoVendaActionKeys, ProjecaoVendaActionUnion } from "./ProjecaoVenda.actions";

const initialState: IProjecaoVendaState = {
  combinacoes: [] as ICombinacao[],
  modelos: [] as IModeloCombinacao[],
  isLoading: false,
  error: undefined,
  message: undefined,
  isImporting: false,
  combinacao: {} as ICombinacao,
  modelo: {} as IModeloCombinacao
}

const metaReducer = (state = initialState, action: ProjecaoVendaActionUnion): IProjecaoVendaState => {

  switch (action.type) {
    case ProjecaoVendaActionKeys.LIST_COMBINACOES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.LIST_COMBINACOES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        combinacoes: action.payload,
      };

    case ProjecaoVendaActionKeys.GET_COMBINACAO_REQUEST:
      return {
        ...state,
        isLoading: true,
        combinacao: {} as ICombinacao,
      };
    case ProjecaoVendaActionKeys.GET_COMBINACAO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        combinacao: action.payload,
      };

    case ProjecaoVendaActionKeys.UPDATE_COMBINACAO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.UPDATE_COMBINACAO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined
      };
    case ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined,
        combinacoes: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case ProjecaoVendaActionKeys.COMBINACAO_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.COMBINACAO_DELETE_SUCCESS: {
      let combinacoes = [...state.combinacoes].filter(x => x.combinacaoId != action.payload);

      return {
        ...state,
        isLoading: false,
        combinacoes: combinacoes,
      };
    }

    case ProjecaoVendaActionKeys.LIST_MODELOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.LIST_MODELOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modelos: action.payload,
      };

    case ProjecaoVendaActionKeys.GET_MODELO_REQUEST:
      return {
        ...state,
        isLoading: true,
        modelo: {} as IModeloCombinacao,
      };
    case ProjecaoVendaActionKeys.GET_MODELO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modelo: action.payload,
      };

    case ProjecaoVendaActionKeys.UPDATE_MODELO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.UPDATE_MODELO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ProjecaoVendaActionKeys.IMPORTAR_MODELO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined
      };
    case ProjecaoVendaActionKeys.IMPORTAR_MODELO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined,
        modelos: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_MODELO_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_MODELO_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case ProjecaoVendaActionKeys.IMPORTAR_MODELO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case ProjecaoVendaActionKeys.MODELO_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProjecaoVendaActionKeys.MODELO_DELETE_SUCCESS: {
      let modelos = [...state.modelos].filter(x => x.modeloId != action.payload);

      return {
        ...state,
        isLoading: false,
        modelos: modelos,
      };
    }

    case ProjecaoVendaActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default metaReducer;
