import { IAtualizarProdutoResponse, IAtualizarProdutoStatus } from "../../data/interfaces/atualizarProduto/IAtualizarProdutoRequest";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IProdutoState } from "./interfaces/IProdutoState";
import { ProdutoActionKeys, ProdutoActionUnion } from "./Produto.actions";

const initialState: IProdutoState = {
  isLoading: false,
  error: undefined,
  message: undefined,
  errosAtualizacao: [] as IErroResponse[],
  atualizarProdutos: [],
  atualizarProduto: {} as IAtualizarProdutoResponse
}

const produtoReducer = (state = initialState, action: ProdutoActionUnion): IProdutoState => {

  switch (action.type) {
    case ProdutoActionKeys.ATUALIZAR_PRODUTO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProdutoActionKeys.ATUALIZAR_PRODUTO_SUCCESS:

      console.log(action.payload);

      return {
        ...state,
        isLoading: false,
        atualizarProdutos: [action.payload, ...state.atualizarProdutos]
      };
    case ProdutoActionKeys.ATUALIZAR_PRODUTO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ProdutoActionKeys.ATUALIZAR_PRODUTO_INVALID:
      return {
        ...state,
        isLoading: false,
        errosAtualizacao: action.payload,
      };
    case ProdutoActionKeys.ATUALIZAR_PRODUTO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosAtualizacao: [] as IErroResponse[]
      };

    case ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarProdutos: action.payload,
      };

    case ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarProduto: action.payload,
      };

    case ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_REQUEST:
      return {
        ...state
      };
    case ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_SUCCESS:

      var atualizarProdutos = [...state.atualizarProdutos];
      var index = atualizarProdutos.findIndex(x => x.atualizarProdutoId == action.payload.atualizarProdutoId);
      atualizarProdutos.splice(index, 1, action.payload);

      return {
        ...state,
        atualizarProdutos: atualizarProdutos,
      };

    case ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_SUCCESS:

      var atualizarProdutos = [...state.atualizarProdutos];
      var index = atualizarProdutos.findIndex(x => x.atualizarProdutoId == action.payload);
      atualizarProdutos[index].status = IAtualizarProdutoStatus.Iniciado;

      return {
        ...state,
        isLoading: false,
        atualizarProdutos: atualizarProdutos,
      };

    case ProdutoActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default produtoReducer;
