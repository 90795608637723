import { IIndustriaResponse } from "../svData/IIndustriaResponse";
import { IUserResponse } from "../user/IUserResponse";

export interface IAtualizarProdutoRequest {
    industriaId: number,
    file: File,
}

export interface IAtualizarProdutoResponse {
    atualizarProdutoId: number,
    industriaFk: number,
    dataCriacao: Date,
    dataFinalizacao: Date,
    userFk: string,
    status: IAtualizarProdutoStatus,
    mensagemErro: string,
    camposAtualizados: string,
    info: string,
    user: IUserResponse,
    industria: IIndustriaResponse,
    items: IAtualizarProdutoItemResponse[]
}

export interface IAtualizarProdutoItemResponse {
    atualizarProdutoItemId: number,
    codigoAntigo: string,
    codigoNovo: string,
    dataLancamento: Date,
    observacao: string,
    detalhe: string,
    especificacao: string,
    atualizado: boolean,
    atualizarProdutoFk: number,
    produtoId: number
}

export enum IAtualizarProdutoStatus {
    Iniciado = 1,
    Concluído = 2,
    Erro = 3
}