import { IImportarMetaRequest, IMeta, IMetaRequest } from "../../data/interfaces/meta/IMeta";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum MetaActionKeys {
  GET_META_REQUEST = 'GET_META_REQUEST',
  GET_META_SUCCESS = 'GET_META_SUCCESS',

  UPDATE_META_REQUEST = 'UPDATE_META_REQUEST',
  UPDATE_META_SUCCESS = 'UPDATE_META_SUCCESS',

  CLEAR_META = 'CLEAR_META',

  IMPORTAR_META_REQUEST = 'IMPORTAR_META_REQUEST',
  IMPORTAR_META_SUCCESS = 'IMPORTAR_META_SUCCESS',
  IMPORTAR_META_FAILED = 'IMPORTAR_META_FAILED',
  IMPORTAR_META_INVALID = 'IMPORTAR_META_INVALID',
  IMPORTAR_META_CLEAR = 'IMPORTAR_META_CLEAR',

  DEFAULT_FAILED = 'DEFAULT_FAILED'
}

export const MetaActions = {
  getMeta: (request: IMetaRequest): GetMetaAction => createAction(MetaActionKeys.GET_META_REQUEST, request),
  getMetaSuccess: (response: IMeta): GetMetaSuccessAction => createAction(MetaActionKeys.GET_META_SUCCESS, response),

  updateMeta: (request: IMeta): UpdateMetaAction => createAction(MetaActionKeys.UPDATE_META_REQUEST, request),
  updateMetaSuccess: (response: IMeta): UpdateMetaSuccessAction => createAction(MetaActionKeys.UPDATE_META_SUCCESS, response),

  clearMeta: (): ClearMetaAction => createAction(MetaActionKeys.CLEAR_META),

  importarMeta: (request: IImportarMetaRequest): ImportarMetaAction => createAction(MetaActionKeys.IMPORTAR_META_REQUEST, request),
  importarMetaSuccess: (): ImportarMetaSuccessAction => createAction(MetaActionKeys.IMPORTAR_META_SUCCESS),
  importarMetaFailure: (err: string): ImportarMetaFailureAction => createAction(MetaActionKeys.IMPORTAR_META_FAILED, err),
  importarMetaInvalid: (err: IErroResponse[]): ImportarMetaInvalidAction => createAction(MetaActionKeys.IMPORTAR_META_INVALID, err),
  importarMetaClear: (): ImportarMetaClearAction => createAction(MetaActionKeys.IMPORTAR_META_CLEAR),

  defaultFailure: (err: string): DefaultFailureAction => createAction(MetaActionKeys.DEFAULT_FAILED, err),
};

export type MetaActionUnion = ActionsUnion<typeof MetaActions>;

export type GetMetaAction = Action<MetaActionKeys.GET_META_REQUEST, IMetaRequest>;
export type GetMetaSuccessAction = Action<MetaActionKeys.GET_META_SUCCESS, IMeta>;

export type UpdateMetaAction = Action<MetaActionKeys.UPDATE_META_REQUEST, IMeta>;
export type UpdateMetaSuccessAction = Action<MetaActionKeys.UPDATE_META_SUCCESS, IMeta>;

export type ClearMetaAction = Action<MetaActionKeys.CLEAR_META>;

export type ImportarMetaAction = Action<MetaActionKeys.IMPORTAR_META_REQUEST, IImportarMetaRequest>;
export type ImportarMetaSuccessAction = Action<MetaActionKeys.IMPORTAR_META_SUCCESS>;
export type ImportarMetaFailureAction = Action<MetaActionKeys.IMPORTAR_META_FAILED, string>;
export type ImportarMetaInvalidAction = Action<MetaActionKeys.IMPORTAR_META_INVALID, IErroResponse[]>;
export type ImportarMetaClearAction = Action<MetaActionKeys.IMPORTAR_META_CLEAR>;

export type DefaultFailureAction = Action<MetaActionKeys.DEFAULT_FAILED, string>;