import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { AtualizarPedidoApi } from '../../data/AtualizarPedido.api';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { AtualizarItensPedidoAction, AtualizarPedidoActions, DetailAtualizacaoPedidosAction, GetAtualizarPedidosAction, RestaurarItemPedidoAction, RestaurarPedidoAction } from './AtualizarPedido.actions';

export function* list() {
  try {
    const { data } = yield call(AtualizarPedidoApi.list);
    yield put(AtualizarPedidoActions.listAtualizarPedidosSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.listAtualizarPedidosFailure(error));
  }
}

export function* getAtualizarPedidoById({ payload }: GetAtualizarPedidosAction) {
  try {
    const { data } = yield call(AtualizarPedidoApi.getAtualizarPedidoById, payload);
    yield put(AtualizarPedidoActions.getAtualizarPedidosSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.getAtualizarPedidosFailure(error));
  }
}

export function* atualizarItensPedido({ payload }: AtualizarItensPedidoAction) {
  try {
    const { data } = yield call(AtualizarPedidoApi.atualizarItensPedido, payload);
    yield put(AtualizarPedidoActions.atualizarItensPedidoSuccess(data.data));
    toast.success('Atualização iniciada com sucesso!');
  } catch (e) {

    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A atualização de itens de pedido está inválida!');
      yield put(AtualizarPedidoActions.atualizarItensPedidoInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(AtualizarPedidoActions.atualizarItensPedidoFailure(error));
    }
  }
}

export function* listByItems() {
  try {
    const { data } = yield call(AtualizarPedidoApi.listByItems);
    yield put(AtualizarPedidoActions.listAtualizarPedidoItemsSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.listAtualizarPedidoItemsFailure(error));
  }
}

export function* detailAtualizacaoById({ payload }: DetailAtualizacaoPedidosAction) {
  try {
    const { data } = yield call(AtualizarPedidoApi.detailByAtualizacaoId, payload);
    yield put(AtualizarPedidoActions.detailAtualizacaoPedidosSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.detailAtualizacaoPedidosFailure(error));
  }
}

export function* restaurarPedido({ payload }: RestaurarPedidoAction) {
  try {
    const { data } = yield call(AtualizarPedidoApi.restaurarPedido, payload);
    yield put(AtualizarPedidoActions.restaurarPedidoSuccess(data.data));
    toast.success('Pedido Recuperado com Sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.restaurarPedidoFailure(error));
  }
}

export function* restaurarItemPedido({ payload }: RestaurarItemPedidoAction) {
  try {
    yield call(AtualizarPedidoApi.restaurarItemPedido, payload);
    yield put(AtualizarPedidoActions.restaurarItemPedidoSuccess(payload));
    toast.success('Itens Recuperados com Sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AtualizarPedidoActions.restaurarItemPedidoFailure(error));
  }
}