import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IContatoAux, IContatoAuxUpdate, IContatoImportarRequest } from "./interfaces/contato/IContato";

export class ContatoApi {

    static async listContatos(): Promise<AxiosResponse<IContatoAux[]>> {
        const axios = await Http.axios();
        return axios.get('/contato/list');
    }

    static async update(request: IContatoAuxUpdate): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.put(`/contato/update`, request);
    }

    static async importarContatos(request: IContatoImportarRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file ?? '');
        formData.append('remover', request.remover?.toString());

        return axios.post(`/contato/importar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}