import { ModuleActionKeys, ModuleActionUnion } from "./Module.actions";
import { IModuleState } from "./interfaces/IModuleState";

const initialState: IModuleState = {
  isLoading: false,
  error: undefined,
  message: undefined,
  isLoadingModules: false,
  modules: []
}

const moduleReducer = (state = initialState, action: ModuleActionUnion): IModuleState => {

  switch (action.type) {
    case ModuleActionKeys.GET_MODULES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingModules: true
      };
    case ModuleActionKeys.GET_MODULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingModules: false,
        modules: action.payload,
      };

    case ModuleActionKeys.UPDATE_MODULES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ModuleActionKeys.UPDATE_MODULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ModuleActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default moduleReducer;
