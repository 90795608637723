import React from "react";
import { ColumnProps } from "antd/lib/table";
import { IAtualizarPedidoResponse, StatusAtualizarPedidoEnum } from "../../../data/interfaces/atualizarPedido/IAtualizarPedidoResponse";
import { getDistinctFilterValues, getFilterDateProps, sorter } from "../../../utils/helpers";
import moment from "moment";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";
import styles from './AtualizarPedidos.module.scss';
import { Button } from "antd";
import { history } from "../../../store";

export const getColumns = (atualizarPedidos: IAtualizarPedidoResponse[]) => {
    return [
        {
            title: '#',
            dataIndex: 'atualizarPedidoId',
            width: 70,
            sorter: (a, b) => a.atualizarPedidoId - b.atualizarPedidoId,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Indústria',
            dataIndex: 'industriaNomeFantasia',
            sorter: (a, b) => sorter(a.industriaNomeFantasia, b.industriaNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidos, 'industriaNomeFantasia'),
            onFilter: (value: string, record) => { return record.industriaNomeFantasia.indexOf(value) === 0 },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
            sorter: (a, b) => sorter(a.status, b.status),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidos, 'status'),
            onFilter: (value: string, record) => { return record.status.indexOf(value) === 0 },
            render: (value, register) => {
                return <b style={{ color: 
                    register.status == StatusAtualizarPedidoEnum.SUCESSO ? 'green' : 
                    (register.status == StatusAtualizarPedidoEnum.FALHOU ? 'red' : 'blue') }}>{register.status}</b>
            }
        },
        {
            title: 'Qtde. Pedidos',
            dataIndex: 'qtdePedidos',
            sorter: (a, b) => a.qtdePedidos - b.qtdePedidos,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value, register) => {
                return register.status == StatusAtualizarPedidoEnum.SUCESSO
                    ? value
                    : register.info?.replace('Atualizando pedidos: ', '')
            }
        },
        {
            title: 'Data',
            dataIndex: 'atualizarPedidoDataHora',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.atualizarPedidoDataHora, b.atualizarPedidoDataHora),
            showSorterTooltip: false,
            ...getFilterDateProps('atualizarPedidoDataHora', [moment().add(-5, 'y'), moment()]),
            render: (value: any, register: IAtualizarPedidoResponse) => {
                return `${dateStringUStoPTBR(register.atualizarPedidoDataHora)}`;
            }
        },
        {
            width: 100,
            className: styles['columnAction'],
            render: (value, register) => {
                return register.status != StatusAtualizarPedidoEnum.PROCESSANDO
                    ? <Button type='link' size='small' onClick={() => history.push(`/pedidoItens/detalharAtualizacao/${register.atualizarPedidoId}`)}>Detalhar</Button>
                    : null;
            }
        },
    ] as Array<ColumnProps<IAtualizarPedidoResponse>>;
}