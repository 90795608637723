import React, { memo, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useDispatch } from "react-redux";
import { PedidoImportadoActions } from '../store/pedido/importador/PedidoImportado.actions';
import { IImportarPedidoNewInfo } from '../data/interfaces/pedido/importador/IImportarPedidoRequest';
import { AuthUtils } from '../utils/AuthUtils';

const PedidoSignalR = () => {
    const [connection, setConnection] = useState<HubConnection>();
    const dispatch = useDispatch();
    var user = AuthUtils.getLoggedUser();

    useEffect(() => {

        if (connection) {
            connection.stop();
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(
                `${process.env.REACT_APP_BASE_API_URL?.substring(
                    0,
                    process.env.REACT_APP_BASE_API_URL?.length - 4
                )}/hubs/pedido`, { accessTokenFactory: () => user.accessToken ?? "" }
            )
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        newConnection.serverTimeoutInMilliseconds = 100000;
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    connection.on('newNotificationImportador', (message) => {
                        var result: IImportarPedidoNewInfo = JSON.parse(message);
                        dispatch(PedidoImportadoActions.importarPedidoInfoAdd(result));
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return <></>;
};

export default memo(PedidoSignalR);