import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./Clientes.helper";
import styles from './Clientes.module.scss';
import { ClienteActions } from "../../store/cliente/Cliente.actions";
import { IClienteIndustriaResponse } from "../../data/interfaces/svData/IClienteIndustriaResponse";
import ClienteForm from './modals/formCliente';
import ClienteView from './modals/viewCliente';
import ImportadorClienteIndustria from './modals/importadorClienteIndustria';
import { toast } from "react-toastify";
import { setVisibleAccessCoordenador } from "../../utils/AuthUtils";
import ViewEmailsCompradores from "./modals/viewEmailsCompradores";
import { TableLocale } from "antd/lib/table/interface";
import { getParameterByName } from "../../utils/helpers";
import ViewAudit from "./modals/viewAudit";
import ClienteSignalR from "../../signalR/clienteSignalR";
import { getAccessToken } from "../../utils/AuthUtils";

const Clientes: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [viewVisible, setViewVisible] = useState<boolean>(false);
    const [clienteIndustria, setClienteIndustria] = useState({} as IClienteIndustriaResponse);
    const [isVisibleModalImportarClienteIndustria, setIsVisibleModalImportarClienteIndustria] = useState(false);
    const [qtdeRows, setQtdeRows] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [clienteIndustriaIdsSelected, setClienteIndustriaIdsSelected] = useState<number[]>([]);
    const [emailCompradoresVisible, setEmailCompradoresVisible] = useState<boolean>(false);
    const [auditoriaVisible, setAuditoriaVisibleVisible] = useState<boolean>(false);
    const [clienteIndustriaAuxIdSelect, setClienteIndustriaAuxIdSelect] = useState<number>(0);

    useEffect(() => {
        dispatch(ClienteActions.listClientesIndustrias());
    }, []);

    useEffect(() => {
        setQtdeRows(props.clientesIndustrias?.length);
        if (props.clientesIndustrias?.length > 0) {
            var clienteIndustriaAuxId = parseInt(getParameterByName('clienteIndustriaAuxId') || '0');

            if (!isNaN(clienteIndustriaAuxId)) {
                var clienteIndustria = props.clientesIndustrias.find(x => x.clienteIndustriaAuxId == clienteIndustriaAuxId);

                if (clienteIndustria) {
                    setViewVisible(false);
                    setClienteIndustria(clienteIndustria);
                    setFormVisible(true);
                }
            }
        }
    }, [props.clientesIndustrias?.length]);


    const onEdit = (clienteIndustria: IClienteIndustriaResponse) => {
        setViewVisible(false);
        setClienteIndustria(clienteIndustria);
        setFormVisible(true);
    }

    const onView = (clienteIndustria: IClienteIndustriaResponse) => {
        if (clienteIndustria.clienteIndustriaAuxId > 0) {
            setClienteIndustria(clienteIndustria);
            setViewVisible(true);
        }
        else {
            toast.info('Ainda não foram inseridos dados nessa relação Cliente/Indústria!');
        }
    }

    const onViewAudit = (clienteIndustriaAuxId: number) => {
        setClienteIndustriaAuxIdSelect(clienteIndustriaAuxId);
        setAuditoriaVisibleVisible(true);
    }

    const tableChange = (qtdeRows: number, data: IClienteIndustriaResponse[]) => {
        setQtdeRows(qtdeRows);
        setClienteIndustriaIdsSelected(data.map(x => x.clienteIndustriaAuxId));
    }

    const onShowEmailsCompradores = () => {
        dispatch(ClienteActions.listCompradoresClienteIndustriaSuccess([]));
        setEmailCompradoresVisible(true);
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeysAux: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeysAux);
            setClienteIndustriaIdsSelected(selectedRowKeysAux.map(x => parseInt(x.toString())));
        }
    };

    const localeTable = {
        emptyText: 'Nenhum dado encontrado',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    return (
        <>
            <ClienteSignalR />
            <PageContent title='Clientes' className={styles['container']}>
                {props.isLoadingClientesIndustrias ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' onClick={() => setIsVisibleModalImportarClienteIndustria(true)}>Importar Clientes/Indústrias</Button>
                            <Button style={{ ...setVisibleAccessCoordenador }} className='btn-green' onClick={() => window.location.href = process.env.REACT_APP_BASE_API_URL + '/Cliente/ExportarExcel?token=' + getAccessToken() } type='primary'>Exportar Excel</Button>
                            <Button type='default' onClick={() => onShowEmailsCompradores()}>Listar Emails Compradores</Button>
                        </div>
                        <div className={styles['qtdeRows']}>
                            {qtdeRows} registros
                        </div>
                        <Table
                            rowSelection={{ type: 'checkbox', ...rowSelection }}
                            onChange={(p, f, s, extra) => tableChange(extra.currentDataSource?.length, extra.currentDataSource)}
                            columns={getColumns(props.clientesIndustrias, onEdit, onView, onViewAudit)}
                            dataSource={props.clientesIndustrias.map((data, index) => ({ ...data, key: index }))}
                            style={{ overflowY: 'auto' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }}
                            locale={localeTable} />
                    </>
                }
            </PageContent>
            {formVisible ?
                <ClienteForm updateStatus={true} clienteIndustria={clienteIndustria} visible={formVisible} onClose={() => setFormVisible(false)}></ClienteForm>
                : null}
            {viewVisible ?
                <ClienteView clienteIndustria={clienteIndustria} visible={viewVisible} onEdit={onEdit} onClose={() => setViewVisible(false)}></ClienteView>
                : null}
            {isVisibleModalImportarClienteIndustria ?
                <ImportadorClienteIndustria visible={isVisibleModalImportarClienteIndustria} onClose={() => setIsVisibleModalImportarClienteIndustria(false)}></ImportadorClienteIndustria>
                : null}
            {emailCompradoresVisible ?
                <ViewEmailsCompradores visible={emailCompradoresVisible} clienteIndustriaIds={clienteIndustriaIdsSelected} onClose={() => setEmailCompradoresVisible(false)}></ViewEmailsCompradores>
                : null}
            {auditoriaVisible ?
                <ViewAudit visible={auditoriaVisible} clienteIndustriaAuxId={clienteIndustriaAuxIdSelect} onClose={() => setAuditoriaVisibleVisible(false)} />
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Clientes);