import { Button, Col, Divider, Modal, Row, Skeleton } from "antd";
import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FieldForm from "../../components/ui/field";
import FieldNumber from "../../components/ui/fieldNumber";
import Form from "../../components/ui/form";
import PageContent from "../../components/ui/pageContent";
import SelectForm, { IOptionData } from "../../components/ui/selectForm";
import { ISellOut, ISellOutItem, ISellOutItemAuditoria, TIPOS_CAMPANHA } from "../../data/interfaces/sellOut/ISellOut";
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { ClienteActions } from "../../store/cliente/Cliente.actions";
import { SellOutActions } from "../../store/sellOut/SellOut.actions";
import { SvDbActions } from "../../store/svDb/SvDb.actions";
import { anosOptions, dateStringUStoPTBR, mesesOptions } from "../../utils/dateHelper";
import { getOptionsDataFromObject } from "../../utils/helpers";
import styles from './SellOut.module.scss';
import ImportadorSellOut from './modals/importador';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import InputNumber from "../../components/ui/inputNumber";

var campanhaOptions =
    [
        { value: 0, text: 'Sem Campanha' },
        { value: 1, text: 'RBR' },
        { value: 2, text: 'RBR Fábrica' },
        { value: 3, text: 'Concorrente' },
    ] as IOptionData[];

const SellOut: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [clientesOptions, setClientesOptions] = useState<IOptionData[]>([]);
    const [valuesLoad, setValuesLoad] = useState({} as ISellOut);
    const [values, setValues] = useState([] as ISellOutItem[]);
    const [isVisibleModalAuditoria, setIsVisibleModalAuditoria] = useState(false);
    const [isVisibleImportador, setIsVisibleImportador] = useState(false);
    const [loadImporting, setLoadImporting] = useState(false);
    const [optionsChartPeca, setOptionsChartPeca] = useState<any>({});
    const [optionsChartValor, setOptionsChartValor] = useState<any>({});

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
        dispatch(ClienteActions.listClientes());
        dispatch(ClienteActions.listClientesIndustrias());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0 && industriasOptions?.length == 0)
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));

        if (props.clientes && props.clientes.length > 0 && clientesOptions?.length == 0) {
            setClientesOptions(getOptionsDataFromObject(props.clientes, 'clienteId', 'clienteNomeFantasia'));
        }
    }, [props.clientes, props.industrias]);

    useEffect(() => {
        if (props.sellOut?.sellOutItems) {
            setValues([...props.sellOut?.sellOutItems]);
            setIsDataLoaded(true);
            handleGenerateOptionsCharts();
        }
    }, [props.sellOut, props.sellOut?.sellOutItems]);

    const handleGenerateOptionsCharts = () => {

        //Peças
        let dataPecasMeta = props.sellOut.sellOutItems.map((item) => item.pecasMeta ?? 0);
        let dataPecasRealizado = props.sellOut.sellOutItems.map((item) => item.pecasRealizado ?? 0);

        const optionsPecas = {
            chart: {
                type: 'spline'
            },
            yAxis: {
                title: {
                    text: 'Qtde Peças'
                }
            },
            title: {
                text: 'Peças - Meta x Realizado'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 1
                }
            },
            series: [
                {
                    name: 'Meta',
                    data: dataPecasMeta
                },
                {
                    name: 'Realizado',
                    data: dataPecasRealizado
                }
            ]
        };

        setOptionsChartPeca(optionsPecas);

        //Valores
        let dataValoresMeta = props.sellOut.sellOutItems.map((item) => item.valorMeta ?? 0);
        let dataValoresRealizado = props.sellOut.sellOutItems.map((item) => item.valorRealizado ?? 0);

        const optionsValores = {
            chart: {
                type: 'spline'
            },
            yAxis: {
                title: {
                    text: 'Valores R$'
                }
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 1
                }
            },
            title: {
                text: 'Valores - Meta x Realizado'
            },
            series: [
                {
                    name: 'Meta',
                    data: dataValoresMeta
                },
                {
                    name: 'Realizado',
                    data: dataValoresRealizado
                }
            ]
        };

        setOptionsChartValor(optionsValores);
    }

    const handleSelectCliente = (clienteId: number) => {
        setValuesLoad({
            ...valuesLoad,
            clienteFk: clienteId,
            industriaFk: undefined
        })

        setIndustriasOptions(getOptionsDataFromObject(
            props.industrias?.filter((industria) => {
                return props?.clientesIndustrias?.some(cXi => clienteId == cXi?.clieIndustriaClienteId && cXi?.clieIndustriaIndustriaId == industria?.industriaId)
            }),
            'industriaId', 'industriaNomeFantasia'));
    }

    const handleSelectIndustria = (industriaId: number) => {
        setValuesLoad({
            ...valuesLoad,
            industriaFk: industriaId
        })
    }

    const handleSelectAno = (ano: number) => {
        setValuesLoad({
            ...valuesLoad,
            ano: ano
        })
    }

    const handleLoadData = () => {
        if (valuesLoad.clienteFk && valuesLoad.industriaFk && valuesLoad.ano)
            dispatch(SellOutActions.getSellOut(valuesLoad));
        else
            toast.error('Selecione os campos!');
    }

    const handleClearForm = () => {
        setValues([] as ISellOutItem[]);
        setIsDataLoaded(false);
    }

    const handleChangeValues = (index: number, param: string, value: any, isInteger: boolean = false) => {
        let valuesAux = [...values];
        valuesAux[index][param] = value;

        setValues(valuesAux);
    }

    const handleCloseImportador = (valuesAux?: ISellOut) => {
        if (valuesAux) {
            handleClearForm();
            setLoadImporting(true);
            setTimeout(() => {
                setLoadImporting(false);
                dispatch(SellOutActions.getSellOut(valuesLoad));
            }, 500);
        }

        setIsVisibleImportador(false);
    }

    const onSubmit = () => {
        if (values) {
            let sellOut = { ...props.sellOut };
            sellOut.sellOutItems = values;
            dispatch(SellOutActions.updateSellOut(sellOut));
        }

        setIsSubmit(false);
    }

    return (
        <>
            <PageContent title='Sell-Out' className={styles['container']}>
                {props.isLoading || loadImporting ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['form']}>
                            <Form onSubmit={onSubmit} isSubmited={isSubmit} initialValues={valuesLoad}>
                                <div className={styles['form-top']}>
                                    <SelectForm disabled={isDataLoaded} className={styles['select-lg']} onSelect={handleSelectCliente} placeholder='Selecione um Cliente' name='clienteFk' label='Cliente' options={clientesOptions} allowClear={false} />
                                    <SelectForm disabled={isDataLoaded || !valuesLoad.clienteFk} className={styles['select-lg']} onSelect={handleSelectIndustria} placeholder='Selecione uma Indústria' name='industriaFk' label='Indústria' options={industriasOptions} allowClear={false} />
                                    <SelectForm disabled={isDataLoaded} className={styles['select-sm']} placeholder='Ano' onSelect={handleSelectAno} name='ano' key='ano' label='Ano' options={anosOptions} allowClear={false} />
                                    <Button hidden={isDataLoaded} onClick={handleLoadData} className={styles['button']} type='primary'>Carregar Dados</Button>
                                    <Button onClick={() => setIsSubmit(true)} className={styles['button']} type='primary' hidden={!isDataLoaded} >Salvar</Button>
                                    <Button style={{ marginLeft: 10 }} hidden={!isDataLoaded} className={`${styles['button']} btn-green`} type='primary' onClick={() => setIsVisibleImportador(true)}>Importar</Button>
                                    <Button style={{ marginLeft: 10 }} hidden={!isDataLoaded} className={styles['button']} type='default' onClick={() => setIsVisibleModalAuditoria(true)}>Histórico de Alteração</Button>
                                    <Button onClick={handleClearForm} hidden={!isDataLoaded} className={styles['button']} type='link'>Cancelar</Button>
                                </div>
                            </Form>
                        </div>
                        <div hidden={isDataLoaded} style={{ height: '200px', lineHeight: '200px', backgroundColor: '#f5f5f5', textAlign: "center" }}>
                            <h3>Escolha os campos e clique para carregar os dados!</h3>
                        </div>
                        <div className={styles['data']} hidden={!isDataLoaded}>
                            <Form onSubmit={() => null}>
                                <Row gutter={16}>
                                    <Col span={1} className={styles['header']}>
                                        Meses
                                    </Col>
                                    <Col span={2} className={styles['header']}>
                                        Peças <br></br> Meta
                                    </Col>
                                    <Col span={2} className={styles['header']}>
                                        Peças <br></br> Realizado
                                    </Col>
                                    <Col span={2} className={styles['header']}>
                                        Valor <br></br> Meta
                                    </Col>
                                    <Col span={2} className={styles['header']}>
                                        Valor <br></br> Realizado
                                    </Col>
                                    <Col span={3} className={styles['header']}>
                                        Campanha
                                    </Col>
                                    <Col span={4} className={styles['header']}>
                                        Observação
                                    </Col>
                                    <Col span={2} className={styles['header']}>
                                        Usuário
                                    </Col>
                                    <Col span={1} className={styles['header']}>
                                        Atualização
                                    </Col>
                                </Row>
                                {
                                    values.map((e, i) => {
                                        return (
                                            <Row gutter={10} className={styles['rows']} key={i}>
                                                <Col span={1} className={styles['mes']}>
                                                    <label>{mesesOptions[i].text}</label>
                                                </Col>
                                                <Col span={2}>
                                                    <InputNumber defaultValue={e.pecasMeta} onChange={(v) => handleChangeValues(i, 'pecasMeta', v)} />
                                                </Col>
                                                <Col span={2}>
                                                    <InputNumber defaultValue={e.pecasRealizado} onChange={(v) => handleChangeValues(i, 'pecasRealizado', v)} />
                                                </Col>
                                                <Col span={2}>
                                                    <InputNumber precision={2} defaultValue={e.valorMeta} onChange={(v) => handleChangeValues(i, 'valorMeta', v)} />
                                                </Col>
                                                <Col span={2}>
                                                    <InputNumber precision={2} defaultValue={e.valorRealizado} onBlur={(v) => handleChangeValues(i, 'valorRealizado', v)} />
                                                </Col>
                                                <Col span={3}>
                                                    <SelectForm name={`campanha-${i}`} defaultValue={e.sellOutCampanha} onSelect={(v) => handleChangeValues(i, 'sellOutCampanha', v)} placeholder="Campanha" options={campanhaOptions} style={{ width: 203 }} />
                                                </Col>
                                                <Col span={4}>
                                                    <FieldForm name={`observacao-${i}`} defaultValue={e.observacao} onBlur={(v) => handleChangeValues(i, 'observacao', v)} styleInput={{ width: 280 }} />
                                                </Col>
                                                <Col span={2}>
                                                    <div className={styles['info']}>
                                                        {e.user?.name}
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div className={styles['info']}>
                                                        {dateStringUStoPTBR(e.updateDate)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Form>
                        </div>
                        <br></br>
                        <br></br>
                        <div hidden={!isDataLoaded}>
                            <Divider>Gráficos</Divider>
                            <div>
                                <div style={{ width: '48%', float: 'right' }}>
                                    <HighchartsReact highcharts={Highcharts} options={optionsChartPeca} />
                                </div>
                                <div style={{ width: '48%' }}>
                                    <HighchartsReact highcharts={Highcharts} options={optionsChartValor} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </PageContent>

            {isVisibleImportador && valuesLoad.clienteFk && valuesLoad.industriaFk ?
                <ImportadorSellOut clientId={valuesLoad.clienteFk} industriaId={valuesLoad.industriaFk} ano={valuesLoad.ano} visible={isVisibleImportador} onClose={(values) => handleCloseImportador(values)} />
                : null}

            <Modal title="Histórico de Alterações" width={1200} open={isVisibleModalAuditoria} onCancel={() => setIsVisibleModalAuditoria(false)} footer={null}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                    <p style={{ width: 120 }}>Mês</p>
                    <p style={{ width: 100 }}>Pç. Meta</p>
                    <p style={{ width: 100 }}>Pç. Realizado</p>
                    <p style={{ width: 100 }}>Valor Meta</p>
                    <p style={{ width: 150 }}>Valor Realizado</p>
                    <p style={{ width: 100 }}>Obs</p>
                    <p style={{ width: 100 }}>Campanha</p>
                    <p style={{ width: 100 }}>Data</p>
                    <p style={{ width: 100 }}>Usuário</p>
                </div>
                {
                    props.sellOut?.sellOutItemsAuditoria?.map((x: ISellOutItemAuditoria) =>
                        <div key={x.codigo} style={{ display: 'flex' }}>
                            <p style={{ width: 120 }}>{mesesOptions[x.mes - 1]?.text}</p>
                            <p style={{ width: 100 }}>{x.pecasMeta}</p>
                            <p style={{ width: 100 }}>{x.pecasRealizado}</p>
                            <p style={{ width: 100 }}>{x.valorMeta}</p>
                            <p style={{ width: 150 }}>{x.valorRealizado}</p>
                            <p style={{ width: 100 }}>{x.observacao}</p>
                            <p style={{ width: 100 }}>{TIPOS_CAMPANHA[x.sellOutCampanha]}</p>
                            <p style={{ width: 100 }}>{dateStringUStoPTBR(x.updateDate)}</p>
                            <p style={{ width: 100 }}>{x.user?.name}</p>
                        </div>
                    )
                }
            </Modal>
        </>
    );

}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente,
    industrias: { ...state }.svDb.industrias,
    ...state.sellOut
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(SellOut);