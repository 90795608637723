export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const EXPIRES_AT_KEY = 'EXPIRES_AT';
export const ISSUED_DATETIME_KEY = 'issued_datetime';
export const NOME_KEY = 'nome';
export const EMAIL_KEY = 'email';
export const USER_IMAGE_KEY = 'user_image';
export const CURRENT_TENANT_KEY = 'current_tenant';
export const USER_ID_KEY = 'user_id';
export const ROLE_NAME = 'role_name';
export const AVATAR_NAME = 'avatar_name';
export const COOKIENAME_DEVICEID = 'deviceId';
export const ALLOWEDITIMPORTADORPEDIDO = 'allow_edit_importador_pedido';

export const ROLE_ADMINISTRADOR = 'Administrador';
export const ROLE_COORDENADOR = 'Coordenador';
