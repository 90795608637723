import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./Contatos.helper";
import styles from './Contatos.module.scss';
import { TableLocale } from "antd/lib/table/interface";
import { ContatoActions } from "../../store/contato/Contato.actions";
import { IContatoAux } from "../../data/interfaces/contato/IContato";
import ImportadorContato from './modals/importador';
import ContatoForm from './modals/form';
import { getAccessToken } from "../../utils/AuthUtils";
import ModalColumnsManager from "../common/modals/listColumnManager";
import { IModuleColumnUser } from "../../data/interfaces/module/IModuleColumnUser";
import { MODULE_CONTATO } from "../../static/constants/Module.constants";
import { ModuleActions } from "../../store/module/Module.actions";

const Contatos: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalImportar, setIsVisibleModalImportar] = useState(false);
    const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
    const [defaultValuesForm, setDefaultValuesForm] = useState<IContatoAux>({} as IContatoAux);
    const [showContatosAgrupados, setShowContatosAgrupados] = useState(true);
    const [showColumnManager, setShowColumnManager] = useState(false);
    var columns: IModuleColumnUser[] = props.modules?.filter(x => x.moduleId == MODULE_CONTATO)[0]?.columns;

    useEffect(() => {
        dispatch(ContatoActions.listContatos());
    }, []);

    const handleCloseImportador = (loadContatos: boolean = false) => {
        if (loadContatos) {
            setTimeout(() => {
                dispatch(ContatoActions.listContatos());
            }, 50);
        }

        setIsVisibleModalImportar(false);
        setIsVisibleModalForm(false);
    }

    const handleEdit = (register: IContatoAux) => {
        setDefaultValuesForm(register);
        setIsVisibleModalForm(true);
    }

    const handleSaveColumns = (columns: IModuleColumnUser[]) => {
        var modules = [...props.modules];
        var index = modules.findIndex(x => x.moduleId == MODULE_CONTATO);
        modules[index].columns = columns;

        dispatch(ModuleActions.listModulesSuccess(modules));
        setShowColumnManager(false);
    }

    console.log(props.modules);

    const localeTable = {
        emptyText: 'Nenhum dado encontrado',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    return (
        <>
            <PageContent title='Contatos' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' onClick={() => setIsVisibleModalImportar(true)}>Importar Contatos</Button>
                            <Button type='primary' className="btn-green" onClick={() => window.location.href = process.env.REACT_APP_BASE_API_URL + '/Contato/ExportarExcelAgrupado?token=' + getAccessToken()}>Exportar Contatos Agrupados</Button>
                            <Button type='default' onClick={() => window.location.href = process.env.REACT_APP_BASE_API_URL + '/Contato/ExportarExcelIndividual?token=' + getAccessToken()}>Exportar Contatos Individualmente</Button>
                            <Button type='link' onClick={() => setShowContatosAgrupados(!showContatosAgrupados)}>{showContatosAgrupados ? 'Exibir Contatos Individuais' : 'Exibir Contatos Agrupados'}</Button>
                            <Button type='link' onClick={() => setShowColumnManager(!showColumnManager)}>Personalizar Colunas</Button>
                        </div>
                        <Table
                            columns={getColumns(columns, handleEdit)}
                            dataSource={props.contatosAgrupados.map((data, index) => ({ ...data, key: index }))}
                            style={{ overflowY: 'auto', display: showContatosAgrupados ? 'block' : 'none' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }}
                            locale={localeTable} />
                        <Table
                            columns={getColumns(columns, handleEdit)}
                            dataSource={props.contatosIndividuais.map((data, index) => ({ ...data, key: index }))}
                            style={{ overflowY: 'auto', display: !showContatosAgrupados ? 'block' : 'none' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }}
                            locale={localeTable} />
                    </>
                }
            </PageContent>

            {isVisibleModalImportar ?
                <ImportadorContato visible={isVisibleModalImportar} onClose={(value) => handleCloseImportador(value)} />
                : null}

            {isVisibleModalForm ?
                <ContatoForm defaultValues={defaultValuesForm} visible={isVisibleModalForm} onClose={(value) => handleCloseImportador(value)} />
                : null}

            {showColumnManager ?
                <ModalColumnsManager moduleId={MODULE_CONTATO} columns={columns} visible={showColumnManager} onClose={() => setShowColumnManager(false)} onSave={handleSaveColumns} />
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.contato,
    ...state.cliente,
    ...state.module
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Contatos);