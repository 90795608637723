import React, { FC } from 'react';
import { IContatoAux } from '../../../../data/interfaces/contato/IContato';
import { Col, Row } from 'antd';
import ValueLabelBox from '../../../../components/ui/valueLabelBox';

export interface IContatoBoxProps {
    contato: IContatoAux,
    camposDesalinhados: string[]
}

const ContatoBox: FC<IContatoBoxProps> = ({ contato, camposDesalinhados }) => {

    const isDesalinhado = (campo: string): boolean => {
        return camposDesalinhados.includes(campo);
    }

    return (
        <>
            <Row>
                <Col>
                    <ValueLabelBox value={contato.contatoNome} label={'Nome Contato'} width={305} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.clientesNomeFantasia} label={'Nome Cliente'} width={300} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoEmail} label={'Email'} />
                </Col>
            </Row>
            <Row>

                <Col>
                    <ValueLabelBox value={contato.departamentoNome} highlited={isDesalinhado('departamentoId')} label={'Departamento'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoCargo} highlited={isDesalinhado('contatoCargo')} label={'Cargo'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoEmailSecundario} highlited={isDesalinhado('contatoEmailSecundario')} label={'Email Secundário'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoDataAniversario} highlited={isDesalinhado('contatoDataAniversario')} label={'Aniv.'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.comprador == 1 ? 'Sim' : 'Não'} highlited={isDesalinhado('comprador')} label={'Comprador'} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ValueLabelBox value={contato.contatoTelefone} highlited={isDesalinhado('contatoTelefone')} label={'Telefone'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoCelular} highlited={isDesalinhado('contatoCelular')} label={'Celular'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoTelefone2} highlited={isDesalinhado('contatoTelefone2')} label={'Telefone Secundário'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoNextel} highlited={isDesalinhado('contatoNextel')} label={'Nextel'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoWhatsapp} highlited={isDesalinhado('contatoWhatsapp')} label={'Whatsapp'} width={150} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ValueLabelBox value={contato.contatoTwitter} highlited={isDesalinhado('contatoTwitter')} label={'Twitter'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoSkype} highlited={isDesalinhado('contatoSkype')} label={'Skype'} width={150} />
                </Col>
                <Col>
                    <ValueLabelBox value={contato.contatoObservacao} highlited={isDesalinhado('contatoObservacao')} label={'Observação'} />
                </Col>
            </Row>
        </>
    );
};

export default ContatoBox;

