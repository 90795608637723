import { combineReducers } from 'redux';
import auth from './auth/Auth.reducer';
import user from './user/User.reducer';
import pedidoImportado from './pedido/importador/PedidoImportado.reducer';
import svApi from './svApi/SvApi.reducer';
import svDb from './svDb/SvDb.reducer';
import config from './config/Config.reducer';
import atualizarPedido from './atualizarPedido/AtualizarPedido.reducer';
import produto from './produto/Produto.reducer';
import cliente from './cliente/Cliente.reducer';
import meta from './meta/Meta.reducer';
import projecaoVenda from './projecaoVenda/ProjecaoVenda.reducer';
import sellOut from './sellOut/SellOut.reducer';
import acompanhamento from './acompanhamento/Acompanhamento.reducer';
import contato from './contato/Contato.reducer';
import module from './module/Module.reducer';
import ticket from './ticket/Ticket.reducer';
import notification from './notification/Notification.reducer';

export const rootReducer = combineReducers({
  auth,
  user,
  pedidoImportado,
  svApi,
  svDb,
  config,
  atualizarPedido,
  produto,
  cliente,
  meta,
  projecaoVenda,
  sellOut,
  acompanhamento,
  contato,
  module,
  ticket,
  notification
});
