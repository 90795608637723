import React from "react";
import { ColumnProps } from "antd/lib/table";
import { getDistinctFilterValues, sorter } from "../../../utils/helpers";

import styles from './CombinacaoForm.module.scss';
import { ICombinacaoItem } from "../../../data/interfaces/projecaoVenda/ICombinacao";
import { ColumnFilterItem } from "antd/lib/table/interface";

export const removeAllEditingClass = () => {
    for (var elements = document.getElementsByClassName('editing'), i = 0, l = elements.length; l > i; i++) {
        elements[0].classList.remove("editing");
    }
}

export const getColumns = (modelos: ICombinacaoItem[], showModalEditStatus: (chave: string) => void) => {
    return [
        {
            title: '#',
            dataIndex: 'codigo',
            width: 70,
            sorter: { compare: (a, b) => sorter(a.codigo, b.codigo) },
            render: (value) => <div style={{ paddingLeft: 5, borderRight: '1px solid #f5f5f5', color: '#b3b3b3', textAlign: 'center' }}>{value}</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: getDistinctFilterValues(modelos, 'status'),
            onFilter: (value: string, record) => record.status.indexOf(value) === 0,
            sorter: { compare: (a, b) => sorter(a.status, b.mes12), multiple: 12 },
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value, register) =>
                <div onClick={(e) => {
                    removeAllEditingClass();
                    e.currentTarget.classList.add('editing');
                    showModalEditStatus(register.chave);
                }} className={styles['projecao-status']}>
                    {value}
                </div >
        },
        {
            title: 'Mês 1',
            dataIndex: 'mes1',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes1, b.mes1), multiple: 1 },
            onFilter: (value: number, record) => record.mes1 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 2',
            dataIndex: 'mes2',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes2, b.mes2), multiple: 2 },
            onFilter: (value: number, record) => record.mes2 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 3',
            dataIndex: 'mes3',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes3, b.mes3), multiple: 3 },
            onFilter: (value: number, record) => record.mes3 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 4',
            dataIndex: 'mes4',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes4, b.mes4), multiple: 4 },
            onFilter: (value: number, record) => record.mes4 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 5',
            dataIndex: 'mes5',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes5, b.mes5), multiple: 5 },
            onFilter: (value: number, record) => record.mes5 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 6',
            dataIndex: 'mes6',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes6, b.mes6), multiple: 6 },
            onFilter: (value: number, record) => record.mes6 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 7',
            dataIndex: 'mes7',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes7, b.mes7), multiple: 7 },
            onFilter: (value: number, record) => record.mes7 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 8',
            dataIndex: 'mes8',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes8, b.mes8), multiple: 8 },
            onFilter: (value: number, record) => record.mes8 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 9',
            dataIndex: 'mes9',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes9, b.mes9), multiple: 9 },
            onFilter: (value: number, record) => record.mes9 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 10',
            dataIndex: 'mes10',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes10, b.mes10), multiple: 10 },
            onFilter: (value: number, record) => record.mes10 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 11',
            dataIndex: 'mes11',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes11, b.mes11), multiple: 11 },
            onFilter: (value: number, record) => record.mes11 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        },
        {
            title: 'Mês 12',
            dataIndex: 'mes12',
            filters: [{ text: 0, value: 0 }, { text: 1, value: 1 }] as ColumnFilterItem[],
            sorter: { compare: (a, b) => sorter(a.mes12, b.mes12), multiple: 12 },
            onFilter: (value: number, record) => record.mes12 == value,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => <div className={styles['projecao-' + value]}>{value}</div>
        }
    ] as Array<ColumnProps<ICombinacaoItem>>;
}