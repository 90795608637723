import { ICampoApi } from "../../data/interfaces/config/ICampoApi";
import { IConfig } from "../../data/interfaces/config/IConfig";
import { IInfoTabelaIntegracao } from "../../data/interfaces/config/IInfoTabelaIntegracao";
import { IStatusFunctions, RBRFunctionEnum } from "../../data/interfaces/config/IStatusFunctions";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum ConfigActionKeys {
    GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST',
    GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS',

    UPDATE_CONFIG_REQUEST = 'UPDATE_CONFIG_REQUEST',
    UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS',

    GET_JOB_ATUALIZAR_BANCO_REQUEST = 'GET_JOB_ATUALIZAR_BANCO_REQUEST',
    GET_JOB_ATUALIZAR_BANCO_SUCCESS = 'GET_JOB_ATUALIZAR_BANCO_SUCCESS',

    START_ATUALIZAR_BANCO_REQUEST = 'START_ATUALIZAR_BANCO_REQUEST',
    START_ATUALIZAR_BANCO_SUCCESS = 'START_ATUALIZAR_BANCO_SUCCESS',

    GET_JOB_ATUALIZAR_TEXTO_API_REQUEST = 'GET_JOB_ATUALIZAR_TEXTO_API_REQUEST',
    GET_JOB_ATUALIZAR_TEXTO_API_SUCCESS = 'GET_JOB_ATUALIZAR_TEXTO_API_SUCCESS',

    START_ATUALIZAR_TEXTO_API_REQUEST = 'START_ATUALIZAR_TEXTO_API_REQUEST',
    START_ATUALIZAR_TEXTO_API_SUCCESS = 'START_ATUALIZAR_TEXTO_API_SUCCESS',

    LIST_CAMPOS_API_REQUEST = 'LIST_CAMPOS_API_REQUEST',
    LIST_CAMPOS_API_SUCCESS = 'LIST_CAMPOS_API_SUCCESS',

    LIST_CAMPOS_INTEGRACAO_REQUEST = 'LIST_CAMPOS_INTEGRACAO_REQUEST',
    LIST_CAMPOS_INTEGRACAO_SUCCESS = 'LIST_CAMPOS_INTEGRACAO_SUCCESS',

    GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_REQUEST = 'GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_REQUEST',
    GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_SUCCESS = 'GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_SUCCESS',

    START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST = 'START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST',
    START_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS = 'START_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS',

    STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST = 'STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST',
    STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS = 'STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS',

    STOP_ATUALIZAR_TEXTO_API_REQUEST = 'STOP_ATUALIZAR_TEXTO_API_REQUEST',
    STOP_ATUALIZAR_TEXTO_API_SUCCESS = 'STOP_ATUALIZAR_TEXTO_API_SUCCESS',

    START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST = 'START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST',
    START_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS = 'START_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS',

    GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST = 'GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST',
    GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS = 'GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS',

    DISABLE_FUNCTION_REQUEST = 'DISABLE_FUNCTION_REQUEST',
    DISABLE_FUNCTION_SUCCESS = 'DISABLE_FUNCTION_SUCCESS',

    ENABLE_FUNCTION_REQUEST = 'ENABLE_FUNCTION_REQUEST',
    ENABLE_FUNCTION_SUCCESS = 'ENABLE_FUNCTION_SUCCESS',

    DEFAULT_CONFIG_FAILED = 'DEFAULT_CONFIG_FAILED',
}

export const ConfigActions = {
    getConfiguracoes: (): GetConfigAction =>
        createAction(ConfigActionKeys.GET_CONFIG_REQUEST),
    getConfiguracoesSuccess: (response: IConfig): GetConfigSuccessAction =>
        createAction(ConfigActionKeys.GET_CONFIG_SUCCESS, response),

    updateConfiguracoes: (request: IConfig): UpdateConfigAction =>
        createAction(ConfigActionKeys.UPDATE_CONFIG_REQUEST, request),
    updateConfiguracoesSuccess: (response: IConfig): UpdateConfigSuccessAction =>
        createAction(ConfigActionKeys.UPDATE_CONFIG_SUCCESS, response),

    getStatusJobAtualizarBanco: (): GetStatusJobAtualizarBancoConfigAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_REQUEST),
    getStatusJobAtualizarBancoSuccess: (response: IStatusFunctions): GetStatusJobAtualizarBancoConfigSuccessAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_SUCCESS, response),

    startJobAtualizarBanco: (request: string): StartJobAtualizarBancoConfigAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_BANCO_REQUEST, request),
    startFunctionAtualizarBancoSuccess: (response: IStatusFunctions): StartJobAtualizarBancoConfigSuccessAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_BANCO_SUCCESS, response),

    getStatusJobAtualizarTextoAPI: (): GetStatusJobAtualizarTextoAPIConfigAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_REQUEST),
    getStatusFunctionAjustesTextosAPISuccess: (response: IStatusFunctions): GetStatusJobAtualizarTextoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_SUCCESS, response),

    startJobAtualizarTextoAPI: (): StartJobAtualizarTextoAPIConfigAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_TEXTO_API_REQUEST),
    startFunctionAjustesTextosAPISuccess: (response: IStatusFunctions): StartJobAtualizarTextoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_TEXTO_API_SUCCESS, response),

    listCamposApi: (): ListCamposApiAction =>
        createAction(ConfigActionKeys.LIST_CAMPOS_API_REQUEST),
    listCamposApiSuccess: (response: ICampoApi[]): ListCamposApiSuccessAction =>
        createAction(ConfigActionKeys.LIST_CAMPOS_API_SUCCESS, response),

    listCamposIntegracao: (request: string): ListCamposIntegracaoAction =>
        createAction(ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_REQUEST, request),
    listCamposIntegracaoSuccess: (response: IInfoTabelaIntegracao): ListCamposIntegracaoSuccessAction =>
        createAction(ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_SUCCESS, response),

    getStatusJobAtualizarTextoLancamentoAPI: (): GetStatusJobAtualizarTextoLancamentoAPIConfigAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_REQUEST),
    getStatusJobAtualizarTextoLancamentoAPISuccess: (response: IStatusFunctions): GetStatusJobAtualizarTextoLancamentoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_SUCCESS, response),

    startJobAtualizarTextoLancamentoAPI: (): StartJobAtualizarTextoLancamentoAPIConfigAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST),
    startJobAtualizarTextoLancamentoAPISuccess: (response: IStatusFunctions): StartJobAtualizarTextoLancamentoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS, response),

    stopJobAtualizarTextoLancamentoAPI: (): StopJobAtualizarTextoLancamentoAPIConfigAction =>
        createAction(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST),
    stopJobAtualizarTextoLancamentoAPISuccess: (response: IStatusFunctions): StopJobAtualizarTextoLancamentoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS, response),

    stopJobAtualizarTextoAPI: (): StopJobAtualizarTextoAPIConfigAction =>
        createAction(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_REQUEST),
    stopJobAtualizarTextoAPISuccess: (response: IStatusFunctions): StopJobAtualizarTextoAPIConfigSuccessAction =>
        createAction(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_SUCCESS, response),

    startJobAtualizarBaseDadosPedido: (): StartJobAtualizarBaseDadosPedidoAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST),
    startJobAtualizarBaseDadosPedidoSuccess: (response: IStatusFunctions): StartJobAtualizarBaseDadosPedidoSuccessAction =>
        createAction(ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS, response),

    getStatusJobAtualizarBaseDadosPedido: (): GetStatusJobAtualizarBaseDadosPedidoAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST),
    getStatusJobAtualizarBaseDadosPedidoSuccess: (response: IStatusFunctions): GetStatusJobAtualizarBaseDadosPedidoSuccessAction =>
        createAction(ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS, response),

    disableFunction: (request: RBRFunctionEnum): DisableFunctionAction => createAction(ConfigActionKeys.DISABLE_FUNCTION_REQUEST, request),
    disableFunctionSucess: (): DisableFunctionSuccessAction => createAction(ConfigActionKeys.DISABLE_FUNCTION_SUCCESS),

    enableAllFunctions: (request: RBRFunctionEnum): EnableFunctionAction => createAction(ConfigActionKeys.ENABLE_FUNCTION_REQUEST, request),
    enableAllFunctionsSucess: (): EnableFunctionSuccessAction => createAction(ConfigActionKeys.ENABLE_FUNCTION_SUCCESS),

    defaultConfiguracoesFailure: (err: string): DefaultConfigFailureAction =>
        createAction(ConfigActionKeys.DEFAULT_CONFIG_FAILED, err),
}

export type ConfigActionUnion = ActionsUnion<typeof ConfigActions>;

export type GetConfigAction = Action<ConfigActionKeys.GET_CONFIG_REQUEST>;
export type GetConfigSuccessAction = Action<ConfigActionKeys.GET_CONFIG_SUCCESS, IConfig>;

export type UpdateConfigAction = Action<ConfigActionKeys.UPDATE_CONFIG_REQUEST, IConfig>;
export type UpdateConfigSuccessAction = Action<ConfigActionKeys.UPDATE_CONFIG_SUCCESS, IConfig>;

export type GetStatusJobAtualizarBancoConfigAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_REQUEST>;
export type GetStatusJobAtualizarBancoConfigSuccessAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_SUCCESS, IStatusFunctions>;

export type StartJobAtualizarBancoConfigAction = Action<ConfigActionKeys.START_ATUALIZAR_BANCO_REQUEST, string>;
export type StartJobAtualizarBancoConfigSuccessAction = Action<ConfigActionKeys.START_ATUALIZAR_BANCO_SUCCESS, IStatusFunctions>;

export type GetStatusJobAtualizarTextoAPIConfigAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_REQUEST>;
export type GetStatusJobAtualizarTextoAPIConfigSuccessAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_SUCCESS, IStatusFunctions>;

export type StartJobAtualizarTextoAPIConfigAction = Action<ConfigActionKeys.START_ATUALIZAR_TEXTO_API_REQUEST>;
export type StartJobAtualizarTextoAPIConfigSuccessAction = Action<ConfigActionKeys.START_ATUALIZAR_TEXTO_API_SUCCESS, IStatusFunctions>;

export type ListCamposApiAction = Action<ConfigActionKeys.LIST_CAMPOS_API_REQUEST>;
export type ListCamposApiSuccessAction = Action<ConfigActionKeys.LIST_CAMPOS_API_SUCCESS, ICampoApi[]>;

export type ListCamposIntegracaoAction = Action<ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_REQUEST, string>;
export type ListCamposIntegracaoSuccessAction = Action<ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_SUCCESS, IInfoTabelaIntegracao>;

export type GetStatusJobAtualizarTextoLancamentoAPIConfigAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_REQUEST>;
export type GetStatusJobAtualizarTextoLancamentoAPIConfigSuccessAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_SUCCESS, IStatusFunctions>;

export type StartJobAtualizarTextoLancamentoAPIConfigAction = Action<ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST>;
export type StartJobAtualizarTextoLancamentoAPIConfigSuccessAction = Action<ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS, IStatusFunctions>;

export type StopJobAtualizarTextoLancamentoAPIConfigAction = Action<ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST>;
export type StopJobAtualizarTextoLancamentoAPIConfigSuccessAction = Action<ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS, IStatusFunctions>;

export type StopJobAtualizarTextoAPIConfigAction = Action<ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_REQUEST>;
export type StopJobAtualizarTextoAPIConfigSuccessAction = Action<ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_SUCCESS, IStatusFunctions>;

export type StartJobAtualizarBaseDadosPedidoAction = Action<ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST>;
export type StartJobAtualizarBaseDadosPedidoSuccessAction = Action<ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS, IStatusFunctions>;

export type GetStatusJobAtualizarBaseDadosPedidoAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST>;
export type GetStatusJobAtualizarBaseDadosPedidoSuccessAction = Action<ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS, IStatusFunctions>;

export type DisableFunctionAction = Action<ConfigActionKeys.DISABLE_FUNCTION_REQUEST, RBRFunctionEnum>;
export type DisableFunctionSuccessAction = Action<ConfigActionKeys.DISABLE_FUNCTION_SUCCESS>;

export type EnableFunctionAction = Action<ConfigActionKeys.ENABLE_FUNCTION_REQUEST, RBRFunctionEnum>;
export type EnableFunctionSuccessAction = Action<ConfigActionKeys.ENABLE_FUNCTION_SUCCESS>;

export type DefaultConfigFailureAction = Action<ConfigActionKeys.DEFAULT_CONFIG_FAILED, string>;
