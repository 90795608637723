import { ISellOut } from "../../data/interfaces/sellOut/ISellOut";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IAcompanhamentoState } from "./interfaces/IAcompanhamentoState";
import { IAcompanhamento } from "../../data/interfaces/acompanhamento/IAcompanhamento";
import { AcompanhamentoActionKeys, AcompanhamentoActionUnion } from "./Acompanhamento.actions";

const initialState: IAcompanhamentoState = {
  acompanhamentos: [] as IAcompanhamento[],
  isLoading: false,
  error: undefined,
  message: undefined,
  // errosImportacao: [] as IErroResponse[],
  // isImporting: false
}

const acompanhamentoReducer = (state = initialState, action: AcompanhamentoActionUnion): IAcompanhamentoState => {

  switch (action.type) {
    case AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acompanhamentos: action.payload,
      };

    case AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined
      };
    case AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined,
      };
    case AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case AcompanhamentoActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default acompanhamentoReducer;
