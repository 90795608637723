import React from "react";
import { ColumnProps } from "antd/lib/table";
import { FaPen, FaTrash } from "react-icons/fa";
import { getDistinctFilterValues, sorter } from "../../../utils/helpers";

import styles from './Modelo.module.scss';
import Confirm from "../../../components/ui/confirm";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";
import { IModeloCombinacao } from "../../../data/interfaces/projecaoVenda/IModeloCombinacao";

export const getColumns = (modelos: IModeloCombinacao[], onEdit: (register: IModeloCombinacao) => void, onDelete: (modeloId: number) => void) => {
    return [
        {
            title: 'Modelo',
            dataIndex: 'nome',
            filters: getDistinctFilterValues(modelos, 'nome'),
            sorter: (a, b) => sorter(a.nome, b.nome),
            onFilter: (value: string, record) => record.nome?.indexOf(value) === 0,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 200,
            render: v => v ?? '-'
        },
        {
            title: 'Ativo',
            dataIndex: 'ativo',
            width: 100,
            render: (value) => value ? 'Sim' : 'Não'
        },
        {
            title: 'Data de Criação',
            dataIndex: 'dataCriacao',
            width: 100,
            render: (value) => dateStringUStoPTBR(value)

        },
        {
            width: 120,
            className: styles['columnAction'],
            render: (value, register) => {
                return <div>
                    <FaPen title='Editar' onClick={() => onEdit(register)} />
                    <FaTrash title='Excluir' onClick={() => Confirm('Excluir Modelo', 'Deseja realmente excluir?', () => onDelete(register.modeloId))} />
                </div>;
            }
        },
    ] as Array<ColumnProps<IModeloCombinacao>>;
}