import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Col, Divider, Row, Skeleton } from "antd";

import styles from './ClienteView.module.scss';
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { IClienteIndustriaResponse } from "../../../../data/interfaces/svData/IClienteIndustriaResponse";
import { IClienteIndustriaAuxView } from "../../../../data/interfaces/cliente/IClienteIndustriaAuxView";

export interface IClienteModal {
    clienteIndustria: IClienteIndustriaResponse;
    visible: boolean;
    onClose: () => void;
    onEdit: (clienteIndustria: IClienteIndustriaResponse) => void;
}

const ClienteView: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [register, setRegister] = useState<IClienteIndustriaAuxView>({} as IClienteIndustriaAuxView);

    useEffect(() => {
        dispatch(ClienteActions.getClienteIndustriaView(props.clienteIndustria.clienteIndustriaAuxId));
    }, [props.clienteIndustria.clienteIndustriaAuxId]);

    useEffect(() => {
        if (props.clienteIndustriaAuxView) {
            setRegister(props.clienteIndustriaAuxView)
        }
    })

    const buttons =
        [
            <Button key="back" onClick={props.onClose}>Fechar</Button>,
            <Button key="submit" type="primary" loading={props.isLoading} htmlType='submit' onClick={() => props.onEdit(props.clienteIndustria)}>Editar</Button>
        ];

    return (
        <Modal title='Ficha do Cliente' className={styles['modal']} open={props.visible} footer={buttons} onCancel={props.onClose} width={1200}>
            {
                !props.clienteIndustriaAuxView || props.isLoading ?
                    <Skeleton active></Skeleton>
                    :
                    <>
                        <Divider orientation="left">Cliente</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Nome Fantasia</b>
                                <label>
                                    <Button
                                        style={{ padding: 0 }}
                                        type='link'
                                        onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/ClienteForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(register.cliente?.clienteId.toString()))}`)}>
                                        {register.cliente?.clienteNomeFantasia}
                                    </Button>
                                </label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Razão Social</b>
                                <label>{register.cliente?.clienteRazaoSocial}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Grupo Cliente</b>
                                <label>{register.cliente?.clienteRegiaoNome}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>CNPJ</b>
                                <label>{register.cliente?.clienteCnpjCpf}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>UF</b>
                                <label>{register.cliente?.clienteEstado}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Cidade</b>
                                <label>{register.cliente?.clienteCidade}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={5}>
                                <b>Código Cliente</b>
                                <label>{register.codigo}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={5}>
                                <b>Condição Pagamento</b>
                                <label>{register.condicaoPagamento}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={4}>
                                <b>Status Projeção</b>
                                <label>[IMPLEMENTANDO...]</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={5}>
                                <b>Comissão Pedido</b>
                                <label>{register.comissaoPedido}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={5}>
                                <b>Pedido Status</b>
                                <label>{register.pedidoStatusNome}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={12}>
                                <b>Desconto Descritivo</b>
                                <label>{register.descontoDescritivo}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={12}>
                                <b>Desconto Encadeado</b>
                                <label>
                                    {register.descontoEncadeado_1}% +
                                    {register.descontoEncadeado_2}% +
                                    {register.descontoEncadeado_3}% +
                                    {register.descontoEncadeado_4}% +
                                    {register.descontoEncadeado_5}% +
                                    {register.descontoEncadeado_6}% +
                                    {register.descontoEncadeado_7}%
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Observação do Pedido</b>
                                <label>{register.observacaoPedido}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Negociação RBR</b>
                                <label>{register.negociacao}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Regime Especial</b>
                                <label>{register.regimeEspecial}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Observação Regime Especial</b>
                                <label>{register.regimeEspecialObservacao}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Aceita Pendência</b>
                                <label>{register.aceitaPendencia}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Observação Aceita Pendência</b>
                                <label>{register.aceitaPendenciaObservacao}</label>
                            </Col>
                        </Row>

                        <Divider orientation="left">Transportadoras</Divider>
                        {
                            register.transportadoras?.map((transportadora) => (
                                <Row key={transportadora.transportadoraId}>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Nome Fantasia</b>
                                        <label>
                                            <Button
                                                style={{ padding: 0 }}
                                                type='link'
                                                onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/TransportadoraForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(transportadora.transportadoraId.toString()))}`)}>
                                                {transportadora.transportadoraNomeFantasia}
                                            </Button>
                                        </label>
                                    </Col>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Razão Social</b>
                                        <label>{transportadora.transportadoraRazaoSocial}</label>
                                    </Col>
                                </Row>
                            ))
                        }

                        <Divider orientation="left" style={{ display: register.compradores?.length > 0 ? 'flex' : 'none' }}>Compradores</Divider>
                        {
                            register.compradores?.map((comprador) => (
                                <>
                                    <Row>
                                        <Col className={`${styles['groupInfo']} ${styles['nome']}`} span={4} title={comprador.contatoNome}>
                                            <b>Nome</b>
                                            <label>
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type='link'
                                                    onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/ContatoForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(comprador.contatoId.toString()))}`)}>
                                                    {comprador.contatoNome}
                                                </Button>
                                            </label>
                                        </Col>
                                        <Col className={`${styles['groupInfo']} ${styles['email']}`} span={4} title={comprador.contatoEmail}>
                                            <b>Email</b>
                                            <label>
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type='link'
                                                    onClick={() => window.open(`mailTo:${comprador.contatoEmail}`)}>
                                                    {comprador.contatoEmail}
                                                </Button>
                                            </label>
                                        </Col>
                                        <Col className={styles['groupInfo']} span={4}>
                                            <b>Telefone Fixo</b>
                                            <label>{comprador.contatoTelefone}</label>
                                        </Col>
                                        <Col className={styles['groupInfo']} span={4}>
                                            <b>Celular</b>
                                            <label>
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type='link'
                                                    onClick={() => window.open(`https://api.whatsapp.com/send?phone=55${comprador.contatoCelular.replace(/\D/g, '')}`)}>
                                                    {comprador.contatoCelular}
                                                </Button>
                                            </label>
                                        </Col>
                                        <Col className={styles['groupInfo']} span={3}>
                                            <b>Whatsapp</b>
                                            <label>
                                                <Button
                                                    style={{ padding: 0 }}
                                                    type='link'
                                                    onClick={() => window.open(`https://api.whatsapp.com/send?phone=55${comprador.contatoNextel.replace(/\D/g, '')}`)}>
                                                    {comprador.contatoNextel}
                                                </Button>
                                            </label>
                                        </Col>
                                        <Col className={styles['groupInfo']} span={3}>
                                            <b>Skype</b>
                                            <label>{comprador.contatoSkype}</label>
                                        </Col>
                                        <Col className={styles['groupInfo']} span={2}>
                                            <b>Aniversário</b>
                                            {comprador.contatoDataAniversario ?
                                                <label>{`${comprador.contatoDataAniversario?.substr(8, 2)}/${comprador.contatoDataAniversario?.substr(5, 2)}`}</label>
                                                : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className={styles['groupInfo']} span={24}>
                                            <label>Observação: {comprador.contatoObservacao}</label>
                                        </Col>
                                    </Row>
                                </>
                            ))
                        }
                        <br></br>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Observação Compradores</b>
                                <label>{register.observacaoCompradores}</label>
                            </Col>
                        </Row>

                        <Divider orientation="left">Indústria</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Indústria Nome Fantasia</b>
                                <label>
                                    <Button
                                        style={{ padding: 0 }}
                                        type='link'
                                        onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/FornecedorForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(register.industria?.industriaId.toString()))}`)}>
                                        {register.industria?.industriaNomeFantasia}
                                    </Button>
                                </label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Indústria Razão Social</b>
                                <label>{register.industria?.industriaRazaoSocial}</label>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Nº Último Pedido</b>
                                <label>{register.numeroUltimaCompra}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Data Último Pedido</b>
                                <label>{register.dataUltimaCompra}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Valor Último Pedido</b>
                                <label>{register.valorUltimaCompra}</label>
                            </Col>
                        </Row>
                        <Divider orientation="left">Gestão Comercial</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Plano de Ação</b>
                                <label>{register.planoAcao}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Interação</b>
                                <label>{register.interacao}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Categoria</b>
                                <label>{register.categoria?.nome}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Potencial de Crescimento</b>
                                <label>{register.potencialCrescimento}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Perfil Cliente</b>
                                <label>{register.perfil}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={styles['groupInfo']} span={24}>
                                <b>Observação do Cliente</b>
                                <label>{register.observacao}</label>
                            </Col>
                        </Row>
                        <Divider orientation="left">Coordenador</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Nome</b>
                                <label>{register.coordenador?.usuarioVendedorNome}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Email</b>
                                <label>{register.coordenador?.usuarioVendedorEmail}</label>
                            </Col>
                        </Row>

                        <Divider orientation="left">Supervisores</Divider>
                        {
                            register.supervisores?.map((supervisor) => (
                                <Row>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Nome</b>
                                        <label>{supervisor.usuarioVendedorNome}</label>
                                    </Col>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Email</b>
                                        <label>{supervisor.usuarioVendedorEmail}</label>
                                    </Col>
                                </Row>
                            ))
                        }

                        <Divider orientation="left">Gestor</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Nome</b>
                                <label>{register.gestor?.usuarioVendedorNome}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Email</b>
                                <label>{register.gestor?.usuarioVendedorEmail}</label>
                            </Col>
                        </Row>

                        <Divider orientation="left">Apoio Princial</Divider>
                        <Row>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Nome</b>
                                <label>{register.apoioPrincial?.usuarioVendedorNome}</label>
                            </Col>
                            <Col className={styles['groupInfo']} span={8}>
                                <b>Email</b>
                                <label>{register.apoioPrincial?.usuarioVendedorEmail}</label>
                            </Col>
                        </Row>

                        <Divider orientation="left">Vendedores</Divider>
                        {
                            register.vendedores?.map((vendedor) => (
                                <Row>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Nome</b>
                                        <label>{vendedor.usuarioVendedorNome}</label>
                                    </Col>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Email</b>
                                        <label>{vendedor.usuarioVendedorEmail}</label>
                                    </Col>
                                </Row>
                            ))
                        }

                        <Divider orientation="left">Promotores</Divider>
                        {
                            register.promotores?.map((promotor) => (
                                <Row>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Nome</b>
                                        <label>{promotor.usuarioVendedorNome}</label>
                                    </Col>
                                    <Col className={styles['groupInfo']} span={8}>
                                        <b>Email</b>
                                        <label>{promotor.usuarioVendedorEmail}</label>
                                    </Col>
                                </Row>
                            ))
                        }
                        <Divider orientation="left" />

                    </>
            }
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IClienteModal;

export default connector(ClienteView);