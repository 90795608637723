import { ISvApiState } from "./interfaces/ISvApiState";
import { SvApiActionKeys, SvApiActionUnion } from "./SvApi.actions";

const initialState: ISvApiState = {
    isLoading: false,
    error: undefined,
    message: undefined,
}

const svApiReducer = (state = initialState, action: SvApiActionUnion): ISvApiState => {

    switch (action.type) {
        case SvApiActionKeys.LIST_INDUSTRIAS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_INDUSTRIAS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                industrias: action.payload,
            };
        case SvApiActionKeys.LIST_INDUSTRIAS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_CLIENTES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_CLIENTES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clientes: action.payload,
            };
        case SvApiActionKeys.LIST_CLIENTES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_TABELAS_PRECO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_TABELAS_PRECO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tabelasPreco: action.payload,
            };
        case SvApiActionKeys.LIST_TABELAS_PRECO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clienteXIndustria: action.payload,
            };
        case SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_CLIENTE_VENDEDOR_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_CLIENTE_VENDEDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clienteXVendedor: action.payload,
            };
        case SvApiActionKeys.LIST_CLIENTE_VENDEDOR_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_TRANSPORTADORAS_REQUEST:
        case SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_TRANSPORTADORAS_SUCCESS:
        case SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                transportadoras: action.payload,
            };
        case SvApiActionKeys.LIST_TRANSPORTADORAS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clienteXTransportadora: action.payload,
            };
        case SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_PEDIDO_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_PEDIDO_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pedidoStatus: action.payload,
            };
        case SvApiActionKeys.LIST_PEDIDO_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_USUARIOS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_USUARIOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                usuarios: action.payload,
            };
        case SvApiActionKeys.LIST_USUARIOS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                usuariosByCliente: action.payload,
            };

        case SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contatos: action.payload,
            };

        case SvApiActionKeys.LIST_DEPARTAMENTOS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvApiActionKeys.LIST_DEPARTAMENTOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                departamentos: action.payload,
            };


        default:
            return state;
    }
}

export default svApiReducer;