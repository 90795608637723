import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import styles from './MetasForm.module.scss';
import Form from "../../../components/ui/form";
import SelectForm from "../../../components/ui/selectForm";
import { IOptionData } from "../../../components/ui/selectForm";
import { MetaActions } from "../../../store/meta/Meta.actions";
import { IMetaRequest, IMeta } from "../../../data/interfaces/meta/IMeta";
import { getColumnsBonusCoordenador, getColumnsBonusQtde, getColumnsBonusTotalVendedores, getColumnsBonusValor, getColumnsMetasQtde, getColumnsMetasValor } from "./index.helper";
import { Tabs } from 'antd';
import { anosOptions } from "../../../utils/dateHelper";
import { SvDbActions } from "../../../store/svDb/SvDb.actions";
import { getOptionsDataFromObject } from "../../../utils/helpers";
import { isObjectEmpty } from "../../../utils/ObjectUtils";
import { toast } from "react-toastify";
import { history } from "../../../store";
import { UserActions } from "../../../store/user/User.actions";
import { toFixed } from "../../../utils/numberHelper";
const { TabPane } = Tabs;

const MetasForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [meta, setMeta] = useState<IMeta>({} as IMeta);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [usuariosVendedoresOptions, setUsuariosVendedoresOptions] = useState<IOptionData[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [tabSelected, setTabSelected] = useState('tab-valor');

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
        dispatch(UserActions.listUsers());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0)
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));

        if (props.users && props.users.length > 0)
            setUsuariosVendedoresOptions(getOptionsDataFromObject(props.users.filter(u => u.hasGoals), 'idSuasVendas', 'name'));

    }, [props.industrias, props.users]);

    useEffect(() => {
        if (props.meta)
            setMeta(props.meta);
    }, [props.meta])

    const loadMetas = () => {
        if (meta.industriaFk > 0 && meta.vendedorFk > 0 && meta.ano > 0) {
            dispatch(MetaActions.getMeta({ industriaId: meta.industriaFk, vendedorId: meta.vendedorFk, ano: meta.ano } as IMetaRequest));
            setIsDataLoaded(true);
        }
        else
            toast.error('Selecione todas opções para carregar as informações de Metas!')
    }

    const onChangeMetas = (clienteId: number, key: string, value: any) => {

        let metaAux = { ...meta };

        var index = metaAux.metaClientes.findIndex(x => x.clienteFk == clienteId);

        var metaClienteAux = metaAux.metaClientes[index];
        metaClienteAux[key] = value;
        metaAux.metaClientes.splice(index, 1, metaClienteAux);

        setMeta(metaAux);
    }

    const onChangeBonus = (key: string, value: any) => {

        var metaAux = { ...meta };

        let metaBonus = metaAux.metaBonus;
        metaBonus[key] = value;

        metaAux.metaBonus = metaBonus;

        setMeta(metaAux);
    }

    const onChangeBonusCoordenador = (key: string, value: any) => {

        var metaAux = { ...meta };

        let metaBonusCoordenador = metaAux.metaBonusCoordenador;
        metaBonusCoordenador[key] = value;

        metaAux.metaBonusCoordenador = metaBonusCoordenador;

        setMeta(metaAux);
    }

    const onClearForm = () => {
        dispatch(MetaActions.clearMeta());
        setIsDataLoaded(false);
    }

    const onSubmit = () => {

        if (meta) {
            dispatch(MetaActions.updateMeta(meta));
        }

        setIsSubmit(false);
    }

    return (
        <>
            <PageContent title='Cadastro de Metas' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['form']}>
                            <Form onSubmit={onSubmit} isSubmited={isSubmit} >
                                <div className={styles['form-top']}>
                                    <SelectForm disabled={isDataLoaded} className={styles['select-lg']} onSelect={(e) => setMeta({ ...meta, vendedorFk: e })} placeholder='Selecione um Usuário' name='usuario' label='Usuário/Vendedor' options={usuariosVendedoresOptions} />
                                    <SelectForm disabled={isDataLoaded} className={styles['select-lg']} onSelect={(e) => setMeta({ ...meta, industriaFk: e })} placeholder='Selecione uma Indústria' name='industria' label='Indústria' options={industriasOptions} />
                                    <SelectForm disabled={isDataLoaded} className={styles['select-sm']} onSelect={(e) => setMeta({ ...meta, ano: e })} placeholder='Ano' name='ano' label='Ano' options={anosOptions} />
                                    <Button hidden={isDataLoaded} onClick={loadMetas} className={styles['button']} type='primary'>Carregar Dados</Button>
                                    <Button onClick={() => history.goBack()} hidden={isDataLoaded} className={styles['button']} type='link'>Voltar</Button>
                                    <Button onClick={() => setIsSubmit(true)} hidden={!isDataLoaded} className={styles['button']} type='primary'>Salvar</Button>
                                    <Button onClick={onClearForm} hidden={!isDataLoaded} className={styles['button']} type='link'>Cancelar</Button>
                                </div>
                            </Form>
                        </div>
                        {
                            isObjectEmpty(props.meta.metaBonus) && !props.isLoadingMeta ?
                                <div style={{ width: '100%', textAlign: 'center', paddingTop: 70, background: '#f5f5f5', height: 200 }}>
                                    <h3>Nenhuma informação de Metas carregado!</h3>
                                    <p style={{ color: 'gray' }}>Escolha os dados e clique para buscar os dados de metas.</p>
                                </div>
                                :
                                (

                                    props.isLoadingMeta ?
                                        <>
                                            <Skeleton active />
                                        </>
                                        :
                                        <>
                                            {
                                                meta.isCoordenador ?
                                                    <div className={styles['tableBonus']}>
                                                        {meta?.metaBonusCoordenador && meta?.metaBonusCoordenador.jan !== undefined ?
                                                            <>
                                                                <Tabs type='card' style={{ marginBottom: 32 }}>
                                                                    <TabPane tab='Bônus Coordenador' key='tab-valor'>
                                                                        <Table size='small' bordered columns={getColumnsBonusCoordenador(onChangeBonusCoordenador)} dataSource={[meta.metaBonusCoordenador]} style={{ overflowY: 'auto' }} pagination={false} />
                                                                    </TabPane>

                                                                </Tabs>
                                                                <Tabs type='card' style={{ marginBottom: 32 }}>
                                                                    <TabPane tab='Total Bônus Vendedores' key='tab-valor'>
                                                                        <Table size='small' bordered columns={getColumnsBonusTotalVendedores()} dataSource={[meta.metaBonusCoordenador]} style={{ overflowY: 'auto' }} pagination={false} />
                                                                    </TabPane>
                                                                </Tabs>
                                                            </>
                                                            : null}
                                                    </div>
                                                    :
                                                    <>
                                                        <div className={styles['tableBonus']}>
                                                            {meta?.metaBonus && meta?.metaBonus.janValor !== undefined && meta?.metaBonus.janQtde !== undefined ?
                                                                <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} onChange={(e) => setTabSelected(e)} type='card' style={{ marginBottom: 32 }}>
                                                                    <TabPane tab='Bônus Valor' key='tab-valor'>
                                                                        <Table size='small' bordered columns={getColumnsBonusValor(onChangeBonus)} dataSource={[meta.metaBonus]} style={{ overflowY: 'auto' }} pagination={false} />
                                                                    </TabPane>
                                                                    <TabPane tab='Bônus Qtde' key='tab-qtde'>
                                                                        <Table size='small' bordered columns={getColumnsBonusQtde(onChangeBonus)} dataSource={[meta.metaBonus]} style={{ overflowY: 'auto' }} pagination={false} />
                                                                    </TabPane>
                                                                </Tabs>
                                                                : null}
                                                        </div>
                                                        <div className={styles['tableMetas']}>
                                                            {meta?.metaClientes ?
                                                                <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} onChange={(e) => setTabSelected(e)} type='card' style={{ marginBottom: 32 }}>
                                                                    <TabPane tab='Metas por Valor' key='tab-valor'>
                                                                        <Table
                                                                            size='small'
                                                                            bordered
                                                                            columns={getColumnsMetasValor(meta.metaClientes, onChangeMetas)}
                                                                            dataSource={meta.metaClientes}
                                                                            style={{ overflowY: 'auto' }}
                                                                            pagination={false}
                                                                            sticky
                                                                            summary={data => {

                                                                                let janTotal = 0;
                                                                                let fevTotal = 0;
                                                                                let marTotal = 0;
                                                                                let abrTotal = 0;
                                                                                let maiTotal = 0;
                                                                                let junTotal = 0;
                                                                                let julTotal = 0;
                                                                                let agoTotal = 0;
                                                                                let setTotal = 0;
                                                                                let outTotal = 0;
                                                                                let novTotal = 0;
                                                                                let dezTotal = 0;

                                                                                data.forEach(d => {
                                                                                    janTotal += d.janValor;
                                                                                    fevTotal += d.fevValor;
                                                                                    marTotal += d.marValor;
                                                                                    abrTotal += d.abrValor;
                                                                                    maiTotal += d.maiValor;
                                                                                    junTotal += d.junValor;
                                                                                    julTotal += d.julValor;
                                                                                    agoTotal += d.agoValor;
                                                                                    setTotal += d.setValor;
                                                                                    outTotal += d.outValor;
                                                                                    novTotal += d.novValor;
                                                                                    dezTotal += d.dezValor;
                                                                                });

                                                                                return (
                                                                                    <Table.Summary fixed='top'>
                                                                                        <Table.Summary.Row className={styles['rowTotal']} >
                                                                                            <Table.Summary.Cell index={0}><b>Total de Metas</b></Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={1}>{toFixed(janTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={2}>{toFixed(fevTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={3}>{toFixed(marTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={4}>{toFixed(abrTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={5}>{toFixed(maiTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={6}>{toFixed(junTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={7}>{toFixed(julTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={8}>{toFixed(agoTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={9}>{toFixed(setTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={10}>{toFixed(outTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={11}>{toFixed(novTotal)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={12}>{toFixed(dezTotal)}</Table.Summary.Cell>

                                                                                        </Table.Summary.Row>
                                                                                    </Table.Summary>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </TabPane>
                                                                    <TabPane tab='Metas por Qtde' key='tab-qtde'>
                                                                        <Table
                                                                            size='small'
                                                                            bordered
                                                                            columns={getColumnsMetasQtde(meta.metaClientes, onChangeMetas)}
                                                                            dataSource={meta.metaClientes}
                                                                            style={{ overflowY: 'auto' }}
                                                                            pagination={false}
                                                                            sticky
                                                                            summary={data => {

                                                                                let janTotal = 0;
                                                                                let fevTotal = 0;
                                                                                let marTotal = 0;
                                                                                let abrTotal = 0;
                                                                                let maiTotal = 0;
                                                                                let junTotal = 0;
                                                                                let julTotal = 0;
                                                                                let agoTotal = 0;
                                                                                let setTotal = 0;
                                                                                let outTotal = 0;
                                                                                let novTotal = 0;
                                                                                let dezTotal = 0;

                                                                                data.forEach(d => {
                                                                                    janTotal += d.janQtde;
                                                                                    fevTotal += d.fevQtde;
                                                                                    marTotal += d.marQtde;
                                                                                    abrTotal += d.abrQtde;
                                                                                    maiTotal += d.maiQtde;
                                                                                    junTotal += d.junQtde;
                                                                                    julTotal += d.julQtde;
                                                                                    agoTotal += d.agoQtde;
                                                                                    setTotal += d.setQtde;
                                                                                    outTotal += d.outQtde;
                                                                                    novTotal += d.novQtde;
                                                                                    dezTotal += d.dezQtde;
                                                                                });

                                                                                return (
                                                                                    <Table.Summary fixed='top'>
                                                                                        <Table.Summary.Row className={styles['rowTotal']} >
                                                                                            <Table.Summary.Cell index={0}><b>Total de Metas</b></Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={1}>{toFixed(janTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={2}>{toFixed(fevTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={3}>{toFixed(marTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={4}>{toFixed(abrTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={5}>{toFixed(maiTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={6}>{toFixed(junTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={7}>{toFixed(julTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={8}>{toFixed(agoTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={9}>{toFixed(setTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={10}>{toFixed(outTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={11}>{toFixed(novTotal, 0)}</Table.Summary.Cell>
                                                                                            <Table.Summary.Cell index={12}>{toFixed(dezTotal, 0)}</Table.Summary.Cell>

                                                                                        </Table.Summary.Row>
                                                                                    </Table.Summary>
                                                                                );
                                                                            }} />
                                                                    </TabPane>
                                                                </Tabs>
                                                                : null}
                                                        </div>
                                                    </>
                                            }
                                        </>
                                )
                        }
                    </>
                }
            </PageContent>

        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.meta,
    ...state.svDb,
    ...state.user
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(MetasForm);