import React, { FC, CSSProperties } from 'react';

import styles from './FieldNumber.module.scss';
import { Field as FieldReact } from 'react-final-form';
import { InputNumber } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export interface IField {
  label?: string | React.ReactNode;
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  styleInput?: CSSProperties;
  className?: string;
  type?: string;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  readonly?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  autoComplete?: 'true' | 'false';
  tagRequired?: boolean,
  precision?: number,
  min?: number,
  noForm?: boolean,
  size?: SizeType
}

const hasError = (meta: any, disabled?: boolean) => (
  !disabled && meta.invalid && meta.touched
);

const FieldNumber: FC<IField> = ({
  label, style, styleInput, className, name, defaultValue,
  type = 'text', placeholder, autoComplete,
  onBlur, disabled, value, readonly,
  tagRequired, precision, min, noForm, size
}) => {

  if (!placeholder && typeof (label) == 'string') {
    placeholder = label;
  }

  if (tagRequired && typeof (label) == 'string') {
    label = <>{label} <span style={{ color: 'red' }}>*</span></>;
  }

  const onKeyDown = (event: any) => {

    if (isNaN(event.target.value)) {
      event.stopPropagation();
      event.preventDefault();
      
      return false;
    }

    return true;
  }

  return (
    <FieldReact name={name} type={type} defaultValue={defaultValue} initialValue={value}>
      {
        (props) => (
          <div className={`${styles['contentInput']} ${className ?? ''}`} style={style}>
            <label className={styles['description']}>{label}</label>
            <label
              hidden={type === 'hidden'}
              className={styles['labelInput']}>
              <InputNumber
                {...!noForm ? props.input : null}
                defaultValue={defaultValue}
                disabled={disabled}
                placeholder={placeholder}
                size={size}
                readOnly={readonly}
                style={{ width: '100%', ...styleInput }}
                className={`${hasError(props.meta, disabled) && !props.meta.active ? styles['invalid'] : ''}`}
                onBlur={(e: any) => onBlur && onBlur(e.target.value)}
                onKeyUp={(e: any) => onKeyDown && onKeyDown(e)}
                bordered={false}
                decimalSeparator=','
                precision={precision}
                min={min?.toString()}
                autoComplete={autoComplete ? autoComplete : 'true'}
              />
            </label>
            {<div className={styles['errorInput']}>
              {hasError(props.meta, disabled) ? props.meta.error : ''}
            </div>}
          </div>
        )
      }
    </FieldReact>
  );
};

export default FieldNumber;
