import { ICategoriaCliente } from "../../data/interfaces/cliente/ICategoriaCliente";
import { IClienteIndustriaAuxForm } from "../../data/interfaces/cliente/IClienteIndustriaAuxForm";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IClienteIndustriaResponse } from "../../data/interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "../../data/interfaces/svData/IClienteResponse";
import { ClienteActionKeys, ClienteActionUnion } from "./Cliente.actions";
import { IClienteState } from "./interfaces/IClienteState";

const initialState: IClienteState = {
  categoriasCliente: [] as ICategoriaCliente[],
  clientesIndustrias: [] as IClienteIndustriaResponse[],
  isLoading: false,
  error: undefined,
  message: undefined,
  isLoadingClientesIndustrias: false,
  clientes: [] as IClienteResponse[]
}

const clienteReducer = (state = initialState, action: ClienteActionUnion): IClienteState => {

  switch (action.type) {
    case ClienteActionKeys.LIST_CLIENTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.LIST_CLIENTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clientes: action.payload,
      };

    case ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingClientesIndustrias: true
      };
    case ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingClientesIndustrias: false,
        clientesIndustrias: action.payload,
      };

    case ClienteActionKeys.GET_CLIENTE_INDUSTRIA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.GET_CLIENTE_INDUSTRIA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clienteIndustriaAux: action.payload,
      };

    case ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_SUCCESS:

      var clienteIndustriaAux = action.payload;
      var clientesIndustrias = [...state.clientesIndustrias];

      if (action.payload.updateStatus) {

        var clienteIndustriaIndex = clientesIndustrias
          .findIndex(x =>
            x.clieIndustriaClienteId == clienteIndustriaAux.clienteIndustriaClienteId &&
            x.clieIndustriaIndustriaId == clienteIndustriaAux.clienteIndustriaIndustriaId);

        clientesIndustrias[clienteIndustriaIndex].clienteIndustriaAuxId = clienteIndustriaAux.clienteIndustriaId;
        clientesIndustrias[clienteIndustriaIndex].isRegisterCompleted = clienteIndustriaAux.isRegisterCompleted;
      }

      return {
        ...state,
        isLoading: false,
        clientesIndustrias: clientesIndustrias
      };

    case ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clienteIndustriaAuxView: action.payload,
      };

    case ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoriasCliente: action.payload,
      };

    case ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_SUCCESS:

      var registros = [...state.categoriasCliente];
      var index = registros.findIndex(x => x.categoriaClienteAuxId == action.payload.categoriaClienteAuxId);

      if (index > -1) {
        registros.splice(index, 1, action.payload);
      }
      else {
        registros = [...registros, action.payload];
      }

      return {
        ...state,
        isLoading: false,
        categoriasCliente: registros
      };

    case ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_SUCCESS:

      return {
        ...state,
        isLoading: false,
        categoriasCliente: [...state.categoriasCliente].filter(x => x.categoriaClienteAuxId != action.payload),
      };

    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INVALID:
      return {
        ...state,
        isLoading: false,
        errosImportacao: action.payload,
      };
    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };
    case ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INFO:
      return {
        ...state,
        clienteIndustriaImportadorInfo: action.payload,
      };

    case ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        compradoresClienteIndustria: action.payload,
      };

    case ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST:
      return {
        ...state,
        isLoading: true,
        clienteIndustriaAuxAuditorias: undefined
      };
    case ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clienteIndustriaAuxAuditorias: action.payload,
      };

    case ClienteActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoadingClientesIndustrias: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default clienteReducer;
