export function isObjectEmpty(object: any) {
  var isEmpty = true;
  for (var keys in object) {
    isEmpty = false;
    break; // exiting since we found that the object is not empty
  }
  return isEmpty;
}

export function sumFromArray(propertyName: string, array: any) {
  let sum = 0;
  array.forEach((item: any) => {
    sum += item[propertyName] ?? 0;
  });
  return sum;
}

export function countDistinctValues(objects: any[], prop: string) {
  const distinctValues: Set<any> = new Set();

  for (const obj of objects) {
    if (obj.hasOwnProperty(prop)) {
      distinctValues.add(obj[prop]);
    }
  }

  return distinctValues.size;
}