import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Divider, Skeleton, Tabs } from "antd";
import styles from './Form.module.scss';
import Form from "../../../../components/ui/form";
import FieldForm from "../../../../components/ui/field";
import schema from "./Form.schema";
import { IContatoAux, IContatoAuxUpdate } from "../../../../data/interfaces/contato/IContato";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import { getOptionsDataFromObject } from "../../../../utils/helpers";
import { ContatoActions } from "../../../../store/contato/Contato.actions";
import { simNaoOptions } from "../../../../utils/optionsHelper";
import ContatoBox from "../../components/contatoBox/ContatoBox";
import { getColunasDesalinhadas as getCamposDesalinhados } from "../../Contatos.helper";

export interface IContatoModal {
    defaultValues: IContatoAux;
    visible: boolean;
    onClose: (loadContatos: boolean) => void;
}

const ContatoForm: FC<Props> = ({ defaultValues, visible, onClose, isLoadingContato, clientes, departamentos, contatosIndividuais }) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const [optionsClientes] = useState<IOptionData[]>(getOptionsDataFromObject(clientes, 'clienteId', 'clienteNomeFantasia'));
    const [optionsDepartamentos] = useState<IOptionData[]>(getOptionsDataFromObject(departamentos, 'departamentoId', 'departamentoNome'));

    const submit = async (values: IContatoAuxUpdate) => {
        if (values) {
            dispatch(ContatoActions.updateContato(values));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    if (!isLoadingContato && isSendingData) {
        setIsSendingData(false);
        onClose(true);
    }

    const contatosIndividuaisAux = defaultValues != null ? contatosIndividuais.filter(x => x.contatoKey == defaultValues.contatoKey) : [];
    const camposDesalinhados = getCamposDesalinhados(contatosIndividuaisAux);

    const buttons =
        [
            <Button key="back" onClick={() => onClose(false)}>Cancelar</Button>,
            <Button key="submit" type="primary" loading={isLoadingContato} htmlType='submit' onClick={() => setIsSubmit(true)}>{'Salvar'}</Button>,
        ];

    return (
        <Form onSubmit={submit} schema={schema} initialValues={defaultValues} isSubmited={isSubmit}>
            <Modal title={'Atualizar Contato'} open={visible} footer={buttons} onCancel={() => onClose(false)} width={900}>
                {isLoadingContato ?
                    <Skeleton active></Skeleton>
                    :
                    <Tabs>
                        <Tabs.TabPane tab="Dados do Contato" key="item-1">
                            <div className={styles['container']}>
                                {
                                    <>
                                        <FieldForm readonly={true} key='contatoNome' label='Nome' name='contatoNome' className={styles['inputForm']}></FieldForm>
                                        <SelectForm mode='multiple' name='clientesIdAgrupados' label='Clientes' options={optionsClientes} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                        <div className={styles['groupField']}>
                                            <FieldForm style={{ width: '50%' }} key='contatoEmail' label='Email' name='contatoEmail' className={styles['inputGroup']}></FieldForm>
                                            <SelectForm style={{ width: '50%' }} name='departamentoId' label='Departamento' options={optionsDepartamentos} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                        </div>
                                        <div className={styles['groupField']}>
                                            <FieldForm style={{ width: '50%' }} key='contatoCargo' label='Cargo' name='contatoCargo' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '50%' }} key='contatoDataAniversario' label='Data de Aniverário' name='contatoDataAniversario' className={styles['inputGroup']}></FieldForm>
                                        </div>
                                        <div className={styles['groupField']}>
                                            <FieldForm style={{ width: '33%' }} key='contatoTelefone' label='Telefone' name='contatoTelefone' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '33%' }} key='contatoCelular' label='Celular' name='contatoCelular' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '33%' }} key='contatoTelefone2' label='Telefone Secundário' name='contatoTelefone2' className={styles['inputGroup']}></FieldForm>
                                        </div>
                                        <div className={styles['groupField']}>
                                            <FieldForm style={{ width: '33%' }} key='contatoNextel' label='Nextel' name='contatoNextel' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '33%' }} key='contatoWhatsapp' label='Whatsapp' name='contatoWhatsapp' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '33%' }} key='contatoEmailSecundario' label='Email Secundário' name='contatoEmailSecundario' className={styles['inputGroup']}></FieldForm>
                                        </div>
                                        <div className={styles['groupField']}>
                                            <FieldForm style={{ width: '33%' }} key='contatoTwitter' label='Twitter' name='contatoTwitter' className={styles['inputGroup']}></FieldForm>
                                            <FieldForm style={{ width: '33%' }} key='contatoSkype' label='Skype' name='contatoSkype' className={styles['inputGroup']}></FieldForm>
                                            <SelectForm style={{ width: '33%' }} key='comprador' name={`comprador`} label='Comprador' options={simNaoOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                        </div>
                                        <FieldForm key='contatoObservacao' label='Observação' name='contatoObservacao' className={styles['inputForm']}></FieldForm>
                                    </>
                                }
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Dados Individuais" key="item-2">
                            {
                                contatosIndividuaisAux?.map(contato => (
                                    <>
                                        <ContatoBox contato={contato} camposDesalinhados={camposDesalinhados} />
                                        <Divider />
                                    </>
                                ))
                            }
                        </Tabs.TabPane>
                    </Tabs>
                }
            </Modal>
        </Form>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.contato,
    ...state.svApi,
    isLoadingContato: { ...state }.contato.isLoading
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IContatoModal;

export default connector(ContatoForm);