import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { ConfigApi } from "../../data/Config.api";
import { ConfigActions, DisableFunctionAction, EnableFunctionAction, ListCamposIntegracaoAction, StartJobAtualizarBancoConfigAction, StartJobAtualizarTextoAPIConfigAction, UpdateConfigAction } from "./Config.actions";

export function* getConfig() {
    try {
        const { data } = yield call(ConfigApi.getConfiguracoes);
        yield put(ConfigActions.getConfiguracoesSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* updateConfig({ payload }: UpdateConfigAction) {
    try {
        const { data } = yield call(ConfigApi.updateConfiguracoes, payload);
        yield put(ConfigActions.updateConfiguracoesSuccess(data.data));
        toast.success('Configurações salvas com sucesso!');
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* getStatusJobAtualizarBanco() {
    try {
        const { data } = yield call(ConfigApi.getStatusFunctionAtualizacaoBanco);

        yield put(ConfigActions.getStatusJobAtualizarBancoSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* startFunctionAtualizarBanco({ payload }: StartJobAtualizarBancoConfigAction) {
    try {
        const { data } = yield call(ConfigApi.startFunctionAtualizacaoBanco, payload);
        yield put(ConfigActions.startFunctionAtualizarBancoSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* getStatusFunctionAjustesTextosAPI() {
    try {
        const { data } = yield call(ConfigApi.getStatusFunctionAjustesTextosAPI);
        yield put(ConfigActions.getStatusFunctionAjustesTextosAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* startFunctionAjustesTextosAPI() {
    try {
        const { data } = yield call(ConfigApi.startFunctionAjustesTextosAPI);
        yield put(ConfigActions.startFunctionAjustesTextosAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* listCamposApi() {
    try {
        const { data } = yield call(ConfigApi.listCamposApi);
        yield put(ConfigActions.listCamposApiSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* listCamposIntegracao({ payload }: ListCamposIntegracaoAction) {
    try {
        const { data } = yield call(ConfigApi.listCamposIntegracao, payload);
        yield put(ConfigActions.listCamposIntegracaoSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* getStatusFunctionAjustesTextosLancamentosAPI() {
    try {
        const { data } = yield call(ConfigApi.getStatusFunctionAjustesTextosLancamentosAPI);
        yield put(ConfigActions.getStatusJobAtualizarTextoLancamentoAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* startFunctionAjustesTextosLancamentosAPI() {
    try {
        const { data } = yield call(ConfigApi.startFunctionAjustesTextosLancamentosAPI);
        yield put(ConfigActions.startJobAtualizarTextoLancamentoAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* stopFunctionAjustesTextosLancamentosAPI() {
    try {
        const { data } = yield call(ConfigApi.stopFunctionAjustesTextosLancamentosAPI);
        yield put(ConfigActions.stopJobAtualizarTextoLancamentoAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* stopFunctionAjustesTextosAPI() {
    try {
        const { data } = yield call(ConfigApi.stopFunctionAjustesTextosAPI);
        yield put(ConfigActions.stopJobAtualizarTextoAPISuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* startFunciontAtualizarBaseDadosPedido() {
    try {
        const { data } = yield call(ConfigApi.startFunctionAtualizarBaseDadosPedido);
        yield put(ConfigActions.startJobAtualizarBaseDadosPedidoSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* getStatusFunctionAtualizarBaseDadosPedido() {
    try {
        const { data } = yield call(ConfigApi.getStatusFunctionAtualizarBaseDadosPedido);
        yield put(ConfigActions.getStatusJobAtualizarBaseDadosPedidoSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* disableFunctionAtualizacaoBanco({ payload }: DisableFunctionAction) {
    try {
        yield call(ConfigApi.disableFunctionAtualizacaoBanco, payload);
        yield put(ConfigActions.disableFunctionSucess());
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}

export function* enableFunctionAtualizacaoBanco({ payload }: EnableFunctionAction) {
    try {
        yield call(ConfigApi.enableFunctionAtualizacaoBanco, payload);
        yield put(ConfigActions.enableAllFunctionsSucess());
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        yield put(ConfigActions.defaultConfiguracoesFailure(error));
    }
}
