import * as Yup from 'yup';

const schema = Yup.object().shape({
    clienteId: Yup.string().required('Selecione uma opção'),
    industriaId: Yup.string().required('Selecione uma opção'),
    tabelaPrecoId: Yup.string().nullable().required('Selecione uma opção'),
    condicaoPagamento: Yup.string().nullable().required('Selecione uma opção'),
    transportadoraId: Yup.string().nullable().required('Selecione uma opção'),
    vendedorId: Yup.string().nullable().required('Selecione uma opção'),
    statusId: Yup.string().nullable().required('Selecione uma opção'),
    freteId: Yup.string().nullable().required('Selecione uma opção'),
});

export default schema;