import React, { memo, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { AuthUtils } from '../utils/AuthUtils';
import { useDispatch } from 'react-redux';
import { INotification } from '../data/interfaces/notification/INotification';
import { NotificationActions } from '../store/notification/Notification.actions';
import { ITicketMessage } from '../data/interfaces/ticket/ITicket';
import { TicketActions } from '../store/ticket/Ticket.actions';
import { IUserResponse } from '../data/interfaces/user/IUserResponse';

const NotificationSignalR = () => {
    const [connection, setConnection] = useState<HubConnection>();
    var user = AuthUtils.getLoggedUser();
    var dispatch = useDispatch();

    useEffect(() => {

        if (connection) {
            connection.stop();
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(
                `${process.env.REACT_APP_BASE_API_URL?.substring(0, process.env.REACT_APP_BASE_API_URL?.length - 4)}/hubs/notification`,
                { accessTokenFactory: () => user.accessToken ?? "" }
            )
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        newConnection.serverTimeoutInMilliseconds = 100000;
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    connection.on('newNotification', (message) => {
                        var result = JSON.parse(message);

                        var notification = {
                            notificationId: result.NotificationId,
                            title: result.Title,
                            dataHoraCriacao: result.DataHoraCriacao,
                            userFk: result.UserFk,
                            hasRead: result.hasRead,
                            hasView: false,
                            link: result.Link
                        } as INotification;

                        dispatch(NotificationActions.addNotificationSuccess(notification));
                    });

                    connection.on('sendNewMessage', (message) => {
                        var result = JSON.parse(message);

                        var ticketMessage = {
                            ticketMessageId: result.TicketMessageId,
                            message: result.Message,
                            ticketFk: result.TicketFk,
                            userFk: result.UserFk,
                            dataHoraEnvio: result.DataHoraEnvio,
                            isEdited: false,
                            user: {
                                avatarFileName: result.User.AvatarFileName,
                                name: result.User.Name,
                                id: result.User.Id,
                                idSuasVendas: result.User.IdSuasVendas
                            } as IUserResponse,
                        } as ITicketMessage;

                        dispatch(TicketActions.sendNewMessageSuccess(ticketMessage));

                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return <></>;
};

export default memo(NotificationSignalR);