import { Skeleton, Table } from "antd";
import React, { FC, useEffect } from "react";
import PageContent from '../../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./CamposIntegracao.helper";
import styles from './CamposIntegracao.module.scss';
import { ConfigActions } from "../../../../store/config/Config.actions";

export interface ICamposIntegracaoAux {
    match: any;
}

const CamposIntegracao: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ConfigActions.listCamposIntegracao(props.match.params.tabela));
    }, [props.match.params.tabela]);

    return (
        <>
            <PageContent title={`Campos Integração - ${props.isLoading ? '' : props.infoTabelaIntegracao.tabela}`} className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <Table columns={getColumns()} dataSource={props.infoTabelaIntegracao.campos} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.config,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ICamposIntegracaoAux;

export default connector(CamposIntegracao);