import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IPedido } from "./interfaces/pedido/IPedido";
import { IUltimoPedidoRequest } from "./interfaces/pedido/IUltimoPedidoRequest";
import { IClienteIndustriaResponse } from "./interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "./interfaces/svData/IClienteResponse";
import { IFormaPagamentoResponse } from "./interfaces/svData/IFormaPagamentoResponse";
import { IIndustriaResponse } from "./interfaces/svData/IIndustriaResponse";
import { IPedidoStatusResponse } from "./interfaces/svData/IPedidoStatusResponse";
import { IUsuarioVendedor } from "./interfaces/svData/IUsuarioVendedor";

export class SVDBApi {
    static async listFormasPagamento(): Promise<AxiosResponse<IFormaPagamentoResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svdb/listFormasPagamento');
    }

    static async listPedidoStatus(): Promise<AxiosResponse<IPedidoStatusResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svdb/listPedidoStatus');
    }

    static async getUltimoPedidoByClienteIdIndustriaId(request: IUltimoPedidoRequest): Promise<AxiosResponse<IPedido>> {
        const axios = await Http.axios();
        return axios.get(`/svdb/getUltimoPedidoByClienteIdIndustriaId?clienteId=${request.clienteId}&industriaId=${request.industriaId}`);
    }

    static async listClienteIndustria(): Promise<AxiosResponse<IClienteIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svdb/listFormasPagamento');
    }

    static async listUsuariosVendedores(): Promise<AxiosResponse<IUsuarioVendedor[]>> {
        const axios = await Http.axios();
        return axios.get('/svdb/listUsuariosVendedores');
    }

    static async listIndustrias(): Promise<AxiosResponse<IIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svdb/listIndustrias');
    }
}