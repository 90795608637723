import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { ClienteApi } from '../../data/Cliente.api';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { ClienteActions, DeleteCategoriaClienteAction, GetClienteIndustriaAction, ImportarClienteIndustriaAction, ListClienteIndustriaAuditoriaAction, ListCompradoresClienteIndustriaAction, PersistCategoriaClienteAction, UpdateClienteIndustriaAuxAction } from './Cliente.actions';

export function* listClientes() {
  try {
    const { data } = yield call(ClienteApi.listClientes);
    yield put(ClienteActions.listClientesSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* listClientesIndustrias() {
  try {
    const { data } = yield call(ClienteApi.listClientesIndustrias);
    yield put(ClienteActions.listClientesIndustriasSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* getClienteIndustriaAuxFormById({ payload }: GetClienteIndustriaAction) {
  try {
    const { data } = yield call(ClienteApi.getClienteIndustriaAuxFormById, payload);
    yield put(ClienteActions.getClienteIndustriaSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* updateClienteIndustriaAux({ payload }: UpdateClienteIndustriaAuxAction) {
  try {
    const { data } = yield call(ClienteApi.updateClienteIndustriaAux, payload);
    yield put(ClienteActions.updateClienteIndustriaAuxSuccess(data.data));

    toast.success('Dados salvos com sucesso!');
  } catch (e) {

    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}


export function* getClienteIndustriaAuxViewById({ payload }: GetClienteIndustriaAction) {
  try {
    const { data } = yield call(ClienteApi.getClienteIndustriaAuxViewById, payload);
    yield put(ClienteActions.getClienteIndustriaViewSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* listCategoriasCliente() {
  try {
    const { data } = yield call(ClienteApi.listCategoriasClient);
    yield put(ClienteActions.listCategoriasClienteSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* persistCategoriaCliente({ payload }: PersistCategoriaClienteAction) {
  try {
    const { data } = payload?.categoriaClienteAuxId > 0 ? yield call(ClienteApi.updateCategoria, payload) : yield call(ClienteApi.createCategoria, payload);
    yield put(ClienteActions.persistCategoriaClienteSuccess(data.data));
    toast.success(`Categoria ${payload?.categoriaClienteAuxId > 0 ? 'atualizada' : 'cadastrada'} com sucesso!`);
  } catch (e) {
    const error = e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* deleteCategoriaCliente({ payload }: DeleteCategoriaClienteAction) {
  try {
    yield call(ClienteApi.deleteCategoria, payload);
    yield put(ClienteActions.deleteCategoriaClienteSuccess(payload));
    toast.success(`Categoria removida com sucesso!`);
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* listCompradoresByClienteIndustriaId({ payload }: ListCompradoresClienteIndustriaAction) {
  try {
    const { data } = yield call(ClienteApi.listCompradoresByClienteIndustriaId, payload);
    yield put(ClienteActions.listCompradoresClienteIndustriaSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}

export function* importarClienteIndustria({ payload }: ImportarClienteIndustriaAction) {
  try {
    yield call(ClienteApi.importarClienteIndustria, payload);
    yield put(ClienteActions.importarClienteIndustriaSuccess());

    const { data } = yield call(ClienteApi.listClientesIndustrias);
    yield put(ClienteActions.listClientesIndustriasSuccess(data.data));

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação do pedido está inválida!');
      yield put(ClienteActions.importarClienteIndustriaInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(ClienteActions.importarClienteIndustriaFailure(error));
    }
  }
}

export function* listClienteIndustriaAuxAuditoria({ payload }: ListClienteIndustriaAuditoriaAction) {
  try {
    const { data } = yield call(ClienteApi.listClienteIndustriaAuxAuditoria, payload);
    yield put(ClienteActions.listClienteIndustriaAuxAuditoriaSuccess(data.data));
  } catch (e) {
    const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ClienteActions.defaultClienteFailure(error));
  }
}
