import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { PedidoImportadoActions } from "../../../store/pedido/importador/PedidoImportado.actions";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import PageContent from "../../../components/ui/pageContent";
import { Button, Modal, Skeleton, Table, Tabs, TabsProps } from "antd";

import styles from './PedidosImportados.module.scss';
import { getColumns } from "./PedidosImportados.helper";
import { TablePaginationConfig } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import ImportadorPedidoV2 from './modals/importadorV2';
import ImportadorPedido from './modals/importador';
import { ConfigActions } from "../../../store/config/Config.actions";
import { isEmpty as isObjectEmpty } from "../../../utils/helpers";
import { AuthUtils, setVisibleAccessAdmin, setVisibleAccessCoordenador } from "../../../utils/AuthUtils";
import { IPedidoImportadoResponse } from "../../../data/interfaces/pedido/importador/IPedidoImportadoResponse";
import { sumFromArray } from "../../../utils/ObjectUtils";
import { IModuleColumnUser } from "../../../data/interfaces/module/IModuleColumnUser";
import { MODULE_PEDIDOS_IMPORTADOR } from "../../../static/constants/Module.constants";
import ModalColumnsManager from "../../common/modals/listColumnManager";
import { ModuleActions } from "../../../store/module/Module.actions";

interface IPedidosImportados {
    match: any;
}

const PedidosImportados: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalImportador, setIsVisibleModalImportador] = useState(false);
    const [isVisibleModalImportadorV2, setIsVisibleModalImportadorV2] = useState(false);
    const [isNovoPedido, setIsNovoPedido] = useState(false);
    const [qtdeRows, setQtdeRows] = useState(0);
    const [qtdeItens, setQtdeItens] = useState(0);
    const [qtdePecas, setQtdePecas] = useState(0);
    const [showColumnManager, setShowColumnManager] = useState(false);
    const [showAnalise, setShowAnalise] = useState(false);
    var columns: IModuleColumnUser[] = props.modules?.filter(x => x.moduleId == MODULE_PEDIDOS_IMPORTADOR)[0]?.columns;

    var user = AuthUtils.getLoggedUser();
    var emailsLiberadosImportadorV2 = ['ricardo@rbrltda.com.br', 'coordenador01@rbrltda.com.br', 'suporte01@rbrltda.com.br', 'comercial01@rbrltda.com.br', 'suporte02@rbrltda.com.br'];
    const [isVisibleButtonImportadorV2] = useState(process.env.REACT_APP_AMBIENTE != 'PRODUCTION' ? true : (emailsLiberadosImportadorV2.includes(user?.email ?? '')));

    useEffect(() => {
        if (isObjectEmpty(props.config)) {
            dispatch(ConfigActions.getConfiguracoes());
        }
    }, [])

    useEffect(() => {
        if (!props.pedidosImportados || props.pedidosImportados?.length == 0) {
            dispatch(PedidoImportadoActions.list(false));
        }

        setQtdeRows(props.pedidosImportados?.length);
        setQtdeItens(sumFromArray('pediQtdeItens', props.pedidosImportados));
        setQtdePecas(sumFromArray('pediQtdePecas', props.pedidosImportados));
    }, [props.pedidosImportados?.length]);

    useEffect(() => {
        if (!props.isLoadedDataImportadorPedido) {
            dispatch(PedidoImportadoActions.getDataImportadorPedido());
        }
    }, []);

    const openModalImportador = (isNovoPedido: boolean) => {
        setIsNovoPedido(isNovoPedido);
        setIsVisibleModalImportador(true);
    }

    const openModalImportadorV2 = (isNovoPedido: boolean) => {
        setIsNovoPedido(isNovoPedido);
        setIsVisibleModalImportadorV2(true);
    }

    const onFilterTable = (data: IPedidoImportadoResponse[]) => {
        setQtdeItens(sumFromArray('pediQtdeItens', data));
        setQtdePecas(sumFromArray('pediQtdePecas', data));
    }

    const tablePaginationConfig = {
        pageSize: 500,
        showSizeChanger: false,
        showTotal: (total) => {
            setQtdeRows(total);
        }
    } as TablePaginationConfig;

    const loadAllData = () => {
        dispatch(PedidoImportadoActions.list(true));
    }

    const handleSaveColumns = (columns: IModuleColumnUser[]) => {
        var modules = [...props.modules];
        var index = modules.findIndex(x => x.moduleId == MODULE_PEDIDOS_IMPORTADOR);
        modules[index].columns = columns;

        dispatch(ModuleActions.listModulesSuccess(modules));
        setShowColumnManager(false);
    }

    const analisarPedido = (pedidoNumero: number) => {
        dispatch(PedidoImportadoActions.clearAnalises());

        setTimeout(() => {
            dispatch(PedidoImportadoActions.analiseGeral(pedidoNumero));
            dispatch(PedidoImportadoActions.analiseCliente(pedidoNumero));
        }, 200);

        setShowAnalise(true);
    }

    const localeTable = {
        emptyText: 'Nenhum dado encontrado',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    //Move to file
    const columnsAnalise = [
        {
            title: 'Produto',
            dataIndex: 'produtoCodigo',
            key: 'produtoCodigo',
        },
        {
            title: 'Descrição',
            dataIndex: 'produtoDescricao',
            key: 'produtoDescricao',
        },
        {
            title: 'Anterior',
            dataIndex: 'curvaABCValorSemestreAnteriorValue',
            key: 'curvaABCValorSemestreAnteriorValue',
        },
        {
            title: 'Atual',
            dataIndex: 'curvaABCValorSemestreAtualValue',
            key: 'curvaABCValorSemestreAtualValue',
        },
    ];

    const analiseGeralItems = <>
        {
            props.isLoadingAnaliseGeral ?
                <Skeleton active />
                :
                <Table locale={localeTable} dataSource={props.analiseGeral} columns={columnsAnalise} pagination={tablePaginationConfig} />
        }
    </>;

    const analiseClienteItems = <>
        {
            props.isLoadingAnaliseCliente ?
                <Skeleton active />
                :
                <Table locale={localeTable} dataSource={props.analiseCliente} columns={columnsAnalise} pagination={tablePaginationConfig} />
        }
    </>;

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Análise Geral',
            children: analiseGeralItems,
        },
        {
            key: '2',
            label: 'Análise Cliente',
            children: analiseClienteItems
        }
    ];

    const buttons =
        [
            <Button key="back" onClick={() => setShowAnalise(false)}>Fechar</Button>,
        ];

    return (<>
        <PageContent title='Pedidos Importados' className={styles['container']}>
            {props.isLoadingPedidosImportados ?
                <Skeleton active />
                :
                <>
                    <div className={styles['toolBar']}>
                        <Button onClick={() => openModalImportador(true)} type='primary'>Importar Pedido Novo</Button>
                        <Button style={{ ...setVisibleAccessAdmin }} onClick={() => openModalImportador(false)} danger type='primary'>Importar Pedido Existente</Button>
                        <Button style={{ ...setVisibleAccessCoordenador }} className='btn-green' onClick={() => window.location.href = process.env.REACT_APP_BASE_API_URL + '/PedidoImportado/ExportarExcel'} type='primary'>Exportar Excel</Button>
                        <Button hidden={!isVisibleButtonImportadorV2} onClick={() => openModalImportadorV2(true)} type='primary'>Importar Pedido Novo V2 - Em Teste</Button>
                        <Button type='link' onClick={loadAllData} >Carregar Todo Período</Button>
                        <Button type='link' onClick={() => setShowColumnManager(!showColumnManager)}>Personalizar Colunas</Button>
                    </div>
                    <div className={styles['qtdeRows']}>
                        {qtdeRows} registros
                    </div>
                    <Table
                        className={styles['tablePedidos']}
                        locale={localeTable}
                        columns={getColumns(columns, props.pedidosImportados, qtdeItens, qtdePecas, analisarPedido)}
                        dataSource={props.pedidosImportados}
                        style={{ overflowY: 'auto' }}
                        pagination={tablePaginationConfig}
                        onChange={(p, f, s, extra) => onFilterTable(extra.currentDataSource)} />
                </>
            }
        </PageContent>
        {isVisibleModalImportador
            ? <ImportadorPedido isNovoPedido={isNovoPedido} visible={isVisibleModalImportador} onClose={() => setIsVisibleModalImportador(false)}></ImportadorPedido>
            : null}
        {isVisibleModalImportadorV2
            ? <ImportadorPedidoV2 isNovoPedido={isNovoPedido} visible={isVisibleModalImportadorV2} onClose={() => setIsVisibleModalImportadorV2(false)}></ImportadorPedidoV2>
            : null}

        {showColumnManager ?
            <ModalColumnsManager moduleId={MODULE_PEDIDOS_IMPORTADOR} columns={columns} visible={showColumnManager} onClose={() => setShowColumnManager(false)} onSave={handleSaveColumns} />
            : null}

        {showAnalise ?
            <Modal title={'Análise Pedido Curva ABC'} open={showAnalise} onCancel={() => setShowAnalise(false)} width={800} footer={buttons}>
                <Tabs defaultActiveKey="1" items={items} />
            </Modal>
            : null}
    </>);
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.pedidoImportado,
    ...state.svApi,
    ...state.config,
    ...state.module
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPedidosImportados;

export default connector(PedidosImportados);