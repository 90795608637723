import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button } from "antd";

import styles from './CategoriaClienteForm.module.scss';
import Form from "../../../../components/ui/form";
import FieldForm from "../../../../components/ui/field";
import schema from "./CategoriaClienteForm.schema";
import { ICategoriaCliente } from "../../../../data/interfaces/cliente/ICategoriaCliente";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";

export interface ICreateCategoriaClienteModal {
    defaultValues: ICategoriaCliente;
    visible: boolean;
    onClose: () => void;
}

const CategoriaClienteForm: FC<Props> = ({ defaultValues, visible, onClose, isLoading }) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const isNew = defaultValues.categoriaClienteAuxId === undefined;
    const titleModal = isNew ? 'Cadastrar Categoria' : 'Atualizar Categoria';

    const submit = (values: ICategoriaCliente) => {
        if (values) {
            dispatch(ClienteActions.persistCategoriaCliente(values));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    if (!isLoading && isSendingData) {
        setIsSendingData(false);
        onClose();
    }

    const buttons =
        [
            <Button key="back" onClick={onClose}>Cancelar</Button>,
            <Button key="submit" type="primary" loading={isLoading} htmlType='submit' onClick={() => setIsSubmit(true)}>{isNew ? 'Cadastrar' : 'Salvar'}</Button>,
        ];

    return (
        <Form onSubmit={submit} schema={schema} initialValues={defaultValues} isSubmited={isSubmit}>
            <Modal title={titleModal} open={visible} footer={buttons} onCancel={onClose}>
                <div className={styles['container']}>
                    <FieldForm type='hidden' name='id'></FieldForm>
                    <FieldForm autoComplete='false' key='nome' label='Nome' name='nome' className={styles['inputForm']}></FieldForm>
                </div>
            </Modal>
        </Form>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ICreateCategoriaClienteModal;

export default connector(CategoriaClienteForm);