import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";
import { ICombinacao, INewCombinacaoRequest } from "../../data/interfaces/projecaoVenda/ICombinacao";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IModeloCombinacao, INewModeloRequest } from "../../data/interfaces/projecaoVenda/IModeloCombinacao";

export enum ProjecaoVendaActionKeys {
  LIST_COMBINACOES_REQUEST = 'LIST_COMBINACOES_REQUEST',
  LIST_COMBINACOES_SUCCESS = 'LIST_COMBINACOES_SUCCESS',

  GET_COMBINACAO_REQUEST = 'GET_COMBINACAO_REQUEST',
  GET_COMBINACAO_SUCCESS = 'GET_COMBINACAO_SUCCESS',

  IMPORTAR_COMBINACAO_REQUEST = 'IMPORTAR_COMBINACAO_REQUEST',
  IMPORTAR_COMBINACAO_SUCCESS = 'IMPORTAR_COMBINACAO_SUCCESS',
  IMPORTAR_COMBINACAO_FAILED = 'IMPORTAR_COMBINACAO_FAILED',
  IMPORTAR_COMBINACAO_INVALID = 'IMPORTAR_COMBINACAO_INVALID',
  IMPORTAR_COMBINACAO_CLEAR = 'IMPORTAR_COMBINACAO_CLEAR',

  UPDATE_COMBINACAO_REQUEST = 'UPDATE_COMBINACAO_REQUEST',
  UPDATE_COMBINACAO_SUCCESS = 'UPDATE_COMBINACAO_SUCCESS',

  LIST_MODELOS_REQUEST = 'LIST_MODELOS_REQUEST',
  LIST_MODELOS_SUCCESS = 'LIST_MODELOS_SUCCESS',

  GET_MODELO_REQUEST = 'GET_MODELO_REQUEST',
  GET_MODELO_SUCCESS = 'GET_MODELO_SUCCESS',

  UPDATE_MODELO_REQUEST = 'UPDATE_MODELO_REQUEST',
  UPDATE_MODELO_SUCCESS = 'UPDATE_MODELO_SUCCESS',

  COMBINACAO_DELETE_REQUEST = 'COMBINACAO_DELETE_REQUEST',
  COMBINACAO_DELETE_SUCCESS = 'COMBINACAO_DELETE_SUCCESS',

  IMPORTAR_MODELO_REQUEST = 'IMPORTAR_MODELO_REQUEST',
  IMPORTAR_MODELO_SUCCESS = 'IMPORTAR_MODELO_SUCCESS',
  IMPORTAR_MODELO_FAILED = 'IMPORTAR_MODELO_FAILED',
  IMPORTAR_MODELO_INVALID = 'IMPORTAR_MODELO_INVALID',
  IMPORTAR_MODELO_CLEAR = 'IMPORTAR_MODELO_CLEAR',

  MODELO_DELETE_REQUEST = 'MODELO_DELETE_REQUEST',
  MODELO_DELETE_SUCCESS = 'MODELO_DELETE_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED'
}

export const ProjecaoVendaActions = {
  listCombinacoes: (): ListCombinacoesAction => createAction(ProjecaoVendaActionKeys.LIST_COMBINACOES_REQUEST),
  listCombinacoesSuccess: (response: ICombinacao[]): ListCombinacoesSuccessAction => createAction(ProjecaoVendaActionKeys.LIST_COMBINACOES_SUCCESS, response),

  getCombinacao: (request: number): GetCombinacaoAction => createAction(ProjecaoVendaActionKeys.GET_COMBINACAO_REQUEST, request),
  getCombinacaoSuccess: (response: ICombinacao): GetCombinacaoSuccessAction => createAction(ProjecaoVendaActionKeys.GET_COMBINACAO_SUCCESS, response),

  updateCombinacao: (request: ICombinacao): UpdateCombinacaoAction => createAction(ProjecaoVendaActionKeys.UPDATE_COMBINACAO_REQUEST, request),
  updateCombinacaoSuccess: (): UpdateCombinacaoSuccessAction => createAction(ProjecaoVendaActionKeys.UPDATE_COMBINACAO_SUCCESS),

  importarCombinacao: (request: INewCombinacaoRequest): ImportarCombinacaoAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_REQUEST, request),
  importarCombinacaoSuccess: (response: ICombinacao[]): ImportarCombinacaoSuccessAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_SUCCESS, response),
  importarCombinacaoFailure: (err: string): ImportarCombinacaoFailureAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_FAILED, err),
  importarCombinacaoInvalid: (err: IErroResponse[]): ImportarCombinacaoInvalidAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_INVALID, err),
  importarCombinacaoClear: (): ImportarCombinacaoClearAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_CLEAR),

  listModelos: (): ListModelosAction => createAction(ProjecaoVendaActionKeys.LIST_MODELOS_REQUEST),
  listModelosSuccess: (response: IModeloCombinacao[]): ListModelosSuccessAction => createAction(ProjecaoVendaActionKeys.LIST_MODELOS_SUCCESS, response),

  getModelo: (request: number): GetModeloAction => createAction(ProjecaoVendaActionKeys.GET_MODELO_REQUEST, request),
  getModeloSuccess: (response: IModeloCombinacao): GetModeloSuccessAction => createAction(ProjecaoVendaActionKeys.GET_MODELO_SUCCESS, response),

  updateModelo: (request: IModeloCombinacao): UpdateModeloAction => createAction(ProjecaoVendaActionKeys.UPDATE_MODELO_REQUEST, request),
  updateModeloSuccess: (): UpdateModeloSuccessAction => createAction(ProjecaoVendaActionKeys.UPDATE_MODELO_SUCCESS),

  deleteCombinacao: (request: number): CombinacaoDeleteAction => createAction(ProjecaoVendaActionKeys.COMBINACAO_DELETE_REQUEST, request),
  deleteCombinacaoSuccess: (response: number): CombinacaoDeleteSuccessAction => createAction(ProjecaoVendaActionKeys.COMBINACAO_DELETE_SUCCESS, response),

  importarModelo: (request: INewModeloRequest): ImportarModeloAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_MODELO_REQUEST, request),
  importarModeloSuccess: (response: IModeloCombinacao[]): ImportarModeloSuccessAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_MODELO_SUCCESS, response),
  importarModeloFailure: (err: string): ImportarModeloFailureAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_MODELO_FAILED, err),
  importarModeloInvalid: (err: IErroResponse[]): ImportarModeloInvalidAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_MODELO_INVALID, err),
  importarModeloClear: (): ImportarModeloClearAction => createAction(ProjecaoVendaActionKeys.IMPORTAR_MODELO_CLEAR),

  deleteModelo: (request: number): ModeloDeleteAction => createAction(ProjecaoVendaActionKeys.MODELO_DELETE_REQUEST, request),
  deleteModeloSuccess: (response: number): ModeloDeleteSuccessAction => createAction(ProjecaoVendaActionKeys.MODELO_DELETE_SUCCESS, response),

  defaultFailure: (err: string): DefaultFailureAction => createAction(ProjecaoVendaActionKeys.DEFAULT_FAILED, err),
};

export type ProjecaoVendaActionUnion = ActionsUnion<typeof ProjecaoVendaActions>;

export type ListCombinacoesAction = Action<ProjecaoVendaActionKeys.LIST_COMBINACOES_REQUEST>;
export type ListCombinacoesSuccessAction = Action<ProjecaoVendaActionKeys.LIST_COMBINACOES_SUCCESS, ICombinacao[]>;

export type GetCombinacaoAction = Action<ProjecaoVendaActionKeys.GET_COMBINACAO_REQUEST, number>;
export type GetCombinacaoSuccessAction = Action<ProjecaoVendaActionKeys.GET_COMBINACAO_SUCCESS, ICombinacao>;

export type UpdateCombinacaoAction = Action<ProjecaoVendaActionKeys.UPDATE_COMBINACAO_REQUEST, ICombinacao>;
export type UpdateCombinacaoSuccessAction = Action<ProjecaoVendaActionKeys.UPDATE_COMBINACAO_SUCCESS>;

export type ImportarCombinacaoAction = Action<ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_REQUEST, INewCombinacaoRequest>;
export type ImportarCombinacaoSuccessAction = Action<ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_SUCCESS, ICombinacao[]>;
export type ImportarCombinacaoFailureAction = Action<ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_FAILED, string>;
export type ImportarCombinacaoInvalidAction = Action<ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_INVALID, IErroResponse[]>;
export type ImportarCombinacaoClearAction = Action<ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_CLEAR>;

export type ListModelosAction = Action<ProjecaoVendaActionKeys.LIST_MODELOS_REQUEST>;
export type ListModelosSuccessAction = Action<ProjecaoVendaActionKeys.LIST_MODELOS_SUCCESS, IModeloCombinacao[]>;

export type GetModeloAction = Action<ProjecaoVendaActionKeys.GET_MODELO_REQUEST, number>;
export type GetModeloSuccessAction = Action<ProjecaoVendaActionKeys.GET_MODELO_SUCCESS, IModeloCombinacao>;

export type UpdateModeloAction = Action<ProjecaoVendaActionKeys.UPDATE_MODELO_REQUEST, IModeloCombinacao>;
export type UpdateModeloSuccessAction = Action<ProjecaoVendaActionKeys.UPDATE_MODELO_SUCCESS>;

export type CombinacaoDeleteAction = Action<ProjecaoVendaActionKeys.COMBINACAO_DELETE_REQUEST, number>;
export type CombinacaoDeleteSuccessAction = Action<ProjecaoVendaActionKeys.COMBINACAO_DELETE_SUCCESS, number>;

export type ImportarModeloAction = Action<ProjecaoVendaActionKeys.IMPORTAR_MODELO_REQUEST, INewModeloRequest>;
export type ImportarModeloSuccessAction = Action<ProjecaoVendaActionKeys.IMPORTAR_MODELO_SUCCESS, IModeloCombinacao[]>;
export type ImportarModeloFailureAction = Action<ProjecaoVendaActionKeys.IMPORTAR_MODELO_FAILED, string>;
export type ImportarModeloInvalidAction = Action<ProjecaoVendaActionKeys.IMPORTAR_MODELO_INVALID, IErroResponse[]>;
export type ImportarModeloClearAction = Action<ProjecaoVendaActionKeys.IMPORTAR_MODELO_CLEAR>;

export type ModeloDeleteAction = Action<ProjecaoVendaActionKeys.MODELO_DELETE_REQUEST, number>;
export type ModeloDeleteSuccessAction = Action<ProjecaoVendaActionKeys.MODELO_DELETE_SUCCESS, number>;

export type DefaultFailureAction = Action<ProjecaoVendaActionKeys.DEFAULT_FAILED, string>;