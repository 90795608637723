import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { INotification } from "./interfaces/notification/INotification";

export class NotificationApi {
    static async listByUser(): Promise<AxiosResponse<INotification[]>> {
        const axios = await Http.axios();
        return axios.get(`/notification/listByUser`);
    }

    static async readAllNotifications() {
        const axios = await Http.axios();
        return axios.put(`/notification/readAllNotifications`);
    }
}