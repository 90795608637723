import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IAtualizarPedidoResponse } from "./interfaces/atualizarPedido/IAtualizarPedidoResponse";
import { IAtualizarPedidoItemResponse } from "./interfaces/atualizarPedido/IAtualizarPedidoItemResponse";
import { IAtualizarPedidoInfosResponse } from "./interfaces/atualizarPedido/IAtualizarPedidoInfosResponse";
import { IRestaurarItemPedido } from "./interfaces/atualizarPedido/IRestaurarItemPedido";
import { IAtualizarItensPedidoRequest } from "./interfaces/atualizarPedido/IAtualizarItensPedidoRequest";

export class AtualizarPedidoApi {
    static async list(): Promise<AxiosResponse<IAtualizarPedidoResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarPedidos/list`);
    }

    static async getAtualizarPedidoById(atualizarPedidoId: number): Promise<AxiosResponse<IAtualizarPedidoResponse>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarPedidos/getAtualizarPedidoById/${atualizarPedidoId}`);
    }

    static async atualizarItensPedido(atualizarItensPedido: IAtualizarItensPedidoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', atualizarItensPedido.file);
        formData.append('industriaId', atualizarItensPedido.industriaId.toString());

        return axios.post('/atualizarPedidos/atualizarItensPedido', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async listByItems(): Promise<AxiosResponse<IAtualizarPedidoItemResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarPedidos/listByItems`);
    }

    static async detailByAtualizacaoId(atualizacaoPedidoId: number): Promise<AxiosResponse<IAtualizarPedidoInfosResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarPedidos/detailByAtualizacaoId/${atualizacaoPedidoId}`);
    }

    static async restaurarPedido(atualizacaoPedidoInfoId: number): Promise<AxiosResponse<IAtualizarPedidoInfosResponse>> {
        const axios = await Http.axios();
        return axios.post(`/atualizarPedidos/restaurarPedido/${atualizacaoPedidoInfoId}`);
    }

    static async restaurarItemPedido(request: IRestaurarItemPedido): Promise<AxiosResponse<IAtualizarPedidoInfosResponse>> {
        const axios = await Http.axios();
        return axios.post(`/atualizarPedidos/restaurarItemAtualizacaoPedidos/${request.produtoIdAntigo}/${request.atualizacaoPedidoInfoId}`);
    }
}