import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AtualizarPedidoActions } from "../../../store/atualizarPedido/AtualizarPedido.actions";
import PageContent from "../../../components/ui/pageContent";

import styles from './DetalharAtualizacao.module.scss';
import { Button, Modal, Skeleton, Table } from "antd";
import { getColumns } from "./DetalharAtualizacao.helper";
import Confirm from "../../../components/ui/confirm";

export interface IDetalharAtualizacao {
    match: any;
}

const DetalharAtualizacao: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isModalDetalhesVisible, setIsModalDetalhesVisible] = useState(false);
    const [dataModalDetalhes, setDataModalDetalhes] = useState<any>();

    useEffect(() => {
        dispatch(AtualizarPedidoActions.detailAtualizacaoPedidos(props.match.params.atualizacaoId));
    }, []);

    const onVisualizarDetalhe = (pedidoId: number) => {
        var detalhes = JSON.parse(props.atualizarPedidoInfos.find(x => x.pedidoId == pedidoId)?.atualizacaoInfo ?? "");

        if (detalhes != "" && detalhes.houveAlteracao) {

            setDataModalDetalhes(detalhes.itensAlterados);
            setIsModalDetalhesVisible(true);
        }
    }

    const onRecuperarPedido = (atualizarPedidoInfoId: number) => {
        Confirm('Deseja restaurar o Pedido?', 'Atenção: Essa ação não poderá ser desfeita.', () => dispatch(AtualizarPedidoActions.restaurarPedido(atualizarPedidoInfoId)));
    }

    const modalDetalhes =
        <Modal title="Detalhes da Atualização" footer={null} open={isModalDetalhesVisible} onCancel={() => setIsModalDetalhesVisible(false)}>
            <table>
                <thead>
                    <th style={{ width: 200 }}><b>Código Antigo</b></th>
                    <th><b>Código Novo</b></th>
                </thead>
                <tbody>
                    {
                        dataModalDetalhes?.map((x: any, i: number) => (
                            <tr key={i}>
                                <td>{x.CodigoAntigo}</td>
                                <td>{x.CodigoNovo}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </Modal>

    return (
        <>
            <PageContent title={`Detalhes Atualização de Pedidos - ${!props.isLoading ? props.atualizarPedidoInfos[0]?.industriaNomeFantasia : '...'}`} className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='link' onClick={() => window.history.back()}>Voltar</Button>
                        </div>
                        <Table
                            columns={getColumns(onVisualizarDetalhe, onRecuperarPedido)}
                            dataSource={props.atualizarPedidoInfos}
                            style={{ overflowY: 'auto' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }} />
                    </>
                }
            </PageContent>
            {modalDetalhes}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.atualizarPedido
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IDetalharAtualizacao;


export default connector(DetalharAtualizacao);