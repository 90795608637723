import React from "react";
import { ColumnProps } from "antd/lib/table/Column";
import { IMetaBonus, IMetaBonusCoordenador, IMetaCliente } from "../../../data/interfaces/meta/IMeta";
import { getDistinctFilterValues, sorter } from "../../../utils/helpers";
import InputNumber from "../../../components/ui/inputNumber";
import { isNullOrEmpty } from "../../../utils/stringHelper";
import { toFixed } from "../../../utils/numberHelper";

interface IColunasMeta {
    nome: string,
    keyValor: string,
    keyQtde: string,
    keyBonusValor: string,
    keyBonusQtde: string,
    keyBonusCoordenador: string
}

var colunasMeta = [
    { nome: 'Janeiro', keyValor: 'janValor', keyQtde: 'janQtde', keyBonusValor: 'janValor', keyBonusQtde: 'janQtde', keyBonusCoordenador: 'jan' },
    { nome: 'Fevereiro', keyValor: 'fevValor', keyQtde: 'fevQtde', keyBonusValor: 'fevValor', keyBonusQtde: 'fevQtde', keyBonusCoordenador: 'fev' },
    { nome: 'Março', keyValor: 'marValor', keyQtde: 'marQtde', keyBonusValor: 'marValor', keyBonusQtde: 'marQtde', keyBonusCoordenador: 'mar' },
    { nome: 'Abril', keyValor: 'abrValor', keyQtde: 'abrQtde', keyBonusValor: 'abrValor', keyBonusQtde: 'abrQtde', keyBonusCoordenador: 'abr' },
    { nome: 'Maio', keyValor: 'maiValor', keyQtde: 'maiQtde', keyBonusValor: 'maiValor', keyBonusQtde: 'maiQtde', keyBonusCoordenador: 'mai' },
    { nome: 'Junho', keyValor: 'junValor', keyQtde: 'junQtde', keyBonusValor: 'junValor', keyBonusQtde: 'junQtde', keyBonusCoordenador: 'jun' },
    { nome: 'Julho', keyValor: 'julValor', keyQtde: 'julQtde', keyBonusValor: 'julValor', keyBonusQtde: 'julQtde', keyBonusCoordenador: 'jul' },
    { nome: 'Agosto', keyValor: 'agoValor', keyQtde: 'agoQtde', keyBonusValor: 'agoValor', keyBonusQtde: 'agoQtde', keyBonusCoordenador: 'ago' },
    { nome: 'Setembro', keyValor: 'setValor', keyQtde: 'setQtde', keyBonusValor: 'setValor', keyBonusQtde: 'setQtde', keyBonusCoordenador: 'set' },
    { nome: 'Outubro', keyValor: 'outValor', keyQtde: 'outQtde', keyBonusValor: 'outValor', keyBonusQtde: 'outQtde', keyBonusCoordenador: 'out' },
    { nome: 'Novembro', keyValor: 'novValor', keyQtde: 'novQtde', keyBonusValor: 'novValor', keyBonusQtde: 'novQtde', keyBonusCoordenador: 'nov' },
    { nome: 'Dezembro', keyValor: 'dezValor', keyQtde: 'dezQtde', keyBonusValor: 'dezValor', keyBonusQtde: 'dezQtde', keyBonusCoordenador: 'dez' },
] as IColunasMeta[];

export const getColumnsBonusCoordenador = (onChangeBonus: (key: string, value: number) => void) => {

    var colunasMesesBonusValor = colunasMeta.map(coluna => (
        {
            title: coluna.nome,
            dataIndex: coluna.keyBonusCoordenador,
            render: (value: any) => (
                <><InputNumber precision={2} defaultValue={value} styleInput={{ width: '100%', border: 0 }} style={{ width: '100%' }} onBlur={(e) => onChangeBonus(coluna.keyBonusCoordenador, parseFloat(e))}></InputNumber></>
            )
        } as ColumnProps<IMetaBonusCoordenador>
    ));

    return [...colunasMesesBonusValor] as Array<ColumnProps<IMetaBonusCoordenador>>;
}

export const getColumnsBonusTotalVendedores = () => {

    var colunasMesesBonusValor = colunasMeta.map(coluna => (
        {
            title: coluna.nome,
            width: '8.33%',
            dataIndex: `${coluna.keyBonusCoordenador}BonusTotal`,
            render: (e) => <label style={{ paddingLeft: 15 }}>{toFixed(e)}</label>
        } as ColumnProps<IMetaBonusCoordenador>
    ));

    return [...colunasMesesBonusValor] as Array<ColumnProps<IMetaBonusCoordenador>>;
}

export const getColumnsBonusValor = (onChangeBonus: (key: string, value: number) => void) => {

    var colunasMesesBonusValor = colunasMeta.map(coluna => (
        {
            title: coluna.nome,
            dataIndex: coluna.keyBonusValor,
            render: (value: any) => (
                <><InputNumber precision={2} defaultValue={value} styleInput={{ width: '100%', border: 0 }} style={{ width: '100%' }} onBlur={(e) => onChangeBonus(coluna.keyBonusValor, parseFloat(e))}></InputNumber></>
            )
        } as ColumnProps<IMetaBonus>
    ));

    return [...colunasMesesBonusValor] as Array<ColumnProps<IMetaBonus>>;
}

export const getColumnsBonusQtde = (onChangeBonus: (key: string, value: number) => void) => {

    var colunasMesesBonusQtde = colunasMeta.map(coluna => (
        {
            title: coluna.nome,
            dataIndex: coluna.keyBonusQtde,
            render: (value: any) => (
                <><InputNumber precision={2} defaultValue={value} styleInput={{ width: '100%', border: 0 }} style={{ width: '100%' }} onBlur={(e) => onChangeBonus(coluna.keyBonusQtde, parseFloat(e))}></InputNumber></>
            )
        } as ColumnProps<IMetaBonus>
    ));

    return [...colunasMesesBonusQtde] as Array<ColumnProps<IMetaBonus>>;
}

export const getColumnsMetasValor = (metaClientes: IMetaCliente[], onChange: (clienteId: number, key: string, value: number) => void) => {

    var colunasMesesValor = colunasMeta.map(coluna => (
        {
            title: coluna.nome.substring(0, 3),
            dataIndex: coluna.keyValor,
            render: (value: any, record: IMetaCliente) => (
                <><InputNumber precision={2} defaultValue={value == 0 ? undefined : value} styleInput={{ width: '100%', border: 0 }} style={{ width: '100%' }} onBlur={(e) => onChange(record.clienteFk, coluna.keyValor, parseFloat(isNullOrEmpty(e) ? '0' : e))}></InputNumber></>
            )
        } as ColumnProps<IMetaCliente>
    ));

    return [
        {
            title: 'Cliente',
            dataIndex: 'clienteNome',
            filters: getDistinctFilterValues(metaClientes, 'clienteNome'),
            onFilter: (value: string, record) => record.clienteNome.indexOf(value) === 0,
            sorter: (a, b) => sorter(a.clienteNome, b.clienteNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            width: 270,
        },
        ...colunasMesesValor
    ] as Array<ColumnProps<IMetaCliente>>;
}

export const getColumnsMetasQtde = (metaClientes: IMetaCliente[], onChange: (clienteId: number, key: string, value: number) => void) => {

    var colunasMesesQtde = colunasMeta.map(coluna => (
        {
            title: coluna.nome.substring(0, 3),
            dataIndex: coluna.keyQtde,
            render: (value: any, record: IMetaCliente) => (
                <><InputNumber defaultValue={value} precision={2} styleInput={{ width: '100%', border: '0px !important' }} style={{ width: '100%' }} onBlur={(e) => onChange(record.clienteFk, coluna.keyQtde, parseFloat(e))}></InputNumber></>
            )
        } as ColumnProps<IMetaCliente>
    ));

    return [
        {
            title: 'Cliente',
            dataIndex: 'clienteNome',
            filters: getDistinctFilterValues(metaClientes, 'clienteNome'),
            onFilter: (value: string, record) => record.clienteNome.indexOf(value) === 0,
            sorter: (a, b) => sorter(a.clienteNome, b.clienteNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            width: 270,
        },
        ...colunasMesesQtde
    ] as Array<ColumnProps<IMetaCliente>>;
}