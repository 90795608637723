import { IImportarPedidoInfo } from '../../../data/interfaces/pedido/importador/IImportarPedidoRequest';
import { IPedidoImportadoResponse } from '../../../data/interfaces/pedido/importador/IPedidoImportadoResponse';
import { IPedidosNaoImportado } from '../../../data/interfaces/pedido/IPedidosNaoImportado';
import { IErroResponse } from '../../../data/interfaces/shared/IErroResponse';
import { IPedidoImportadoState } from './interfaces/IPedidoImportadoState';
import { PedidoImportadoActionKeys, PedidoImportadoActionUnion } from './PedidoImportado.actions';

const initialState: IPedidoImportadoState = {
	pedidosImportados: [] as IPedidoImportadoResponse[],
	isLoadingPedidosImportados: false,
	isLoadingDataImportadorPedido: false,
	isLoadedDataImportadorPedido: false,
	isLoading: false,
	isImportandoPedido: false,
	error: undefined,
	message: undefined,
	errosImportacao: [] as IErroResponse[],
	pedidosNaoImportados: [] as IPedidosNaoImportado[],
	pedidoImportadoInfo: {} as IImportarPedidoInfo,
	analiseCliente: [],
	analiseGeral: [],
	isLoadingAnaliseGeral: false,
	isLoadingAnaliseCliente: false
};

const pedidoImportadoReducer = (state = initialState, action: PedidoImportadoActionUnion): IPedidoImportadoState => {
	switch (action.type) {
		case PedidoImportadoActionKeys.LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				isLoadingPedidosImportados: true
			};
		case PedidoImportadoActionKeys.LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoadingPedidosImportados: false,
				pedidosImportados: action.payload
			};
		case PedidoImportadoActionKeys.LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isLoadingPedidosImportados: false,
				error: action.payload
			};

		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_REQUEST:
			return {
				...state,
				isLoading: true,
				isImportandoPedido: true
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_SUCCESS:
			var pedidoImportado = action.payload;
			pedidoImportado.isImportedNow = true;

			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[],
				pedidosImportados: [pedidoImportado, ...state.pedidosImportados]
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INVALID:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: action.payload
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_CLEAR:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[]
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_FAILED:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[],
				error: action.payload
			};

		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_REQUEST:
			return {
				...state,
				isLoading: true,
				isImportandoPedido: true
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_SUCCESS:
			var pedidoImportado = action.payload;
			pedidoImportado.isImportedNow = true;

			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[],
				pedidosImportados: [pedidoImportado, ...state.pedidosImportados]
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_INVALID:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: action.payload
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_CLEAR:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[]
			};
		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_FAILED:
			return {
				...state,
				isLoading: false,
				isImportandoPedido: false,
				errosImportacao: [] as IErroResponse[],
				error: action.payload
			};

		case PedidoImportadoActionKeys.LIMPAR_PEDIDO_REQUEST:
		case PedidoImportadoActionKeys.RECUPERAR_PEDIDO_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case PedidoImportadoActionKeys.LIMPAR_PEDIDO_SUCCESS:
		case PedidoImportadoActionKeys.RECUPERAR_PEDIDO_SUCCESS:
			return {
				...state,
				isLoading: false
			};
		case PedidoImportadoActionKeys.LIMPAR_PEDIDO_FAILED:
		case PedidoImportadoActionKeys.RECUPERAR_PEDIDO_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};

		case PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_REQUEST:
			return {
				...state,
				isLoading: true,
				isLoadingDataImportadorPedido: true
			};
		case PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoadingDataImportadorPedido: false,
				isLoadedDataImportadorPedido: true
			};
		case PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_FAILED:
			return {
				...state,
				isLoading: false,
				isLoadingDataImportadorPedido: false,
				error: action.payload
			};

		case PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_SUCCESS:
			return {
				...state,
				isLoading: false,
				pedidosNaoImportados: action.payload
			};

		case PedidoImportadoActionKeys.DEFAULT_FAILED:
			return {
				...state,
				isLoading: false,
				isLoadingPedidosImportados: false,
				error: action.payload,
				isLoadingAnaliseCliente: false,
				isLoadingAnaliseGeral: false
			};

		case PedidoImportadoActionKeys.DEFAULT_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoadingPedidosImportados: false,
			};

		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CREATE:
			return {
				...state,
				pedidoImportadoInfo: { pediGuid: action.payload, infos: ['Iniciando importação de pedido...'] }
			};

		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_ADD:

			if (state.pedidoImportadoInfo.pediGuid == action.payload.pediGuid) {

				var pedidoInfoAux = { ...state.pedidoImportadoInfo };
				pedidoInfoAux.infos = [...state.pedidoImportadoInfo.infos, action.payload.message];
				pedidoInfoAux.qtdeItensImportado = action.payload.qtdeItensImportado;
				pedidoInfoAux.qtdeTotalItens = action.payload.qtdeTotalItens;

				return {
					...state,
					pedidoImportadoInfo: pedidoInfoAux
				};
			}

			return { ...state };

		case PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CLEAR:
			return {
				...state,
				pedidoImportadoInfo: {} as IImportarPedidoInfo
			}

		case PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_SUCCESS:
			var pedidosNaoImportadosResponse = action.payload;
			var pedidosNaoImportados = [...state.pedidosNaoImportados];

			pedidosNaoImportadosResponse.forEach((pedidoNaoImportado) => {
				var index = pedidosNaoImportados.findIndex((x) => x.pedidoNumero === pedidoNaoImportado.pedidoNumero);

				pedidoNaoImportado.clienteNomeFantasia = pedidosNaoImportados[index].clienteNomeFantasia;
				pedidoNaoImportado.industriaNomeFantasia = pedidosNaoImportados[index].industriaNomeFantasia;
				pedidoNaoImportado.usuarioNomeQuemDigitou = pedidosNaoImportados[index].usuarioNomeQuemDigitou;
				pedidoNaoImportado.dataEmissao = pedidosNaoImportados[index].dataEmissao;

				pedidosNaoImportados.splice(index, 1, pedidoNaoImportado);
			});

			return {
				...state,
				isLoading: false,
				pedidosNaoImportados: pedidosNaoImportados
			};

		case PedidoImportadoActionKeys.LIST_CLIENTE_INDUSTRIA_FULL_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_REQUEST:
			return {
				...state,
				isLoadingAnaliseCliente: true
			};

		case PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_SUCCESS:
			return {
				...state,
				isLoadingAnaliseCliente: false,
				analiseCliente: action.payload
			};

		case PedidoImportadoActionKeys.GET_ANALISE_GERAL_REQUEST:
			return {
				...state,
				isLoadingAnaliseGeral: true
			};

		case PedidoImportadoActionKeys.GET_ANALISE_GERAL_SUCCESS:
			return {
				...state,
				isLoadingAnaliseGeral: false,
				analiseGeral: action.payload
			};

		case PedidoImportadoActionKeys.CLEAR_ANALISES:
			return {
				...state,
				isLoadingAnaliseGeral: false,
				isLoadingAnaliseCliente: false,
				analiseGeral: [],
				analiseCliente: []
			};

		default:
			return state;
	}
};

export default pedidoImportadoReducer;
