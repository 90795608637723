import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { IDataImportadorPedido } from "../../../data/interfaces/pedido/importador/IDataImportadorPedido";
import { IErroResponse } from "../../../data/interfaces/shared/IErroResponse";
import { PedidoImportadoApi } from "../../../data/PedidoImportado.api";
import { SvApiActions } from "../../svApi/SvApi.actions";
import { SvDbActions } from "../../svDb/SvDb.actions";
import { AnaliseClienteAction, AnaliseGeralAction, ImportarPedidoAction, ImportarPedidoV2Action, LimparPedidoAction, ListAction, PedidoImportadoActions, RecuperarPedidoAction, UpdatePedidoNaoImportadoAction } from "./PedidoImportado.actions";

export function* list({ payload }: ListAction) {
    try {
        const { data } = yield call(PedidoImportadoApi.list, payload);
        yield put(PedidoImportadoActions.listSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.listFailure(error));
    }
}

export function* getDataImportadorPedido() {
    try {
        const { data } = yield call(PedidoImportadoApi.getDataImportadorPedido);
        var result = data.data as IDataImportadorPedido;

        yield put(SvApiActions.listIndustriasSuccess(result.industrias));
        yield put(SvApiActions.listClientesSuccess(result.clientes));
        yield put(SvApiActions.listTabelasPrecoSuccess(result.tabelasPreco));
        yield put(SvApiActions.listClienteIndustriaSuccess(result.clientesIndustria));
        yield put(SvApiActions.listClienteVendedorSuccess(result.clientesVendedor));
        yield put(SvApiActions.listTransportadorasSuccess(result.transportadoras));
        yield put(SvApiActions.listClienteTransportadoraSuccess(result.clientesTransportadoras));
        yield put(SvApiActions.listPedidoStatusSuccess(result.pedidoStatus));
        yield put(SvApiActions.listUsuariosSuccess(result.usuarios));
        yield put(SvDbActions.listFormasPagamentoSuccess(result.formasPagamento));

        yield put(PedidoImportadoActions.getDataImportadorPedidoSuccess());
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.getDataImportadorPedidoFailure(error));
    }
}

export function* importarPedido({ payload }: ImportarPedidoAction) {
    try {
        const { data } = yield call(PedidoImportadoApi.importarPedido, payload);
        yield put(PedidoImportadoActions.importarPedidoSuccess(data.data));
        toast.success('Pedido Importado com sucesso!');
    } catch (e: any) {

        if (e.errors && e.errors.length) {
            const errors = e.errors as IErroResponse[];
            toast.error('A importação do pedido está inválida!');
            yield put(PedidoImportadoActions.importarPedidoInvalid(errors));
        }
        else {
            const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar importação de pedido';
            toast.error(error);
            yield put(PedidoImportadoActions.importarPedidoFailure(error));
        }
    }
}

export function* importarPedidoV2({ payload }: ImportarPedidoV2Action) {
    try {
        const { data } = yield call(PedidoImportadoApi.importarPedidoV2, payload);
        yield put(PedidoImportadoActions.importarPedidoV2Success(data.data));
        toast.success('Pedido Importado com sucesso!');
    } catch (e: any) {

        if (e.errors && e.errors.length) {
            const errors = e.errors as IErroResponse[];
            toast.error('A importação do pedido está inválida!');
            yield put(PedidoImportadoActions.importarPedidoV2Invalid(errors));
        }
        else {
            const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar importação de pedido';
            toast.error(error);
            yield put(PedidoImportadoActions.importarPedidoV2Failure(error));
        }
    }
}

export function* limparPedido({ payload }: LimparPedidoAction) {
    try {
        yield call(PedidoImportadoApi.limparPedido, payload);
        yield put(PedidoImportadoActions.limparPedidoSuccess());
        toast.success('Pedido limpo com sucesso!');
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.limparPedidoFailure(error));
    }
}


export function* recuperarPedido({ payload }: RecuperarPedidoAction) {
    try {
        yield call(PedidoImportadoApi.recuperarPedido, payload);
        yield put(PedidoImportadoActions.recuperarPedidoSuccess());
        toast.success('Pedido recuperado com sucesso!');
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.recuperarPedidoFailure(error));
    }
}

export function* listPedidosNaoImportados() {
    try {
        const { data } = yield call(PedidoImportadoApi.listPedidosNaoImportados);
        yield put(PedidoImportadoActions.listPedidosNaoImportadosSuccess(data.data));
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.defaultFailure(error));
    }
}

export function* updatePedidoNaoImportado({ payload }: UpdatePedidoNaoImportadoAction) {
    try {
        const { data } = yield call(PedidoImportadoApi.updatePedidoNaoImportadoInfo, payload);
        yield put(PedidoImportadoActions.updatePedidoNaoImportadoSuccess(data.data));
        toast.success('Dados salvos com sucesso!');
    } catch (e: any) {
        const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.defaultFailure(error));
    }
}

export function* listClienteIndustriaFull() {
    try {
        const { data } = yield call(PedidoImportadoApi.listClienteIndustriaFull);
        yield put(SvApiActions.listClienteIndustriaSuccess(data.data));
        yield put(PedidoImportadoActions.defaultSuccess());
    } catch (e: any) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.defaultFailure(error));
    }
}

export function* analiseGeral({ payload }: AnaliseGeralAction) {
    try {
        const { data } = yield call(PedidoImportadoApi.analisePedidoGeral, payload);
        yield put(PedidoImportadoActions.analiseGeralSuccess(data.data));
    } catch (e: any) {
        const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.defaultFailure(error));
    }
}

export function* analiseCliente({ payload }: AnaliseClienteAction) {
    try {
        const { data } = yield call(PedidoImportadoApi.analisePedidoCliente, payload);
        yield put(PedidoImportadoActions.analiseClienteSuccess(data.data));
    } catch (e: any) {
        const error = e?.errors && e?.errors[0]?.message ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(PedidoImportadoActions.defaultFailure(error));
    }
}