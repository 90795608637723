import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IRolesResponse } from "./interfaces/user/IRoles";
import { IUpdatePasswordRequest } from "./interfaces/user/IUpdatePassword";
import { IUserData, IUserRequest } from "./interfaces/user/IUserRequest";
import { IUserResponse } from "./interfaces/user/IUserResponse";

export class UserApi {
    static async listUsers(): Promise<AxiosResponse<IUserResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/user/list');
    }

    static async listRoles(): Promise<AxiosResponse<IRolesResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/user/listroles');
    }

    static async registerUser(body: IUserData): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', body.file);
        formData.append('data', JSON.stringify(body.data));

        return axios.post('/user/register', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async updateUser(body: IUserData): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();

        const formData = new FormData();
        formData.append('file', body.file);
        formData.append('data', JSON.stringify(body.data));

        return axios.put(`/user/update/${body.data.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async updatePassword(body: IUpdatePasswordRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.put(`/user/updatepassword/${body.id}`, body);
    }

    static async deleteUser(id: string): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.delete(`/user/delete/${id}`);
    }
}