import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IImportSellOutRequest, ISellOut } from "./interfaces/sellOut/ISellOut";

export class SellOutApi {
    static async get(request: ISellOut): Promise<AxiosResponse<ISellOut>> {
        const axios = await Http.axios();
        return axios.get(`/sellOut/get?clienteId=${request.clienteFk}&industriaId=${request.industriaFk}&ano=${request.ano}`);
    }

    static async update(request: ISellOut): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.put(`/sellOut/update`, request);
    }

    static async importarSellOut(request: IImportSellOutRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file ?? '');
        formData.append('clienteId', request.clienteId?.toString());
        formData.append('industriaId', request.industriaId?.toString());
        formData.append('ano', request.ano?.toString());

        return axios.post(`/sellOut/importar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}