import { Button, Skeleton, Space, Table } from "antd";
import React, { FC, useEffect } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import styles from './Modelo.module.scss';
import { history } from "../../../store";
import { ProjecaoVendaActions } from "../../../store/projecaoVenda/ProjecaoVenda.actions";
import { getColumns } from "./Modelo.helper";
import { IModeloCombinacao } from "../../../data/interfaces/projecaoVenda/IModeloCombinacao";

const Modelo: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ProjecaoVendaActions.listModelos());
    }, []);

    const handleEdit = (modelo: IModeloCombinacao) => {
        history.push(`/projecao-venda/modelo/${modelo.modeloId}`)
    }

    const handleDelete = (id: number) => {
        dispatch(ProjecaoVendaActions.deleteModelo(id));
    }

    return (
        <>
            <PageContent title='Projeção de Venda' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Space>
                                <Button type='default' onClick={() => history.goBack()}>Voltar</Button>
                            </Space>
                        </div>
                        <Table columns={getColumns(props.modelos, handleEdit, handleDelete)} dataSource={props.modelos} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Modelo);