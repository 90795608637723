import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IContato } from "./interfaces/contato/IContato";
import { IDepartamento } from "./interfaces/departamento/IDepartamento";
import { IClienteIndustriaResponse } from "./interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "./interfaces/svData/IClienteResponse";
import { IClienteTransportadoraResponse } from "./interfaces/svData/IClienteTransportadoraResponse";
import { IClienteVendedorResponse } from "./interfaces/svData/IClienteVendedorResponse";
import { IIndustriaResponse } from "./interfaces/svData/IIndustriaResponse";
import { IPedidoStatusResponse } from "./interfaces/svData/IPedidoStatusResponse";
import { ITabelaPrecoResponse } from "./interfaces/svData/ITabelaPrecoResponse";
import { ITransportadoraResponse } from "./interfaces/svData/ITransportadoraResponse";
import { IUserResponse } from "./interfaces/user/IUserResponse";

export class SVAPIApi {
    static async listIndustrias(): Promise<AxiosResponse<IIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listIndustrias');
    }

    static async listClientes(): Promise<AxiosResponse<IClienteResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listClientes');
    }

    static async listTabelasPreco(): Promise<AxiosResponse<ITabelaPrecoResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listTabelasPreco');
    }

    static async listClienteXIndustria(): Promise<AxiosResponse<IClienteIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listClienteXIndustria');
    }

    static async listClienteXVendedor(): Promise<AxiosResponse<IClienteVendedorResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listClienteXVendedor');
    }

    static async listTransportadoras(): Promise<AxiosResponse<ITransportadoraResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listTransportadoras');
    }

    static async listClienteXTransportadora(): Promise<AxiosResponse<IClienteTransportadoraResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listClienteXTransportadora');
    }

    static async listPedidoStatus(): Promise<AxiosResponse<IPedidoStatusResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listPedidoStatus');
    }

    static async listUsuarios(): Promise<AxiosResponse<IUserResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/svapi/listUsuarios');
    }

    static async listUsuariosByClienteId(clienteId: number): Promise<AxiosResponse<IUserResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/svapi/listUsuariosByClienteId/${clienteId}`);
    }

    static async listTransportadorasByClienteId(clienteId: number): Promise<AxiosResponse<ITransportadoraResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/svapi/listTransportadorasByClienteId/${clienteId}`);
    }

    static async listContatosByClienteId(clienteId: number): Promise<AxiosResponse<IContato[]>> {
        const axios = await Http.axios();
        return axios.get(`/svapi/listContatosByClienteId/${clienteId}`);
    }
}