import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch, Route } from 'react-router';
import Login from '../auth/login';

//import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import { IGlobalReducerState } from '../../store/base/interface/IGlobalReducerState';
import { isAuthenticatedSelector } from '../../store/auth/Auth.selector';
import ShellHost from '../../components/shellHost';
import Users from '../users';
import PedidosImportados from '../pedido/importador';
import PedidosNaoImportados from '../pedido/pedidosNaoImportados';
import AtualizarPedidos from '../pedidoItens/atualizarPedidos';
import DetalharAtualizacao from '../pedidoItens/detalharAtualizacao';
import AtualizarItensPedidos from '../pedidoItens/atualizarItensPedidos';
import ListAtualizarProduto from '../produto/listAtualizarProduto';
import CategoriasCliente from '../cliente/categoria';
import Clientes from '../cliente';
import Config from '../config';
import Dashboards from '../dashboards';
import CamposApi from '../api/camposApi';
import TabelasIntegracao from '../api/camposIntegracao';
import CamposIntegracao from '../api/camposIntegracao/listaCampos';
import Cookies from 'universal-cookie';
import { COOKIENAME_DEVICEID } from '../../store/auth/Auth.constants';
import PedidoSignalR from '../../signalR/pedidoSignalR';
import Metas from '../metas';
import MetasForm from '../metas/form';
import ProjecaoVenda from '../projecaoVenda';
import Modelo from '../projecaoVenda/modelo';
import CombinacaoForm from '../projecaoVenda/combinacaoForm';
import ModeloForm from '../projecaoVenda/modeloForm';
import SellOut from '../sell-out';
import CRM from '../crm';
import CRMView from '../crm/CrmView';
import CrmForm from '../crm/CrmForm';
import Acompanhamento from '../acompanhamento';
import Contatos from '../contatos';
import { ModuleActions } from '../../store/module/Module.actions';
import NotificationSignalR from '../../signalR/notificationSignalR';

interface IApp {
  isAuthenticated: boolean;
}

const App: FC<IApp> = ({ isAuthenticated }) => {
  const dispatch = useDispatch();

  if (process.env.REACT_APP_AMBIENTE == 'PRODUCTION' && window.location.origin.indexOf('integracao.rbrltda.com.br') == -1) {
    window.location.href = 'https://integracao.rbrltda.com.br';
  }

  const cookies = new Cookies();
  var deviceId = cookies.get(COOKIENAME_DEVICEID)

  if (deviceId == undefined || deviceId == "") {
    var d = new Date();
    deviceId = d.getDate() + "" + d.getMonth() + "" + d.getFullYear() + "" + d.getHours() + "" + d.getMinutes() + "" + d.getSeconds();
    deviceId += "-" + Math.floor((Math.random()) * 0x10000)
    cookies.set(COOKIENAME_DEVICEID, deviceId, { path: '/' });
  }

  useEffect(() => {
    if (isAuthenticated)
      dispatch(ModuleActions.listModules());
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PedidoSignalR />
      <NotificationSignalR />

      {isAuthenticated ?
        <>
          <ShellHost>
            <Switch>
              <Route path='/usuarios' component={Users} />
              <Route path='/pedidos/importador' exact component={PedidosImportados} />
              <Route path='/pedidos/naoImportados' exact component={PedidosNaoImportados} />
              <Route path='/pedidoItens/atualizar' exact component={AtualizarPedidos} />
              <Route path='/pedidoItens/detalharAtualizacao/:atualizacaoId' exact component={DetalharAtualizacao} />
              <Route path='/pedidoItens/atualizarItens' exact component={AtualizarItensPedidos} />
              <Route path='/produtos/atualizar' exact component={ListAtualizarProduto} />
              <Route path='/clientes' exact component={Clientes} />
              <Route path='/clientes/categorias' exact component={CategoriasCliente} />
              <Route path='/config' exact component={Config} />
              <Route path='/dashboards/:dashboard' exact component={Dashboards} />
              <Route path='/api/campos-api' exact component={CamposApi} />
              <Route path='/api/campos-integracao' exact component={TabelasIntegracao} />
              <Route path='/api/campos-integracao/:tabela' exact component={CamposIntegracao} />
              <Route path='/metas' exact component={Metas} />
              <Route path='/metas/form' exact component={MetasForm} />
              <Route path='/projecao-venda' exact component={ProjecaoVenda} />
              <Route path='/projecao-venda/modelos' exact component={Modelo} />
              <Route path='/projecao-venda/:id' exact component={CombinacaoForm} />
              <Route path='/projecao-venda/modelo/:id' exact component={ModeloForm} />
              <Route path='/sell-out' exact component={SellOut} />
              <Route path='/crm' exact component={CRM} />
              <Route path='/crm/acompanhamento' exact component={Acompanhamento} />
              <Route path='/contatos' exact component={Contatos} />
            </Switch>
          </ShellHost>
        </>
        :
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/' component={Login} />
        </Switch>
      }
    </>
  );
}

const mapStateProps = (state: IGlobalReducerState) => {
  return {
    isAuthenticated: isAuthenticatedSelector(state),
  };
};

export default connect(mapStateProps)(App);
