import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { NotificationActions } from './Notification.actions';
import { NotificationApi } from '../../data/Notification.api';

export function* listNotifications() {
  try {
    const { data } = yield call(NotificationApi.listByUser);
    yield put(NotificationActions.listNotificationsSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(NotificationActions.defaultFailure(error));
  }
}

export function* readAllNotifications() {
  try {
    yield call(NotificationApi.readAllNotifications);
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(NotificationActions.defaultFailure(error));
  }
}