import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ITicket, ITicketChangeStatusRequest, ITicketMessage, ITicketMessageRequest, IUploadTicketAttachmentRequest } from "./interfaces/ticket/ITicket";
import { useDispatch } from "react-redux";
import { store } from "../store";
import { TicketActions } from "../store/ticket/Ticket.actions";

export class TicketApi {
    static async getByAcompanhamentoId(acompanhamentoId: number): Promise<AxiosResponse<ITicket>> {
        const axios = await Http.axios();
        return axios.get(`/ticket/getByAcompanhamentoId/${acompanhamentoId}`);
    }

    static async get(ticketId: number): Promise<AxiosResponse<ITicket>> {
        const axios = await Http.axios();
        return axios.get(`/ticket/get/${ticketId}`);
    }

    static async changeStatus(request: ITicketChangeStatusRequest) {
        const axios = await Http.axios();
        axios.put(`/ticket/changeStatus/${request.ticketId}?status=${request.status}`);
    }

    static async addMessage(request: ITicketMessageRequest): Promise<AxiosResponse<ITicketMessage>> {
        const axios = await Http.axios();
        return axios.post('/ticket/addMessage', request);
    }

    static async deleteMessage(id: number) {
        const axios = await Http.axios();
        axios.delete(`/ticket/deleteMessage/${id}`);
    }

    static async deleteAttachment(id: number) {
        const axios = await Http.axios();
        axios.delete(`/ticket/deleteAttachment/${id}`);
    }

    static async attachment(body: IUploadTicketAttachmentRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', body.file);

        return axios
            .post(`/ticket/${body.ticketId}/attachment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: async (progressEvent: any) => {
                    var progress = progressEvent.loaded / progressEvent.total * 100;
                    await store.dispatch(TicketActions.uploadAttachmentUpdate(progress))
                },
            });
    }
}