import React from "react";
import { ColumnProps } from "antd/lib/table";
import { getDistinctFilterValues, getFilterSearchProps, sorter } from "../../utils/helpers";
import styles from './CRM.module.scss';
import { IClienteResponse } from "../../data/interfaces/svData/IClienteResponse";
import { FaEye } from "react-icons/fa";

export const getColumns = (data: IClienteResponse[], onView: (id: number) => void) => {
    return [
        {
            title: 'Cliente',
            dataIndex: 'clienteNomeFantasia',
            sorter: (a, b) => sorter(a.clienteNomeFantasia, b.clienteNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteNomeFantasia', 'Cliente'),
        },
        {
            title: 'Cliente Cidade',
            dataIndex: 'clienteCidade',
            sorter: (a, b) => sorter(a.clienteCidade, b.clienteCidade),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteCidade', 'Cliente Cidade'),
        },
        {
            title: 'Cliente UF',
            dataIndex: 'clienteEstado',
            sorter: (a, b) => sorter(a.clienteEstado, b.clienteEstado),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteEstado', 'Cliente UF'),
        },
        {
            title: 'Grupo',
            dataIndex: 'clienteRegiaoNome',
            sorter: (a, b) => sorter(a.clienteRegiaoNome, b.clienteRegiaoNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(data, 'clienteRegiaoNome'),
            onFilter: (value: string, record) => { return record.clienteRegiaoNome.indexOf(value) === 0 },
        },
        {
            width: 140,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaEye title='Visualizar' onClick={() => onView(register.clienteId)} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<IClienteResponse>>;
}