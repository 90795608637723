import { Button, Modal } from "antd";
import React, { FC, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import FieldForm from "../../../../components/ui/field";
import Form from "../../../../components/ui/form";
import { IUpdatePasswordRequest } from "../../../../data/interfaces/user/IUpdatePassword";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import schema from "./UpdatePassword.schema";

import styles from './UpdatePassword.module.scss';
import { UserActions } from "../../../../store/user/User.actions";

export interface IUpdatePasswordModal {
    userId: string;
    visible: boolean;
    onClose: () => void;
}

const UpdatePassword: FC<Props> = ({ userId, visible, isLoading, onClose }) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = React.useState<boolean>(false);

    const submit = (values: IUpdatePasswordRequest) => {

        if (values) {
            dispatch(UserActions.updatePassword(values));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    if (!isLoading && isSendingData) {
        setIsSendingData(false);
        onClose();
    }

    const buttons =
        [
            <Button key="back" onClick={onClose}>Cancelar</Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={() => setIsSubmit(true)}>Salvar</Button>,
        ];

    return (
        <Modal title='Atualizar Senha' open={visible} footer={buttons} onCancel={onClose}>
            <div className={styles['container']}>
                <Form onSubmit={submit} isSubmited={isSubmit} schema={schema} >
                    <FieldForm type='hidden' name='id' defaultValue={userId}></FieldForm>
                    <FieldForm label='Nova Senha' name='newPassword' type='password' className={styles['inputForm']}></FieldForm>
                    <FieldForm label='Confirmar Nova Senha' name='confirmNewPassword' type='password' className={styles['inputForm']}></FieldForm>
                </Form>
            </div>
        </Modal>
    );
}


const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IUpdatePasswordModal;


export default connector(UpdatePassword);
