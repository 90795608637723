import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IImportarMetaRequest, IMeta, IMetaRequest } from "./interfaces/meta/IMeta";

export class MetaApi {
    static async get(request: IMetaRequest): Promise<AxiosResponse<IMeta[]>> {
        const axios = await Http.axios();
        return axios.get(`/meta/get?vendedorId=${request.vendedorId}&industriaId=${request.industriaId}&ano=${request.ano}`);
    }

    static async update(request: IMeta): Promise<AxiosResponse<IMeta>> {
        const axios = await Http.axios();
        return axios.post(`/meta/update`, request);
    }

    static async importarMeta(request: IImportarMetaRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file);
        formData.append('vendedorId', request.vendedorId?.toString());
        formData.append('industriaId', request.industriaId?.toString());
        formData.append('ano', request.ano?.toString());
        formData.append('isCoordenador', request.isCoordenador?.toString());

        return axios.post('/meta/importar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}