import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ICategoriaCliente } from "./interfaces/cliente/ICategoriaCliente";
import { IClienteIndustriaAuxAuditoria } from "./interfaces/cliente/IClienteIndustriaAuxAuditoria";
import { IClienteIndustriaAuxForm } from "./interfaces/cliente/IClienteIndustriaAuxForm";
import { IClienteIndustriaAuxView } from "./interfaces/cliente/IClienteIndustriaAuxView";
import { IContato } from "./interfaces/contato/IContato";
import { IClienteIndustriaResponse } from "./interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "./interfaces/svData/IClienteResponse";

export class ClienteApi {

    static async listClientes(): Promise<AxiosResponse<IClienteResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/cliente/listClientes');
    }

    static async listClientesIndustrias(): Promise<AxiosResponse<IClienteIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get('/cliente/listClientesIndustrias');
    }

    static async listClientesIndustriasByIndustriaId(request: number): Promise<AxiosResponse<IClienteIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/cliente/listClientesIndustrias?industriaId=${request}`);
    }

    static async getClienteIndustriaAuxFormById(request: number): Promise<AxiosResponse<IClienteIndustriaAuxForm>> {
        const axios = await Http.axios();
        return axios.get(`/cliente/getClienteIndustriaAuxFormById/${request}`);
    }

    static async getClienteIndustriaAuxViewById(request: number): Promise<AxiosResponse<IClienteIndustriaAuxView>> {
        const axios = await Http.axios();
        return axios.get(`/cliente/getClienteIndustriaAuxViewById/${request}`);
    }

    static async updateClienteIndustriaAux(request: IClienteIndustriaAuxForm): Promise<AxiosResponse<IClienteIndustriaAuxForm>> {
        const axios = await Http.axios();
        return axios.post(`/cliente/updateClienteIndustriaAux`, request);
    }

    static async listCategoriasClient(): Promise<AxiosResponse<ICategoriaCliente[]>> {
        const axios = await Http.axios();
        return axios.get('/cliente/listCategorias');
    }

    static async createCategoria(request: ICategoriaCliente): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post('/cliente/createCategoria', request);
    }

    static async updateCategoria(request: ICategoriaCliente): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.put(`/cliente/updateCategoria/${request.categoriaClienteAuxId}`, request);
    }

    static async deleteCategoria(request: number): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.delete(`/cliente/deleteCategoria/${request}`);
    }

    static async listCompradoresByClienteIndustriaId(request: number[]): Promise<AxiosResponse<IContato[]>> {
        const axios = await Http.axios();
        return axios.post(`/cliente/listCompradoresByClienteIndustriaId`, request);
    }

    static async importarClienteIndustria(file: File): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', file);

        return axios.post('/cliente/importarClienteIndustria', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async listClienteIndustriaAuxAuditoria(request: number): Promise<AxiosResponse<IClienteIndustriaAuxAuditoria[]>> {
        const axios = await Http.axios();
        return axios.get(`/cliente/listAuditoriaByClienteIndustriaAuxId/${request}`);
    }

}