export function numberWithSeparators(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function toFixed(value: any, precision?: number) {

    if (value == null || value == undefined || isNaN(value))
        return 0;

    return parseFloat(value.toFixed(2)).toLocaleString('pt-BR', {
        currency: 'BRL',
        minimumFractionDigits: precision ?? 2
    })
}