import * as Yup from 'yup';
import { TipoCombinacaoEnum } from '../..';

const schema = Yup.object().shape({
    tipoOrigem: Yup.string().required('Selecione uma opção'),
    clientesId: Yup.string()
        .when('tipo', {
            is: (tipo: TipoCombinacaoEnum) => {
                return tipo == TipoCombinacaoEnum.CLIENTEXINDUSTRIA || tipo == TipoCombinacaoEnum.CLIENTE;
            },
            then: Yup.string().required('Selecione uma opção'),
        }),
    industriasId: Yup.string()
        .when('tipo', {
            is: (tipo: TipoCombinacaoEnum) => {
                return tipo == TipoCombinacaoEnum.CLIENTEXINDUSTRIA || tipo == TipoCombinacaoEnum.INDUSTRIA;
            },
            then: Yup.string().required('Selecione uma opção'),
        })
});

export default schema;