import { IPedido } from "../../data/interfaces/pedido/IPedido";
import { IUltimoPedidoRequest } from "../../data/interfaces/pedido/IUltimoPedidoRequest";
import { IFormaPagamentoResponse } from "../../data/interfaces/svData/IFormaPagamentoResponse";
import { IIndustriaResponse } from "../../data/interfaces/svData/IIndustriaResponse";
import { IPedidoStatusResponse } from "../../data/interfaces/svData/IPedidoStatusResponse";
import { IUsuarioVendedor } from "../../data/interfaces/svData/IUsuarioVendedor";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum SvDbActionKeys {
    LIST_FORMAS_PAGAMENTO_REQUEST = 'LIST_FORMAS_PAGAMENTO_REQUEST',
    LIST_FORMAS_PAGAMENTO_SUCCESS = 'LIST_FORMAS_PAGAMENTO_SUCCESS',
    LIST_FORMAS_PAGAMENTO_FAILED = 'LIST_FORMAS_PAGAMENTO_FAILED',

    LIST_PEDIDO_STATUS_REQUEST = 'LIST_PEDIDO_STATUS_REQUEST',
    LIST_PEDIDO_STATUS_SUCCESS = 'LIST_PEDIDO_STATUS_SUCCESS',

    GET_ULTIMO_PEDIDO_REQUEST = 'GET_ULTIMO_PEDIDO_REQUEST',
    GET_ULTIMO_PEDIDO_SUCCESS = 'GET_ULTIMO_PEDIDO_SUCCESS',

    LIST_USUARIOS_VENDEDORES_REQUEST = 'LIST_USUARIOS_VENDEDORES_REQUEST',
    LIST_USUARIOS_VENDEDORES_SUCCESS = 'LIST_USUARIOS_VENDEDORES_SUCCESS',

    LIST_INDUSTRIAS_REQUEST = 'LIST_INDUSTRIAS_REQUEST',
    LIST_INDUSTRIAS_SUCCESS = 'LIST_INDUSTRIAS_SUCCESS',

    DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const SvDbActions = {
    listFormasPagamento: (): ListFormasPagamentoAction =>
        createAction(SvDbActionKeys.LIST_FORMAS_PAGAMENTO_REQUEST),
    listFormasPagamentoSuccess: (response: IFormaPagamentoResponse[]): ListFormasPagamentoSuccessAction =>
        createAction(SvDbActionKeys.LIST_FORMAS_PAGAMENTO_SUCCESS, response),
    listFormasPagamentoFailure: (err: string): ListFormasPagamentoFailureAction =>
        createAction(SvDbActionKeys.LIST_FORMAS_PAGAMENTO_FAILED, err),

    listPedidoStatus: (): ListPedidoStatusAction =>
        createAction(SvDbActionKeys.LIST_PEDIDO_STATUS_REQUEST),
    listPedidoStatusSuccess: (response: IPedidoStatusResponse[]): ListPedidoStatusSuccessAction =>
        createAction(SvDbActionKeys.LIST_PEDIDO_STATUS_SUCCESS, response),

    getUltimoPedido: (request: IUltimoPedidoRequest): GetUltimoPedidoAction =>
        createAction(SvDbActionKeys.GET_ULTIMO_PEDIDO_REQUEST, request),
    getUltimoPedidoSuccess: (response: IPedido): GetUltimoPedidoSuccessAction =>
        createAction(SvDbActionKeys.GET_ULTIMO_PEDIDO_SUCCESS, response),

    listUsuariosVendedores: (): ListUsuariosVendedoresAction =>
        createAction(SvDbActionKeys.LIST_USUARIOS_VENDEDORES_REQUEST),
    listUsuariosVendedoresSuccess: (response: IUsuarioVendedor[]): ListUsuariosVendedoresSuccessAction =>
        createAction(SvDbActionKeys.LIST_USUARIOS_VENDEDORES_SUCCESS, response),

    listIndustrias: (): ListIndustriasAction =>
        createAction(SvDbActionKeys.LIST_INDUSTRIAS_REQUEST),
    listIndustriasSuccess: (response: IIndustriaResponse[]): ListIndustriasSuccessAction =>
        createAction(SvDbActionKeys.LIST_INDUSTRIAS_SUCCESS, response),

    defaultSvApiFailure: (err: string): DefaultSvApiFailureAction =>
        createAction(SvDbActionKeys.DEFAULT_FAILED, err),
}

export type SvDbActionUnion = ActionsUnion<typeof SvDbActions>;

export type ListFormasPagamentoAction = Action<SvDbActionKeys.LIST_FORMAS_PAGAMENTO_REQUEST>;
export type ListFormasPagamentoSuccessAction = Action<SvDbActionKeys.LIST_FORMAS_PAGAMENTO_SUCCESS, IFormaPagamentoResponse[]>;
export type ListFormasPagamentoFailureAction = Action<SvDbActionKeys.LIST_FORMAS_PAGAMENTO_FAILED, string>;

export type ListPedidoStatusAction = Action<SvDbActionKeys.LIST_PEDIDO_STATUS_REQUEST>;
export type ListPedidoStatusSuccessAction = Action<SvDbActionKeys.LIST_PEDIDO_STATUS_SUCCESS, IPedidoStatusResponse[]>;

export type GetUltimoPedidoAction = Action<SvDbActionKeys.GET_ULTIMO_PEDIDO_REQUEST, IUltimoPedidoRequest>;
export type GetUltimoPedidoSuccessAction = Action<SvDbActionKeys.GET_ULTIMO_PEDIDO_SUCCESS, IPedido>;

export type ListUsuariosVendedoresAction = Action<SvDbActionKeys.LIST_USUARIOS_VENDEDORES_REQUEST>;
export type ListUsuariosVendedoresSuccessAction = Action<SvDbActionKeys.LIST_USUARIOS_VENDEDORES_SUCCESS, IUsuarioVendedor[]>;

export type ListIndustriasAction = Action<SvDbActionKeys.LIST_INDUSTRIAS_REQUEST>;
export type ListIndustriasSuccessAction = Action<SvDbActionKeys.LIST_INDUSTRIAS_SUCCESS, IIndustriaResponse[]>;

export type DefaultSvApiFailureAction = Action<SvDbActionKeys.DEFAULT_FAILED, string>;