import React, { FC } from "react";

export interface IDashboards {
    match: any;
}

const Dashboards: FC<IDashboards> = (props) => {

    var urlDashboard: string = '';

    switch (props.match.params.dashboard) {

        case 'vendas':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiNTViNGU2NjUtMTRlYi00NDBmLWE3YjgtMDI5ODMzMmQwZGRhIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
        case 'comparativo':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiYWJmMTJjZmEtZjE2MS00YzRmLWI4OGQtYTlkZGYyODIwOWIxIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
        case 'projecao':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiYmM4ZWVjMzQtODE5Zi00MjkzLWJkNTktNTg2NjUxMjYxODlkIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
        case 'diassemcomprar':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiZDk1ZTc1NWQtOTE4Ni00NjY4LWE4NzUtNTEzZTJkMjY5ZDE5IiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
        case 'analisecliente':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiNmU5YzM2NzgtODI0ZC00MTM5LWEyN2YtMmM1ZWUxMTZkZGNlIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
        case 'comparativoproduto':
            urlDashboard = 'https://app.powerbi.com/view?r=eyJrIjoiZmFhNjlkMDktMGU4My00ZjQ4LTljMTEtNzY2N2U1NGYzOGMwIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9';
            break;
    }

    return (
        <div style={{ width: 'calc(100% - 20px)', height: 'calc(100vh - 80px)', margin: 3 }}>
            <iframe style={{ width: '100%', height: '100%' }} src={window.location.origin + '/dashboard.html?dashboard=' + props.match.params.dashboard}></iframe>
        </div>
    );

}

export default Dashboards;