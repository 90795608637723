export interface IStatusFunctions {
    status: 'Success' | 'Running' | 'Failed' | 'Stoped' | 'Disabled',
    startDateTime: Date,
    endDateTime: Date,
    duration: number,
    info: string
}

export enum RBRFunctionEnum {
    AtualizarBancoSV = 'AtualizarBancoSV',
    AjustarCamposAPI = 'AjustarCamposAPI',
    AtualizarPedidos = 'AtualizarPedidos',
    VerificarCamposAPI = 'VerificarCamposAPI',
    AjustarCamposLancamentosAPI = 'AjustarCamposLancamentosAPI',
    AtualizarBaseDadosPedido = 'AtualizarBaseDadosPedido'
}