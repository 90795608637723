import { IAttachment } from "../common/IAttachment"
import { IUserResponse } from "../user/IUserResponse"

export interface ITicket {
    ticketId: number,
    title: string,
    description: string,
    status: TicketStatusEnum,
    acompanhamentoFk: number,
    dataCriacao: Date,
    messages: ITicketMessage[],
    attachments: ITicketAttachment[]
}

export interface ITicketMessage {
    ticketMessageId: number,
    message: string,
    ticketFk: number,
    userFk: string,
    isEdited: boolean,
    dataHoraEnvio: Date,
    user: IUserResponse
}

export interface ITicketMessageRequest {
    ticketId: number,
    message: string
}

export interface ITicketChangeStatusRequest {
    ticketId: number,
    status: TicketStatusEnum
}

export enum TicketStatusEnum {
    Aberto = 0,
    Fechado = 1
}

export interface IUploadTicketAttachmentRequest {
    ticketId: number,
    file: File
}

export interface ITicketAttachment {
    ticketAttachmentId: number,
    ticketFk: number,
    attachmentFk: number,
    attachment: IAttachment,
    progress: number,
    size: number,
    status: 'error' | 'success' | 'done' | 'uploading' | 'removed'
}