import { ICampoApi } from "../../data/interfaces/config/ICampoApi";
import { IConfig } from "../../data/interfaces/config/IConfig";
import { IInfoTabelaIntegracao } from "../../data/interfaces/config/IInfoTabelaIntegracao";
import { ConfigActionKeys, ConfigActionUnion } from "./Config.actions";
import { IConfigState } from "./interface/IConfigState";

const initialState: IConfigState = {
    config: {} as IConfig,
    isLoading: false,
    error: undefined,
    message: undefined,
    camposApi: [] as ICampoApi[],
    infoTabelaIntegracao: {} as IInfoTabelaIntegracao
}

const configReducer = (state = initialState, action: ConfigActionUnion): IConfigState => {

    switch (action.type) {
        case ConfigActionKeys.GET_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ConfigActionKeys.GET_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                config: action.payload,
            };

        case ConfigActionKeys.UPDATE_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ConfigActionKeys.UPDATE_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                config: action.payload,
            };

        case ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_SUCCESS:
            return {
                ...state,
                statusJobAtualizarBanco: action.payload,
            };

        case ConfigActionKeys.START_ATUALIZAR_BANCO_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.START_ATUALIZAR_BANCO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarBanco: action.payload,
            };

        case ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_SUCCESS:
            return {
                ...state,
                statusJobAtualizarTextoAPI: action.payload,
            };

        case ConfigActionKeys.START_ATUALIZAR_TEXTO_API_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.START_ATUALIZAR_TEXTO_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarTextoAPI: action.payload,
            };

        case ConfigActionKeys.LIST_CAMPOS_API_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ConfigActionKeys.LIST_CAMPOS_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                camposApi: action.payload,
            };

        case ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                infoTabelaIntegracao: action.payload,
            };

        case ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarTextoLancamentoAPI: action.payload,
            };

        case ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_SUCCESS:
            return {
                ...state,
                statusJobAtualizarTextoLancamentoAPI: action.payload,
            };

        case ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarTextoAPI: action.payload,
            };

        case ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarTextoLancamentoAPI: action.payload,
            };

        case ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS:
            return {
                ...state,
                statusJobAtualizarBaseDadosPedido: action.payload,
            };

        case ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statusJobAtualizarBaseDadosPedido: action.payload,
            };

        case ConfigActionKeys.DISABLE_FUNCTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.DISABLE_FUNCTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case ConfigActionKeys.ENABLE_FUNCTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };

        case ConfigActionKeys.ENABLE_FUNCTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };


        case ConfigActionKeys.DEFAULT_CONFIG_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export default configReducer;