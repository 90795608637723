import { Button, List, Progress, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import SelectForm, { IOptionData } from "../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import schema from "./AtualizarItensPedidos.schema";
import styles from './AtualizarItensPedidos.module.scss';
import { getOptionsDataFromObject } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { secondsToMs } from "../../../utils/dateHelper";
import { SvApiActions } from "../../../store/svApi/SvApi.actions";
import { AtualizarPedidoActions } from "../../../store/atualizarPedido/AtualizarPedido.actions";
import { IAtualizarItensPedidoRequest } from "../../../data/interfaces/atualizarPedido/IAtualizarItensPedidoRequest";

export interface IAtualizarItensPedidos {
    visible: boolean;
    onClose: () => void;
}

interface formValues {
    industriaId?: number
}

const AtualizarItensPedidos: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeUpdating, setTimeUpdating] = useState(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);

    useEffect(() => {
        dispatch(SvApiActions.listIndustrias());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0)
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));
    }, [props.industrias?.length]);

    useEffect(() => {
        if (isUpdating) {
            setTimeout(() => {
                setTimeUpdating(timeUpdating + 1);
            }, 1000);
        }
    }, [timeUpdating, isUpdating])

    useEffect(() => {
        if (!props.isLoading && isUpdating) {
            if (!props.errosAtualizacao || props.errosAtualizacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsUpdating(false);
        }
    }, [props.isLoading])

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        dispatch(AtualizarPedidoActions.atualizarItensPedidoClear());
        props.onClose();
    }

    const submit = (values: any) => {
        if (values) {
            if (file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {
                var data = {
                    file: file,
                    industriaId: values.industriaId
                } as IAtualizarItensPedidoRequest;

                dispatch(AtualizarPedidoActions.atualizarItensPedido(data));
                setIsUpdating(true);
                setShowForm(false);
                setValues(values);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(AtualizarPedidoActions.atualizarItensPedidoClear());
        setFile(null);
    }

    const downloadArquivoPadrao = () => {
        window.open(`${process.env.REACT_APP_BASE_API_URL}/AtualizarPedidos/DownloadArquivoPadrao`);
    }

    const buttons =
        [
            <Button hidden={!showForm} key='downloadFile' type='link' style={{ position: 'absolute', left: 10 }} onClick={downloadArquivoPadrao}>Baixar arquivo padrão</Button>,
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosAtualizacao && props.errosAtualizacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Atualizar Itens Pedidos'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosAtualizacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosAtualizacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const updateForm =
        <Form onSubmit={submit} isSubmited={isSubmit} schema={schema} initialValues={values} className={styles['form']}>
            <div className={styles['groupSelect']} style={{ display: "flex" }}>
                <SelectForm name='industriaId' label='Indústria' options={industriasOptions} placeholder={'Selecione uma Indústria'} className={styles['select']} />
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileAtualizarItensPedido')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileAtualizarItensPedido' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    const infoAtualizacao = <div className={styles['infoUpdating']} id='infoUpdating'>
        <p>Iniciando Atualização de Itens de Pedido - <span>{secondsToMs(timeUpdating)}</span></p>
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={100}
            showInfo={false}
            status="active"
        />
    </div>;

    return (
        <Modal title={'Atualização Itens de Pedidos'} open={props.visible} footer={!isUpdating ? buttons : null} closable={false} destroyOnClose={true} width={858}>
            <div className={styles['container']}>
                {
                    isUpdating ?
                        infoAtualizacao :
                        (
                            !showForm && props.errosAtualizacao && props.errosAtualizacao.length > 0 ?
                                errosAtualizacao
                                : updateForm
                        )}
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.svApi,
    ...state.atualizarPedido
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAtualizarItensPedidos;

export default connector(AtualizarItensPedidos);