import { Skeleton, Table } from "antd";
import React, { FC } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { getColumns, TabelasIntegracaoData } from "./TabelasIntegracao.helper";
import styles from './TabelasIntegracao.module.scss';

const TabelasIntegracao: FC<Props> = (props) => {
    return (
        <>
            <PageContent title={`Tabelas Integração - SuasVendas`} className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <Table columns={getColumns()} dataSource={TabelasIntegracaoData} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.config,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(TabelasIntegracao);