import { Button, Divider, Skeleton, Switch, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import styles from './Config.module.scss';
import { ConfigActions } from "../../store/config/Config.actions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { IConfig } from "../../data/interfaces/config/IConfig";
import Field from "../../components/ui/input";
import moment from "moment";
import { dateTimeStringUStoPTBR } from "../../utils/dateHelper";
import { IStatusFunctions, RBRFunctionEnum } from "../../data/interfaces/config/IStatusFunctions";

const Config: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [config, setConfig] = useState<IConfig>({} as IConfig);
    const [statusJobAtualizarBanco, setStatusJobAtualizarBanco] = useState<string>('...');
    const [msgStatusJobAtualizarBanco, setMsgStatusJobAtualizarBanco] = useState<string>();
    const [dataAtualizarBanco, setDataAtualizarBanco] = useState<string>(() => {
        var mes = new Date().getMonth() + 1;
        var dia = new Date().getDate();
        var ano = new Date().getFullYear();
        return `${ano}-${mes >= 10 ? mes : '0' + mes}-${dia >= 10 ? dia : '0' + dia}`;
    });

    const [currentCount, setCount] = useState(5);
    const timer = () => setCount(currentCount - 1);

    useEffect(() => {
        dispatch(ConfigActions.getConfiguracoes());
    }, []);

    useEffect(() => {
        if (props.config) {
            setConfig(props.config);
        }
    }, [props.config]);

    const saveConfig = () => {
        dispatch(ConfigActions.updateConfiguracoes(config));
    }

    const startJobAtualizarBanco = () => {
        if (dataAtualizarBanco && window.confirm('Deseja iniciar a Atualização do Banco?')) {
            dispatch(ConfigActions.startJobAtualizarBanco(dataAtualizarBanco));
        }
    }

    const startJobAtualizarTextoAPI = () => {
        if (window.confirm('Deseja iniciar o serviço de Ajuste de Texto via API?')) {
            dispatch(ConfigActions.startJobAtualizarTextoAPI());
        }
    }

    const startJobAtualizarTextoLancamentoAPI = () => {
        if (window.confirm('Deseja iniciar o serviço de Ajuste de Texto de LANÇAMENTOS via API?')) {
            dispatch(ConfigActions.startJobAtualizarTextoLancamentoAPI());
        }
    }

    const startJobAtualizarBaseDadosPedido = () => {
        if (window.confirm('Deseja iniciar o serviço de Atualizar Base de Dados de Pedidos (Planilhas Excel)?')) {
            dispatch(ConfigActions.startJobAtualizarBaseDadosPedido());
        }
    }

    useEffect(
        () => {
            if (props.statusJobAtualizarBanco) {
                if (props.statusJobAtualizarBanco?.status == "Running") {
                    var diffDate = new Date(props.statusJobAtualizarBanco?.startDateTime).getTime() - new Date().getTime();
                    var diffTime = moment.duration(diffDate);

                    setMsgStatusJobAtualizarBanco("Em andamento: " + (diffTime.minutes() * -1).toString().padStart(2, "0") + ":" + (diffTime.seconds() * -1).toString().padStart(2, "0"));
                    setStatusJobAtualizarBanco('Atualização em Andamento no Servidor...');
                }
                else {
                    setStatusJobAtualizarBanco('Atualização Concluída em ' + dateTimeStringUStoPTBR(props.statusJobAtualizarBanco.startDateTime));

                    var duration = props.statusJobAtualizarBanco?.duration;
                    var minutes = Math.floor(duration / 60);
                    var seconds = duration - minutes * 60;

                    setMsgStatusJobAtualizarBanco(`Duração total: ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
                }
            }

            if (currentCount <= 0) {
                dispatch(ConfigActions.getStatusJobAtualizarBanco());
                dispatch(ConfigActions.getStatusJobAtualizarTextoAPI());
                dispatch(ConfigActions.getStatusJobAtualizarTextoLancamentoAPI());
                dispatch(ConfigActions.getStatusJobAtualizarBaseDadosPedido());
                setCount(5);
                return;
            }
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        }, [currentCount]);


    const getInfoFunction = (status?: IStatusFunctions) => {
        switch (status?.status) {
            case 'Failed':
                return (
                    <div style={{ paddingTop: 10, color: 'red', fontSize: 14 }}>
                        <b>Falha na última execução.</b> Contate equipe técnica.
                        <br /> Último status: {props.statusJobAtualizarTextoLancamentoAPI?.info}
                    </div>)
            case 'Stoped':
                return (
                    <div style={{ paddingTop: 10, fontSize: 14 }}>
                        <b style={{ color: 'orange' }}>Execução Interrompida.</b>
                        <br /> Último status: {props.statusJobAtualizarTextoLancamentoAPI?.info}
                    </div>)
            default:
                return (
                    <div style={{ paddingTop: 10, fontSize: 14 }}>
                        <b>{props.statusJobAtualizarTextoLancamentoAPI ? props.statusJobAtualizarTextoLancamentoAPI?.info : '...'}</b>
                    </div>)
        }
    }

    const stopJobAtualizarTextoLancamentoAPI = () => {
        if (window.confirm('Deseja interromper a execução?')) {
            dispatch(ConfigActions.stopJobAtualizarTextoLancamentoAPI());
        }
    }

    const stopJobAtualizarTextoAPI = () => {
        if (window.confirm('Deseja interromper a execução?')) {
            dispatch(ConfigActions.stopJobAtualizarTextoAPI());
        }
    }

    const disableFunction = (value: RBRFunctionEnum) => {
        if (window.confirm('Deseja desabilitar essa atualização?')) {
            dispatch(ConfigActions.disableFunction(value));
        }
    }

    const enableFunction = (value: RBRFunctionEnum) => {
        dispatch(ConfigActions.enableAllFunctions(value));
    }

    return (
        <>
            <PageContent title='Configurações' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['config']}>
                            <Divider orientation="left">Config. Importação de Pedido</Divider>
                            <div className={styles['toolBar']}>
                                <Button type='primary' loading={props.isLoading} onClick={() => saveConfig()}>Salvar Configurações</Button>
                            </div>
                            <br />
                            <div className={styles['configItem']}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={props.config.confFiltrarClienteXIndustria}
                                    onChange={(e) =>
                                        setConfig(
                                            {
                                                ...config,
                                                confFiltrarClienteXIndustria: e
                                            }
                                        )}
                                    size='small'
                                />
                                <label>Mostrar apenas indústrias relacionadas com cliente no pedido</label>
                            </div>
                            <div className={styles['configItem']}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={props.config.confFiltrarClienteXVendedor}
                                    size='small'
                                    onChange={(e) =>
                                        setConfig(
                                            {
                                                ...config,
                                                confFiltrarClienteXVendedor: e
                                            }
                                        )}
                                />
                                <label>Mostrar apenas vendedores relacionadas com cliente no pedido</label>
                            </div>
                            <div className={styles['configItem']}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={props.config.confFiltrarClienteXTransportadora}
                                    size='small'
                                    onChange={(e) =>
                                        setConfig(
                                            {
                                                ...config,
                                                confFiltrarClienteXTransportadora: e
                                            }
                                        )}
                                />
                                <label>Mostrar apenas transportadoras relacionadas com cliente no pedido</label>
                            </div>
                            <div className={styles['configItem']}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={props.config.confNaoImportarProdutoInativo}
                                    size='small'
                                    onChange={(e) =>
                                        setConfig(
                                            {
                                                ...config,
                                                confNaoImportarProdutoInativo: e
                                            }
                                        )}
                                />
                                <label>{`Não importar produtos com status 'Inativo'`}</label>
                            </div>
                        </div>
                        <div>
                            <Divider orientation="left">Atualizar Extração no Banco de Dados</Divider>
                            <div style={{ display: "flex" }}>
                                <Field hidden={props.statusJobAtualizarBanco && props.statusJobAtualizarBanco?.status == "Disabled"} type='date' onInput={(e) => setDataAtualizarBanco(e)} defaultValue={dataAtualizarBanco} readonly={!props.statusJobAtualizarBanco || props.statusJobAtualizarBanco?.status == "Running"} label='Data de Atualização'></Field>
                                <Button hidden={props.statusJobAtualizarBanco && props.statusJobAtualizarBanco?.status == "Disabled"} style={{ marginTop: 26, marginLeft: 15 }} type='primary' loading={!props.statusJobAtualizarBanco || props.statusJobAtualizarBanco?.status == "Running"} onClick={() => startJobAtualizarBanco()}>Atualizar</Button>
                                <Button onClick={() => disableFunction(RBRFunctionEnum.AtualizarBancoSV)} hidden={props.statusJobAtualizarBanco == undefined || (props.statusJobAtualizarBanco && (props.statusJobAtualizarBanco?.status == "Running" || props.statusJobAtualizarBanco?.status == "Disabled"))} style={{ marginTop: 26, marginLeft: 15 }} type='link'>Desabilitar</Button>
                                <Button onClick={() => enableFunction(RBRFunctionEnum.AtualizarBancoSV)} hidden={props.statusJobAtualizarBanco == undefined || (props.statusJobAtualizarBanco && (props.statusJobAtualizarBanco?.status != "Disabled"))} style={{ marginTop: 26 }} type='primary'>Habilitar Execução</Button>
                            </div>
                            <div style={{ paddingTop: 10, fontSize: 14 }}>
                                <b>{statusJobAtualizarBanco}</b><br />
                                <label>{msgStatusJobAtualizarBanco}</label>
                            </div>
                        </div>
                        <br />
                        <div>
                            <Divider orientation="left">Atualizar Base de Dados de Pedidos (Planilhas Excel)</Divider>
                            <div>
                                <Button hidden={props.statusJobAtualizarBaseDadosPedido && props.statusJobAtualizarBaseDadosPedido?.status == "Disabled"} type='primary' loading={!props.statusJobAtualizarBaseDadosPedido || props.statusJobAtualizarBaseDadosPedido?.status == 'Running'} onClick={() => startJobAtualizarBaseDadosPedido()}>Atualizar</Button>
                                <Button onClick={() => disableFunction(RBRFunctionEnum.AtualizarBaseDadosPedido)} hidden={props.statusJobAtualizarBaseDadosPedido == undefined || (props.statusJobAtualizarBaseDadosPedido && (props.statusJobAtualizarBaseDadosPedido?.status == "Running" || props.statusJobAtualizarBaseDadosPedido?.status == "Disabled"))} style={{ marginTop: 26, marginLeft: 15 }} type='link'>Desabilitar</Button>
                                <Button onClick={() => enableFunction(RBRFunctionEnum.AtualizarBaseDadosPedido)} hidden={props.statusJobAtualizarBaseDadosPedido == undefined || (props.statusJobAtualizarBaseDadosPedido && (props.statusJobAtualizarBaseDadosPedido?.status != "Disabled"))} style={{ marginTop: 26 }} type='primary'>Habilitar Execução</Button>
                            </div>
                            {props.statusJobAtualizarBaseDadosPedido?.status == "Failed"
                                ?
                                <div style={{ paddingTop: 10, color: 'red', fontSize: 14 }}>
                                    <b>Falha na última execução.</b> Contate equipe técnica.
                                </div>
                                :
                                <div style={{ paddingTop: 10, fontSize: 14 }}>
                                    <b>{props.statusJobAtualizarBaseDadosPedido ? props.statusJobAtualizarBaseDadosPedido?.info : '...'}</b>
                                </div>
                            }
                        </div>
                        <br />
                        <div>
                            <Divider orientation="left">Ajustar Textos de Pedidos</Divider>
                            <div>
                                <Button type='primary' loading={!props.statusJobAtualizarTextoAPI || props.statusJobAtualizarTextoAPI?.status == 'Running'} onClick={() => startJobAtualizarTextoAPI()}>Atualizar</Button>
                                <Button hidden={props.statusJobAtualizarTextoAPI?.status != 'Running'} type='default' style={{ marginLeft: 10 }} onClick={stopJobAtualizarTextoAPI}>Interromper Execução</Button>
                            </div>
                            {props.statusJobAtualizarTextoAPI?.status == "Failed"
                                ?
                                <div style={{ paddingTop: 10, color: 'red', fontSize: 14 }}>
                                    <b>Falha na última execução.</b> Contate equipe técnica.
                                </div>
                                :
                                <div style={{ paddingTop: 10, fontSize: 14 }}>
                                    <b>{props.statusJobAtualizarTextoAPI ? props.statusJobAtualizarTextoAPI?.info : '...'}</b>
                                </div>
                            }
                        </div>
                        <br />
                        <div>
                            <Divider orientation="left">Ajustar Textos de Lançamentos do Financeiro</Divider>
                            <div>
                                <Button type='primary' loading={!props.statusJobAtualizarTextoLancamentoAPI || props.statusJobAtualizarTextoLancamentoAPI?.status == 'Running'} onClick={() => startJobAtualizarTextoLancamentoAPI()}>Atualizar</Button>
                                <Button hidden={props.statusJobAtualizarTextoLancamentoAPI?.status != 'Running'} type='default' style={{ marginLeft: 10 }} onClick={stopJobAtualizarTextoLancamentoAPI}>Interromper Execução</Button>
                            </div>
                            {
                                getInfoFunction(props?.statusJobAtualizarTextoLancamentoAPI)
                            }
                        </div>
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.config
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Config);