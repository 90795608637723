import { Button, Divider, Skeleton, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { dateTimeStringUStoPTBR } from "../../../../utils/dateHelper";

export interface IViewEmailsCompradores {
    visible: boolean,
    clienteIndustriaAuxId: number,
    onClose?: () => void;
}

const columns = [
    {
        title: 'Campo',
        dataIndex: 'nameField',
    },
    {
        title: 'Valor Anterior',
        dataIndex: 'oldValue',
    },
    {
        title: 'Valor Novo',
        dataIndex: 'newValue',
    },
];

const ViewAudit: FC<Props> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ClienteActions.listClienteIndustriaAuxAuditoria(props.clienteIndustriaAuxId));
    }, []);

    useEffect(() => {
        if (props.clienteIndustriaAuxAuditorias) {

        }
    }, [props.clienteIndustriaAuxAuditorias])

    const buttons = [<Button key="back" onClick={props.onClose}>Fechar</Button>];

    return (
        <Modal title='Auditoria - Alterações de Cliente/Indústria' visible={props.visible} footer={buttons} onCancel={props.onClose} width={700}>
            {props.isLoading || !props.clienteIndustriaAuxAuditorias ?
                <Skeleton active></Skeleton>
                :
                <>
                    {
                        props.clienteIndustriaAuxAuditorias?.length > 0 ?
                            <>
                                {props.clienteIndustriaAuxAuditorias?.map(x => (
                                    <div key={x.clienteIndustriaAuxAuditoriaId}>
                                        <p><b>{x.user.name}</b> - {dateTimeStringUStoPTBR(x.date)}</p>
                                        <Table columns={columns} dataSource={x.diffFields} size="small" pagination={false} />
                                        <Divider />
                                    </div>))}
                            </>
                            :
                            <div>Não há alterações nesse registro!</div>
                    }
                </>
            }
        </Modal>)
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IViewEmailsCompradores;

export default connector(ViewAudit);