import { IAtualizarPedidoResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoResponse";
import { IAtualizarPedidoItemResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoItemResponse";
import { IAtualizarPedidoState } from "./interfaces/IAtualizarPedidoState";
import { AtualizarPedidoActionKeys, AtualizarPedidoActionUnion } from "./AtualizarPedido.actions";
import { IAtualizarPedidoInfosResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoInfosResponse";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";

const initialState: IAtualizarPedidoState = {
  atualizarPedidos: [] as IAtualizarPedidoResponse[],
  atualizarPedidosItems: [] as IAtualizarPedidoItemResponse[],
  atualizarPedidoInfos: [] as IAtualizarPedidoInfosResponse[],
  isLoading: false,
  error: undefined,
  message: undefined,
}

const atualizarPedidoReducer = (state = initialState, action: AtualizarPedidoActionUnion): IAtualizarPedidoState => {

  switch (action.type) {
    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarPedidos: action.payload,
      };
    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_SUCCESS:

      var atualizarPedidos = [...state.atualizarPedidos];
      var index = atualizarPedidos.findIndex(x => x.atualizarPedidoId == action.payload.atualizarPedidoId);
      atualizarPedidos.splice(index, 1, action.payload);

      return {
        ...state,
        isLoading: false,
        atualizarPedidos: atualizarPedidos,
      };
    case AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarPedidosItems: action.payload,
      };
    case AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarPedidoInfos: action.payload,
      };
    case AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isRestaurandoPedido: true
      };
    case AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_SUCCESS:

      var atualizarPedidoInfos = [...state.atualizarPedidoInfos];
      var index = atualizarPedidoInfos.findIndex(x => x.atualizarPedidoInfoId == action.payload.atualizarPedidoInfoId);
      atualizarPedidoInfos[index] = action.payload;

      return {
        ...state,
        isLoading: false,
        isRestaurandoPedido: false,
        atualizarPedidoInfos: atualizarPedidoInfos
      };
    case AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_FAILED:
      return {
        ...state,
        isLoading: false,
        isRestaurandoPedido: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_SUCCESS:

      var request = action.payload;
      var atualizarPedidosItems = [...state.atualizarPedidosItems];

      if (request.atualizacaoPedidoInfoId == 0) {
        atualizarPedidosItems = atualizarPedidosItems
          .filter(x =>
            x.produtoIDAntigo != request.produtoIdAntigo);
      }
      else {
        atualizarPedidosItems = atualizarPedidosItems
          .filter(x =>
            !(x.atualizarPedidoInfoID == request.atualizacaoPedidoInfoId &&
              x.produtoIDAntigo == request.produtoIdAntigo));
      }

      return {
        ...state,
        isLoading: false,
        atualizarPedidosItems: atualizarPedidosItems
      };
    case AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        atualizarPedidos: [action.payload, ...state.atualizarPedidos]
      };
    case AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_INVALID:
      return {
        ...state,
        isLoading: false,
        errosAtualizacao: action.payload,
      };
    case AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosAtualizacao: [] as IErroResponse[]
      };
    default:
      return state;
  }
};

export default atualizarPedidoReducer;
