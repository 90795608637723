import { IModule } from "../../data/interfaces/module/IModuleColumnUser";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";


export enum ModuleActionKeys {
  GET_MODULES_REQUEST = 'GET_MODULES_REQUEST',
  GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS',

  UPDATE_MODULES_REQUEST = 'UPDATE_MODULES_REQUEST',
  UPDATE_MODULES_SUCCESS = 'UPDATE_MODULES_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const ModuleActions = {
  listModules: (): GetModuleAction => createAction(ModuleActionKeys.GET_MODULES_REQUEST),
  listModulesSuccess: (response: IModule[]): GetModuleSuccessAction => createAction(ModuleActionKeys.GET_MODULES_SUCCESS, response),

  updateModule: (request: IModule): UpdateModuleAction => createAction(ModuleActionKeys.UPDATE_MODULES_REQUEST, request),
  updateModuleSuccess: (response: IModule): UpdateModuleSuccessAction => createAction(ModuleActionKeys.UPDATE_MODULES_SUCCESS, response),

  defaultFailure: (err: string): DefaultFailureAction => createAction(ModuleActionKeys.DEFAULT_FAILED, err),
};

export type ModuleActionUnion = ActionsUnion<typeof ModuleActions>;

export type GetModuleAction = Action<ModuleActionKeys.GET_MODULES_REQUEST>;
export type GetModuleSuccessAction = Action<ModuleActionKeys.GET_MODULES_SUCCESS, IModule[]>;

export type UpdateModuleAction = Action<ModuleActionKeys.UPDATE_MODULES_REQUEST, IModule>;
export type UpdateModuleSuccessAction = Action<ModuleActionKeys.UPDATE_MODULES_SUCCESS, IModule>;

export type DefaultFailureAction = Action<ModuleActionKeys.DEFAULT_FAILED, string>;