import { ITicket, ITicketAttachment } from "../../data/interfaces/ticket/ITicket";
import { AuthUtils } from "../../utils/AuthUtils";
import { ITicketState } from "./interfaces/ITicketState";
import { TicketActionKeys, TicketActionUnion } from "./Ticket.actions";

const initialState: ITicketState = {
  ticket: {} as ITicket,
  isLoading: false,
  error: undefined,
  message: undefined,
  isUploading: false
}

const sellOutReducer = (state = initialState, action: TicketActionUnion): ITicketState => {

  switch (action.type) {
    case TicketActionKeys.GET_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.GET_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticket: action.payload,
      };

    case TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticket: action.payload,
      };

    case TicketActionKeys.CHANGE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case TicketActionKeys.ADD_MESSAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.ADD_MESSAGE_SUCCESS:
      {
        let ticketAux = { ...state.ticket };
        ticketAux.messages = [...ticketAux.messages, action.payload];

        return {
          ...state,
          isLoading: false,
          ticket: ticketAux,
        };
      }

    case TicketActionKeys.SEND_NEW_MESSAGE_SUCCESS:
      {
        let ticketAux = { ...state.ticket }

        if (ticketAux &&
          ticketAux?.ticketId == action.payload.ticketFk &&
          action.payload.userFk != AuthUtils.getLoggedUser().userId) {

          ticketAux.messages = [...ticketAux.messages, action.payload];

          return {
            ...state,
            ticket: ticketAux
          }
        }

        return { ...state };
      }

    case TicketActionKeys.DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.DELETE_MESSAGE_SUCCESS:
      {
        let ticketAux = { ...state.ticket };
        ticketAux.messages = ticketAux.messages.filter(x => x.ticketMessageId != action.payload);

        return {
          ...state,
          isLoading: false,
          ticket: ticketAux
        };
      }

    case TicketActionKeys.CLEAR_TICKET:
      return {
        ...state,
        isLoading: false,
        ticket: {} as ITicket
      };

    case TicketActionKeys.UPLOAD_ATTACHMENT_REQUEST:
      {
        var fileUploading = { status: 'uploading', ticketAttachmentId: -1 } as ITicketAttachment;
        let ticketAux = { ...state.ticket };
        ticketAux.attachments = [...ticketAux.attachments, fileUploading];

        return {
          ...state,
          ticket: ticketAux,
          isUploading: true
        };
      }
    case TicketActionKeys.UPLOAD_ATTACHMENT_SUCCESS:
      {
        let ticketAux = { ...state.ticket };
        ticketAux.attachments = [...ticketAux.attachments.filter(x => x.ticketAttachmentId != -1), action.payload];

        return {
          ...state,
          isUploading: false,
          ticket: ticketAux
        };
      }

    case TicketActionKeys.UPLOAD_ATTACHMENT_UPDATE:
      {
        let ticketAux = { ...state.ticket };
        ticketAux.attachments = [...ticketAux.attachments.filter(x => x.ticketAttachmentId != -1), { ticketAttachmentId: -1, progress: action.payload, status: 'uploading' } as ITicketAttachment];

        return {
          ...state,
          isUploading: false,
          ticket: ticketAux
        };
      }

    case TicketActionKeys.DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TicketActionKeys.DELETE_ATTACHMENT_SUCCESS:
      {
        let ticketAux = { ...state.ticket };
        ticketAux.attachments = ticketAux.attachments.filter(x => x.ticketAttachmentId != action.payload);

        return {
          ...state,
          isLoading: false,
          ticket: ticketAux
        };
      }

    case TicketActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sellOutReducer;
