import { ColumnProps } from "antd/lib/table";
import { sorter } from "../../../../utils/helpers";
import { ICamposIntegracao } from "../../../../data/interfaces/config/IInfoTabelaIntegracao";
import { Button } from "antd";
import React from "react";
import { history } from "../../../../store";

export const getColumns = () => {
    return [
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            sorter: (a, b) => sorter(a.tipo, b.tipo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            sorter: (a, b) => sorter(a.nome, b.nome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: 'Observação',
            dataIndex: 'observacao',
            sorter: (a, b) => sorter(a.observacao, b.observacao),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: 'Tabela Estrangeira',
            dataIndex: 'tabelaEstrangeira',
            sorter: (a, b) => sorter(a.tabelaEstrangeira, b.tabelaEstrangeira),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value: string) => {
                return value ? <Button type='link' size='small' onClick={() => history.push(`/api/campos-integracao/${value}`)}>{value}</Button> : null;
            }
        },
    ] as Array<ColumnProps<ICamposIntegracao>>;
}