import { ITicket, ITicketAttachment, ITicketChangeStatusRequest, ITicketMessage, ITicketMessageRequest, IUploadTicketAttachmentRequest } from "../../data/interfaces/ticket/ITicket";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum TicketActionKeys {
  GET_TICKET_REQUEST = 'GET_TICKET_REQUEST',
  GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS',

  GET_TICKET_ACOMPANHAMENTO_REQUEST = 'GET_TICKET_ACOMPANHAMENTO_REQUEST',
  GET_TICKET_ACOMPANHAMENTO_SUCCESS = 'GET_TICKET_ACOMPANHAMENTO_SUCCESS',

  CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS',

  ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST',
  ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS',

  DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST',
  DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS',

  SEND_NEW_MESSAGE_SUCCESS = 'SEND_NEW_MESSAGE_SUCCESS',

  CLEAR_TICKET = 'CLEAR_TICKET',

  UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_UPDATE = 'UPLOAD_ATTACHMENT_UPDATE',

  DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST',
  DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const TicketActions = {
  getTicket: (request: number): GetTicketAction => createAction(TicketActionKeys.GET_TICKET_REQUEST, request),
  getTicketSuccess: (response: ITicket): GetTicketSuccessAction => createAction(TicketActionKeys.GET_TICKET_SUCCESS, response),

  getTicketAcompanhamento: (request: number): GetTicketAcompanhamentoAction => createAction(TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_REQUEST, request),
  getTicketAcompanhamentoSuccess: (response: ITicket): GetTicketAcompanhamentoSuccessAction => createAction(TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_SUCCESS, response),

  changeStatus: (request: ITicketChangeStatusRequest): ChangeStatusAction => createAction(TicketActionKeys.CHANGE_STATUS_REQUEST, request),
  changeStatusSuccess: (): ChangeStatusSuccessAction => createAction(TicketActionKeys.CHANGE_STATUS_SUCCESS),

  addMessage: (request: ITicketMessageRequest): AddMessageAction => createAction(TicketActionKeys.ADD_MESSAGE_REQUEST, request),
  addMessageSuccess: (response: ITicketMessage): AddMessageSuccessAction => createAction(TicketActionKeys.ADD_MESSAGE_SUCCESS, response),

  sendNewMessageSuccess: (response: ITicketMessage): SendNewMessageSuccessAction => createAction(TicketActionKeys.SEND_NEW_MESSAGE_SUCCESS, response),

  deleteMessage: (request: number): DeleteMessageAction => createAction(TicketActionKeys.DELETE_MESSAGE_REQUEST, request),
  deleteMessageSuccess: (response: number): DeleteMessageSuccessAction => createAction(TicketActionKeys.DELETE_MESSAGE_SUCCESS, response),

  clearTicket: (): ClearTicketAction => createAction(TicketActionKeys.CLEAR_TICKET),

  uploadAttachment: (request: IUploadTicketAttachmentRequest): UploadAttachmentAction => createAction(TicketActionKeys.UPLOAD_ATTACHMENT_REQUEST, request),
  uploadAttachmentSuccess: (response: ITicketAttachment): UploadAttachmentSuccessAction => createAction(TicketActionKeys.UPLOAD_ATTACHMENT_SUCCESS, response),
  uploadAttachmentUpdate: (response: number): UploadAttachmentUpdateAction => createAction(TicketActionKeys.UPLOAD_ATTACHMENT_UPDATE, response),

  deleteAttachment: (request: number): DeleteAttachmentAction => createAction(TicketActionKeys.DELETE_ATTACHMENT_REQUEST, request),
  deleteAttachmentSuccess: (response: number): DeleteAttachmentSuccessAction => createAction(TicketActionKeys.DELETE_ATTACHMENT_SUCCESS, response),

  defaultFailure: (err: string): DefaultFailureAction => createAction(TicketActionKeys.DEFAULT_FAILED, err),
};

export type TicketActionUnion = ActionsUnion<typeof TicketActions>;

export type GetTicketAction = Action<TicketActionKeys.GET_TICKET_REQUEST, number>;
export type GetTicketSuccessAction = Action<TicketActionKeys.GET_TICKET_SUCCESS, ITicket>;

export type GetTicketAcompanhamentoAction = Action<TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_REQUEST, number>;
export type GetTicketAcompanhamentoSuccessAction = Action<TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_SUCCESS, ITicket>;

export type ChangeStatusAction = Action<TicketActionKeys.CHANGE_STATUS_REQUEST, ITicketChangeStatusRequest>;
export type ChangeStatusSuccessAction = Action<TicketActionKeys.CHANGE_STATUS_SUCCESS>;

export type AddMessageAction = Action<TicketActionKeys.ADD_MESSAGE_REQUEST, ITicketMessageRequest>;
export type AddMessageSuccessAction = Action<TicketActionKeys.ADD_MESSAGE_SUCCESS, ITicketMessage>;

export type SendNewMessageSuccessAction = Action<TicketActionKeys.SEND_NEW_MESSAGE_SUCCESS, ITicketMessage>;

export type DeleteMessageAction = Action<TicketActionKeys.DELETE_MESSAGE_REQUEST, number>;
export type DeleteMessageSuccessAction = Action<TicketActionKeys.DELETE_MESSAGE_SUCCESS, number>;

export type UploadAttachmentAction = Action<TicketActionKeys.UPLOAD_ATTACHMENT_REQUEST, IUploadTicketAttachmentRequest>;
export type UploadAttachmentSuccessAction = Action<TicketActionKeys.UPLOAD_ATTACHMENT_SUCCESS, ITicketAttachment>;
export type UploadAttachmentUpdateAction = Action<TicketActionKeys.UPLOAD_ATTACHMENT_UPDATE, number>;

export type ClearTicketAction = Action<TicketActionKeys.CLEAR_TICKET>;

export type DeleteAttachmentAction = Action<TicketActionKeys.DELETE_ATTACHMENT_REQUEST, number>;
export type DeleteAttachmentSuccessAction = Action<TicketActionKeys.DELETE_ATTACHMENT_SUCCESS, number>;

export type DefaultFailureAction = Action<TicketActionKeys.DEFAULT_FAILED, string>;