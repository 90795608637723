import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { UserApi } from '../../data/User.api';
import { UserActions, UserDeleteAction, UserRegisterAction, UserUpdateAction, UserUpdatePasswordAction } from './User.actions';

export function* list() {
  try {
    const { data } = yield call(UserApi.listUsers);
    yield put(UserActions.listUsersSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.listUsersFailure(error));
  }
}

export function* listRoles() {
  try {
    const { data } = yield call(UserApi.listRoles);
    yield put(UserActions.listRolesSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.listRolesFailure(error));
  }
}

export function* registerUser({ payload }: UserRegisterAction) {
  try {
    const { data } = yield call(UserApi.registerUser, payload);
    yield put(UserActions.registerUserSuccess(data.data));
    toast.success('Usuário cadastrado com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.registerUserFailure(error));
  }
}

export function* updateUser({ payload }: UserUpdateAction) {
  try {
    const { data } = yield call(UserApi.updateUser, payload);
    yield put(UserActions.updateUserSuccess(data.data));
    toast.success('Usuário atualizado com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.updateUserFailure(error));
  }
}


export function* updatePassword({ payload }: UserUpdatePasswordAction) {
  try {
    yield call(UserApi.updatePassword, payload);
    yield put(UserActions.updatePasswordSuccess());
    toast.success('Senha atualizada com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.updatePasswordFailure(error));
  }
}

export function* deleteUser({ payload }: UserDeleteAction) {
  try {
    const { data } = yield call(UserApi.deleteUser, payload);
    yield put(UserActions.deleteUserSuccess(data.data));
    toast.success('Usuário excluído com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(UserActions.deleteUserFailure(error));
  }
}