import { IObjectKeys } from "../base/IObjectKeys";
import { IUserResponse } from "../user/IUserResponse";

export interface ISellOut extends IObjectKeys {
    sellOutId: number,
    industriaFk?: number,
    clienteFk?: number,
    ano: number,

    sellOutItems: ISellOutItem[],
    sellOutItemsAuditoria: ISellOutItemAuditoria[]
}

export interface ISellOutItem extends IObjectKeys {
    mes: number,
    pecasMeta: number,
    pecasRealizado: number,
    valorMeta: number,
    valorRealizado: number,
    sellOutCampanha: number,
    observacao: string,
    userFk: string,
    updateDate: Date,
    user: IUserResponse
}

export interface ISellOutItemAuditoria extends IObjectKeys {
    mes: number,
    pecasMeta: number,
    pecasRealizado: number,
    valorMeta: number,
    valorRealizado: number,
    sellOutCampanha: number,
    observacao: string,
    userFk: string,
    updateDate: Date,
    user: IUserResponse
}

export interface IImportSellOutRequest {
    industriaId: number,
    clienteId: number,
    ano: number,
    file?: File | null
}

export const TIPOS_CAMPANHA = ['Sem Campanha', 'RBR', 'RBR Fábrica', 'Concorrente'];