import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { ModuleActions, UpdateModuleAction } from './Module.actions';
import { ModuleApi } from '../../data/Module.api';

export function* listModules() {
  try {
    const { data } = yield call(ModuleApi.listModules);
    yield put(ModuleActions.listModulesSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ModuleActions.defaultFailure(error));
  }
}

export function* updateModule({ payload }: UpdateModuleAction) {
  try {
    const { data } = yield call(ModuleApi.updateModule, payload);
    yield put(ModuleActions.updateModuleSuccess(data.data));
    toast.success('Colunas personalizadas com sucesso');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ModuleActions.defaultFailure(error));
  }
}