import { Button, List, Progress, Skeleton, Switch } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import FieldForm from "../../../../../components/ui/field";
import SelectForm, { IOptionData } from "../../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../../store/base/interface/IGlobalReducerState";
import schema from "./ImportadorPedidoV2.schema";
import styles from './ImportadorPedidoV2.module.scss';
import { getOptionsDataFromObject } from "../../../../../utils/helpers";
import { toast } from "react-toastify";
import { IImportarPedidoData, IImportarPedidoRequest } from "../../../../../data/interfaces/pedido/importador/IImportarPedidoRequest";
import { PedidoImportadoActions } from "../../../../../store/pedido/importador/PedidoImportado.actions";
import { secondsToMs } from "../../../../../utils/dateHelper";
import ClienteForm from '../../../../cliente/modals/formCliente';
import { IClienteIndustriaResponse } from "../../../../../data/interfaces/svData/IClienteIndustriaResponse";
import { isObjectEmpty } from "../../../../../utils/ObjectUtils";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import FieldNumber from "../../../../../components/ui/fieldNumber";
import Field from "../../../../../components/ui/input";
import TextArea from "../../../../../components/ui/textArea";
import { AuthUtils } from "../../../../../utils/AuthUtils";
import { DiffFields } from "../../../../../data/interfaces/utils/IUtil";
import { v4 as uuidv4 } from 'uuid';

export interface IImportadorPedidoV2 {
    visible: boolean;
    onClose: () => void;
    isNovoPedido: boolean;
}

interface formValues {
    clienteId?: number,
    industriaId?: number,
    tabelaPrecoId?: number,
    condicaoPagamento?: string,
    transportadoraId?: number,
    freteId?: number,
    vendedorId?: number,
    statusId?: number,
    isNovoPedido: boolean,
    pedidoId?: number,
    comissao?: number,
    desconto1?: number,
    desconto2?: number,
    desconto3?: number,
    desconto4?: number,
    desconto5?: number,
    desconto6?: number,
    desconto7?: number,
    descontoTotal?: number,
    observacao: string
}

const ImportadorPedidoV2: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const titleModal = props.isNovoPedido ? 'Importar Pedido Novo' : 'Importar Pedido Existente';
    const [clientesOptions, setClientesOptions] = useState<IOptionData[]>([]);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [tabelasPrecoOptions, setTabelasPrecoOptions] = useState<IOptionData[]>([]);
    const [condicoesPagamentoOptions, setCondicoesPagamentoOptions] = useState<IOptionData[]>([]);
    const [transportadorasOptions, setTransportadorasOptions] = useState<IOptionData[]>([]);
    const [vendedorOptions, setVendedorOptions] = useState<IOptionData[]>([]);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeImportation, setTimeImportation] = useState(0);
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [pedidoId, setPedidoId] = useState<number>(0);
    const [statusLimparPedido, setStatusLimparPedido] = useState<'parado' | 'limpandoPedido' | 'recuperandoPedido' | 'concluido'>('parado');
    const [formClienteVisible, setFormClienteVisible] = useState(false);
    const [clienteIndustria, setClienteIndustria] = useState<IClienteIndustriaResponse>({} as IClienteIndustriaResponse);
    const [descontoActive, setDescontoActive] = useState(true);
    const [userLogged] = useState(AuthUtils.getLoggedUser());
    const [defaultValues, setDefaultValuesClienteIndustria] = useState({} as formValues);

    var pedidoStatusOptions = (getOptionsDataFromObject(props.pedidoStatusDB, 'pedidoStatusId', 'pedidoStatusNome'));
    var fretesOptions = ([{ value: 0, text: 'CIF' }, { value: 1, text: 'FOB' }] as IOptionData[]);

    useEffect(() => {
        if (props.clientes && props.clientes?.length > 0) {
            setClientesOptions(getOptionsDataFromObject(props.clientes, 'clienteId', 'clienteRazaoSocial'));
        }
    }, [props.clientes])

    useEffect(() => {
        if (isImporting) {
            setTimeout(() => {
                setTimeImportation(timeImportation + 1);
            }, 1000);
        }
    }, [timeImportation, isImporting])

    useEffect(() => {
        if (!props.isLoading && isImporting) {

            if (props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsImporting(false);
        }

        if (!props.isLoading && statusLimparPedido == 'limpandoPedido') {
            setStatusLimparPedido('concluido');
        }

        if (!props.isLoading && statusLimparPedido == 'recuperandoPedido') {
            setStatusLimparPedido('parado');
        }
    }, [props.isLoading]);

    const clearClienteIndustria = () => setClienteIndustria({} as IClienteIndustriaResponse);

    const onSelectCliente = (clienteId: number) => {

        clearClienteIndustria();

        setValues(
            {
                ...values,
                pedidoId: pedidoId > 0 ? pedidoId : undefined,
                isNovoPedido: props.isNovoPedido,
                clienteId: clienteId,
                industriaId: undefined,
                transportadoraId: undefined,
                vendedorId: undefined,
                tabelaPrecoId: undefined,
                condicaoPagamento: undefined,
                freteId: undefined,
                statusId: undefined,
                comissao: undefined
            });

        setIndustriasOptions(getOptionsDataFromObject(
            props.config.confFiltrarClienteXIndustria ?
                props.industrias?.filter((industria) => {
                    var index = props?.clienteXIndustria?.findIndex(cXi => cXi?.clieIndustriaClienteId == clienteId && cXi?.clieIndustriaIndustriaId == industria?.industriaId);
                    return index === 0 || (index && index > -1);
                })
                :
                props.industrias,
            'industriaId', 'industriaNomeFantasia'));
    }

    const onSelectIndustria = (industriaId: number) => {

        var clienteIndustria = props?.clienteXIndustria?.find(cXi => cXi?.clieIndustriaClienteId == values.clienteId && cXi?.clieIndustriaIndustriaId == industriaId);

        if (clienteIndustria) {

            setClienteIndustria(clienteIndustria);

            var optionsTabelaPreco = getOptionsDataFromObject(
                props.tabelasPreco?.filter((tabelaPreco) => tabelaPreco.tabelaPrecoIndustriaId == industriaId),
                'tabelaPrecoId', 'tabelaPrecoNome');
            setTabelasPrecoOptions(optionsTabelaPreco);

            var optionsTransportadoras = getOptionsDataFromObject(
                props.config.confFiltrarClienteXTransportadora ?
                    props.transportadoras?.filter((transportadora) => {
                        var index = clienteIndustria?.transportadorasId?.findIndex(id => id == transportadora?.transportadoraId) ?? -1;
                        return index > -1;
                    })
                    :
                    props.transportadoras,
                'transportadoraId', 'transportadoraNomeFantasia');
            setTransportadorasOptions(optionsTransportadoras);

            var optionsVendedores = getOptionsDataFromObject(
                props.config.confFiltrarClienteXVendedor ?
                    props.usuarios?.filter((usuario) => {
                        var index = clienteIndustria?.vendedoresId?.findIndex(id => id == usuario?.idSuasVendas) ?? -1;
                        return index > -1;
                    })
                    :
                    props.usuarios,
                'idSuasVendas', 'name');
            setVendedorOptions(optionsVendedores);

            setValues(
                {
                    ...values,
                    industriaId: industriaId,
                    tabelaPrecoId: optionsTabelaPreco?.length == 1 ? parseInt(optionsTabelaPreco[0]?.value.toString()) : undefined,
                    condicaoPagamento: clienteIndustria?.condicaoPagamento,
                    comissao: clienteIndustria?.comissaoPedido,
                    freteId: clienteIndustria?.freteId,
                    statusId: clienteIndustria?.pedidoStatusId,
                    transportadoraId: optionsTransportadoras?.length == 1 ? parseInt(optionsTransportadoras[0]?.value.toString()) : undefined,
                    vendedorId: optionsVendedores?.length == 1 ? parseInt(optionsVendedores[0]?.value.toString()) : undefined,
                    observacao: clienteIndustria?.observacao,
                    desconto1: clienteIndustria?.descontoEncadeado_1,
                    desconto2: clienteIndustria?.descontoEncadeado_2,
                    desconto3: clienteIndustria?.descontoEncadeado_3,
                    desconto4: clienteIndustria?.descontoEncadeado_4,
                    desconto5: clienteIndustria?.descontoEncadeado_5,
                    desconto6: clienteIndustria?.descontoEncadeado_6,
                    desconto7: clienteIndustria?.descontoEncadeado_7,
                    descontoTotal: clienteIndustria?.clieIndustriaDesconto
                });

            var formasPagamentoClientesOptions = getOptionsDataFromObject(
                props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == industriaId && formaPagamento.clientesId.includes(values.clienteId ?? -1)),
                'formaPagamentoNome', 'formaPagamentoNome');
            formasPagamentoClientesOptions.forEach(x => { x.text += ' (PEDIDOS CLIENTE)'; x.style = { color: 'blue' }; });

            var formasPagamentoIndustriasOptions = getOptionsDataFromObject(
                props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == industriaId && !formaPagamento.clientesId.includes(values.clienteId ?? -1)),
                'formaPagamentoNome', 'formaPagamentoNome');
            formasPagamentoIndustriasOptions.forEach(x => { x.text += ' (INDÚSTRIA)'; x.style = { color: '#d87724' }; });

            var formasPagamento = [...formasPagamentoClientesOptions, ...formasPagamentoIndustriasOptions];

            if (clienteIndustria?.condicaoPagamento) {
                var formaPagamentoIndex = formasPagamento.findIndex(x => x.value == clienteIndustria?.condicaoPagamento);
                if (formaPagamentoIndex > -1) {
                    var formaPagamentoFicha = formasPagamento.find(x => x.value == clienteIndustria?.condicaoPagamento) ?? {} as IOptionData;
                    formaPagamentoFicha.text = formaPagamentoFicha.value + ' (FICHA CLIENTE)';
                    formaPagamentoFicha.style = { color: 'green' };

                    formasPagamento.splice(formaPagamentoIndex, 1);
                    formasPagamento.splice(0, 0, formaPagamentoFicha);
                }
            }

            setCondicoesPagamentoOptions(formasPagamento);
        }
        else {
            clearClienteIndustria();
        }
    }

    useEffect(() => {
        setDefaultValuesClienteIndustria(values);
    }, [values]);

    const onAddFormaPagamento = (e: any) => {

        if (condicoesPagamentoOptions.findIndex(x => x.value.toString().toLowerCase() == e.toLowerCase()) > -1) {
            toast.error('Condição de Pagamento já existente');
        }
        else {
            var newItem = {
                value: e,
                text: `${e} (NOVO)`,
                style: { color: 'green' },
                info: { isNovaFormaPagamento: true }
            } as IOptionData;

            setCondicoesPagamentoOptions([newItem, ...condicoesPagamentoOptions]);

            setValues({
                ...values,
                condicaoPagamento: e
            });
        }
    }

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        setStatusLimparPedido('parado');
        setPedidoId(0);
        dispatch(PedidoImportadoActions.importarPedidoV2Clear());
        props.onClose();
    }

    const limparPedido = () => {
        if (pedidoId == 0) {
            toast.error('Informe o Número do Pedido');
        }
        else {
            dispatch(PedidoImportadoActions.limparPedido(pedidoId));
            setStatusLimparPedido('limpandoPedido');
        }
    }

    const recuperarPedido = () => {
        if (pedidoId == 0) {
            toast.error('Informe o Número do Pedido');
        }
        else {
            dispatch(PedidoImportadoActions.recuperarPedido(pedidoId));
            setStatusLimparPedido('recuperandoPedido');
        }
    }

    const getDiffValues = (valuesForm: IImportarPedidoData) => {

        var fieldsDiff: DiffFields[] = [];

        if (defaultValues.tabelaPrecoId != valuesForm.tabelaPrecoId) {
            var oldValue = tabelasPrecoOptions.find(x => x.value == defaultValues.tabelaPrecoId);
            var newValue = tabelasPrecoOptions.find(x => x.value == valuesForm.tabelaPrecoId);
            fieldsDiff.push({ nameField: 'Tabela de Preço', oldValue: oldValue?.text, newValue: newValue?.text });
        }

        if (defaultValues.condicaoPagamento != valuesForm.condicaoPagamento) {
            fieldsDiff.push({ nameField: 'Condição de Pagamento', oldValue: defaultValues.condicaoPagamento, newValue: valuesForm.condicaoPagamento });
        }

        if (defaultValues.comissao != valuesForm.comissao) {
            fieldsDiff.push({ nameField: 'Comissão', oldValue: defaultValues.comissao?.toString(), newValue: valuesForm.comissao?.toString() });
        }

        if (defaultValues.freteId != valuesForm.freteId) {
            var oldValue = fretesOptions.find(x => x.value == defaultValues.freteId);
            var newValue = fretesOptions.find(x => x.value == valuesForm.freteId);
            fieldsDiff.push({ nameField: 'Frete', oldValue: oldValue?.text, newValue: newValue?.text });
        }

        if (defaultValues.statusId != valuesForm.statusId) {
            var oldValue = pedidoStatusOptions.find(x => x.value == defaultValues.statusId);
            var newValue = pedidoStatusOptions.find(x => x.value == valuesForm.statusId);
            fieldsDiff.push({ nameField: 'Status', oldValue: oldValue?.text, newValue: newValue?.text });
        }

        if (defaultValues.observacao != valuesForm.observacao) {
            fieldsDiff.push({ nameField: 'Observação', oldValue: defaultValues.observacao, newValue: valuesForm.observacao });
        }

        return fieldsDiff;
    }

    const submit = (valuesForm: any) => {
        if (valuesForm) {
            if (file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {

                var data = {
                    file: file,
                    data: valuesForm,
                    isNovaFormaPagamento: condicoesPagamentoOptions.find(x => x.value == valuesForm.condicaoPagamento)?.info?.isNovaFormaPagamento ?? false,
                    fieldsDiff: getDiffValues(valuesForm)
                } as IImportarPedidoRequest;

                var pedidoGuid = uuidv4();
                data.data.GUIDImportacao = pedidoGuid;

                if (!descontoActive) {
                    data.data.descontoTotal = 0;
                    data.data.descontoEncadeado = '0+0+0+0+0+0+0'
                }
                else {
                    data.data.descontoEncadeado =
                        (values.desconto1 && values.desconto1 > 0 ? values.desconto1.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto2 && values.desconto2 > 0 ? values.desconto2.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto3 && values.desconto3 > 0 ? values.desconto3.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto4 && values.desconto4 > 0 ? values.desconto4.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto5 && values.desconto5 > 0 ? values.desconto5.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto6 && values.desconto6 > 0 ? values.desconto6.toFixed(2).replaceAll('.', ',') : '0') + '+' +
                        (values.desconto7 && values.desconto7 > 0 ? values.desconto7.toFixed(2).replaceAll('.', ',') : '0');
                }

                dispatch(PedidoImportadoActions.importarPedidoInfoCreate(pedidoGuid));
                dispatch(PedidoImportadoActions.importarPedidoV2(data));
                setIsImporting(true);
                setShowForm(false);
                setValues(valuesForm);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(PedidoImportadoActions.importarPedidoV2Clear());
        setFile(null);
    }

    const downloadArquivoPadrao = () => {
        window.open(`${process.env.REACT_APP_BASE_API_URL}/PedidoImportado/DownloadArquivoPadrao`);
    }

    const onSaveClienteForm = () => {
        clearClienteIndustria();
        setValues(
            {
                ...values,
                pedidoId: pedidoId > 0 ? pedidoId : undefined,
                isNovoPedido: props.isNovoPedido,
                clienteId: values.clienteId,
                industriaId: undefined,
                transportadoraId: undefined,
                vendedorId: undefined,
                tabelaPrecoId: undefined,
                condicaoPagamento: undefined,
                freteId: undefined,
                statusId: undefined,
                comissao: undefined
            });

        dispatch(PedidoImportadoActions.listClienteIndustria());
    }

    const buttons =
        [
            <Button key='showCadastro' disabled={isObjectEmpty(clienteIndustria)} onClick={() => setFormClienteVisible(!formClienteVisible)}>Ver Cadastro</Button>,
            <Button hidden={!showForm} key='downloadFile' type='link' style={{ position: 'absolute', left: 10 }} onClick={downloadArquivoPadrao}>Baixar arquivo padrão</Button>,
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} disabled={!props.isNovoPedido && statusLimparPedido != 'concluido'} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosImportacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const onChangeDesconto = (descNum: number, value: string) => {
        var descAux = (!isNaN(parseFloat(value.replaceAll(',', '.'))) && !(parseFloat(value.replaceAll(',', '.')) < 0)) ? parseFloat(value.replaceAll(',', '.')) : 0;
        setValues({ ...values, ['desconto' + descNum.toString()]: descAux });
    }

    useEffect(() => {
        var descTotal =
            (values.desconto1 && values.desconto1 > 0 ? 1 - (values.desconto1 / 100) : 1) *
            (values.desconto2 && values.desconto2 > 0 ? 1 - (values.desconto2 / 100) : 1) *
            (values.desconto3 && values.desconto3 > 0 ? 1 - (values.desconto3 / 100) : 1) *
            (values.desconto4 && values.desconto4 > 0 ? 1 - (values.desconto4 / 100) : 1) *
            (values.desconto5 && values.desconto5 > 0 ? 1 - (values.desconto5 / 100) : 1) *
            (values.desconto6 && values.desconto6 > 0 ? 1 - (values.desconto6 / 100) : 1) *
            (values.desconto7 && values.desconto7 > 0 ? 1 - (values.desconto7 / 100) : 1);

        descTotal = 100 - (descTotal * 100);
        setValues({ ...values, descontoTotal: descTotal });

    }, [values.desconto1, values.desconto2, values.desconto3, values.desconto4, values.desconto5, values.desconto6, values.desconto7]);

    const importForm =
        <Form onSubmit={submit} isSubmited={isSubmit} schema={schema} initialValues={values} className={styles['form']}>
            <FieldForm type='hidden' name='isNovoPedido'></FieldForm>
            <div className={styles['groupSelect']} style={{ display: "flex" }} hidden={props.isNovoPedido}>
                <FieldForm readonly={statusLimparPedido != 'parado'} type='number' name='pedidoId' label='Número do Pedido' placeholder={'Número do Pedido'} onInput={(e) => setPedidoId(e ? parseInt(e) : 0)} />
                <Button loading={props.isLoading} hidden={!(statusLimparPedido == 'parado' || statusLimparPedido == 'limpandoPedido')} type='primary' danger onClick={() => limparPedido()}>{'Limpar Pedido'}</Button>
                <Button loading={props.isLoading} hidden={!(statusLimparPedido == 'concluido' || statusLimparPedido == 'recuperandoPedido')} type='link' onClick={() => recuperarPedido()}>Recuperar Pedido</Button>
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='clienteId' label='Cliente' options={clientesOptions} placeholder={'Selecione um Cliente'} className={styles['select']} onSelect={onSelectCliente} />
                <SelectForm onSelect={onSelectIndustria} onClear={() => onSelectCliente(values.clienteId ?? 0)} name='industriaId' label='Indústria' options={industriasOptions} placeholder={values.clienteId ? 'Selecione uma Indústria' : 'Selecione um Cliente'} disabled={!values.clienteId} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm style={{ width: 350, display: 'table-cell', paddingRight: 10 }} name='tabelaPrecoId' label='Tabela de Preço' options={tabelasPrecoOptions} placeholder={values.industriaId ? 'Selecione uma Tabela de Preço' : 'Selecione uma Indústria'} disabled={!values.industriaId || !userLogged.allowEditImportadorPedido} />
                <SelectForm style={{ width: 350, display: 'table-cell', paddingRight: 10 }} name='condicaoPagamento' showAddItem={true} onAddItem={onAddFormaPagamento} label='Condição de Pagamento' options={condicoesPagamentoOptions} placeholder={values.industriaId ? 'Selecione uma Condição de Pagamento' : 'Selecione um Indústria'} disabled={!values.industriaId || !userLogged.allowEditImportadorPedido} />
                <FieldForm style={{ width: 50, display: 'table-cell' }} name='comissao' label='Comissão' placeholder={values.industriaId ? 'Comissão' : 'Selecione uma Indústria'} disabled={!values.industriaId || !userLogged.allowEditImportadorPedido} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='transportadoraId' label='Transportadora' options={transportadorasOptions} placeholder={values.industriaId ? 'Selecione uma Transportadora' : 'Selecione uma Indústria'} disabled={!values.industriaId || (!userLogged.allowEditImportadorPedido && transportadorasOptions?.length == 1)} className={styles['select']} />
                <SelectForm name='freteId' label='Frete' options={fretesOptions} placeholder={values.industriaId ? 'Selecione um Frete' : 'Selecione uma Indústria'} disabled={!values.industriaId || !userLogged.allowEditImportadorPedido} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='vendedorId' label='Vendedor' options={vendedorOptions} placeholder={values.industriaId ? 'Selecione um Vendedor' : 'Selecione uma Indústria'} disabled={!values.industriaId || (!userLogged.allowEditImportadorPedido && vendedorOptions?.length == 1)} className={styles['select']} />
                <SelectForm name='statusId' label='Status' options={pedidoStatusOptions} placeholder={values.industriaId ? 'Selecione um Status' : 'Selecione uma Indústria'} disabled={!values.industriaId || !userLogged.allowEditImportadorPedido} className={styles['select']} />
            </div>
            <div>
                <TextArea autoComplete='false' key='observacao' label='Observação' name='observacao' styleInput={{ width: '100%' }} disabled={!userLogged.allowEditImportadorPedido} rows={3}></TextArea>
            </div>
            <div className={styles['groupSelect']}>
                <div className={styles['buttonDesconto']} style={{ display: userLogged.allowEditImportadorPedido ? 'block' : 'none' }}>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={descontoActive}
                        size='small'
                        onChange={(e) => setDescontoActive(e)}
                    />
                    <label>Editar Desconto</label>
                </div>
                <div style={{ display: 'flex', marginTop: userLogged.allowEditImportadorPedido ? 0 : 10 }}>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(1, e)} label='D1 %' autoComplete='false' key='desconto1' name='desconto1' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(2, e)} label='D2 %' autoComplete='false' key='desconto2' name='desconto2' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(3, e)} label='D3 %' autoComplete='false' key='desconto3' name='desconto3' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(4, e)} label='D4 %' autoComplete='false' key='desconto4' name='desconto4' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(5, e)} label='D5 %' autoComplete='false' key='desconto5' name='desconto5' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(6, e)} label='D6 %' autoComplete='false' key='desconto6' name='desconto6' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <FieldNumber readonly={!descontoActive || !userLogged.allowEditImportadorPedido} min={0} precision={2} onBlur={(e) => onChangeDesconto(7, e)} label='D7 %' autoComplete='false' key='desconto7' name='desconto7' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                    <Field value={values.descontoTotal?.toFixed(2).replaceAll('.', ',')} readonly={true} label='Desc Total %' autoComplete='false' key='descontoTotal' styleInput={{ width: 118 }} className={`${styles['inputGroup']} ${styles['campoDescontoTotal']}`}></Field>
                </div>
            </div>
            <div className={styles['groupSelect']}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportadorPedido')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportadorPedido' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form >;

    var percentProgress = 0;
    var messageImportacao = <p>Importando Pedido - (0%) <span>{secondsToMs(timeImportation)}</span></p>;

    if (!props.pedidoImportadoInfo?.qtdeTotalItens || props.pedidoImportadoInfo?.qtdeTotalItens == 0) {
        percentProgress = 5;
        messageImportacao = <p>Iniciando Importação - (5%) <span>{secondsToMs(timeImportation)}</span></p>;
    }
    else if (props.pedidoImportadoInfo?.qtdeTotalItens && props.pedidoImportadoInfo?.qtdeTotalItens > 0 && props.pedidoImportadoInfo?.qtdeItensImportado == props.pedidoImportadoInfo?.qtdeTotalItens) {
        percentProgress = 95;
        messageImportacao = <p>Finalizando Importação - (95%) <span>{secondsToMs(timeImportation)}</span></p>;
    }
    else if (props.pedidoImportadoInfo?.qtdeItensImportado && props.pedidoImportadoInfo?.qtdeTotalItens) {
        percentProgress = parseFloat((((props.pedidoImportadoInfo?.qtdeItensImportado / props.pedidoImportadoInfo?.qtdeTotalItens) * 90) + 5).toFixed(2));
        messageImportacao = <p>Importando Itens do Pedido - {props.pedidoImportadoInfo?.qtdeItensImportado}/{props.pedidoImportadoInfo?.qtdeTotalItens} ({percentProgress}%) - <span>{secondsToMs(timeImportation)}</span></p>;
    }

    var logImportacao = props.pedidoImportadoInfo?.infos?.map((info: string) => info).join('\r\n');

    const infoImportation = <div className={styles['infoImportation']} id='infoImportation'>
        {messageImportacao}
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={percentProgress}
            showInfo={false}
            status="active"
        />
        <div>
            <textarea id='logImportacao' name='logImportacao' rows={6} value={logImportacao} disabled={true} style={{ marginTop: 20, color: '#483E41', width: '100%' }} />
        </div>
    </div>;

    var textarea = document.getElementById('logImportacao');

    if (textarea)
        textarea.scrollTop = textarea.scrollHeight;

    return (
        <>
            <Modal title={titleModal} open={props.visible} footer={!isImporting ? buttons : null} closable={false} destroyOnClose={true} width={858}>
                <div className={styles['container']}>
                    {
                        props.isLoadingDataImportadorPedido ?
                            <Skeleton active />
                            :
                            <>
                                {isImporting ?
                                    infoImportation :
                                    (
                                        !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                            errosImportacao
                                            : importForm
                                    )}
                            </>
                    }
                </div>
            </Modal>
            {formClienteVisible ?
                <ClienteForm updateStatus={false} clienteIndustria={clienteIndustria} visible={formClienteVisible} onSave={onSaveClienteForm} onClose={() => setFormClienteVisible(false)}></ClienteForm>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.svApi,
    ...state.config,
    ...state.svDb,
    ...state.pedidoImportado
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IImportadorPedidoV2;

export default connector(ImportadorPedidoV2);