import { ColumnProps } from "antd/lib/table";
import React from "react";
import { FaPen } from "react-icons/fa";
import { IContatoAux } from "../../data/interfaces/contato/IContato";
import { getFilterSearchProps, sorter } from "../../utils/helpers";
import styles from './Contatos.module.scss';
import { countDistinctValues } from "../../utils/ObjectUtils";
import { IModuleColumnUser } from "../../data/interfaces/module/IModuleColumnUser";

export const getColumns = (columns: IModuleColumnUser[], onEdit: (register: IContatoAux) => void) => {

    var visibleColumns = [{
        width: 50,
        className: styles['columnAction'],
        sorter: (a, b) => sorter(a.dadosAlinhados, b.dadosAlinhados),
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        render: (value, register) => {
            return (
                <div style={{ borderLeft: register.dadosAlinhados ? '' : '3px solid red' }}>
                    <FaPen title='Editar' onClick={() => onEdit(register)} />
                </div>
            )
        }
    }] as Array<ColumnProps<IContatoAux>>;

    var columnsAux = [
        {
            title: 'Nome',
            dataIndex: 'contatoNome',
            sorter: (a, b) => sorter(a.contatoNome, b.contatoNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('contatoNome', 'Nome'),
        },
        {
            title: 'Email',
            dataIndex: 'contatoEmail',
            sorter: (a, b) => sorter(a.contatoEmail, b.contatoEmail),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('contatoEmail', 'Email'),
        },
        {
            title: 'Clientes',
            dataIndex: 'clientes',
            sorter: (a, b) => sorter(a.clientes, b.clientes),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clientes', 'Clientes'),
        },
        {
            title: 'Celular',
            dataIndex: 'contatoCelular',
        },
        {
            title: 'Telefone',
            dataIndex: 'contatoTelefone',
        },
        {
            title: 'Whatsapp',
            dataIndex: 'contatoWhatsapp',
        },
        {
            title: 'Cargo',
            dataIndex: 'contatoCargo',
            sorter: (a, b) => sorter(a.contatoCargo, b.contatoCargo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('contatoCargo', 'Cargo'),
        }
    ] as Array<ColumnProps<IContatoAux>>;

    columns?.forEach(c => {
        if (c.display) {
            var item = columnsAux.find(x => x.title?.toString().startsWith(c.name));
            visibleColumns.push(item ?? {});
        }
    });

    return visibleColumns;
}

export const getColunasDesalinhadas = (contatos: IContatoAux[]): string[] => {
    var camposDesalinhados: string[] = [];
    var camposVerificar = ["contatoTelefone", "contatoCelular", "contatoNextel", "contatoSkype", "contatoDataAniversario", "contatoObservacao",
        "contatoWhatsapp", "contatoCargo", "departamentoId", "contatoEmailSecundario", "contatoTwitter", "contatoTelefone2", "comprador"];

    camposVerificar.forEach(campo => {
        if (countDistinctValues(contatos, campo) > 1) {
            camposDesalinhados.push(campo);
        }
    });

    return camposDesalinhados;
}

