import { Button, Col, DatePicker, Modal, Row, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import styles from './Acompanhamento.module.scss';
import { toast } from "react-toastify";
import { history } from "../../store";
import Form from "../../components/ui/form";
import SelectForm, { IOptionData } from "../../components/ui/selectForm";
import { anosOptions, dateStringUStoPTBR, mesesOptions, stringDBToDate, stringDBToMoment } from "../../utils/dateHelper";
import { getOptionsDataFromObject } from "../../utils/helpers";
import { SvDbActions } from "../../store/svDb/SvDb.actions";
import { IAcompanhamentoRequest, IUpdateAcompanhamentoRequest } from "../../data/interfaces/acompanhamento/IAcompanhamentoRequest";
import { AcompanhamentoActions } from "../../store/acompanhamento/Acompanhamento.actions";
import { IAcompanhamento, IAcompanhamentoAuditoria } from "../../data/interfaces/acompanhamento/IAcompanhamento";
import FieldForm from "../../components/ui/field";
import { simNaoOptions } from "../../utils/optionsHelper";
import 'dayjs/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import ImportadorAcompanhamento from './modals/importador';
import Conversation from "./modals/conversation";
import dayjs from "dayjs";
import { Http } from "../../utils/Http";

export interface IAcompanhamentoValues {
    match: any;
}

const Acompanhamento: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [valuesLoad, setQueryAcompanhamento] = useState<IAcompanhamentoRequest>({} as IAcompanhamentoRequest);
    const [values, setValues] = useState([] as IAcompanhamento[]);
    const [loadImporting, setLoadImporting] = useState(false);
    const [isVisibleImportador, setIsVisibleImportador] = useState(false);
    const [isVisibleModalAuditoria, setIsVisibleModalAuditoria] = useState(false);
    const [isVisibleMensagens, setIsVisibleMensagens] = useState(false);
    const [acompanhamentoIdSelected, setAcompanhamentoIdSelected] = useState(0);

    var ano = parseInt(Http.GetQueryParams("ano") ?? '0');
    var mes = parseInt(Http.GetQueryParams("mes") ?? '0');
    var industriaId = parseInt(Http.GetQueryParams("industriaId") ?? '0');
    var ticketAcompanhamentoId = parseInt(Http.GetQueryParams("ticketAcompanhamentoId") ?? '0');

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0) {
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));

            if (industriaId > 0 && mes > 0 && ano > 0 && ticketAcompanhamentoId > 0) {
                setQueryAcompanhamento({
                    ...valuesLoad,
                    industriaId: industriaId,
                    ano: ano,
                    mes: mes
                });

                var request = { industriaId: industriaId, mes: mes, ano: ano } as IAcompanhamentoRequest;
                dispatch(AcompanhamentoActions.getAcompanhamento(request));
                handleOpenMensagens(ticketAcompanhamentoId);
            }
        }
    }, [props.industrias]);

    useEffect(() => {
        if (props.acompanhamentos && props.acompanhamentos?.length > 0) {
            setValues([...props.acompanhamentos]);
            setIsDataLoaded(true);
        }
    }, [props.acompanhamentos]);

    const handleLoadDados = () => {
        if (valuesLoad.industriaId && valuesLoad.mes && valuesLoad.ano)
            dispatch(AcompanhamentoActions.getAcompanhamento(valuesLoad));
        else
            toast.error('Selecione os campos!');
    }

    const handleChangeValues = (index: number, param: string, value: any, isInteger: boolean = false) => {
        let valuesAux = [...values];
        valuesAux[index][param] = value;

        setValues(valuesAux);
    }

    const handleClearForm = () => {
        setValues([] as IAcompanhamento[]);
        setIsDataLoaded(false);
    }

    const handleCloseImportador = (reload?: boolean) => {
        if (reload) {
            handleClearForm();
            setLoadImporting(true);
            setTimeout(() => {
                setLoadImporting(false);
                dispatch(AcompanhamentoActions.getAcompanhamento(valuesLoad));
            }, 500);
        }

        setIsVisibleImportador(false);
    }

    const handleOpenMensagens = (acompanhamentoId: number) => {
        setAcompanhamentoIdSelected(acompanhamentoId);
        setIsVisibleMensagens(true);
    }

    const onSubmit = () => {
        if (values) {
            let updateAcompanhamento: IUpdateAcompanhamentoRequest = {
                ano: valuesLoad.ano,
                mes: valuesLoad.mes,
                industriaId: valuesLoad.industriaId,
                items: values
            }

            dispatch(AcompanhamentoActions.updateAcompanhamento(updateAcompanhamento));
        }

        setIsSubmit(false);
    }

    return (
        <>
            <PageContent title='Acompanhamento Mensal' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['form']}>
                            <Form onSubmit={onSubmit} isSubmited={isSubmit} initialValues={valuesLoad} >
                                <div className={styles['form-top']}>
                                    <SelectForm disabled={isDataLoaded} className={styles['select-lg']} onSelect={(e) => setQueryAcompanhamento({ ...valuesLoad, industriaId: e })} placeholder='Selecione uma Indústria' name='industriaId' label='Indústria' options={industriasOptions} />
                                    <SelectForm disabled={isDataLoaded} className={styles['select-sm']} onSelect={(e) => setQueryAcompanhamento({ ...valuesLoad, mes: e })} placeholder='Mês' name='mes' label='Mês' options={mesesOptions} />
                                    <SelectForm disabled={isDataLoaded} className={styles['select-sm']} onSelect={(e) => setQueryAcompanhamento({ ...valuesLoad, ano: e })} placeholder='Ano' name='ano' label='Ano' options={anosOptions} />
                                    <Button hidden={isDataLoaded} className={styles['button']} type='primary' onClick={handleLoadDados}>Carregar Dados</Button>

                                    <Button onClick={() => history.goBack()} hidden={isDataLoaded} className={styles['button']} type='link'>Voltar</Button>
                                    <Button onClick={() => setIsSubmit(true)} hidden={!isDataLoaded} className={styles['button']} type='primary'>Salvar</Button>
                                    <Button style={{ marginLeft: 10 }} hidden={!isDataLoaded} className={`${styles['button']} btn-green`} type='primary' onClick={() => setIsVisibleImportador(true)}>Importar</Button>
                                    <Button style={{ marginLeft: 10 }} hidden={!isDataLoaded} className={styles['button']} type='default' onClick={() => setIsVisibleModalAuditoria(true)}>Histórico de Alteração</Button>
                                    <Button hidden={!isDataLoaded} className={styles['button']} type='link' onClick={handleClearForm}>Cancelar</Button>
                                </div>
                            </Form>
                        </div>
                        <div>
                            {
                                !isDataLoaded || loadImporting ?
                                    <div style={{ width: '100%', textAlign: 'center', paddingTop: 70, background: '#f5f5f5', height: 200 }}>
                                        <h3>Nenhuma informação carregada!</h3>
                                        <p style={{ color: 'gray' }}>Escolha os dados e clique para buscar os dados.</p>
                                    </div>
                                    :
                                    <div className={styles['formData']}>
                                        <Form onSubmit={() => null}>
                                            <div style={{ display: 'flex' }}>
                                                <div className={styles['header']} style={{ width: 145 }}>
                                                    Cliente
                                                </div>
                                                <div className={styles['header']} style={{ width: 185 }}>
                                                    Contato - Semanas
                                                </div>
                                                <div className={styles['header']} style={{ width: 160 }}>
                                                    Data-Base<br />Faturamento
                                                </div>
                                                <div className={styles['header']} style={{ width: 92 }}>
                                                    Camp. de<br /> Venda
                                                </div>
                                                <div className={styles['header']} style={{ width: 95 }}>
                                                    Plano de<br />Bônus
                                                </div>
                                                <div className={styles['header']} style={{ width: 190 }}>
                                                    Obs. Fábrica/Mês
                                                </div>
                                                <div className={styles['header']} style={{ width: 103 }}>
                                                    Mensagens
                                                </div>
                                                <div className={styles['header']} style={{ width: 150 }}>1ª Compra</div>
                                                <div className={styles['header']} style={{ width: 134 }}>Últ. Compra</div>
                                                <div className={styles['header']} style={{ width: 150 }}>Dias S/ Compra</div>
                                                {/* <Col span={2} className={styles['header']}>Status</Col> */}
                                                {/* <Col span={2} className={styles['header']}>Projetado</Col>
                                                <Col span={2} className={styles['header']}>Realizado</Col> */}
                                                {/*<Col span={2} className={styles['header']}>Obs. Cliente</Col>
                                                <Col span={2} className={styles['header']}>Cliente VIP</Col>
                                                <Col span={2} className={styles['header']}>Vendedor</Col>
                                                <Col span={2} className={styles['header']}>Promotor</Col> */}
                                            </div>
                                            {
                                                values.map((e, i) => {
                                                    return (
                                                        <div className={styles['rows']} key={i} style={{ display: 'flex' }}>
                                                            <div style={{ marginRight: 5 }}>
                                                                <div className={styles['info']}>
                                                                    {e.clienteNomeFantasia}
                                                                </div>
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <FieldForm name={`obsContato-${i}`} defaultValue={e.obsContato} onBlur={(v) => handleChangeValues(i, 'obsContato', v)} />
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <DatePicker locale={locale} style={{ marginTop: 5, height: 35, borderRadius: 4 }} name={`dataBaseFaturamento-${i}`} format={'DD/MM/yyyy'} defaultValue={e.dataBaseFaturamento ? dayjs(e.dataBaseFaturamento) : undefined} onChange={(v) => handleChangeValues(i, 'dataBaseFaturamento', v)} />
                                                            </div>
                                                            <div style={{ marginRight: 5, width: 90 }}>
                                                                <SelectForm hideLabel={true} defaultValue={e.campanhaVenda} name={`campanhaVenda-${i}`} label='Regime Especial' options={simNaoOptions} tagRequired={true} placeholder={'Selecione...'} onSelect={(v) => handleChangeValues(i, 'campanhaVenda', v)} />
                                                            </div>
                                                            <div style={{ marginRight: 5, width: 90 }}>
                                                                <SelectForm hideLabel={true} defaultValue={e.planoBonus} name={`planoBonus-${i}`} label='Regime Especial' options={simNaoOptions} tagRequired={true} placeholder={'Selecione...'} onSelect={(v) => handleChangeValues(i, 'planoBonus', v)} />
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <FieldForm name={`observacao-${i}`} defaultValue={e.observacao} onBlur={(v) => handleChangeValues(i, 'observacao', v)} />
                                                            </div>
                                                            <div className={styles['header']} style={{ marginRight: 5 }}>
                                                                <Button type='primary' onClick={() => handleOpenMensagens(e.clienteIndustriaAcompanhamentoId)} style={{ position: 'relative', top: 5 }}>Mensagens</Button>
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <div className={styles['info']}>
                                                                    10/02/2020
                                                                </div>
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <div className={styles['info']}>
                                                                    22/11/2022
                                                                </div>
                                                            </div>
                                                            <div style={{ marginRight: 5 }}>
                                                                <div className={styles['info']}>
                                                                    130
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Form >
                                    </div >
                            }
                        </div >
                    </>
                }
            </PageContent >

            {isVisibleImportador && valuesLoad.industriaId && valuesLoad.ano && valuesLoad.mes ?
                <ImportadorAcompanhamento industriaId={valuesLoad.industriaId} ano={valuesLoad.ano} mes={valuesLoad.mes} visible={isVisibleImportador} onClose={(values) => handleCloseImportador(values)} />
                : null}

            {isVisibleMensagens && valuesLoad.industriaId && valuesLoad.ano && valuesLoad.mes ?
                <Conversation acompanhamentoId={acompanhamentoIdSelected} visible={isVisibleMensagens} onClose={() => setIsVisibleMensagens(!isVisibleMensagens)} />
                : null}

            <Modal title="Histórico de Alterações" width={1000} visible={isVisibleModalAuditoria} onCancel={() => setIsVisibleModalAuditoria(false)} footer={null}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                    <p style={{ width: 120 }}>Cliente</p>
                    <p style={{ width: 100 }}>Contato</p>
                    <p style={{ width: 100 }}>Data Fat.</p>
                    <p style={{ width: 100 }}>Campanha Venda</p>
                    <p style={{ width: 150 }}>Plano Bonus</p>
                    <p style={{ width: 100 }}>Obs</p>
                    <p style={{ width: 100 }}>Data</p>
                    <p style={{ width: 100 }}>Usuário</p>
                </div>
                {
                    props.acompanhamentos?.map((x: IAcompanhamento) =>
                        x.auditorias?.map((a: IAcompanhamentoAuditoria) =>
                            <div key={x.codigo} style={{ display: 'flex' }}>
                                <p style={{ width: 120 }}></p>
                                <p style={{ width: 100 }}>{x.obsContato}</p>
                                <p style={{ width: 100 }}>{dateStringUStoPTBR(x.dataBaseFaturamento)}</p>
                                <p style={{ width: 100 }}>{x.campanhaVenda}</p>
                                <p style={{ width: 150 }}>{x.planoBonus}</p>
                                <p style={{ width: 100 }}>{x.observacao}</p>
                                <p style={{ width: 100 }}>{dateStringUStoPTBR(x.updateDate)}</p>
                                <p style={{ width: 100 }}>{x.user?.name}</p>
                            </div>
                        )
                    )
                }
            </Modal>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente,
    ...state.svDb,
    ...state.acompanhamento
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAcompanhamentoValues;

export default connector(Acompanhamento);