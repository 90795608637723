import { IDataImportadorPedido } from '../../../data/interfaces/pedido/importador/IDataImportadorPedido';
import { IImportarPedidoInfo, IImportarPedidoNewInfo, IImportarPedidoRequest } from '../../../data/interfaces/pedido/importador/IImportarPedidoRequest';
import { IPedidoImportadoResponse } from '../../../data/interfaces/pedido/importador/IPedidoImportadoResponse';
import { IPedidosNaoImportado } from '../../../data/interfaces/pedido/IPedidosNaoImportado';
import { IPedidosNaoImportadoRequest } from '../../../data/interfaces/pedido/IPedidosNaoImportadoRequest';
import { IProdutoCurvaABC } from '../../../data/interfaces/pedido/IProdutoCurvaABC';
import { IErroResponse } from '../../../data/interfaces/shared/IErroResponse';
import { Action, ActionsUnion, createAction } from '../../../utils/actionHelper';

export enum PedidoImportadoActionKeys {
	LIST_REQUEST = 'LIST_REQUEST',
	LIST_SUCCESS = 'LIST_SUCCESS',
	LIST_FAILED = 'LIST_FAILED',

	IMPORTAR_PEDIDO_REQUEST = 'IMPORTAR_PEDIDO_REQUEST',
	IMPORTAR_PEDIDO_SUCCESS = 'IMPORTAR_PEDIDO_SUCCESS',
	IMPORTAR_PEDIDO_FAILED = 'IMPORTAR_PEDIDO_FAILED',
	IMPORTAR_PEDIDO_INVALID = 'IMPORTAR_PEDIDO_INVALID',
	IMPORTAR_PEDIDO_CLEAR = 'IMPORTAR_PEDIDO_CLEAR',
	IMPORTAR_PEDIDO_INFO_CREATE = 'IMPORTAR_PEDIDO_INFO_CREATE',
	IMPORTAR_PEDIDO_INFO_ADD = 'IMPORTAR_PEDIDO_INFO_ADD',
	IMPORTAR_PEDIDO_INFO_CLEAR = 'IMPORTAR_PEDIDO_INFO_CLEAR',

	IMPORTAR_PEDIDO_V2_REQUEST = 'IMPORTAR_PEDIDO_V2_REQUEST',
	IMPORTAR_PEDIDO_V2_SUCCESS = 'IMPORTAR_PEDIDO_V2_SUCCESS',
	IMPORTAR_PEDIDO_V2_FAILED = 'IMPORTAR_PEDIDO_V2_FAILED',
	IMPORTAR_PEDIDO_V2_INVALID = 'IMPORTAR_PEDIDO_V2_INVALID',
	IMPORTAR_PEDIDO_V2_CLEAR = 'IMPORTAR_PEDIDO_V2_CLEAR',

	LIMPAR_PEDIDO_SUCCESS = 'LIMPAR_PEDIDO_SUCCESS',
	LIMPAR_PEDIDO_REQUEST = 'LIMPAR_PEDIDO_REQUEST',
	LIMPAR_PEDIDO_FAILED = 'LIMPAR_PEDIDO_FAILED',

	RECUPERAR_PEDIDO_SUCCESS = 'RECUPERAR_PEDIDO_SUCCESS',
	RECUPERAR_PEDIDO_REQUEST = 'RECUPERAR_PEDIDO_REQUEST',
	RECUPERAR_PEDIDO_FAILED = 'RECUPERAR_PEDIDO_FAILED',

	GET_DATA_IMPORTADOR_PEDIDO_SUCCESS = 'GET_DATA_IMPORTADOR_PEDIDO_SUCCESS',
	GET_DATA_IMPORTADOR_PEDIDO_REQUEST = 'GET_DATA_IMPORTADOR_PEDIDO_REQUEST',
	GET_DATA_IMPORTADOR_PEDIDO_FAILED = 'GET_DATA_IMPORTADOR_PEDIDO_FAILED',

	LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST = 'LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST',
	LIST_PEDIDOS_NAO_IMPORTADOS_SUCCESS = 'LIST_PEDIDOS_NAO_IMPORTADOS_SUCCESS',

	UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST = 'UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST',
	UPDATE_PEDIDOS_NAO_IMPORTADOS_SUCCESS = 'UPDATE_PEDIDOS_NAO_IMPORTADOS_SUCCESS',

	GET_ANALISE_GERAL_REQUEST = 'GET_ANALISE_GERAL_REQUEST',
	GET_ANALISE_GERAL_SUCCESS = 'GET_ANALISE_GERAL_SUCCESS',

	GET_ANALISE_CLIENTE_REQUEST = 'GET_ANALISE_CLIENTE_REQUEST',
	GET_ANALISE_CLIENTE_SUCCESS = 'GET_ANALISE_CLIENTE_SUCCESS',

	CLEAR_ANALISES = 'CLEAR_ANALISES',

	LIST_CLIENTE_INDUSTRIA_FULL_REQUEST = 'LIST_CLIENTE_INDUSTRIA_FULL_REQUEST',

	DEFAULT_FAILED = 'DEFAULT_FAILED',
	DEFAULT_SUCCESS = 'DEFAULT_SUCCESS'
}

export const PedidoImportadoActions = {
	list: (request: boolean): ListAction => createAction(PedidoImportadoActionKeys.LIST_REQUEST, request),
	listSuccess: (response: IPedidoImportadoResponse[]): ListSuccessAction =>
		createAction(PedidoImportadoActionKeys.LIST_SUCCESS, response),
	listFailure: (err: string): ListFailureAction => createAction(PedidoImportadoActionKeys.LIST_FAILED, err),

	importarPedido: (request: IImportarPedidoRequest): ImportarPedidoAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_REQUEST, request),
	importarPedidoSuccess: (response: IPedidoImportadoResponse): ImportarPedidoSuccessAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_SUCCESS, response),
	importarPedidoFailure: (err: string): ImportarPedidoFailureAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_FAILED, err),
	importarPedidoInvalid: (erros: IErroResponse[]): ImportarPedidoInvalidAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INVALID, erros),
	importarPedidoClear: (): ImportarPedidoClearAction => createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_CLEAR),

	importarPedidoV2: (request: IImportarPedidoRequest): ImportarPedidoV2Action =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_REQUEST, request),
	importarPedidoV2Success: (response: IPedidoImportadoResponse): ImportarPedidoV2SuccessAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_SUCCESS, response),
	importarPedidoV2Failure: (err: string): ImportarPedidoV2FailureAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_FAILED, err),
	importarPedidoV2Invalid: (erros: IErroResponse[]): ImportarPedidoV2InvalidAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_INVALID, erros),
	importarPedidoV2Clear: (): ImportarPedidoV2ClearAction => createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_CLEAR),

	importarPedidoInfoCreate: (request: string): ImportarPedidoInfoCreateAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CREATE, request),
	importarPedidoInfoAdd: (request: IImportarPedidoNewInfo): ImportarPedidoInfoAddAction =>
		createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_ADD, request),
	importarPedidoInfoClear: (): ImportarPedidoInfoClearAction => createAction(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CLEAR),

	limparPedido: (pedidoId: number): LimparPedidoAction =>
		createAction(PedidoImportadoActionKeys.LIMPAR_PEDIDO_REQUEST, pedidoId),
	limparPedidoSuccess: (): LimparPedidoSuccessAction => createAction(PedidoImportadoActionKeys.LIMPAR_PEDIDO_SUCCESS),
	limparPedidoFailure: (err: string): LimparPedidoFailureAction =>
		createAction(PedidoImportadoActionKeys.LIMPAR_PEDIDO_FAILED, err),

	recuperarPedido: (pedidoId: number): RecuperarPedidoAction =>
		createAction(PedidoImportadoActionKeys.RECUPERAR_PEDIDO_REQUEST, pedidoId),
	recuperarPedidoSuccess: (): RecuperarPedidoSuccessAction =>
		createAction(PedidoImportadoActionKeys.RECUPERAR_PEDIDO_SUCCESS),
	recuperarPedidoFailure: (err: string): RecuperarPedidoFailureAction =>
		createAction(PedidoImportadoActionKeys.RECUPERAR_PEDIDO_FAILED, err),

	getDataImportadorPedido: (): GetDataImportadorPedidoAction =>
		createAction(PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_REQUEST),
	getDataImportadorPedidoSuccess: (): GetDataImportadorPedidoSuccessAction =>
		createAction(PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_SUCCESS),
	getDataImportadorPedidoFailure: (err: string): GetDataImportadorPedidoFailureAction =>
		createAction(PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_FAILED, err),

	listPedidosNaoImportados: (): ListPedidosNaoImportadosAction =>
		createAction(PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST),
	listPedidosNaoImportadosSuccess: (response: IPedidosNaoImportado[]): ListPedidosNaoImportadosSuccessAction =>
		createAction(PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_SUCCESS, response),

	updatePedidoNaoImportado: (request: IPedidosNaoImportadoRequest): UpdatePedidoNaoImportadoAction =>
		createAction(PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST, request),
	updatePedidoNaoImportadoSuccess: (response: IPedidosNaoImportado[]): UpdatePedidoNaoImportadoSuccessAction =>
		createAction(PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_SUCCESS, response),

	analiseGeral: (request: number): AnaliseGeralAction =>
		createAction(PedidoImportadoActionKeys.GET_ANALISE_GERAL_REQUEST, request),
	analiseGeralSuccess: (response: IProdutoCurvaABC[]): AnaliseGeralSuccessAction =>
		createAction(PedidoImportadoActionKeys.GET_ANALISE_GERAL_SUCCESS, response),

	analiseCliente: (request: number): AnaliseClienteAction =>
		createAction(PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_REQUEST, request),
	analiseClienteSuccess: (response: IProdutoCurvaABC[]): AnaliseClienteSuccessAction =>
		createAction(PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_SUCCESS, response),

	clearAnalises: (): ClearAnalisesAction => createAction(PedidoImportadoActionKeys.CLEAR_ANALISES),

	listClienteIndustria: (): ListClienteIndustriaAction => createAction(PedidoImportadoActionKeys.LIST_CLIENTE_INDUSTRIA_FULL_REQUEST),

	defaultFailure: (err: string): DefaultFailureAction => createAction(PedidoImportadoActionKeys.DEFAULT_FAILED, err),
	defaultSuccess: (): DefaultSuccessAction => createAction(PedidoImportadoActionKeys.DEFAULT_SUCCESS)
};

export type PedidoImportadoActionUnion = ActionsUnion<typeof PedidoImportadoActions>;

export type ListAction = Action<PedidoImportadoActionKeys.LIST_REQUEST, boolean>;
export type ListSuccessAction = Action<PedidoImportadoActionKeys.LIST_SUCCESS, IPedidoImportadoResponse[]>;
export type ListFailureAction = Action<PedidoImportadoActionKeys.LIST_FAILED, string>;

export type ImportarPedidoAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_REQUEST, IImportarPedidoRequest>;
export type ImportarPedidoSuccessAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_SUCCESS, IPedidoImportadoResponse>;
export type ImportarPedidoFailureAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_FAILED, string>;
export type ImportarPedidoInvalidAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INVALID, IErroResponse[]>;
export type ImportarPedidoClearAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_CLEAR>;

export type ImportarPedidoV2Action = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_REQUEST, IImportarPedidoRequest>;
export type ImportarPedidoV2SuccessAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_SUCCESS, IPedidoImportadoResponse>;
export type ImportarPedidoV2FailureAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_FAILED, string>;
export type ImportarPedidoV2InvalidAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_INVALID, IErroResponse[]>;
export type ImportarPedidoV2ClearAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_CLEAR>;

export type ImportarPedidoInfoCreateAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CREATE, string>;
export type ImportarPedidoInfoAddAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_ADD, IImportarPedidoNewInfo>;
export type ImportarPedidoInfoClearAction = Action<PedidoImportadoActionKeys.IMPORTAR_PEDIDO_INFO_CLEAR>;

export type LimparPedidoAction = Action<PedidoImportadoActionKeys.LIMPAR_PEDIDO_REQUEST, number>;
export type LimparPedidoSuccessAction = Action<PedidoImportadoActionKeys.LIMPAR_PEDIDO_SUCCESS>;
export type LimparPedidoFailureAction = Action<PedidoImportadoActionKeys.LIMPAR_PEDIDO_FAILED, string>;

export type RecuperarPedidoAction = Action<PedidoImportadoActionKeys.RECUPERAR_PEDIDO_REQUEST, number>;
export type RecuperarPedidoSuccessAction = Action<PedidoImportadoActionKeys.RECUPERAR_PEDIDO_SUCCESS>;
export type RecuperarPedidoFailureAction = Action<PedidoImportadoActionKeys.RECUPERAR_PEDIDO_FAILED, string>;

export type GetDataImportadorPedidoAction = Action<PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_REQUEST>;
export type GetDataImportadorPedidoSuccessAction = Action<PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_SUCCESS>;
export type GetDataImportadorPedidoFailureAction = Action<PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_FAILED, string>;

export type ListPedidosNaoImportadosAction = Action<PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST>;
export type ListPedidosNaoImportadosSuccessAction = Action<PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_SUCCESS, IPedidosNaoImportado[]>;

export type UpdatePedidoNaoImportadoAction = Action<PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST, IPedidosNaoImportadoRequest>;
export type UpdatePedidoNaoImportadoSuccessAction = Action<PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_SUCCESS, IPedidosNaoImportado[]>;

export type AnaliseGeralAction = Action<PedidoImportadoActionKeys.GET_ANALISE_GERAL_REQUEST, number>;
export type AnaliseGeralSuccessAction = Action<PedidoImportadoActionKeys.GET_ANALISE_GERAL_SUCCESS, IProdutoCurvaABC[]>;

export type AnaliseClienteAction = Action<PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_REQUEST, number>;
export type AnaliseClienteSuccessAction = Action<PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_SUCCESS, IProdutoCurvaABC[]>;

export type ClearAnalisesAction = Action<PedidoImportadoActionKeys.CLEAR_ANALISES>;

export type ListClienteIndustriaAction = Action<PedidoImportadoActionKeys.LIST_CLIENTE_INDUSTRIA_FULL_REQUEST>;

export type DefaultFailureAction = Action<PedidoImportadoActionKeys.DEFAULT_FAILED, string>;
export type DefaultSuccessAction = Action<PedidoImportadoActionKeys.DEFAULT_SUCCESS>;

