import { ISellOut } from "../../data/interfaces/sellOut/ISellOut";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { ISellOutState } from "./interfaces/ISellOutState";
import { SellOutActionKeys, SellOutActionUnion } from "./SellOut.actions";

const initialState: ISellOutState = {
  sellOut: {} as ISellOut,
  sellOutImporting: {} as ISellOut,
  isLoading: false,
  error: undefined,
  message: undefined,
  errosImportacao: [] as IErroResponse[],
  isImporting: false
}

const sellOutReducer = (state = initialState, action: SellOutActionUnion): ISellOutState => {

  switch (action.type) {
    case SellOutActionKeys.GET_SELL_OUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SellOutActionKeys.GET_SELL_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sellOut: action.payload,
      };

    case SellOutActionKeys.UPDATE_SELL_OUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SellOutActionKeys.UPDATE_SELL_OUT_SUCCESS:
      return {
        ...state,
        sellOut: action.payload,
        isLoading: false,
      };

    case SellOutActionKeys.IMPORTAR_SELL_OUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined
      };
    case SellOutActionKeys.IMPORTAR_SELL_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined,
        sellOutImporting: action.payload,
      };
    case SellOutActionKeys.IMPORTAR_SELL_OUT_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case SellOutActionKeys.IMPORTAR_SELL_OUT_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case SellOutActionKeys.IMPORTAR_SELL_OUT_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case SellOutActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default sellOutReducer;
