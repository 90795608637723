import React from "react";
import { ColumnProps } from "antd/lib/table";
import { FaEye, FaFileAlt, FaMailBulk, FaPen, FaUser, FaVoicemail } from "react-icons/fa";
import { getDistinctFilterValues, getFilterSearchProps, sorter } from "../../utils/helpers";

import styles from './Clientes.module.scss';
import { IClienteIndustriaResponse } from "../../data/interfaces/svData/IClienteIndustriaResponse";

export const getColumns = (data: IClienteIndustriaResponse[], onEdit: (clienteIndustria: IClienteIndustriaResponse) => void, onView: (clienteIndustria: IClienteIndustriaResponse) => void,
    onViewAudit: (clienteIndustriaAuxId: number) => void) => {
    return [
        {
            title: 'Cliente',
            dataIndex: 'clienteNomeFantasia',
            sorter: (a, b) => sorter(a.clienteNomeFantasia, b.clienteNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteNomeFantasia', 'Cliente'),
        },
        {
            title: 'Cliente Código',
            dataIndex: 'clienteCodigo',
            sorter: (a, b) => sorter(a.clienteCodigo, b.clienteCodigo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteCodigo', 'Cliente Código'),
        },
        {
            title: 'Cliente Cidade',
            dataIndex: 'clienteCidade',
            sorter: (a, b) => sorter(a.clienteCidade, b.clienteCidade),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteCidade', 'Cliente Cidade'),
        },
        {
            title: 'Cliente UF',
            dataIndex: 'clienteEstado',
            sorter: (a, b) => sorter(a.clienteEstado, b.clienteEstado),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('clienteEstado', 'Cliente UF'),
        },
        {
            title: 'Grupo',
            dataIndex: 'clienteRegiaoNome',
            sorter: (a, b) => sorter(a.clienteRegiaoNome, b.clienteRegiaoNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(data, 'clienteRegiaoNome'),
            onFilter: (value: string, record) => { return record.clienteRegiaoNome.indexOf(value) === 0 },
        },
        {
            title: 'Indústria',
            dataIndex: 'industriaNomeFantasia',
            sorter: (a, b) => sorter(a.industriaNomeFantasia, b.industriaNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(data, 'industriaNomeFantasia'),
            onFilter: (value: string, record) => { return record.industriaNomeFantasia.indexOf(value) === 0 },
        },
        {
            title: 'Cadastro Completo',
            dataIndex: 'isRegisterCompleted',
            sorter: (a, b) => sorter(a.isRegisterCompleted, b.isRegisterCompleted),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: [{ text: 'Completo', value: true }, { text: 'Incompleto', value: false }],
            onFilter: (value: string, record) => { return record.isRegisterCompleted.toString().indexOf(value) === 0 },
            render: (value) => {
                return (
                    <div>
                        {value === true ? 'Completo' : 'Incompleto'}
                    </div>
                )
            }
        },
        {
            width: 140,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaEye title='Visualizar' onClick={() => onView(register)} />
                        <FaFileAlt title='Auditoria' onClick={() => onViewAudit(register.clienteIndustriaAuxId)} />
                        <FaPen title='Editar' onClick={() => onEdit(register)} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<IClienteIndustriaResponse>>;
}