import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { SVDBApi } from "../../data/SVDB.api";
import { GetUltimoPedidoAction, SvDbActions } from "./SvDb.actions";

export function* listFormasPagamento() {
    try {
        const { data } = yield call(SVDBApi.listFormasPagamento);
        yield put(SvDbActions.listFormasPagamentoSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvDbActions.listFormasPagamentoFailure(error));
    }
}

export function* listPedidoStatus() {
    try {
        const { data } = yield call(SVDBApi.listPedidoStatus);
        yield put(SvDbActions.listPedidoStatusSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvDbActions.defaultSvApiFailure(error));
    }
}

export function* getUltimoPedido({ payload }: GetUltimoPedidoAction) {
    try {
        const { data } = yield call(SVDBApi.getUltimoPedidoByClienteIdIndustriaId, payload);
        yield put(SvDbActions.getUltimoPedidoSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvDbActions.defaultSvApiFailure(error));
    }
}

export function* listUsuariosVendedores() {
    try {
        const { data } = yield call(SVDBApi.listUsuariosVendedores);
        yield put(SvDbActions.listUsuariosVendedoresSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvDbActions.defaultSvApiFailure(error));
    }
}

export function* listIndustrias() {
    try {
        const { data } = yield call(SVDBApi.listIndustrias);
        yield put(SvDbActions.listIndustriasSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvDbActions.defaultSvApiFailure(error));
    }
}
