import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IDataImportadorPedido } from "./interfaces/pedido/importador/IDataImportadorPedido";
import { IImportarPedidoRequest } from "./interfaces/pedido/importador/IImportarPedidoRequest";
import { IPedidoImportadoResponse } from "./interfaces/pedido/importador/IPedidoImportadoResponse";
import { IPedidosNaoImportado } from "./interfaces/pedido/IPedidosNaoImportado";
import { IPedidosNaoImportadoRequest } from "./interfaces/pedido/IPedidosNaoImportadoRequest";
import { IClienteIndustriaResponse } from "./interfaces/svData/IClienteIndustriaResponse";
import { IProdutoCurvaABC } from "./interfaces/pedido/IProdutoCurvaABC";

export class PedidoImportadoApi {
    static async list(allData: boolean): Promise<AxiosResponse<IPedidoImportadoResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/pedidoimportado/list?allData=${allData}`);
    }

    static async getDataImportadorPedido(): Promise<AxiosResponse<IDataImportadorPedido>> {
        const axios = await Http.axios();
        return axios.get(`/pedidoimportado/getDataImportadorPedido`);
    }

    static async importarPedido(importarPedido: IImportarPedidoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', importarPedido.file);
        formData.append('data', JSON.stringify(importarPedido.data));
        formData.append('isNovaFormaPagamento', importarPedido.isNovaFormaPagamento.toString());

        return axios.post('/pedidoimportado/importar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 1200000
        });
    }

    static async importarPedidoV2(importarPedido: IImportarPedidoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', importarPedido.file);
        formData.append('data', JSON.stringify(importarPedido.data));
        formData.append('isNovaFormaPagamento', importarPedido.isNovaFormaPagamento.toString());
        formData.append('fieldsDiff', JSON.stringify(importarPedido.fieldsDiff));

        return axios.post('/pedidoimportado/importarV2', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            timeout: 1200000
        });
    }

    static async limparPedido(pedidoId: number): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post(`/pedidoimportado/limparPedido?pedidoID=${pedidoId}`);
    }

    static async recuperarPedido(pedidoId: number): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.post(`/pedidoimportado/recuperarPedido?pedidoID=${pedidoId}`);
    }

    static async listPedidosNaoImportados(): Promise<AxiosResponse<IPedidosNaoImportado[]>> {
        const axios = await Http.axios();
        return axios.get(`/pedidoimportado/listPedidosNaoImportados`);
    }

    static async updatePedidoNaoImportadoInfo(request: IPedidosNaoImportadoRequest): Promise<AxiosResponse<IPedidosNaoImportado[]>> {
        const axios = await Http.axios();
        return axios.put(`/pedidoimportado/updatePedidoNaoImportadoInfo`, request);
    }

    static async listClienteIndustriaFull(): Promise<AxiosResponse<IClienteIndustriaResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/pedidoimportado/listClienteIndustria`);
    }

    static async analisePedidoGeral(pedidoId: number): Promise<AxiosResponse<IProdutoCurvaABC[]>> {
        const axios = await Http.axios();
        return axios.get(`/pedidos/${pedidoId}/analise-geral`);
    }

    static async analisePedidoCliente(pedidoId: number): Promise<AxiosResponse<IProdutoCurvaABC[]>> {
        const axios = await Http.axios();
        return axios.get(`/pedidos/${pedidoId}/analise-cliente`);
    }
}