import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IModule } from "./interfaces/module/IModuleColumnUser";

export class ModuleApi {
    static async listModules(): Promise<AxiosResponse<IModule[]>> {
        const axios = await Http.axios();
        return axios.get(`/module/listModules`);
    }

    static async updateModule(request: IModule): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.put(`/module/updateModule/${request.moduleId}`, request.columns);
    }
}