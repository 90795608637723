import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import PageContent from "../../../components/ui/pageContent";
import { Button, Skeleton, Table } from "antd";

import styles from './ListAtualizarProduto.module.scss';
import AtualizarProduto from './modals';
import AtualizarProdutoDetail from './modals/detail';
import { getColumns } from "./ListAtualizarProduto.helper";
import { ProdutoActions } from "../../../store/produto/Produto.actions";
import { IAtualizarProdutoStatus } from "../../../data/interfaces/atualizarProduto/IAtualizarProdutoRequest";
import { TableLocale } from "antd/es/table/interface";

const ListAtualizarProduto: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalAtualizarProduto, setIsVisibleModalAtualizarProduto] = useState(false);
    const [isVisibleModalDetail, setIsVisibleModalDetail] = useState(false);
    const [atualizacaoEmAndamento, setAtualizacaoEmAndamento] = useState(false);
    const [qtdeRows, setQtdeRows] = useState(0);
    const [currentCount, setCount] = useState(5);
    const timer = () => setCount(currentCount - 1);

    useEffect(() => {
        dispatch(ProdutoActions.listAtualizarProdutos());
    }, []);

    useEffect(() => {
        if (props.atualizarProdutos && props.atualizarProdutos.length > 0) {
            setAtualizacaoEmAndamento(props.atualizarProdutos.findIndex(x => x.status == IAtualizarProdutoStatus.Iniciado) > -1);
        }

        setQtdeRows(props.atualizarProdutos?.length);
    }, [props.atualizarProdutos]);

    useEffect(() => {

        if (currentCount <= 0 &&
            props.atualizarProdutos &&
            props.atualizarProdutos?.length > 0 &&
            !props.isLoading) {

            var atualizarProdutoCurrentId = props.atualizarProdutos.find(x => x.status == IAtualizarProdutoStatus.Iniciado)?.atualizarProdutoId;

            if (atualizarProdutoCurrentId)
                dispatch(ProdutoActions.getAtualizarProduto(atualizarProdutoCurrentId));

            setCount(5);
            return;
        }

        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [currentCount]);

    const showAtualizacaoProdutoInDetail = (id: number) => {
        setIsVisibleModalDetail(true);
        dispatch(ProdutoActions.getAtualizarProdutoInDetail(id));
    }

    const reiniciarAtualizacaoProduto = (id: number) => {
        if (window.confirm("Deseja tentar novamente a atualização?"))
            dispatch(ProdutoActions.reiniciarAtualizarProduto(id));
    }

    const tableChange = (qtdeRows: number) => {
        setQtdeRows(qtdeRows);
    }

    const localeTable = {
        emptyText: 'Nenhuma Atualização realizada',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    return (<>
        <PageContent title='Atualização de Produtos' className={styles['container']}>
            {props.isLoading ?
                <Skeleton active />
                :
                <>
                    <div className={styles['toolBar']}>
                        <Button loading={atualizacaoEmAndamento} onClick={() => setIsVisibleModalAtualizarProduto(true)} type='primary'>
                            {atualizacaoEmAndamento ? 'Atualização em Andamento...' : 'Atualizar Produtos'}</Button>
                    </div>
                    <div className={styles['qtdeRows']}>
                        {qtdeRows} registros
                    </div>
                    <Table
                        onChange={(p, f, s, extra) => tableChange(extra.currentDataSource?.length)}
                        columns={getColumns(showAtualizacaoProdutoInDetail, reiniciarAtualizacaoProduto)}
                        dataSource={props.atualizarProdutos}
                        style={{ overflowY: 'auto' }}
                        pagination={false}
                        locale={localeTable} />

                </>
            }
        </PageContent>
        {isVisibleModalAtualizarProduto ?
            <AtualizarProduto visible={isVisibleModalAtualizarProduto} onClose={() => setIsVisibleModalAtualizarProduto(false)}></AtualizarProduto>
            : null}
        {isVisibleModalDetail ?
            <AtualizarProdutoDetail visible={isVisibleModalDetail} onClose={() => setIsVisibleModalDetail(false)}></AtualizarProdutoDetail>
            : null}
    </>);
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.produto
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(ListAtualizarProduto);