import { Button, Skeleton } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import Field from "../../../../components/ui/input";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { copyToClp, removeDuplicateArray, sliceIntoChunks } from "../../../../utils/helpers";

export interface IViewEmailsCompradores {
    visible: boolean,
    clienteIndustriaIds: number[],
    onClose?: () => void;
}

const ViewEmailsCompradores: FC<Props> = (props) => {
    const dispatch = useDispatch();
    var emails: string[] = [];
    var chunksEmails: any[] = [];

    useEffect(() => {
        dispatch(ClienteActions.listCompradoresClienteIndustria(props.clienteIndustriaIds));
    }, []);

    if (props.compradoresClienteIndustria && props.compradoresClienteIndustria.length > 0) {
        emails = props.compradoresClienteIndustria.map(e => e.contatoEmail);
        emails = removeDuplicateArray(emails);
        chunksEmails = sliceIntoChunks(emails, 2);
    }

    const buttons = [<Button key="back" onClick={props.onClose}>Fechar</Button>];

    return (
        <Modal title='Emails de Compradores' visible={props.visible} footer={buttons} onCancel={props.onClose} width={500}>
            {props.isLoading ?
                <Skeleton active></Skeleton>
                :
                <>
                    {chunksEmails.map(chunkEmails => (
                        <div style={{ display: 'inline-flex', marginBottom: 10 }}>
                            <Field disabled={true} value={chunkEmails.join(',')} styleInput={{ width: 270 }}></Field>
                            <Button style={{ marginTop: 6, marginRight: 10, marginLeft: 10 }} onClick={() => copyToClp(chunkEmails)} >Copiar</Button>
                            <Button style={{ marginTop: 6 }} type='primary' onClick={() => window.open("mailTo:" + chunkEmails)}>Enviar</Button>
                        </div>))}
                </>
            }
        </Modal>)
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IViewEmailsCompradores;

export default connector(ViewEmailsCompradores);