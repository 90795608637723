import * as Yup from 'yup';

const schema = Yup.object().shape({
    idSuasVendas: Yup.number().typeError('O campo deve ser um número').required('O campo deve ser preenchido').min(1),
    name: Yup.string().required('O campo deve ser preenchido'),
    email: Yup.string().required('O campo deve ser preenchido').email('Informe um email válido'),
    password: Yup.string().when('id', (id: any, password: any) => id ? password : password.required('O campo deve ser preenchido')),
    roleName: Yup.string().required('Selecione uma opção')
});

export default schema;