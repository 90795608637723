import { IContato } from "../../data/interfaces/contato/IContato";
import { IDepartamento } from "../../data/interfaces/departamento/IDepartamento";
import { IClienteIndustriaResponse } from "../../data/interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "../../data/interfaces/svData/IClienteResponse";
import { IClienteTransportadoraResponse } from "../../data/interfaces/svData/IClienteTransportadoraResponse";
import { IClienteVendedorResponse } from "../../data/interfaces/svData/IClienteVendedorResponse";
import { IIndustriaResponse } from "../../data/interfaces/svData/IIndustriaResponse";
import { IPedidoStatusResponse } from "../../data/interfaces/svData/IPedidoStatusResponse";
import { ITabelaPrecoResponse } from "../../data/interfaces/svData/ITabelaPrecoResponse";
import { ITransportadoraResponse } from "../../data/interfaces/svData/ITransportadoraResponse";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum SvApiActionKeys {
    LIST_INDUSTRIAS_REQUEST = 'LIST_INDUSTRIAS_REQUEST',
    LIST_INDUSTRIAS_SUCCESS = 'LIST_INDUSTRIAS_SUCCESS',
    LIST_INDUSTRIAS_FAILED = 'LIST_INDUSTRIAS_FAILED',

    LIST_CLIENTES_REQUEST = 'LIST_CLIENTES_REQUEST',
    LIST_CLIENTES_SUCCESS = 'LIST_CLIENTES_SUCCESS',
    LIST_CLIENTES_FAILED = 'LIST_CLIENTES_FAILED',

    LIST_TABELAS_PRECO_REQUEST = 'LIST_TABELAS_PRECO_REQUEST',
    LIST_TABELAS_PRECO_SUCCESS = 'LIST_TABELAS_PRECO_SUCCESS',
    LIST_TABELAS_PRECO_FAILED = 'LIST_TABELAS_PRECO_FAILED',

    LIST_CLIENTE_INDUSTRIA_REQUEST = 'LIST_CLIENTE_INDUSTRIA_REQUEST',
    LIST_CLIENTE_INDUSTRIA_SUCCESS = 'LIST_CLIENTE_INDUSTRIA_SUCCESS',
    LIST_CLIENTE_INDUSTRIA_FAILED = 'LIST_CLIENTE_INDUSTRIA_FAILED',

    LIST_CLIENTE_VENDEDOR_REQUEST = 'LIST_CLIENTE_VENDEDOR_REQUEST',
    LIST_CLIENTE_VENDEDOR_SUCCESS = 'LIST_CLIENTE_VENDEDOR_SUCCESS',
    LIST_CLIENTE_VENDEDOR_FAILED = 'LIST_CLIENTE_VENDEDOR_FAILED',

    LIST_TRANSPORTADORAS_REQUEST = 'LIST_TRANSPORTADORAS_REQUEST',
    LIST_TRANSPORTADORAS_SUCCESS = 'LIST_TRANSPORTADORAS_SUCCESS',
    LIST_TRANSPORTADORAS_FAILED = 'LIST_TRANSPORTADORAS_FAILED',

    LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST = 'LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST',
    LIST_TRANSPORTADORAS_BY_CLIENTE_SUCCESS = 'LIST_TRANSPORTADORAS_BY_CLIENTE_SUCCESS',

    LIST_CLIENTE_TRANSPORTADORA_REQUEST = 'LIST_CLIENTE_TRANSPORTADORA_REQUEST',
    LIST_CLIENTE_TRANSPORTADORA_SUCCESS = 'LIST_CLIENTE_TRANSPORTADORA_SUCCESS',
    LIST_CLIENTE_TRANSPORTADORA_FAILED = 'LIST_CLIENTE_TRANSPORTADORA_FAILED',

    LIST_PEDIDO_STATUS_REQUEST = 'LIST_PEDIDO_STATUS_REQUEST',
    LIST_PEDIDO_STATUS_SUCCESS = 'LIST_PEDIDO_STATUS_SUCCESS',
    LIST_PEDIDO_STATUS_FAILED = 'LIST_PEDIDO_STATUS_FAILED',

    LIST_USUARIOS_REQUEST = 'LIST_USUARIOS_REQUEST',
    LIST_USUARIOS_SUCCESS = 'LIST_USUARIOS_SUCCESS',
    LIST_USUARIOS_FAILED = 'LIST_USUARIOS_FAILED',

    LIST_USUARIOS_BY_CLIENTE_REQUEST = 'LIST_USUARIOS_BY_CLIENTE_REQUEST',
    LIST_USUARIOS_BY_CLIENTE_SUCCESS = 'LIST_USUARIOS_BY_CLIENTE_SUCCESS',

    LIST_CONTATOS_BY_CLIENTE_REQUEST = 'LIST_CONTATOS_BY_CLIENTE_REQUEST',
    LIST_CONTATOS_BY_CLIENTE_SUCCESS = 'LIST_CONTATOS_BY_CLIENTE_SUCCESS',

    LIST_DEPARTAMENTOS_REQUEST = 'LIST_DEPARTAMENTOS_REQUEST',
    LIST_DEPARTAMENTOS_SUCCESS = 'LIST_DEPARTAMENTOS_SUCCESS',

    DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const SvApiActions = {
    listIndustrias: (): ListIndustriasAction => createAction(SvApiActionKeys.LIST_INDUSTRIAS_REQUEST),
    listIndustriasSuccess: (response: IIndustriaResponse[]): ListIndustriasSuccessAction => createAction(SvApiActionKeys.LIST_INDUSTRIAS_SUCCESS, response),
    listIndustriasFailure: (err: string): ListIndustriasFailureAction => createAction(SvApiActionKeys.LIST_INDUSTRIAS_FAILED, err),

    listClientes: (): ListClientesAction => createAction(SvApiActionKeys.LIST_CLIENTES_REQUEST),
    listClientesSuccess: (response: IClienteResponse[]): ListClientesSuccessAction => createAction(SvApiActionKeys.LIST_CLIENTES_SUCCESS, response),
    listClientesFailure: (err: string): ListClientesFailureAction => createAction(SvApiActionKeys.LIST_CLIENTES_FAILED, err),

    listTabelasPreco: (): ListTabelasPrecoAction => createAction(SvApiActionKeys.LIST_TABELAS_PRECO_REQUEST),
    listTabelasPrecoSuccess: (response: ITabelaPrecoResponse[]): ListTabelasPrecoSuccessAction => createAction(SvApiActionKeys.LIST_TABELAS_PRECO_SUCCESS, response),
    listTabelasPrecoFailure: (err: string): ListTabelasPrecoFailureAction => createAction(SvApiActionKeys.LIST_TABELAS_PRECO_FAILED, err),

    listClienteIndustria: (): ListClienteIndustriaAction => createAction(SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_REQUEST),
    listClienteIndustriaSuccess: (response: IClienteIndustriaResponse[]): ListClienteIndustriaSuccessAction => createAction(SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_SUCCESS, response),
    listClienteIndustriaFailure: (err: string): ListClienteIndustriaFailureAction => createAction(SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_FAILED, err),

    listClienteVendedor: (): ListClienteVendedorAction => createAction(SvApiActionKeys.LIST_CLIENTE_VENDEDOR_REQUEST),
    listClienteVendedorSuccess: (response: IClienteVendedorResponse[]): ListClienteVendedorSuccessAction => createAction(SvApiActionKeys.LIST_CLIENTE_VENDEDOR_SUCCESS, response),
    listClienteVendedorFailure: (err: string): ListClienteVendedorFailureAction => createAction(SvApiActionKeys.LIST_CLIENTE_VENDEDOR_FAILED, err),

    listTransportadoras: (): ListTransportadorasAction => createAction(SvApiActionKeys.LIST_TRANSPORTADORAS_REQUEST),
    listTransportadorasSuccess: (response: ITransportadoraResponse[]): ListTransportadorasSuccessAction => createAction(SvApiActionKeys.LIST_TRANSPORTADORAS_SUCCESS, response),
    listTransportadorasFailure: (err: string): ListTransportadorasFailureAction => createAction(SvApiActionKeys.LIST_TRANSPORTADORAS_FAILED, err),

    listTransportadorasByCliente: (request: number): ListTransportadorasByClienteAction => createAction(SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST, request),
    listTransportadorasByClienteSuccess: (response: ITransportadoraResponse[]): ListTransportadorasByClienteSuccessAction => createAction(SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_SUCCESS, response),

    listClienteTransportadora: (): ListClienteTransportadoraAction => createAction(SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_REQUEST),
    listClienteTransportadoraSuccess: (response: IClienteTransportadoraResponse[]): ListClienteTransportadoraSuccessAction => createAction(SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_SUCCESS, response),
    listClienteTransportadoraFailure: (err: string): ListClienteTransportadoraFailureAction => createAction(SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_FAILED, err),

    listPedidoStatus: (): ListPedidoStatusAction => createAction(SvApiActionKeys.LIST_PEDIDO_STATUS_REQUEST),
    listPedidoStatusSuccess: (response: IPedidoStatusResponse[]): ListPedidoStatusSuccessAction => createAction(SvApiActionKeys.LIST_PEDIDO_STATUS_SUCCESS, response),
    listPedidoStatusFailure: (err: string): ListPedidoStatusFailureAction => createAction(SvApiActionKeys.LIST_PEDIDO_STATUS_FAILED, err),

    listUsuarios: (): ListUsuariosAction => createAction(SvApiActionKeys.LIST_USUARIOS_REQUEST),
    listUsuariosSuccess: (response: IUserResponse[]): ListUsuariosSuccessAction => createAction(SvApiActionKeys.LIST_USUARIOS_SUCCESS, response),
    listUsuariosFailure: (err: string): ListUsuariosFailureAction => createAction(SvApiActionKeys.LIST_USUARIOS_FAILED, err),

    listUsuariosByCliente: (request: number): ListUsuariosByClienteAction => createAction(SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_REQUEST, request),
    listUsuariosByClienteSuccess: (response: IUserResponse[]): ListUsuariosByClienteSuccessAction => createAction(SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_SUCCESS, response),

    listContatosByCliente: (request: number): ListContatosByClienteAction => createAction(SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_REQUEST, request),
    listContatosByClienteSuccess: (response: IContato[]): ListContatosByClienteSuccessAction => createAction(SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_SUCCESS, response),

    listDepartamentosByCliente: (): ListDepartamentosAction => createAction(SvApiActionKeys.LIST_DEPARTAMENTOS_REQUEST),
    listDepartamentosByClienteSuccess: (response: IDepartamento[]): ListDepartamentosSuccessAction => createAction(SvApiActionKeys.LIST_DEPARTAMENTOS_SUCCESS, response),

    defaultSvApiFailure: (err: string): DefaultSvApiFailureAction => createAction(SvApiActionKeys.DEFAULT_FAILED, err),
}

export type SvApiActionUnion = ActionsUnion<typeof SvApiActions>;

export type ListIndustriasAction = Action<SvApiActionKeys.LIST_INDUSTRIAS_REQUEST>;
export type ListIndustriasSuccessAction = Action<SvApiActionKeys.LIST_INDUSTRIAS_SUCCESS, IIndustriaResponse[]>;
export type ListIndustriasFailureAction = Action<SvApiActionKeys.LIST_INDUSTRIAS_FAILED, string>;

export type ListClientesAction = Action<SvApiActionKeys.LIST_CLIENTES_REQUEST>;
export type ListClientesSuccessAction = Action<SvApiActionKeys.LIST_CLIENTES_SUCCESS, IClienteResponse[]>;
export type ListClientesFailureAction = Action<SvApiActionKeys.LIST_CLIENTES_FAILED, string>;

export type ListTabelasPrecoAction = Action<SvApiActionKeys.LIST_TABELAS_PRECO_REQUEST>;
export type ListTabelasPrecoSuccessAction = Action<SvApiActionKeys.LIST_TABELAS_PRECO_SUCCESS, ITabelaPrecoResponse[]>;
export type ListTabelasPrecoFailureAction = Action<SvApiActionKeys.LIST_TABELAS_PRECO_FAILED, string>;

export type ListClienteIndustriaAction = Action<SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_REQUEST>;
export type ListClienteIndustriaSuccessAction = Action<SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_SUCCESS, IClienteIndustriaResponse[]>;
export type ListClienteIndustriaFailureAction = Action<SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_FAILED, string>;

export type ListClienteVendedorAction = Action<SvApiActionKeys.LIST_CLIENTE_VENDEDOR_REQUEST>;
export type ListClienteVendedorSuccessAction = Action<SvApiActionKeys.LIST_CLIENTE_VENDEDOR_SUCCESS, IClienteVendedorResponse[]>;
export type ListClienteVendedorFailureAction = Action<SvApiActionKeys.LIST_CLIENTE_VENDEDOR_FAILED, string>;

export type ListTransportadorasAction = Action<SvApiActionKeys.LIST_TRANSPORTADORAS_REQUEST>;
export type ListTransportadorasSuccessAction = Action<SvApiActionKeys.LIST_TRANSPORTADORAS_SUCCESS, ITransportadoraResponse[]>;
export type ListTransportadorasFailureAction = Action<SvApiActionKeys.LIST_TRANSPORTADORAS_FAILED, string>;

export type ListTransportadorasByClienteAction = Action<SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST, number>;
export type ListTransportadorasByClienteSuccessAction = Action<SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_SUCCESS, ITransportadoraResponse[]>;

export type ListClienteTransportadoraAction = Action<SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_REQUEST>;
export type ListClienteTransportadoraSuccessAction = Action<SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_SUCCESS, IClienteTransportadoraResponse[]>;
export type ListClienteTransportadoraFailureAction = Action<SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_FAILED, string>;

export type ListPedidoStatusAction = Action<SvApiActionKeys.LIST_PEDIDO_STATUS_REQUEST>;
export type ListPedidoStatusSuccessAction = Action<SvApiActionKeys.LIST_PEDIDO_STATUS_SUCCESS, IPedidoStatusResponse[]>;
export type ListPedidoStatusFailureAction = Action<SvApiActionKeys.LIST_PEDIDO_STATUS_FAILED, string>;

export type ListUsuariosAction = Action<SvApiActionKeys.LIST_USUARIOS_REQUEST>;
export type ListUsuariosSuccessAction = Action<SvApiActionKeys.LIST_USUARIOS_SUCCESS, IUserResponse[]>;
export type ListUsuariosFailureAction = Action<SvApiActionKeys.LIST_USUARIOS_FAILED, string>;

export type ListUsuariosByClienteAction = Action<SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_REQUEST, number>;
export type ListUsuariosByClienteSuccessAction = Action<SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_SUCCESS, IUserResponse[]>;

export type ListContatosByClienteAction = Action<SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_REQUEST, number>;
export type ListContatosByClienteSuccessAction = Action<SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_SUCCESS, IContato[]>;

export type ListDepartamentosAction = Action<SvApiActionKeys.LIST_DEPARTAMENTOS_REQUEST>;
export type ListDepartamentosSuccessAction = Action<SvApiActionKeys.LIST_DEPARTAMENTOS_SUCCESS, IDepartamento[]>;

export type DefaultSvApiFailureAction = Action<SvApiActionKeys.DEFAULT_FAILED, string>;