/* eslint-disable react/jsx-key */
import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Skeleton } from "antd";

import styles from './ClienteForm.module.scss';
import Form from "../../../../components/ui/form";
import FieldForm from "../../../../components/ui/field";
import schema from "./ClienteForm.schema";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { IClienteIndustriaAuxForm } from "../../../../data/interfaces/cliente/IClienteIndustriaAuxForm";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import TextArea from "../../../../components/ui/textArea";
import { SvApiActions } from "../../../../store/svApi/SvApi.actions";
import { getOptionsDataFromObject } from "../../../../utils/helpers";
import { IClienteIndustriaResponse } from "../../../../data/interfaces/svData/IClienteIndustriaResponse";
import { SvDbActions } from "../../../../store/svDb/SvDb.actions";
import $ from 'jquery';
import { IUltimoPedidoRequest } from "../../../../data/interfaces/pedido/IUltimoPedidoRequest";
import { toast } from "react-toastify";
import { isAccessAdmin } from "../../../../utils/AuthUtils";
import FieldNumber from "../../../../components/ui/fieldNumber";
import { ICompradorCliente } from "../../../../data/interfaces/cliente/ICompradorCliente";
import { simNaoOptions } from "../../../../utils/optionsHelper";
import Field from "../../../../components/ui/input";
import { FaTrash } from "react-icons/fa";

export interface IClienteModal {
    clienteIndustria: IClienteIndustriaResponse;
    visible: boolean;
    updateStatus: boolean;
    onClose: () => void;
    onSave?: () => void;
}

const freteOptions = [
    {
        text: 'CIF',
        value: 0
    },
    {
        text: 'FOB',
        value: 1
    }
] as IOptionData[];

const aceitaPendenciaOptions = [
    {
        text: 'NÃO',
        value: 'NÃO'
    },
    {
        text: 'SIM',
        value: 'SIM'
    },
    {
        text: 'SIM ERP',
        value: 'SIM ERP'
    }
] as IOptionData[];

const ClienteForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const [defaultValuesForm, setDefaultValuesForm] = useState<IClienteIndustriaAuxForm>({} as IClienteIndustriaAuxForm);
    const [usuariosOptions, setUsuariosOptions] = useState<IOptionData[]>([]);
    const [usuariosByClienteOptions, setUsuariosByClienteOptions] = useState<IOptionData[]>([]);
    const [transportadorasOptions, setTransportadorasOptions] = useState<IOptionData[]>([]);
    const [pedidoStatusOptions, setPedidoStatusOptions] = useState<IOptionData[]>([]);
    const [categoriasClienteOptions, setCategoriasClienteOptions] = useState<IOptionData[]>([]);
    const [contatosOptions, setContatosOptions] = useState<IOptionData[]>([]);
    const [formasPagamentoOptions, setFormasPagamentoOptions] = useState<IOptionData[]>([]);
    const [onLoadData, setOnLoadData] = useState<string[]>([]);
    const [compradores, setCompradores] = useState<ICompradorCliente[]>([]);
    const [descontoTotal, setDescontoTotal] = useState(0);

    const addLoadData = (data: string) => {
        if (onLoadData.indexOf(data) == -1)
            setOnLoadData([...onLoadData, data]);
    }

    $(function () {
        $('textarea')
            .on('input', function () {
                $(this).outerHeight(30.55).outerHeight(this.scrollHeight + 5);
            })
            .trigger('input');
    });

    useEffect(() => {
        if (props.clienteIndustria.clienteIndustriaAuxId > 0) {
            dispatch(ClienteActions.getClienteIndustria(props.clienteIndustria.clienteIndustriaAuxId));
        }
        else {
            dispatch(SvDbActions.getUltimoPedido(
                {
                    clienteId: props.clienteIndustria.clieIndustriaClienteId,
                    industriaId: props.clienteIndustria.clieIndustriaIndustriaId
                } as IUltimoPedidoRequest)
            );
        }

        dispatch(SvApiActions.listUsuarios());
        dispatch(SvApiActions.listTransportadorasByCliente(props.clienteIndustria.clieIndustriaClienteId));
        dispatch(SvDbActions.listPedidoStatus());
        dispatch(ClienteActions.listCategoriasCliente());
        dispatch(SvApiActions.listContatosByCliente(props.clienteIndustria.clieIndustriaClienteId));
        dispatch(SvApiActions.listUsuariosByCliente(props.clienteIndustria.clieIndustriaClienteId));
        dispatch(SvDbActions.listFormasPagamento());
    }, [props.clienteIndustria.clienteIndustriaAuxId, props.clienteIndustria.clieIndustriaClienteId, props.clienteIndustria.clieIndustriaIndustriaId]);

    useEffect(() => {
        if (props.usuarios) {
            setUsuariosOptions(getOptionsDataFromObject(props.usuarios, 'idSuasVendas', 'name'));
            addLoadData('usuarios');
        }
    }, [props.usuarios]);

    useEffect(() => {
        if (props.pedidoStatusDB) {
            setPedidoStatusOptions(getOptionsDataFromObject(props.pedidoStatusDB, 'pedidoStatusId', 'pedidoStatusNome'));
            addLoadData('pedidoStatus');
        }
    }, [props.pedidoStatusDB]);

    useEffect(() => {
        if (props.categoriasCliente) {
            setCategoriasClienteOptions(getOptionsDataFromObject(props.categoriasCliente, 'categoriaClienteAuxId', 'nome'));
            addLoadData('categoriasCliente');
        }
    }, [props.categoriasCliente]);

    useEffect(() => {
        if (props.formasPagamento) {
            var formasPagamentoClientesOptions = getOptionsDataFromObject(
                props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == props.clienteIndustria.clieIndustriaIndustriaId && formaPagamento.clientesId.includes(props.clienteIndustria.clieIndustriaClienteId)),
                'formaPagamentoNome', 'formaPagamentoNome');
            formasPagamentoClientesOptions.forEach(x => { x.text += ' (CLIENTE)'; x.style = { color: 'blue' }; });

            var formasPagamentoIndustriasOptions = getOptionsDataFromObject(
                props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == props.clienteIndustria.clieIndustriaIndustriaId && !formaPagamento.clientesId.includes(props.clienteIndustria.clieIndustriaClienteId)),
                'formaPagamentoNome', 'formaPagamentoNome');
            formasPagamentoIndustriasOptions.forEach(x => { x.text += ' (INDÚSTRIA)'; x.style = { color: '#d87724' }; });

            setFormasPagamentoOptions([...formasPagamentoClientesOptions, ...formasPagamentoIndustriasOptions]);
            addLoadData('formasPagamento');
        }
    }, [props.formasPagamento, props.clienteIndustria.clieIndustriaClienteId, props.clienteIndustria.clieIndustriaIndustriaId]);

    useEffect(() => {
        if (props.usuariosByCliente) {
            setUsuariosByClienteOptions(getOptionsDataFromObject(props.usuariosByCliente, 'idSuasVendas', 'name'));
            addLoadData('usuariosByCliente');
        }
    }, [props.usuariosByCliente]);

    useEffect(() => {
        if (props.transportadoras) {
            setTransportadorasOptions(getOptionsDataFromObject(props.transportadoras, 'transportadoraId', 'transportadoraNomeFantasia'));
            addLoadData('transportadoras');
        }
    }, [props.transportadoras]);

    useEffect(() => {
        if (props.contatos) {
            setContatosOptions(getOptionsDataFromObject(props.contatos, 'contatoId', 'contatoNome'));
            addLoadData('contatos');
        }
    }, [props.contatos]);

    useEffect(() => {
        if (props.clienteIndustria.clienteIndustriaAuxId == null || props.clienteIndustria.clienteIndustriaAuxId == 0) {

            var hasPedido = (props.ultimoPedido && props.ultimoPedido.pedidoId > 0) ?? false;

            setDefaultValuesForm(
                {
                    ...{} as IClienteIndustriaAuxForm,
                    clienteIndustriaClienteId: props.clienteIndustria.clieIndustriaClienteId,
                    clienteIndustriaIndustriaId: props.clienteIndustria.clieIndustriaIndustriaId,
                    clienteNomeFantasia: props.clienteIndustria.clienteNomeFantasia,
                    industriaNomeFantasia: props.clienteIndustria.industriaNomeFantasia,
                    isUltimaCompraSV: hasPedido,
                    numeroUltimaCompra: hasPedido ? props.ultimoPedido?.pedidoId.toString() ?? '' : '',
                    dataUltimaCompra: hasPedido ? props.ultimoPedido?.pedidoDataEmissao.toString().substr(0, 10) ?? '' : '',
                    valorUltimaCompra: props.ultimoPedido?.pedidoValorTotal ?? 0,
                }
            );

            addLoadData('clienteIndustriaAux');
        }
        else if (props.clienteIndustria.clienteIndustriaAuxId > 0 && props.clienteIndustriaAux) {
            setDefaultValuesForm(
                {
                    ...props.clienteIndustriaAux,
                    clienteIndustriaClienteId: props.clienteIndustria.clieIndustriaClienteId,
                    clienteIndustriaIndustriaId: props.clienteIndustria.clieIndustriaIndustriaId,
                    clienteNomeFantasia: props.clienteIndustria.clienteNomeFantasia,
                    industriaNomeFantasia: props.clienteIndustria.industriaNomeFantasia,
                    dataUltimaCompra: props?.clienteIndustriaAux?.dataUltimaCompra?.toString().substr(0, 10),
                }
            );

            addLoadData('clienteIndustriaAux');
        }
    }, [props.clienteIndustriaAux, props.ultimoPedido]);

    useEffect(() => {
        if (props.clienteIndustriaAux?.compradores) {

            if (props.clienteIndustriaAux?.compradores.length > 0) {
                var compradoresAux = props.clienteIndustriaAux.compradores.map((comprador, index) => {
                    return {
                        compradorId: comprador.compradorId,
                        observacao: comprador.observacao
                    } as ICompradorCliente;
                });

                setCompradores(compradoresAux);
            }
        }

    }, [props.clienteIndustriaAux?.compradores])

    const submit = (values: IClienteIndustriaAuxForm) => {

        if (values) {

            values.compradores = compradores.filter(x => x.compradorId);
            values.updateStatus = props.updateStatus;

            dispatch(ClienteActions.updateClienteIndustriaAux(values));
            setIsSendingData(true);
        }
        else {
            toast.error('Preencha todos os campos obrigatórios!');
        }

        setIsSubmit(false);
    }

    if (!props.isLoading && isSendingData) {
        setIsSendingData(false);
        dispatch(ClienteActions.getClienteIndustriaSuccess({} as IClienteIndustriaAuxForm));
        props.onClose();
        props.onSave && props.onSave();
    }

    const onAddComprador = (newComprador: ICompradorCliente) => {
        if (newComprador.compradorId)
            setCompradores([...compradores, newComprador]);
    }

    const onRemoveComprador = (index: number) => {
        var compradoresAux = [...compradores];
        compradoresAux.splice(index, 1);
        setCompradores(compradoresAux);
    }

    const getOptionsCompradores = () => {
        var compradorClienteIds = compradores.map(x => x.compradorId);
        var contatosOptionsAux = contatosOptions.filter(x => !compradorClienteIds.includes(parseInt(x.value.toString())));
        return contatosOptionsAux;
    }

    const onClose = () => {
        dispatch(ClienteActions.getClienteIndustriaSuccess({} as IClienteIndustriaAuxForm));
        props.onClose();
    }

    const onChangeDesconto = (descNum: number, value: string) => {
        var descAux = (!isNaN(parseFloat(value.replaceAll(',', '.'))) && !(parseFloat(value.replaceAll(',', '.')) < 0)) ? parseFloat(value.replaceAll(',', '.')) : 0;
        setDefaultValuesForm({ ...defaultValuesForm, ['descontoEncadeado_' + descNum.toString()]: descAux });
    }

    useEffect(() => {
        var descTotal =
            (defaultValuesForm.descontoEncadeado_1 && defaultValuesForm.descontoEncadeado_1 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_1 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_2 && defaultValuesForm.descontoEncadeado_2 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_2 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_3 && defaultValuesForm.descontoEncadeado_3 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_3 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_4 && defaultValuesForm.descontoEncadeado_4 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_4 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_5 && defaultValuesForm.descontoEncadeado_5 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_5 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_6 && defaultValuesForm.descontoEncadeado_6 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_6 / 100) : 1) *
            (defaultValuesForm.descontoEncadeado_7 && defaultValuesForm.descontoEncadeado_7 > 0 ? 1 - (defaultValuesForm.descontoEncadeado_7 / 100) : 1);

        descTotal = 100 - (descTotal * 100);
        setDescontoTotal(descTotal);

    }, [defaultValuesForm.descontoEncadeado_1, defaultValuesForm.descontoEncadeado_2, defaultValuesForm.descontoEncadeado_3, defaultValuesForm.descontoEncadeado_4, defaultValuesForm.descontoEncadeado_5, defaultValuesForm.descontoEncadeado_6, defaultValuesForm.descontoEncadeado_7]);

    const buttons =
        [
            <Button key="back" onClick={onClose}>Cancelar</Button>,
            <Button key="submit" type="primary" loading={props.isLoading} htmlType='submit' onClick={() => setIsSubmit(true)}>Salvar</Button>,
        ];

    return (
        <>
            <Modal title='Cadastro de Cliente' open={props.visible} footer={buttons} closable={false} destroyOnClose={true} width={800}>
                {
                    props.isLoading || onLoadData.length < 8 ?
                        <Skeleton active></Skeleton>
                        :
                        <>
                            <Form onSubmit={submit} schema={schema} initialValues={defaultValuesForm} isSubmited={isSubmit}>
                                <div>

                                    <FieldForm type='hidden' name='clienteIndustriaClienteId'></FieldForm>
                                    <FieldForm type='hidden' name='clienteIndustriaIndustriaId' ></FieldForm>
                                    <FieldForm autoComplete='false' readonly={true} key='clienteNomeFantasia' label='Cliente' name='clienteNomeFantasia' className={styles['inputForm']}></FieldForm>
                                    <div className={styles['groupField']}>
                                        <FieldForm autoComplete='false' readonly={true} key='industriaNomeFantasia' label='Indústria' name='industriaNomeFantasia' style={{ width: '66%' }} className={styles['inputGroup']}></FieldForm>
                                        <SelectForm name='pedidoStatusId' label='Pedido Status' tagRequired={true} options={pedidoStatusOptions} placeholder={'Selecione...'} style={{ width: '34%' }} className={styles['selectGroup']} />
                                    </div>
                                    <div className={styles['groupField']}>
                                        <SelectForm name='condicaoPagamento' label='Condição Pagamento' tagRequired={true} options={formasPagamentoOptions} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                        <SelectForm name='freteId' label='Frete' options={freteOptions} tagRequired={true} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                        <FieldNumber precision={0} autoComplete='false' key='comissaoPedido' label='Comissão' tagRequired={true} name='comissaoPedido' style={{ width: '34%' }} className={styles['inputGroup']}></FieldNumber>
                                    </div>
                                    <FieldForm autoComplete='false' key='descontoDescritivo' tagRequired={true} label='Desconto Descritivo' name='descontoDescritivo' className={styles['inputForm']}></FieldForm>
                                    <FieldNumber precision={2} autoComplete='false' key='descontoValor' tagRequired={true} label={<label>Desconto Percentual <small style={{ color: 'red' }}>*** Campo será removido ***</small></label>} name='descontoValor' className={styles['inputForm']}></FieldNumber>
                                    <div style={{ display: 'flex', marginTop: 10, marginBottom: 15 }}>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(1, e)} label='D1 %' autoComplete='false' key='descontoEncadeado_1' name='descontoEncadeado_1' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(2, e)} label='D2 %' autoComplete='false' key='descontoEncadeado_2' name='descontoEncadeado_2' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(3, e)} label='D3 %' autoComplete='false' key='descontoEncadeado_3' name='descontoEncadeado_3' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(4, e)} label='D4 %' autoComplete='false' key='descontoEncadeado_4' name='descontoEncadeado_4' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(5, e)} label='D5 %' autoComplete='false' key='descontoEncadeado_5' name='descontoEncadeado_5' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(6, e)} label='D6 %' autoComplete='false' key='descontoEncadeado_6' name='descontoEncadeado_6' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <FieldNumber min={0} precision={2} onBlur={(e) => onChangeDesconto(7, e)} label='D7 %' autoComplete='false' key='descontoEncadeado_7' name='descontoEncadeado_7' className={`${styles['inputGroup']} ${styles['campoDescontos']}`}></FieldNumber>
                                        <Field value={descontoTotal.toFixed(2).replaceAll('.', ',')} readonly={true} label='Desc Total %' autoComplete='false' key='descontoTotal' styleInput={{ width: 100 }} className={`${styles['inputGroup']} ${styles['campoDescontoTotal']}`}></Field>
                                    </div>
                                    <TextArea autoComplete='false' key='observacaoPedido' label='Observação do Pedido' name='observacaoPedido' className={styles['inputForm']}></TextArea>
                                    <TextArea rows={3} autoComplete='false' key='negociacao' label='Negociação RBR' name='negociacao' className={styles['inputForm']}></TextArea>
                                    <div className={styles['groupField']}>
                                        <SelectForm name='regimeEspecial' label='Regime Especial' options={simNaoOptions} tagRequired={true} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                        <FieldForm autoComplete='false' key='regimeEspecialObservacao' label='Observação Regime Especial' name='regimeEspecialObservacao' style={{ width: '67%' }} className={styles['inputGroup']}></FieldForm>
                                    </div>
                                    <div className={styles['groupField']}>
                                        <SelectForm name='aceitaPendencia' label='Aceita Pendência' tagRequired={true} options={aceitaPendenciaOptions} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                        <FieldForm autoComplete='false' key='aceitaPendenciaObservacao' tagRequired={true} label='Observação Aceita Pendência' name='aceitaPendenciaObservacao' style={{ width: '67%' }} className={styles['inputGroup']}></FieldForm>
                                    </div>
                                    <SelectForm mode='multiple' name='transportadorasId' tagRequired={true} label='Transportadoras' options={transportadorasOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm mode='multiple' name='compradoresId' tagRequired={true} label='Compradores' options={contatosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <TextArea rows={3} autoComplete='false' key='observacaoCompradores' label='Observação Compradores' name='observacaoCompradores' className={styles['inputForm']}></TextArea>
                                    <div className={styles['groupField']}>
                                        <FieldForm readonly={defaultValuesForm?.isUltimaCompraSV} autoComplete='false' key='numeroUltimaCompra' label='Nº Último Pedido' name='numeroUltimaCompra' style={{ width: '33%' }} className={styles['inputGroup']}></FieldForm>
                                        <FieldForm readonly={defaultValuesForm?.isUltimaCompraSV} type='date' autoComplete='false' key='dataUltimaCompra' label='Data Último Pedido' name='dataUltimaCompra' style={{ width: '33%' }} className={styles['inputGroup']}></FieldForm>
                                        <FieldNumber precision={2} readonly={defaultValuesForm?.isUltimaCompraSV} autoComplete='false' key='valorUltimaCompra' label='Valor Último Pedido' name='valorUltimaCompra' style={{ width: '34%' }} className={styles['inputGroup']}></FieldNumber>
                                    </div>
                                    <TextArea rows={3} autoComplete='false' key='planoAcao' label='Plano de Ação' name='planoAcao' className={styles['inputForm']}></TextArea>
                                    <div className={styles['groupField']}>
                                        <SelectForm disabled={!(props.clienteIndustriaAux?.isSupervisorUserLogged || isAccessAdmin())} tagRequired={true} name='interacao' label='Interação' options={simNaoOptions} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                        <SelectForm disabled={!(props.clienteIndustriaAux?.isSupervisorUserLogged || isAccessAdmin())} tagRequired={true} name='categoriaClienteId' label='Categoria' options={categoriasClienteOptions} placeholder={'Selecione...'} style={{ width: '34%' }} className={styles['selectGroup']} />
                                        <SelectForm disabled={!(props.clienteIndustriaAux?.isSupervisorUserLogged || isAccessAdmin())} tagRequired={true} name='potencialCrescimento' label='Potencial de Crescimento' options={simNaoOptions} placeholder={'Selecione...'} style={{ width: '33%' }} className={styles['selectGroup']} />
                                    </div>
                                    <TextArea rows={3} autoComplete='false' key='perfil' label='Perfil Cliente' name='perfil' className={styles['inputForm']}></TextArea>
                                    <TextArea rows={3} autoComplete='false' key='observacao' label='Observação do Cliente' name='observacao' className={styles['inputForm']}></TextArea>
                                    <SelectForm name='coordenadorUsuarioId' label='Coordenador' tagRequired={true} options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm mode='multiple' name='supervisoresId' label='Supervisores' options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm name='gestorUsuarioId' label='Gestor' tagRequired={true} options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm name='apoioPrincialUsuarioId' label='Apoio Principal' options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm mode='multiple' name='vendedoresId' label='Vendedores' tagRequired={true} options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                    <SelectForm mode='multiple' name='promotoresId' label='Promotores' tagRequired={true} options={usuariosOptions} placeholder={'Selecione...'} className={styles['selectGroup']} />
                                </div>
                            </Form >
                            <div>
                                <Form onSubmit={onAddComprador} resetForm={true}>
                                    <div className={styles['groupField']} >
                                        <SelectForm
                                            name='compradorId'
                                            label={'Compradores'}
                                            placeholder={'Selecione...'}
                                            className={styles['selectGroup']}
                                            tagRequired={true}
                                            style={{ width: '33%' }}
                                            options={getOptionsCompradores()}
                                        />
                                        <FieldForm
                                            name='observacao'
                                            placeholder='Observação'
                                            autoComplete='false'
                                            label={'Observação'}
                                            style={{ width: '60%' }}
                                            className={styles['inputGroup']}
                                        />
                                        <Button disabled={getOptionsCompradores().length == 0} htmlType='submit' type='primary' className={styles['buttonAddComprador']} >+</Button>
                                    </div>
                                </Form>
                                {compradores.map((comprador, index) =>
                                (
                                    <div className={styles["compradores"]}>
                                        <div className={styles['nome']}>
                                            {contatosOptions.find(x => x.value == comprador.compradorId)?.text}
                                        </div>
                                        <div className={styles['observacao']}>
                                            {comprador.observacao}
                                        </div>
                                        <div>
                                            <FaTrash title='Excluir' onClick={() => onRemoveComprador(index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                }
            </Modal>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.svApi,
    ...state.svDb,
    ...state.cliente,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IClienteModal;

export default connector(ClienteForm);