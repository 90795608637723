import { Button, Modal, Skeleton, Space, Table, TablePaginationConfig } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import styles from './ModeloForm.module.scss';
import { history } from "../../../store";
import { ProjecaoVendaActions } from "../../../store/projecaoVenda/ProjecaoVenda.actions";
import { getColumns, removeAllEditingClass } from "./ModeloForm.helper";
import { IModeloCombinacao, IModeloCombinacaoItem } from "../../../data/interfaces/projecaoVenda/IModeloCombinacao";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";

export interface IModeloForm {
    match: any;
}

const ModeloForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalEditStatus, setIsVisibleModalEditStatus] = useState(false);
    const [isVisibleModalHistorico, setIsVisibleModalHistorico] = useState(false);
    const [chaveEditing, setChaveEditing] = useState<string | null>(null);
    const [modelo, setModelo] = useState<IModeloCombinacao>({ modeloItens: [] as IModeloCombinacaoItem[] } as IModeloCombinacao);

    useEffect(() => {
        setModelo({} as IModeloCombinacao);
        dispatch(ProjecaoVendaActions.getModelo(props.match.params.id));
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.modelo.modeloItens?.length > 0) {
            setModelo(props.modelo);
        }
    }, [props.modelo]);

    const showModalEditStatus = (chave: string) => {
        setIsVisibleModalEditStatus(true);
        setChaveEditing(chave);
    }

    const handleEditStatus = (newStatus: string) => {
        var modeloAux = { ...modelo };
        var index = modeloAux.modeloItens.findIndex(x => x.chave == chaveEditing);
        var registerUpdate = modeloAux.modeloItens[index];
        registerUpdate.status = newStatus;
        registerUpdate.editing = false;

        modeloAux.modeloItens.splice(index, 1, registerUpdate);

        setModelo(modeloAux);
        setIsVisibleModalEditStatus(false);
    }

    const closeModalEditStatus = () => {
        removeAllEditingClass();
        setIsVisibleModalEditStatus(false);
    }

    const handleSaveModelo = () => {
        dispatch(ProjecaoVendaActions.updateModelo(modelo));
    }

    const tablePaginationConfig = {
        pageSize: 100,
        showSizeChanger: false,
    } as TablePaginationConfig;

    return (
        <>
            <PageContent title='Modelo - Projeção de Venda' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Space>
                                <Button type='primary' onClick={handleSaveModelo}>Salvar</Button>
                                <Button type='default' onClick={() => setIsVisibleModalHistorico(true)}>Histórico de Alteração</Button>
                                <Button type='link' onClick={() => history.goBack()}>Voltar</Button>                            </Space>
                        </div>
                        <Table size="small" rowKey={'chave'} columns={getColumns({ ...modelo }.modeloItens, showModalEditStatus)} dataSource={props.modelo.modeloItens} style={{ overflowY: 'auto' }} pagination={tablePaginationConfig} />
                    </>
                }
            </PageContent>

            <Modal title="Editar Status - Projeção de Venda" width={390} open={isVisibleModalEditStatus} onCancel={() => closeModalEditStatus()} footer={null}>
                <Space>
                    <Button type="default" onClick={() => handleEditStatus("Sim")}>Sim</Button>
                    <Button type="default" onClick={() => handleEditStatus("Não")}>Não</Button>
                    <Button type="default" onClick={() => handleEditStatus("Pós Venda")}>Pós Venda</Button>
                    <Button type="default" onClick={() => handleEditStatus("Verificar")}>Verificar</Button>
                </Space>
            </Modal>

            <Modal title="Histórico de Alterações" width={700} open={isVisibleModalHistorico} onCancel={() => setIsVisibleModalHistorico(false)} footer={null}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                    <p style={{ width: 100 }}>Código</p>
                    <p style={{ width: 170 }}>Status Antigo</p>
                    <p style={{ width: 150 }}>Status Novo</p>
                    <p style={{ width: 130 }}>Data</p>
                    <p style={{ width: 200 }}>Usuário</p>
                </div>
                {
                    props.modelo?.auditorias?.map(x =>
                        <div key={x.codigo} style={{ display: 'flex' }}>
                            <p style={{ width: 100 }}>{x.codigo}</p>
                            <p style={{ width: 170 }}>{x.oldStatus}</p>
                            <p style={{ width: 150 }}>{x.newStatus}</p>
                            <p style={{ width: 130 }}>{dateStringUStoPTBR(x.date)}</p>
                            <p style={{ width: 200 }}>{x.user.name}</p>
                        </div>
                    )
                }
            </Modal>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IModeloForm;

export default connector(ModeloForm);