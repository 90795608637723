import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { ProjecaoVendaApi } from '../../data/ProjecaoVenda.api';
import { CombinacaoDeleteAction, GetCombinacaoAction, GetModeloAction, ImportarCombinacaoAction, ImportarModeloAction, ModeloDeleteAction, ProjecaoVendaActions, UpdateCombinacaoAction, UpdateModeloAction } from './ProjecaoVenda.actions';

export function* listCombinacoes() {
  try {
    const { data } = yield call(ProjecaoVendaApi.listCombinacoes);
    yield put(ProjecaoVendaActions.listCombinacoesSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* getCombinacao({ payload }: GetCombinacaoAction) {
  try {
    const { data } = yield call(ProjecaoVendaApi.getCombinacao, payload);
    yield put(ProjecaoVendaActions.getCombinacaoSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* updateCombinacao({ payload }: UpdateCombinacaoAction) {
  try {
    yield call(ProjecaoVendaApi.updateCombinacaoItens, payload);
    yield put(ProjecaoVendaActions.updateCombinacaoSuccess());

    toast.success('Projeção de venda alterada com Sucesso!')
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* importarCombinacao({ payload }: ImportarCombinacaoAction) {
  try {
    const { data } = yield call(ProjecaoVendaApi.importarCombinacao, payload);
    yield put(ProjecaoVendaActions.importarCombinacaoSuccess(data.data));

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação das Projeção de Vendas está inválida!');
      yield put(ProjecaoVendaActions.importarCombinacaoInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(ProjecaoVendaActions.importarCombinacaoFailure(error));
    }
  }
}

export function* deleteCombinacao({ payload }: CombinacaoDeleteAction) {
  try {
    yield call(ProjecaoVendaApi.deleteCombinacao, payload);
    yield put(ProjecaoVendaActions.deleteCombinacaoSuccess(payload));
    toast.success('Combinação excluída com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* listModelos() {
  try {
    const { data } = yield call(ProjecaoVendaApi.listModelos);
    yield put(ProjecaoVendaActions.listModelosSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* importarModelo({ payload }: ImportarModeloAction) {
  try {
    const { data } = yield call(ProjecaoVendaApi.importarModelo, payload);
    yield put(ProjecaoVendaActions.importarModeloSuccess(data.data));

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação das Modelos está inválida!');
      yield put(ProjecaoVendaActions.importarModeloInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(ProjecaoVendaActions.importarModeloFailure(error));
    }
  }
}

export function* deleteModelo({ payload }: ModeloDeleteAction) {
  try {
    yield call(ProjecaoVendaApi.deleteModelo, payload);
    yield put(ProjecaoVendaActions.deleteModeloSuccess(payload));
    toast.success('Modelo excluído com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* getModelo({ payload }: GetModeloAction) {
  try {
    const { data } = yield call(ProjecaoVendaApi.getModelo, payload);
    yield put(ProjecaoVendaActions.getModeloSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}

export function* updateModelo({ payload }: UpdateModeloAction) {
  try {
    yield call(ProjecaoVendaApi.updateModeloItens, payload);
    yield put(ProjecaoVendaActions.updateModeloSuccess());

    toast.success('Modelo alterado com Sucesso!')
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProjecaoVendaActions.defaultFailure(error));
  }
}