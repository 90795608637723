import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { PedidoImportadoActions } from "../../../store/pedido/importador/PedidoImportado.actions";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import PageContent from "../../../components/ui/pageContent";
import { Button, Skeleton, Table } from "antd";

import styles from './PedidosImportados.module.scss';
import { getColumns } from "./PedidosImportados.helper";
import { TablePaginationConfig } from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import { IPedidosNaoImportado } from "../../../data/interfaces/pedido/IPedidosNaoImportado";
import PedidoNaoImportadoForm from './modals/pedidoNaoImportadoForm';
import { IPedidosNaoImportadoRequest } from "../../../data/interfaces/pedido/IPedidosNaoImportadoRequest";
import { toast } from "react-toastify";

const PedidosNaoImportados: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [defaultValuesUserForm, setDefaultValuesUserForm] = useState<IPedidosNaoImportadoRequest>({} as IPedidosNaoImportadoRequest);
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [qtdeRows, setQtdeRows] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        dispatch(PedidoImportadoActions.listPedidosNaoImportados());
    }, [dispatch]);

    const tablePaginationConfig = {
        pageSize: 500,
        showSizeChanger: false,
        showTotal: (total) => {
            setQtdeRows(total);
        }
    } as TablePaginationConfig;

    const localeTable = {
        emptyText: 'Nenhum dado encontrado',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    const onEdit = (register: IPedidosNaoImportado) => {
        var request = {} as IPedidosNaoImportadoRequest;

        if (register) {
            request.pedidosNumero = [register.pedidoNumero];
            request.observacao = register.observacao;
            request.verificado = register.verificado;
        }

        setDefaultValuesUserForm(request);
        setFormVisible(true);
    }

    const onEditMulti = (ids: React.Key[]) => {
        if (ids?.length > 0) {
            var request = {} as IPedidosNaoImportadoRequest;
            request.pedidosNumero = ids.map(x => parseInt(x.toString()));
            setDefaultValuesUserForm(request);
            setFormVisible(true);
        }
        else{
            toast.warning('Selecione um ou mais pedidos');
        }
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeysAux: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeysAux);
        }
    };

    return (<>
        <PageContent title='Pedidos Importados' className={styles['container']}>
            {props.isLoading ?
                <Skeleton active />
                :
                <>
                    <div className={styles['toolBar']}>
                        <Button type='primary' onClick={() => onEditMulti(rowSelection.selectedRowKeys)}>Editar Informações</Button>
                    </div>
                    <div className={styles['qtdeRows']}>
                        {qtdeRows} registros
                    </div>
                    <br></br>
                    <Table
                        rowSelection={{ type: 'checkbox', ...rowSelection }}
                        className={styles['tablePedidos']}
                        locale={localeTable}
                        columns={getColumns(props.pedidosNaoImportados, onEdit)}
                        dataSource={props.pedidosNaoImportados.map((data) => ({ ...data, key: data.pedidoNumero }))}
                        style={{ overflowY: 'auto' }}
                        pagination={tablePaginationConfig} />
                </>
            }
        </PageContent>
        {formVisible
            ? <PedidoNaoImportadoForm defaultValues={defaultValuesUserForm} visible={formVisible} onClose={() => setFormVisible(false)}></PedidoNaoImportadoForm>
            : null}
    </>);
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.pedidoImportado,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(PedidosNaoImportados);