import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { MetaApi } from '../../data/Meta.api';
import { GetMetaAction, ImportarMetaAction, MetaActions, UpdateMetaAction } from './Meta.actions';

export function* getMeta({ payload }: GetMetaAction) {
  try {
    const { data } = yield call(MetaApi.get, payload);
    yield put(MetaActions.getMetaSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MetaActions.defaultFailure(error));
  }
}

export function* updateMeta({ payload }: UpdateMetaAction) {
  try {
    const { data } = yield call(MetaApi.update, payload);
    yield put(MetaActions.updateMetaSuccess(data.data));
    toast.success('Metas atualizadas com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(MetaActions.defaultFailure(error));
  }
}

export function* importarMeta({ payload }: ImportarMetaAction) {
  try {
    yield call(MetaApi.importarMeta, payload);
    yield put(MetaActions.importarMetaSuccess());

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação das Metas está inválida!');
      yield put(MetaActions.importarMetaInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(MetaActions.importarMetaFailure(error));
    }
  }
}