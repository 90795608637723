import React from "react";
import { ColumnProps } from "antd/lib/table";
import { FaPen, FaTrash, FaUnlockAlt } from "react-icons/fa";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { getFilterSearchProps, getDistinctFilterValues, sorter } from "../../utils/helpers";

import styles from './Users.module.scss';
import Confirm from "../../components/ui/confirm";

export const getColumns = (users: IUserResponse[], onEdit: (register: IUserResponse) => void, onDelete: (userId: string) => void, onUpdatePassword: (userId: string) => void) => {
    return [
        {
            title: 'ID SV',
            dataIndex: 'idSuasVendas',
            sorter: (a, b) => a.idSuasVendas - b.idSuasVendas,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 120,
            ...getFilterSearchProps('idSuasVendas'),
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            filters: getDistinctFilterValues(users, 'name'),
            onFilter: (value: string, record) => record.name.indexOf(value) === 0,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.email, b.email),
            showSorterTooltip: false,
            ...getFilterSearchProps('email'),
        },
        {
            title: 'Tipo',
            dataIndex: 'roleName',
            width: 250,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.roleName, b.roleName),
            showSorterTooltip: false,
            filters: getDistinctFilterValues(users, 'roleName'),
            onFilter: (value: string, record) => record.roleName.indexOf(value) === 0,
        },
        {
            width: 170,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaPen title='Editar' onClick={() => onEdit(register)} />
                        <FaUnlockAlt title='Alterar Senha' onClick={() => onUpdatePassword(register.id)} />
                        <FaTrash title='Excluir' onClick={() => Confirm('Excluir Usuário', 'Deseja realmente excluir usuário?', () => onDelete(register.id))} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<IUserResponse>>;
}