import { ColumnProps } from "antd/es/table";
import { IAtualizarProdutoItemResponse } from "../../../../../data/interfaces/atualizarProduto/IAtualizarProdutoRequest";
import { sorter } from "../../../../../utils/helpers";
import { dateStringUStoPTBR } from "../../../../../utils/dateHelper";
import { history } from "../../../../../store";
import React from "react";

//CODIGONOVO,DATALANCAMENTO,OBSERVACAO,DETALHE,ESPECIFICACAO
//onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/ClienteForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(register.cliente?.clienteId.toString()))}`)}>
export const getColumns = (colunasAtualizadas: string) => {
    var colunas = [
        {
            title: 'Código',
            dataIndex: 'codigoAntigo',
            sorter: (a, b) => sorter(a.codigoAntigo, a.codigoAntigo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            key: "CODIGO",
            render: (value, registro) => {
                return <a onClick={() => window.open(`https://app4.suasvendas.com/Modulo/YourSales/ProdutoForm.aspx?Op=UldScGRHRnk=&Id=${btoa(btoa(registro.produtoId?.toString()))}`)}>
                    {value}
                </a>
            }
        },
        {
            title: 'Código Novo',
            dataIndex: 'codigoNovo',
            sorter: (a, b) => sorter(a.codigoNovo, a.codigoNovo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            key: "CODIGONOVO"
        },
        {
            title: 'Data Lançamento',
            dataIndex: 'dataLancamento',
            sorter: (a, b) => sorter(a.dataLancamento, a.dataLancamento),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            key: "DATALANCAMENTO",
            render: (value, register) => {
                return `${dateStringUStoPTBR(register.dataLancamento)}`;
            }
        },
        {
            title: 'Observação',
            dataIndex: 'observacao',
            sorter: (a, b) => sorter(a.observacao, a.observacao),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            key: "OBSERVACAO",
        },
        {
            title: 'Detalhe',
            dataIndex: 'detalhe',
            sorter: (a, b) => sorter(a.detalhe, a.detalhe),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            key: "DETALHE",
        },
        {
            title: 'Especificação',
            dataIndex: 'especificacao',
            sorter: (a, b) => sorter(a.especificacao, a.especificacao),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            key: "ESPECIFICACAO",
        },
        {
            title: 'Atualizado',
            dataIndex: 'atualizado',
            showSorterTooltip: false,
            key: "ATUALIZADO",
            render: (value) => value == 1 ? <b style={{ color: 'green' }}>Sim</b> : <b style={{ color: 'red' }}>Não</b>
        },
    ] as Array<ColumnProps<IAtualizarProdutoItemResponse>>;

    colunasAtualizadas += "CODIGO,ATUALIZADO";

    colunas = colunas.filter((coluna) => {
        return colunasAtualizadas.indexOf(coluna.key?.toString() ?? '') > -1;
    });

    return colunas;
}
