import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { ProdutoApi } from '../../data/Produto.api';
import { AtualizarProdutoAction, ProdutoActions, ListAtualizarProdutosAction, GetAtualizarProdutoInDetailAction, ReiniciarAtualizarProdutoAction } from './Produto.actions';
import { GetAtualizarPedidosAction } from '../atualizarPedido/AtualizarPedido.actions';

export function* atualizarProduto({ payload }: AtualizarProdutoAction) {
  try {
    const { data } = yield call(ProdutoApi.atualizarProduto, payload);
    yield put(ProdutoActions.atualizarProdutoSuccess(data.data));
  } catch (e) {

    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação do pedido está inválida!');
      yield put(ProdutoActions.atualizarProdutoInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(ProdutoActions.atualizarProdutoFailure(error));
    }
  }
}

export function* listAtualizarProdutos() {
  try {
    const { data } = yield call(ProdutoApi.list);
    yield put(ProdutoActions.listAtualizarProdutosSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProdutoActions.defaultFailure(error));
  }
}

export function* getAtualizarProdutoInDetail({ payload }: GetAtualizarProdutoInDetailAction) {
  try {
    const { data } = yield call(ProdutoApi.get, payload);
    yield put(ProdutoActions.getAtualizarProdutoInDetailSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProdutoActions.defaultFailure(error));
  }
}

export function* getAtualizarProduto({ payload }: GetAtualizarPedidosAction) {
  try {
    const { data } = yield call(ProdutoApi.get, payload);
    yield put(ProdutoActions.getAtualizarProdutoSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProdutoActions.defaultFailure(error));
  }
}

export function* reiniciarAtualizarProduto({ payload }: ReiniciarAtualizarProdutoAction) {
  try {
    yield call(ProdutoApi.reiniciar, payload);
    yield put(ProdutoActions.reiniciarAtualizarProdutoSuccess(payload));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ProdutoActions.defaultFailure(error));
  }
}
