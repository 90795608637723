import { IAtualizarPedidoResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoResponse";
import { IAtualizarPedidoItemResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoItemResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";
import { IAtualizarPedidoInfosResponse } from "../../data/interfaces/atualizarPedido/IAtualizarPedidoInfosResponse";
import { IRestaurarItemPedido } from "../../data/interfaces/atualizarPedido/IRestaurarItemPedido";
import { IAtualizarItensPedidoRequest } from "../../data/interfaces/atualizarPedido/IAtualizarItensPedidoRequest";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";

export enum AtualizarPedidoActionKeys {
  LIST_ATUALIZAR_PEDIDOS_REQUEST = 'LIST_ATUALIZAR_PEDIDOS_REQUEST',
  LIST_ATUALIZAR_PEDIDOS_SUCCESS = 'LIST_ATUALIZAR_PEDIDOS_SUCCESS',
  LIST_ATUALIZAR_PEDIDOS_FAILED = 'LIST_ATUALIZAR_PEDIDOS_FAILED',

  GET_ATUALIZAR_PEDIDOS_REQUEST = 'GET_ATUALIZAR_PEDIDOS_REQUEST',
  GET_ATUALIZAR_PEDIDOS_SUCCESS = 'GET_ATUALIZAR_PEDIDOS_SUCCESS',
  GET_ATUALIZAR_PEDIDOS_FAILED = 'GET_ATUALIZAR_PEDIDOS_FAILED',

  LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST = 'LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST',
  LIST_ATUALIZAR_PEDIDOS_ITEMS_SUCCESS = 'LIST_ATUALIZAR_PEDIDOS_ITEMS_SUCCESS',
  LIST_ATUALIZAR_PEDIDOS_ITEMS_FAILED = 'LIST_ATUALIZAR_PEDIDOS_ITEMS_FAILED',

  DETAIL_ATUALIZACAO_PEDIDOS_REQUEST = 'DETAIL_ATUALIZACAO_PEDIDOS_REQUEST',
  DETAIL_ATUALIZACAO_PEDIDOS_SUCCESS = 'DETAIL_ATUALIZACAO_PEDIDOS_SUCCESS',
  DETAIL_ATUALIZACAO_PEDIDOS_FAILED = 'DETAIL_ATUALIZACAO_PEDIDOS_FAILED',

  RESTAURAR_PEDIDO_REQUEST = 'RESTAURAR_PEDIDO_REQUEST',
  RESTAURAR_PEDIDO_SUCCESS = 'RESTAURAR_PEDIDO_SUCCESS',
  RESTAURAR_PEDIDO_FAILED = 'RESTAURAR_PEDIDO_FAILED',

  RESTAURAR_ITEM_PEDIDO_REQUEST = 'RESTAURAR_ITEM_PEDIDO_REQUEST',
  RESTAURAR_ITEM_PEDIDO_SUCCESS = 'RESTAURAR_ITEM_PEDIDO_SUCCESS',
  RESTAURAR_ITEM_PEDIDO_FAILED = 'RESTAURAR_ITEM_PEDIDO_FAILED',

  ATUALIZAR_ITENS_PEDIDO_REQUEST = 'ATUALIZAR_ITENS_PEDIDO_REQUEST',
  ATUALIZAR_ITENS_PEDIDO_SUCCESS = 'ATUALIZAR_ITENS_PEDIDO_SUCCESS',
  ATUALIZAR_ITENS_PEDIDO_FAILED = 'ATUALIZAR_ITENS_PEDIDO_FAILED',
  ATUALIZAR_ITENS_PEDIDO_INVALID = 'ATUALIZAR_ITENS_PEDIDO_INVALID',
  ATUALIZAR_ITENS_PEDIDO_CLEAR = 'ATUALIZAR_ITENS_PEDIDO_CLEAR',

}

export const AtualizarPedidoActions = {
  listAtualizarPedidos: (): ListAtualizarPedidosAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_REQUEST),
  listAtualizarPedidosSuccess: (response: IAtualizarPedidoResponse[]): ListAtualizarPedidosSuccessAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_SUCCESS, response),
  listAtualizarPedidosFailure: (err: string): ListAtualizarPedidosFailureAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_FAILED, err),

  getAtualizarPedidos: (request: number): GetAtualizarPedidosAction =>
    createAction(AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_REQUEST, request),
  getAtualizarPedidosSuccess: (response: IAtualizarPedidoResponse): GetAtualizarPedidosSuccessAction =>
    createAction(AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_SUCCESS, response),
  getAtualizarPedidosFailure: (err: string): GetAtualizarPedidosFailureAction =>
    createAction(AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_FAILED, err),

  listAtualizarPedidoItems: (): ListAtualizarPedidoItemsAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST),
  listAtualizarPedidoItemsSuccess: (response: IAtualizarPedidoItemResponse[]): ListAtualizarPedidoItemsSuccessAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_SUCCESS, response),
  listAtualizarPedidoItemsFailure: (err: string): ListAtualizarPedidoItemsFailureAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_FAILED, err),

  detailAtualizacaoPedidos: (atualizacaoId: number): DetailAtualizacaoPedidosAction =>
    createAction(AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_REQUEST, atualizacaoId),
  detailAtualizacaoPedidosSuccess: (response: IAtualizarPedidoInfosResponse[]): DetailAtualizacaoPedidosSuccessAction =>
    createAction(AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_SUCCESS, response),
  detailAtualizacaoPedidosFailure: (err: string): DetailAtualizacaoPedidosFailureAction =>
    createAction(AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_FAILED, err),

  restaurarPedido: (atualizarPedidoInfoId: number): RestaurarPedidoAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_REQUEST, atualizarPedidoInfoId),
  restaurarPedidoSuccess: (response: IAtualizarPedidoInfosResponse): RestaurarPedidoSuccessAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_SUCCESS, response),
  restaurarPedidoFailure: (err: string): RestaurarPedidoFailureAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_FAILED, err),

  restaurarItemPedido: (request: IRestaurarItemPedido): RestaurarItemPedidoAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_REQUEST, request),
  restaurarItemPedidoSuccess: (response: IRestaurarItemPedido): RestaurarItemPedidoSuccessAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_SUCCESS, response),
  restaurarItemPedidoFailure: (err: string): RestaurarItemPedidoFailureAction =>
    createAction(AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_FAILED, err),

  atualizarItensPedido: (request: IAtualizarItensPedidoRequest): AtualizarItensPedidoAction =>
    createAction(AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_REQUEST, request),
  atualizarItensPedidoSuccess: (response: IAtualizarPedidoResponse): AtualizarItensPedidoSuccessAction =>
    createAction(AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_SUCCESS, response),
  atualizarItensPedidoFailure: (err: string): AtualizarItensPedidoFailureAction =>
    createAction(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_FAILED, err),
  atualizarItensPedidoInvalid: (err: IErroResponse[]): AtualizarItensPedidoInvalidAction =>
    createAction(AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_INVALID, err),
  atualizarItensPedidoClear: (): AtualizarItensPedidoClearAction =>
    createAction(AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_CLEAR),
};

export type AtualizarPedidoActionUnion = ActionsUnion<typeof AtualizarPedidoActions>;

export type ListAtualizarPedidosAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_REQUEST>;
export type ListAtualizarPedidosSuccessAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_SUCCESS, IAtualizarPedidoResponse[]>;
export type ListAtualizarPedidosFailureAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_FAILED, string>;

export type GetAtualizarPedidosAction = Action<AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_REQUEST, number>;
export type GetAtualizarPedidosSuccessAction = Action<AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_SUCCESS, IAtualizarPedidoResponse>;
export type GetAtualizarPedidosFailureAction = Action<AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_FAILED, string>;

export type ListAtualizarPedidoItemsAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST>;
export type ListAtualizarPedidoItemsSuccessAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_SUCCESS, IAtualizarPedidoItemResponse[]>;
export type ListAtualizarPedidoItemsFailureAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_FAILED, string>;

export type DetailAtualizacaoPedidosAction = Action<AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_REQUEST, number>;
export type DetailAtualizacaoPedidosSuccessAction = Action<AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_SUCCESS, IAtualizarPedidoInfosResponse[]>;
export type DetailAtualizacaoPedidosFailureAction = Action<AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_FAILED, string>;

export type RestaurarPedidoAction = Action<AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_REQUEST, number>;
export type RestaurarPedidoSuccessAction = Action<AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_SUCCESS, IAtualizarPedidoInfosResponse>;
export type RestaurarPedidoFailureAction = Action<AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_FAILED, string>;

export type RestaurarItemPedidoAction = Action<AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_REQUEST, IRestaurarItemPedido>;
export type RestaurarItemPedidoSuccessAction = Action<AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_SUCCESS, IRestaurarItemPedido>;
export type RestaurarItemPedidoFailureAction = Action<AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_FAILED, string>;

export type AtualizarItensPedidoAction = Action<AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_REQUEST, IAtualizarItensPedidoRequest>;
export type AtualizarItensPedidoSuccessAction = Action<AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_SUCCESS, IAtualizarPedidoResponse>;
export type AtualizarItensPedidoFailureAction = Action<AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_FAILED, string>;
export type AtualizarItensPedidoInvalidAction = Action<AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_INVALID, IErroResponse[]>;
export type AtualizarItensPedidoClearAction = Action<AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_CLEAR>;
