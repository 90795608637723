import React, { FC, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { IModule, IModuleColumnUser } from '../../../../data/interfaces/module/IModuleColumnUser';
import { Button, Modal } from 'antd';
import { getOptionsDataFromObject } from '../../../../utils/helpers';
import Select from '../../../../components/ui/select';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { ModuleActions } from '../../../../store/module/Module.actions';

export interface IListColumnManager {
    moduleId: number,
    columns: IModuleColumnUser[],
    visible: boolean,
    onSave: (columns: IModuleColumnUser[]) => void;
    onClose: () => void;
}

const ModalColumnsManager: FC<IListColumnManager> = (props) => {
    const dispatch = useDispatch();
    const [items, setItems] = useState(props.columns);
    const [columnSelected, setColumnSelected] = useState<number>();

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const updatedItems = Array.from(items);
        const [movedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, movedItem);

        var orderAux = 1;
        updatedItems.forEach(x => x.order = orderAux++);

        setItems(updatedItems);
    };

    const handleAddColumn = () => {
        if (columnSelected) {
            var itemsAux = [...items];
            itemsAux.filter(x => x.moduleColumnId == columnSelected)[0].display = true;

            setItems(itemsAux);
            setColumnSelected(undefined);
        }
    }

    const handleRemoveColumn = (id: number) => {
        var itemsAux = [...items];
        itemsAux.filter(x => x.moduleColumnId == id)[0].display = false;

        setItems(itemsAux);
    }

    const save = () => {
        var module = {
            moduleId: props.moduleId,
            columns: items
        } as IModule;

        let order = 1;
        module.columns.forEach(x => x.order = x.display ? order++ : null);

        dispatch(ModuleActions.updateModule(module));
        props.onSave(items);
    }

    var columnOptions = getOptionsDataFromObject(props.columns.filter(x => !x.display), 'moduleColumnId', 'name')

    const buttons =
        [
            <Button key="back" onClick={() => props.onClose()}>Cancelar</Button>,
            <Button key="submit" type="primary" htmlType='submit' onClick={() => save()}>{'Salvar'}</Button>,
        ];

    return (
        <Modal title={'Personalizar Colunas'} open={props.visible} footer={buttons} onCancel={props.onClose} width={500}>
            <div style={{ display: 'flex' }}>
                <Select
                    onSelect={(e: any) => setColumnSelected(e)}
                    placeholder='Colunas para Adicionar'
                    key='colunas'
                    options={columnOptions}
                    allowClear={true}
                    value={columnSelected}
                    style={{ width: 330 }}
                />
                <Button type='primary' onClick={() => handleAddColumn()} style={{ position: 'relative', left: 25, top: 5 }}>Adicionar</Button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sortableList">
                    {(provided: any) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => (
                                <Draggable key={item.moduleColumnId} draggableId={item.moduleColumnId.toString()} index={index}>
                                    {(provided: any) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            hidden={!item.display}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                backgroundColor: 'white',
                                                boxShadow: '0 0 4px rgba(0,0,0,0.3)',
                                                display: 'flex',
                                                ...provided.draggableProps.style,
                                            }}
                                        >
                                            <div style={{ width: 390 }}>
                                                {item.name}
                                            </div>
                                            {
                                                !item.required
                                                    ?
                                                    <label style={{ cursor: 'pointer' }} onClick={() => handleRemoveColumn(item.moduleColumnId)}>
                                                        <FaTrashAlt color='gray' />
                                                    </label>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>
    );
};

export default ModalColumnsManager;
