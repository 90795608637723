import React, { FC } from 'react';

export interface ValueLabelBoxProps {
    value: string,
    label: string,
    width?: number,
    style?: React.CSSProperties,
    highlited?: boolean
}

const ValueLabelBox: FC<ValueLabelBoxProps> = ({ value, label, style, highlited, width }) => {

    style = {
        ...style,
        backgroundColor: highlited ? '#ff00002e' : undefined,
        margin: 3,
        width: width
    };

    return (
        <div style={style}>
            <div style={{ padding: '0 5px' }}>
                <small style={{ color: 'gray' }}>{label}</small>
                <p>{value}</p>
            </div>
        </div>
    );
}

export default ValueLabelBox;