import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ICampoApi } from "./interfaces/config/ICampoApi";
import { IConfig } from "./interfaces/config/IConfig";
import { IInfoTabelaIntegracao } from "./interfaces/config/IInfoTabelaIntegracao";
import { IStatusFunctions, RBRFunctionEnum } from "./interfaces/config/IStatusFunctions";

export class ConfigApi {
    static async getConfiguracoes(): Promise<AxiosResponse<IConfig>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/getConfiguracoes');
    }

    static async updateConfiguracoes(config: IConfig): Promise<AxiosResponse<IConfig>> {
        const axios = await Http.axios();
        return axios.put('/configuracoes/updateConfiguracoes', config);
    }

    static async getStatusFunctionAtualizacaoBanco(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/getStatusFunctionAtualizacaoBanco');
    }

    static async startFunctionAtualizacaoBanco(date: string): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/startFunctionAtualizacaoBanco?date=${date}`);
    }

    static async getStatusFunctionAjustesTextosAPI(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/getStatusFunctionAjustarCamposAPI');
    }

    static async startFunctionAjustesTextosAPI(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/startFunctionAjustarCamposAPI`);
    }

    static async listCamposApi(): Promise<AxiosResponse<ICampoApi[]>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/listCamposApi');
    }

    static async listCamposIntegracao(tabela: string): Promise<AxiosResponse<IInfoTabelaIntegracao>> {
        const axios = await Http.axios();
        return axios.get(`/configuracoes/listCamposIntegracao?tabela=${tabela}`);
    }

    static async getStatusFunctionAjustesTextosLancamentosAPI(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/getStatusFunctionAjustarCamposLancamentosAPI');
    }

    static async startFunctionAjustesTextosLancamentosAPI(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/startFunctionAjustarCamposLancamentosAPI`);
    }

    static async stopFunctionAjustesTextosLancamentosAPI(): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/StopFunctionAjustarCamposLancamentosAPI`);
    }

    static async stopFunctionAjustesTextosAPI(): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/StopFunctionAjustarCamposAPI`);
    }

    static async startFunctionAtualizarBaseDadosPedido(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.post(`/configuracoes/startFunctionAtualizarBaseDadosPedido`);
    }

    static async getStatusFunctionAtualizarBaseDadosPedido(): Promise<AxiosResponse<IStatusFunctions>> {
        const axios = await Http.axios();
        return axios.get('/configuracoes/getStatusFunctionAtualizarBaseDadosPedido');
    }

    static async disableFunctionAtualizacaoBanco(request: RBRFunctionEnum): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.post('/configuracoes/DisableFunction/' + request);
    }

    static async enableFunctionAtualizacaoBanco(request: RBRFunctionEnum): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.post('/configuracoes/EnableFunction/' + request);
    }
}