import React from "react";
import { ColumnProps } from "antd/lib/table";
import { FaPen, FaTrash, FaUnlockAlt } from "react-icons/fa";
import { getFilterSearchProps, getDistinctFilterValues, sorter } from "../../../utils/helpers";

import styles from './CategoriasCliente.module.scss';
import Confirm from "../../../components/ui/confirm";
import { ICategoriaCliente } from "../../../data/interfaces/cliente/ICategoriaCliente";

export const getColumns = (categorias: ICategoriaCliente[], onEdit: (register: ICategoriaCliente) => void, onDelete: (categoriaClienteId: number) => void) => {
    return [
        {
            title: 'Nome',
            dataIndex: 'nome',
            filters: getDistinctFilterValues(categorias, 'nome'),
            onFilter: (value: string, record) => record.nome.indexOf(value) === 0,
            sorter: (a, b) => sorter(a.nome, b.nome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false
        },
        {
            width: 120,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaPen title='Editar' onClick={() => onEdit(register)} />
                        <FaTrash title='Excluir' onClick={() => Confirm('Excluir Categoria', 'Deseja realmente excluir a categoria?', () => onDelete(register.categoriaClienteAuxId))} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<ICategoriaCliente>>;
}