import { IAcompanhamento } from "../../data/interfaces/acompanhamento/IAcompanhamento";
import { IAcompanhamentoRequest, IImportAcompanhamentoRequest, IUpdateAcompanhamentoRequest } from "../../data/interfaces/acompanhamento/IAcompanhamentoRequest";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";


export enum AcompanhamentoActionKeys {
  GET_ACOMPANHAMENTO_REQUEST = 'GET_ACOMPANHAMENTO_REQUEST',
  GET_ACOMPANHAMENTO_SUCCESS = 'GET_ACOMPANHAMENTO_SUCCESS',

  UPDATE_ACOMPANHAMENTO_REQUEST = 'UPDATE_ACOMPANHAMENTO_REQUEST',
  UPDATE_ACOMPANHAMENTO_SUCCESS = 'UPDATE_ACOMPANHAMENTO_SUCCESS',

  IMPORTAR_ACOMPANHAMENTO_REQUEST = 'IMPORTAR_ACOMPANHAMENTO_REQUEST',
  IMPORTAR_ACOMPANHAMENTO_SUCCESS = 'IMPORTAR_ACOMPANHAMENTO_SUCCESS',
  IMPORTAR_ACOMPANHAMENTO_FAILED = 'IMPORTAR_ACOMPANHAMENTO_FAILED',
  IMPORTAR_ACOMPANHAMENTO_INVALID = 'IMPORTAR_ACOMPANHAMENTO_INVALID',
  IMPORTAR_ACOMPANHAMENTO_CLEAR = 'IMPORTAR_ACOMPANHAMENTO_CLEAR',

  DEFAULT_FAILED = 'DEFAULT_FAILED'
}

export const AcompanhamentoActions = {
  getAcompanhamento: (request: IAcompanhamentoRequest): GetAcompanhamentoAction => createAction(AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_REQUEST, request),
  getAcompanhamentoSuccess: (response: IAcompanhamento[]): GetAcompanhamentoSuccessAction => createAction(AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_SUCCESS, response),

  updateAcompanhamento: (request: IUpdateAcompanhamentoRequest): UpdateAcompanhamentoAction => createAction(AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_REQUEST, request),
  updateAcompanhamentoSuccess: (): UpdateAcompanhamentoSuccessAction => createAction(AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_SUCCESS),

  importarAcompanhamento: (request: IImportAcompanhamentoRequest): ImportarAcompanhamentoAction => createAction(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_REQUEST, request),
  importarAcompanhamentoSuccess: (): ImportarAcompanhamentoSuccessAction => createAction(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_SUCCESS),
  importarAcompanhamentoFailure: (err: string): ImportarAcompanhamentoFailureAction => createAction(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_FAILED, err),
  importarAcompanhamentoInvalid: (err: IErroResponse[]): ImportarAcompanhamentoInvalidAction => createAction(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_INVALID, err),
  importarAcompanhamentoClear: (): ImportarAcompanhamentoClearAction => createAction(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_CLEAR),

  defaultFailure: (err: string): DefaultFailureAction => createAction(AcompanhamentoActionKeys.DEFAULT_FAILED, err),
};

export type AcompanhamentoActionUnion = ActionsUnion<typeof AcompanhamentoActions>;

export type GetAcompanhamentoAction = Action<AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_REQUEST, IAcompanhamentoRequest>;
export type GetAcompanhamentoSuccessAction = Action<AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_SUCCESS, IAcompanhamento[]>;

export type UpdateAcompanhamentoAction = Action<AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_REQUEST, IUpdateAcompanhamentoRequest>;
export type UpdateAcompanhamentoSuccessAction = Action<AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_SUCCESS>;

export type ImportarAcompanhamentoAction = Action<AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_REQUEST, IImportAcompanhamentoRequest>;
export type ImportarAcompanhamentoSuccessAction = Action<AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_SUCCESS>;
export type ImportarAcompanhamentoFailureAction = Action<AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_FAILED, string>;
export type ImportarAcompanhamentoInvalidAction = Action<AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_INVALID, IErroResponse[]>;
export type ImportarAcompanhamentoClearAction = Action<AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_CLEAR>;

export type DefaultFailureAction = Action<AcompanhamentoActionKeys.DEFAULT_FAILED, string>;