export interface IAtualizarPedidoResponse {
    atualizarPedidoId: number;
    industriaId: number;
    status: StatusAtualizarPedidoEnum;
    info: string;
    atualizarPedidoDataHora: Date;
    industriaNomeFantasia: string;
    qtdePedidos: number;
}

export enum StatusAtualizarPedidoEnum {
    SUCESSO = 'Sucesso',
    PROCESSANDO = 'Processando',
    FALHOU = 'Falhou'
}