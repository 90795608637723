import { notification } from "antd";
import { INotificationState } from "./interfaces/INotificationState";
import { NotificationActionKeys, NotificationActionUnion } from "./Notification.actions";

const initialState: INotificationState = {
  notifications: [],
  isLoading: false,
}

const notificationReducer = (state = initialState, action: NotificationActionUnion): INotificationState => {

  switch (action.type) {
    case NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS:

      action.payload.forEach(notification => {
        notification.hasView = notification.hasRead
      });

      return {
        ...state,
        isLoading: false,
        notifications: action.payload,
      };

    case NotificationActionKeys.ADD_NOTIFICATION:
      return {
        ...state,
        isLoading: false,
        notifications: [action.payload, ...state.notifications]
      };

    case NotificationActionKeys.READ_ALL_NOTIFICATIONS_REQUEST:
      var notificationsAux = [...state.notifications];
      notificationsAux.forEach(x => x.hasRead = true);

      return {
        ...state,
        notifications: notificationsAux
      }

    case NotificationActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
