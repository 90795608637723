import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IAtualizarProdutoRequest, IAtualizarProdutoResponse } from "./interfaces/atualizarProduto/IAtualizarProdutoRequest";

export class ProdutoApi {
    static async atualizarProduto(atualizarProduto: IAtualizarProdutoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', atualizarProduto.file);
        formData.append('industriaId', atualizarProduto.industriaId.toString());

        return axios.post('/atualizarProduto/atualizar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async list(): Promise<AxiosResponse<IAtualizarProdutoResponse[]>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarProduto/list`);
    }

    static async get(request: number): Promise<AxiosResponse<IAtualizarProdutoResponse>> {
        const axios = await Http.axios();
        return axios.get(`/atualizarProduto/get/${request}`);
    }

    static async reiniciar(request: number): Promise<AxiosResponse<number>> {
        const axios = await Http.axios();
        return axios.put(`/atualizarProduto/reiniciar/${request}`);
    }
}