import { IContatoAuxUpdate, IContatoImportarRequest, IContatoResponse } from "../../data/interfaces/contato/IContato";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum ContatoActionKeys {
  GET_CONTATO_REQUEST = 'GET_CONTATO_REQUEST',
  GET_CONTATO_SUCCESS = 'GET_CONTATO_SUCCESS',

  UPDATE_CONTATO_REQUEST = 'UPDATE_CONTATO_REQUEST',
  UPDATE_CONTATO_SUCCESS = 'UPDATE_CONTATO_SUCCESS',

  IMPORTAR_CONTATO_REQUEST = 'IMPORTAR_CONTATO_REQUEST',
  IMPORTAR_CONTATO_SUCCESS = 'IMPORTAR_CONTATO_SUCCESS',
  IMPORTAR_CONTATO_FAILED = 'IMPORTAR_CONTATO_FAILED',
  IMPORTAR_CONTATO_INVALID = 'IMPORTAR_CONTATO_INVALID',
  IMPORTAR_CONTATO_CLEAR = 'IMPORTAR_CONTATO_CLEAR',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const ContatoActions = {
  listContatos: (): GetContatoAction => createAction(ContatoActionKeys.GET_CONTATO_REQUEST),
  listContatosSuccess: (response: IContatoResponse): GetContatoSuccessAction => createAction(ContatoActionKeys.GET_CONTATO_SUCCESS, response),

  updateContato: (request: IContatoAuxUpdate): UpdateContatoAction => createAction(ContatoActionKeys.UPDATE_CONTATO_REQUEST, request),
  updateContatoSuccess: (): UpdateContatoSuccessAction => createAction(ContatoActionKeys.UPDATE_CONTATO_SUCCESS),

  importarContato: (request: IContatoImportarRequest): ImportarContatoAction => createAction(ContatoActionKeys.IMPORTAR_CONTATO_REQUEST, request),
  importarContatoSuccess: (): ImportarContatoSuccessAction => createAction(ContatoActionKeys.IMPORTAR_CONTATO_SUCCESS),
  importarContatoFailure: (err: string): ImportarContatoFailureAction => createAction(ContatoActionKeys.IMPORTAR_CONTATO_FAILED, err),
  importarContatoInvalid: (err: IErroResponse[]): ImportarContatoInvalidAction => createAction(ContatoActionKeys.IMPORTAR_CONTATO_INVALID, err),
  importarContatoClear: (): ImportarContatoClearAction => createAction(ContatoActionKeys.IMPORTAR_CONTATO_CLEAR),

  defaultFailure: (err: string): DefaultFailureAction => createAction(ContatoActionKeys.DEFAULT_FAILED, err),
};

export type ContatoActionUnion = ActionsUnion<typeof ContatoActions>;

export type GetContatoAction = Action<ContatoActionKeys.GET_CONTATO_REQUEST>;
export type GetContatoSuccessAction = Action<ContatoActionKeys.GET_CONTATO_SUCCESS, IContatoResponse>;

export type UpdateContatoAction = Action<ContatoActionKeys.UPDATE_CONTATO_REQUEST, IContatoAuxUpdate>;
export type UpdateContatoSuccessAction = Action<ContatoActionKeys.UPDATE_CONTATO_SUCCESS>;

export type ImportarContatoAction = Action<ContatoActionKeys.IMPORTAR_CONTATO_REQUEST, IContatoImportarRequest>;
export type ImportarContatoSuccessAction = Action<ContatoActionKeys.IMPORTAR_CONTATO_SUCCESS>;
export type ImportarContatoFailureAction = Action<ContatoActionKeys.IMPORTAR_CONTATO_FAILED, string>;
export type ImportarContatoInvalidAction = Action<ContatoActionKeys.IMPORTAR_CONTATO_INVALID, IErroResponse[]>;
export type ImportarContatoClearAction = Action<ContatoActionKeys.IMPORTAR_CONTATO_CLEAR>;

export type DefaultFailureAction = Action<ContatoActionKeys.DEFAULT_FAILED, string>;