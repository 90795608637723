import React from "react";
import { ColumnProps } from "antd/lib/table";
import { Button } from "antd";
import { IAtualizarPedidoInfosResponse } from "../../../data/interfaces/atualizarPedido/IAtualizarPedidoInfosResponse";
import { getFilterDateProps, sorter } from "../../../utils/helpers";
import moment from "moment";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";

export const getColumns = (onVisualizarDetalhe: (pedidoId: number) => void, onRecuperarPedido: (atualizarPedidoInfoId: number) => void) => {
    return [
        {
            title: 'Nº Pedido',
            dataIndex: 'pedidoId',
            width: 150,
            sorter: (a, b) => a.pedidoId - b.pedidoId,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Cliente',
            dataIndex: 'clienteNomeFantasia',
            width: 500,
            sorter: (a, b) => sorter(a.clienteNomeFantasia, b.clienteNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Data',
            dataIndex: 'dataAtualizacao',
            width: 120,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.dataAtualizacao, b.dataAtualizacao),
            showSorterTooltip: false,
            ...getFilterDateProps('dataAtualizacao', [moment().add(-5, 'y'), moment()]),
            render: (value: any, register: IAtualizarPedidoInfosResponse) => {
                return `${dateStringUStoPTBR(register.dataAtualizacao)}`;
            }
        },
        {
            title: 'Alterações',
            dataIndex: 'pedidoOriginal',
            width: 150,
            sorter: (a, b) => sorter(a.houveAlteracao, b.houveAlteracao),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value: any, register: IAtualizarPedidoInfosResponse) => {
                return value == null ? null : <Button type='link' size='small' onClick={() => onVisualizarDetalhe(register.pedidoId)}>Visualizar</Button>;
            }
        },
        {
            title: 'Restaurar Pedido',
            dataIndex: 'pedidoOriginal',
            width: 200,
            sorter: (a, b) => sorter(a.houveAlteracao, b.houveAlteracao),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value: any, register: IAtualizarPedidoInfosResponse) => {
                return value == null ? '[NÃO HOUVE ALTERAÇÃO]' : <Button type='link' size='small' onClick={() => onRecuperarPedido(register.atualizarPedidoInfoId)}>Restaurar Pedido</Button>;
            }
        },
        {
            title: 'Acessar no SV',
            dataIndex: 'pedidoId',
            width: 150,
            showSorterTooltip: false,
            render: (value: any, register: IAtualizarPedidoInfosResponse) => {
                return <Button type='link' size='small' onClick={() => window.open('https://app4.suasvendas.com/Modulo/YourSales/PedidoFormV2.aspx?Op=UldScGRHRnk=&Id=' + btoa(btoa(value)))}>Acessar</Button>;
            }
        },
    ] as Array<ColumnProps<IAtualizarPedidoInfosResponse>>;
}