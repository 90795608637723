import { Button, List, Progress } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import styles from './ImportadorModelo.module.scss';
import { toast } from "react-toastify";
import { secondsToMs } from "../../../../utils/dateHelper";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { SvDbActions } from "../../../../store/svDb/SvDb.actions";
import { getOptionsDataFromObject } from "../../../../utils/helpers";
import schema from "./ImportadorModelo.schema";
import { ProjecaoVendaActions } from "../../../../store/projecaoVenda/ProjecaoVenda.actions";
import { INewModeloRequest } from "../../../../data/interfaces/projecaoVenda/IModeloCombinacao";
import FieldForm from "../../../../components/ui/field";

export interface IImportadorModelo {
    visible: boolean;
    onClose: () => void;
}

interface formValues {
    nome?: string,
}

const tipoOrigemOptions =
    [
        { text: 'Importar de Arquivo', value: 'importar' },
        { text: 'Copiar de Combinação Existente', value: 'copiarCombinacao' },
        { text: 'Referenciar um Modelo', value: 'referenciarModelo' }
    ] as IOptionData[];

const ImportadorModelo: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeUpdating, setTimeUpdating] = useState(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [combinacoesOptions, setCombinacoesOptions] = useState<IOptionData[]>([]);
    const [modelosOptions, setModelosOptions] = useState<IOptionData[]>([]);
    const [tipoOrigem, setTipoOrigem] = useState<string>('importar');

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
        dispatch(ClienteActions.listClientes());
        dispatch(ProjecaoVendaActions.listModelos());
    }, []);

    useEffect(() => {
        if (props.combinacoes && props.combinacoes.length > 0 && combinacoesOptions?.length == 0) {
            setCombinacoesOptions(getOptionsDataFromObject(props.combinacoes, 'combinacaoId', 'nomeAux'));
        }

        if (props.modelos && props.modelos.length > 0 && modelosOptions?.length == 0) {
            setModelosOptions(getOptionsDataFromObject(props.modelos, 'modeloId', 'nome'));
        }
    }, [props.combinacoes, props.modelos]);

    useEffect(() => {
        if (isUpdating) {
            setTimeout(() => {
                setTimeUpdating(timeUpdating + 1);
            }, 1000);
        }
    }, [timeUpdating, isUpdating])

    useEffect(() => {
        if (!props.isImporting && isUpdating) {
            if (!props.errosImportacao || props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsUpdating(false);
        }
    }, [props.isImporting])

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        dispatch(ClienteActions.importarClienteIndustriaClear());
        props.onClose();
    }

    const submit = (values: INewModeloRequest) => {
        if (values) {
            if (tipoOrigem == 'importar' && file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {
                values.file = file;
                dispatch(ProjecaoVendaActions.importarModelo(values));
                setIsUpdating(true);
                setShowForm(false);
                setValues(values);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(ClienteActions.importarClienteIndustriaClear());
        setFile(null);
    }

    const buttons =
        [
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosAtualizacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const form =
        <Form onSubmit={submit} isSubmited={isSubmit} initialValues={values} schema={schema} className={styles['form']}>
            <div>
                <FieldForm placeholder="Nome do Modelo" name='nome' label='Nome do Modelo' className={styles['inputForm']} />
                <SelectForm placeholder='Selecione a Origem' name='tipoOrigem' label='Tipo de Origem' defaultValue={tipoOrigem} options={tipoOrigemOptions} onSelect={(e) => setTipoOrigem(e)} />
                <SelectForm hidden={tipoOrigem != 'copiarCombinacao'} placeholder='Selecione a Combinação' name='combinacaoId' label='Combinações' options={combinacoesOptions} />
                <SelectForm hidden={tipoOrigem != 'referenciarModelo'} placeholder='Selecione o Modelo' name='modeloId' label='Modelos' options={modelosOptions} />
            </div>
            <div className={styles['groupSelect']} style={{ display: "flex" }} hidden={tipoOrigem != 'importar'}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportar')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportar' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    const infoAtualizacao = <div className={styles['infoUpdating']} id='infoUpdating'>
        <p>Importando Modelo - <span>{secondsToMs(timeUpdating)}</span></p>
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={100}
            showInfo={false}
            status="active"
        />
    </div>;

    return (
        <Modal title={'Importação Modelo'} open={props.visible} footer={!isUpdating ? buttons : null} closable={false} destroyOnClose={true} width={!showForm && props.errosImportacao && props.errosImportacao.length > 0 ? 750 : 450}>
            <div className={styles['container']}>
                {
                    isUpdating ?
                        infoAtualizacao :
                        (
                            !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                errosAtualizacao
                                : form
                        )}
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IImportadorModelo;

export default connector(ImportadorModelo);