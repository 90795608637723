import * as Yup from 'yup';

const schema = Yup.object().shape({
    pedidoStatusId: Yup.string().required('Selecione uma opção').nullable(),
    coordenadorUsuarioId: Yup.string().required('Selecione uma opção').nullable(),
    gestorUsuarioId: Yup.string().required('Selecione uma opção').nullable(),
    vendedoresId: Yup.string().required('Selecione uma ou mais opções').nullable(),
    promotoresId: Yup.string().required('Selecione uma ou mais opções').nullable(),
    comissaoPedido: Yup.string().required('O campo deve ser preenchido').nullable(),
});

export default schema;