import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ILoginRequest } from "./interfaces/auth/ILoginRequest";
import { ILoginResponse } from "./interfaces/auth/ILoginResponse";
import { IUserResponse } from "./interfaces/user/IUserResponse";

export class AuthApi {
  static async signIn(body: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> {
    const axios = await Http.axios();
    return axios.post('/auth/login', body);
  }

  static async getUserInfo(): Promise<AxiosResponse<IUserResponse>> {
    const axios = await Http.axios();
    return axios.get('/auth/getUserInfo');
  }
}