import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AtualizarPedidoActions } from "../../../store/atualizarPedido/AtualizarPedido.actions";
import PageContent from "../../../components/ui/pageContent";

import styles from './AtualizarPedidos.module.scss';
import { Button, Skeleton, Table } from "antd";
import { getColumns } from "./AtualizarPedidos.helper";
import AtualizarItensPedidos from "../modals";
import { StatusAtualizarPedidoEnum } from "../../../data/interfaces/atualizarPedido/IAtualizarPedidoResponse";
import { setVisibleAccessAdmin } from "../../../utils/AuthUtils";

const AtualizarPedidos: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalAtualizarItensPedido, setIsVisibleModalAtualizarItensPedido] = useState(false);
    const [atualizacaoEmAndamento, setAtualizacaoEmAndamento] = useState(false);
    const [currentCount, setCount] = useState(5);
    const [qtdeRows, setQtdeRows] = useState(0);
    const timer = () => setCount(currentCount - 1);

    useEffect(() => {
        dispatch(AtualizarPedidoActions.listAtualizarPedidos());
    }, []);

    useEffect(() => {
        if (props.atualizarPedidos && props.atualizarPedidos.length > 0) {
            setAtualizacaoEmAndamento(props.atualizarPedidos.findIndex(x => x.status == StatusAtualizarPedidoEnum.PROCESSANDO) > -1);
        }

        setQtdeRows(props.atualizarPedidos?.length);
    }, [props.atualizarPedidos]);

    useEffect(() => {

        if (currentCount <= 0 &&
            props.atualizarPedidos &&
            props.atualizarPedidos?.length > 0 &&
            !props.isLoading) {

            var atualizarPedidoCurrentId = props.atualizarPedidos.find(x => x.status == 'Processando')?.atualizarPedidoId;

            if (atualizarPedidoCurrentId)
                dispatch(AtualizarPedidoActions.getAtualizarPedidos(atualizarPedidoCurrentId));

            setCount(5);
            return;
        }

        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [currentCount]);

    const tableChange = (qtdeRows: number) => {
        setQtdeRows(qtdeRows);
    }

    return (
        <>
            <PageContent title='Atualizar Pedidos' className={styles['container']}>
                {!props.atualizarPedidos ?
                    <Skeleton active />
                    :
                    <>
                        <div style={{ ...setVisibleAccessAdmin }} className={styles['toolBar']}>
                            <Button loading={atualizacaoEmAndamento} onClick={() => setIsVisibleModalAtualizarItensPedido(true)} type='primary'>
                                {atualizacaoEmAndamento ? 'Atualização em Andamento...' : 'Atualizar Itens de Pedidos'}
                            </Button>
                        </div>
                        <div className={styles['qtdeRows']}>
                            {qtdeRows} registros
                        </div>
                        <Table onChange={(p, f, s, extra) => tableChange(extra.currentDataSource?.length)} columns={getColumns(props.atualizarPedidos)} dataSource={props.atualizarPedidos} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
            {isVisibleModalAtualizarItensPedido ?
                <AtualizarItensPedidos visible={isVisibleModalAtualizarItensPedido} onClose={() => setIsVisibleModalAtualizarItensPedido(false)}></AtualizarItensPedidos>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.atualizarPedido
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;


export default connector(AtualizarPedidos);