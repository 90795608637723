import { ColumnProps } from "antd/es/table";
import { IAtualizarProdutoResponse, IAtualizarProdutoStatus } from "../../../data/interfaces/atualizarProduto/IAtualizarProdutoRequest";
import { sorter } from "../../../utils/helpers";
import styles from './ListAtualizarProduto.module.scss';
import { dateStringUStoPTBR } from "../../../utils/dateHelper";
import { Button } from "antd";
import React from "react";
import { history } from "../../../store";

export const getColumns = (showInDetail: (id: number) => void, reiniciar: (id: number) => void) => {
    return [
        {
            title: '#',
            dataIndex: 'atualizarProdutoId',
            width: 30,
            sorter: (a, b) => a.atualizarProdutoId - b.atualizarProdutoId,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Indústria',
            sorter: (a, b) => sorter(a.industria?.industriaNomeFantasia, a.industria?.industriaNomeFantasia),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value, register) => register.industria?.industriaNomeFantasia
        },
        {
            title: 'Data',
            width: 70,
            dataIndex: 'dataCriacao',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.dataCriacao, b.dataCriacao),
            showSorterTooltip: false,
            render: (value, register) => {
                return `${dateStringUStoPTBR(register.dataCriacao)}`;
            }
        },
        {
            title: 'Status',
            width: 200,
            render: (value, register) => {
                switch (register.status) {
                    case IAtualizarProdutoStatus.Concluído:
                        return <><b style={{ color: 'green' }}>Concluído</b><br />{register.info} Itens</>
                    case IAtualizarProdutoStatus.Erro:
                        return <><b style={{ color: 'red' }}>Erro</b><br />{register.info}</>
                    case IAtualizarProdutoStatus.Iniciado:
                        return <><b style={{ color: 'blue' }}>Em Andamento...</b><br />{register.info}</>
                }
            }
        },
        {
            width: 50,
            className: styles['columnAction'],
            render: (value, register) => {
                return <>
                    <Button type='link' size='small' onClick={() => showInDetail(register.atualizarProdutoId)}>Detalhar</Button >
                    <Button hidden={register.status != IAtualizarProdutoStatus.Erro} type='link' size='small' onClick={() => reiniciar(register.atualizarProdutoId)}>Tentar Novamente</Button >
                </>;
            }
        },
    ] as Array<ColumnProps<IAtualizarProdutoResponse>>;
}