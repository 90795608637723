import { Button, Dropdown, Menu, Modal, Skeleton, Space, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import styles from './ProjecaoVenda.module.scss';
import { history } from "../../store";
import { ProjecaoVendaActions } from "../../store/projecaoVenda/ProjecaoVenda.actions";
import { getColumns } from "./ProjecaoVenda.helper";
import { ICombinacao } from "../../data/interfaces/projecaoVenda/ICombinacao";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import ImportadorProjecaoVenda from "./modals/importador";
import ImportadorModelo from "./modals/importadorModelo";

export enum TipoCombinacaoEnum {
    CLIENTEXINDUSTRIA,
    CLIENTE,
    INDUSTRIA,
    COMBINACAOGERAL
}

const ProjecaoVenda: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleImportadorProjecao, setIsVisibleImportadorProjecao] = useState(false);
    const [isVisibleImportadorModelo, setIsVisibleImportadorModelo] = useState(false);
    const [tipoCombinacao, setTipoCombinacao] = useState<TipoCombinacaoEnum>(TipoCombinacaoEnum.CLIENTEXINDUSTRIA);

    useEffect(() => {
        dispatch(ProjecaoVendaActions.listCombinacoes());
    }, []);

    const warningEdit = () => {
        Modal.warning({
            title: <b>Edição não permitida!</b>,
            content: 'Não é possível alterar Projeção de Venda baseada em um Modelo.',
        });
    };

    const handleEdit = (combinacao: ICombinacao) => {

        if (combinacao.combinacaoModeloId) {
            warningEdit();
        } else {
            history.push(`/projecao-venda/${combinacao.combinacaoId}`);
        }
    }

    const handleDelete = (id: number) => {
        dispatch(ProjecaoVendaActions.deleteCombinacao(id));
    }

    const showModalImportador = (tipoCombinacao: TipoCombinacaoEnum) => {
        setTipoCombinacao(tipoCombinacao);
        setIsVisibleImportadorProjecao(true);
    }

    const buttonNovaConfiguracao = (
        <Menu
            items={[
                {
                    label: 'Cliente X Indústria',
                    key: '1',
                    onClick: () => showModalImportador(TipoCombinacaoEnum.CLIENTEXINDUSTRIA)
                },
                {
                    label: 'Cliente',
                    key: '2',
                    onClick: () => showModalImportador(TipoCombinacaoEnum.CLIENTE)
                },
                {
                    label: 'Indústria',
                    key: '3',
                    onClick: () => showModalImportador(TipoCombinacaoEnum.INDUSTRIA)
                },
                {
                    label: 'Combinacao Geral',
                    key: '4',
                    onClick: () => showModalImportador(TipoCombinacaoEnum.COMBINACAOGERAL)
                },
            ]}
        />
    );

    return (
        <>
            <PageContent title='Projeção de Venda' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Space>
                                <Dropdown overlay={buttonNovaConfiguracao}>
                                    <Button type='primary'><Space>Nova Configuração <DownOutlined /></Space></Button>
                                </Dropdown>
                                <Button type='primary' className='btn-green' onClick={() => setIsVisibleImportadorModelo(true)}>Novo Modelo</Button>
                                <Button type='default' className='btn-green' onClick={() => history.push('/projecao-venda/modelos')}>Listar Modelos</Button>
                            </Space>
                        </div>
                        <Table columns={getColumns(props.combinacoes, handleEdit, handleDelete)} dataSource={props.combinacoes} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
            {isVisibleImportadorProjecao ?
                <ImportadorProjecaoVenda tipo={tipoCombinacao} visible={isVisibleImportadorProjecao} onClose={() => setIsVisibleImportadorProjecao(false)}></ImportadorProjecaoVenda>
                : null}
            {isVisibleImportadorModelo ?
                <ImportadorModelo visible={isVisibleImportadorModelo} onClose={() => setIsVisibleImportadorModelo(false)}></ImportadorModelo>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(ProjecaoVenda);