import { IAtualizarProdutoRequest, IAtualizarProdutoResponse } from "../../data/interfaces/atualizarProduto/IAtualizarProdutoRequest";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum ProdutoActionKeys {
  ATUALIZAR_PRODUTO_REQUEST = 'ATUALIZAR_PRODUTO_REQUEST',
  ATUALIZAR_PRODUTO_SUCCESS = 'ATUALIZAR_PRODUTO_SUCCESS',
  ATUALIZAR_PRODUTO_FAILED = 'ATUALIZAR_PRODUTO_FAILED',
  ATUALIZAR_PRODUTO_INVALID = 'ATUALIZAR_PRODUTO_INVALID',
  ATUALIZAR_PRODUTO_CLEAR = 'ATUALIZAR_PRODUTO_CLEAR',

  LIST_ATUALIZAR_PRODUTOS_REQUEST = 'LIST_ATUALIZAR_PRODUTOS_REQUEST',
  LIST_ATUALIZAR_PRODUTOS_SUCCESS = 'LIST_ATUALIZAR_PRODUTOS_SUCCESS',

  GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST = 'GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST',
  GET_ATUALIZAR_PRODUTO_IN_DETAIL_SUCCESS = 'GET_ATUALIZAR_PRODUTO_IN_DETAIL_SUCCESS',

  GET_ATUALIZAR_PRODUTO_REQUEST = 'GET_ATUALIZAR_PRODUTO_REQUEST',
  GET_ATUALIZAR_PRODUTO_SUCCESS = 'GET_ATUALIZAR_PRODUTO_SUCCESS',

  REINICIAR_ATUALIZAR_PRODUTO_REQUEST = 'REINICIAR_ATUALIZAR_PRODUTO_REQUEST',
  REINICIAR_ATUALIZAR_PRODUTO_SUCCESS = 'REINICIAR_ATUALIZAR_PRODUTO_SUCCESS',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const ProdutoActions = {
  atualizarProduto: (request: IAtualizarProdutoRequest): AtualizarProdutoAction =>
    createAction(ProdutoActionKeys.ATUALIZAR_PRODUTO_REQUEST, request),
  atualizarProdutoSuccess: (response: IAtualizarProdutoResponse): AtualizarProdutoSuccessAction =>
    createAction(ProdutoActionKeys.ATUALIZAR_PRODUTO_SUCCESS, response),
  atualizarProdutoFailure: (err: string): AtualizarProdutoFailureAction =>
    createAction(ProdutoActionKeys.ATUALIZAR_PRODUTO_FAILED, err),
  atualizarProdutoInvalid: (err: IErroResponse[]): AtualizarProdutoInvalidAction =>
    createAction(ProdutoActionKeys.ATUALIZAR_PRODUTO_INVALID, err),
  atualizarProdutoClear: (): AtualizarProdutoClearAction =>
    createAction(ProdutoActionKeys.ATUALIZAR_PRODUTO_CLEAR),

  listAtualizarProdutos: (): ListAtualizarProdutosAction =>
    createAction(ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_REQUEST),
  listAtualizarProdutosSuccess: (response: IAtualizarProdutoResponse[]): ListAtualizarProdutosSuccessAction =>
    createAction(ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_SUCCESS, response),

  getAtualizarProdutoInDetail: (request: number): GetAtualizarProdutoInDetailAction =>
    createAction(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST, request),
  getAtualizarProdutoInDetailSuccess: (response: IAtualizarProdutoResponse): GetAtualizarProdutoInDetailSuccessAction =>
    createAction(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_SUCCESS, response),

  getAtualizarProduto: (request: number): GetAtualizarProdutoAction =>
    createAction(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_REQUEST, request),
  getAtualizarProdutoSuccess: (response: IAtualizarProdutoResponse): GetAtualizarProdutoSuccessAction =>
    createAction(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_SUCCESS, response),

  reiniciarAtualizarProduto: (request: number): ReiniciarAtualizarProdutoAction =>
    createAction(ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_REQUEST, request),
  reiniciarAtualizarProdutoSuccess: (response: number): ReiniciarAtualizarProdutoSuccessAction =>
    createAction(ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_SUCCESS, response),

  defaultFailure: (err: string): DefaultFailureAction => createAction(ProdutoActionKeys.DEFAULT_FAILED, err),
};

export type ProdutoActionUnion = ActionsUnion<typeof ProdutoActions>;

export type AtualizarProdutoAction = Action<ProdutoActionKeys.ATUALIZAR_PRODUTO_REQUEST, IAtualizarProdutoRequest>;
export type AtualizarProdutoSuccessAction = Action<ProdutoActionKeys.ATUALIZAR_PRODUTO_SUCCESS, IAtualizarProdutoResponse>;
export type AtualizarProdutoFailureAction = Action<ProdutoActionKeys.ATUALIZAR_PRODUTO_FAILED, string>;
export type AtualizarProdutoInvalidAction = Action<ProdutoActionKeys.ATUALIZAR_PRODUTO_INVALID, IErroResponse[]>;
export type AtualizarProdutoClearAction = Action<ProdutoActionKeys.ATUALIZAR_PRODUTO_CLEAR>;

export type ListAtualizarProdutosAction = Action<ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_REQUEST>;
export type ListAtualizarProdutosSuccessAction = Action<ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_SUCCESS, IAtualizarProdutoResponse[]>;

export type GetAtualizarProdutoInDetailAction = Action<ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST, number>;
export type GetAtualizarProdutoInDetailSuccessAction = Action<ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_SUCCESS, IAtualizarProdutoResponse>;

export type GetAtualizarProdutoAction = Action<ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_REQUEST, number>;
export type GetAtualizarProdutoSuccessAction = Action<ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_SUCCESS, IAtualizarProdutoResponse>;

export type ReiniciarAtualizarProdutoAction = Action<ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_REQUEST, number>;
export type ReiniciarAtualizarProdutoSuccessAction = Action<ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_SUCCESS, number>;

export type DefaultFailureAction = Action<ProdutoActionKeys.DEFAULT_FAILED, string>;