import { ICategoriaCliente } from "../../data/interfaces/cliente/ICategoriaCliente";
import { IClienteIndustriaAuxAuditoria } from "../../data/interfaces/cliente/IClienteIndustriaAuxAuditoria";
import { IClienteIndustriaAuxForm } from "../../data/interfaces/cliente/IClienteIndustriaAuxForm";
import { IClienteIndustriaAuxView } from "../../data/interfaces/cliente/IClienteIndustriaAuxView";
import { IContato } from "../../data/interfaces/contato/IContato";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IClienteIndustriaResponse } from "../../data/interfaces/svData/IClienteIndustriaResponse";
import { IClienteResponse } from "../../data/interfaces/svData/IClienteResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum ClienteActionKeys {
  LIST_CLIENTES_REQUEST = 'LIST_CLIENTES_REQUEST',
  LIST_CLIENTES_SUCCESS = 'LIST_CLIENTES_SUCCESS',

  LIST_CLIENTES_INDUSTRIAS_REQUEST = 'LIST_CLIENTES_INDUSTRIAS_REQUEST',
  LIST_CLIENTES_INDUSTRIAS_SUCCESS = 'LIST_CLIENTES_INDUSTRIAS_SUCCESS',

  GET_CLIENTE_INDUSTRIA_REQUEST = 'GET_CLIENTE_INDUSTRIA_REQUEST',
  GET_CLIENTE_INDUSTRIA_SUCCESS = 'GET_CLIENTE_INDUSTRIA_SUCCESS',

  UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST = 'UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST',
  UPDATE_CLIENTE_INDUSTRIA_AUX_SUCCESS = 'UPDATE_CLIENTE_INDUSTRIA_AUX_SUCCESS',

  GET_CLIENTE_INDUSTRIA_VIEW_REQUEST = 'GET_CLIENTE_INDUSTRIA_VIEW_REQUEST',
  GET_CLIENTE_INDUSTRIA_VIEW_SUCCESS = 'GET_CLIENTE_INDUSTRIA_VIEW_SUCCESS',

  LIST_CATEGORIAS_CLIENTE_REQUEST = 'LIST_CATEGORIAS_CLIENTE_REQUEST',
  LIST_CATEGORIAS_CLIENTE_SUCCESS = 'LIST_CATEGORIAS_CLIENTE_SUCCESS',

  PERSIST_CATEGORIA_CLIENTE_REQUEST = 'PERSIST_CATEGORIA_CLIENTE_REQUEST',
  PERSIST_CATEGORIA_CLIENTE_SUCCESS = 'PERSIST_CATEGORIA_CLIENTE_SUCCESS',

  DELETE_CATEGORIA_CLIENTE_REQUEST = 'DELETE_CATEGORIA_CLIENTE_REQUEST',
  DELETE_CATEGORIA_CLIENTE_SUCCESS = 'DELETE_CATEGORIA_CLIENTE_SUCCESS',

  IMPORTAR_CLIENTE_INDUSTRIA_REQUEST = 'IMPORTAR_CLIENTE_INDUSTRIA_REQUEST',
  IMPORTAR_CLIENTE_INDUSTRIA_SUCCESS = 'IMPORTAR_CLIENTE_INDUSTRIA_SUCCESS',
  IMPORTAR_CLIENTE_INDUSTRIA_FAILED = 'IMPORTAR_CLIENTE_INDUSTRIA_FAILED',
  IMPORTAR_CLIENTE_INDUSTRIA_INVALID = 'IMPORTAR_CLIENTE_INDUSTRIA_INVALID',
  IMPORTAR_CLIENTE_INDUSTRIA_CLEAR = 'IMPORTAR_CLIENTE_INDUSTRIA_CLEAR',
  IMPORTAR_CLIENTE_INDUSTRIA_INFO = 'IMPORTAR_CLIENTE_INDUSTRIA_INFO',

  LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST = 'LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST',
  LIST_COMPRADORES_CLIENTE_INDUSTRIA_SUCCESS = 'LIST_COMPRADORES_CLIENTE_INDUSTRIA_SUCCESS',

  LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST = 'LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST',
  LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_SUCCESS = 'LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_SUCCESS',


  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const ClienteActions = {
  listClientes: (): ListClientesAction =>
    createAction(ClienteActionKeys.LIST_CLIENTES_REQUEST),
  listClientesSuccess: (response: IClienteResponse[]): ListClientesSuccessAction =>
    createAction(ClienteActionKeys.LIST_CLIENTES_SUCCESS, response),

  listClientesIndustrias: (): ListClientesIndustriasAction =>
    createAction(ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_REQUEST),
  listClientesIndustriasSuccess: (response: IClienteIndustriaResponse[]): ListClientesIndustriasSuccessAction =>
    createAction(ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_SUCCESS, response),

  getClienteIndustria: (request: number): GetClienteIndustriaAction =>
    createAction(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_REQUEST, request),
  getClienteIndustriaSuccess: (response: IClienteIndustriaAuxForm): GetClienteIndustriaSuccessAction =>
    createAction(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_SUCCESS, response),

  updateClienteIndustriaAux: (request: IClienteIndustriaAuxForm): UpdateClienteIndustriaAuxAction =>
    createAction(ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST, request),
  updateClienteIndustriaAuxSuccess: (response: IClienteIndustriaAuxForm): UpdateClienteIndustriaAuxSuccessAction =>
    createAction(ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_SUCCESS, response),

  getClienteIndustriaView: (request: number): GetClienteIndustriaViewAction =>
    createAction(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_REQUEST, request),
  getClienteIndustriaViewSuccess: (response: IClienteIndustriaAuxView): GetClienteIndustriaViewSuccessAction =>
    createAction(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_SUCCESS, response),

  listCategoriasCliente: (): ListCategoriasClienteAction =>
    createAction(ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_REQUEST),
  listCategoriasClienteSuccess: (response: ICategoriaCliente[]): ListCategoriasClienteSuccessAction =>
    createAction(ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_SUCCESS, response),

  persistCategoriaCliente: (request: ICategoriaCliente): PersistCategoriaClienteAction =>
    createAction(ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_REQUEST, request),
  persistCategoriaClienteSuccess: (response: ICategoriaCliente): PersistCategoriaClienteSuccessAction =>
    createAction(ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_SUCCESS, response),

  deleteCategoriaCliente: (request: number): DeleteCategoriaClienteAction =>
    createAction(ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_REQUEST, request),
  deleteCategoriaClienteSuccess: (response: number): DeleteCategoriaClienteSuccessAction =>
    createAction(ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_SUCCESS, response),

  importarClienteIndustria: (request: File): ImportarClienteIndustriaAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_REQUEST, request),
  importarClienteIndustriaSuccess: (): ImportarClienteIndustriaSuccessAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_SUCCESS),
  importarClienteIndustriaFailure: (err: string): ImportarClienteIndustriaFailureAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_FAILED, err),
  importarClienteIndustriaInvalid: (err: IErroResponse[]): ImportarClienteIndustriaInvalidAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INVALID, err),
  importarClienteIndustriaClear: (): ImportarClienteIndustriaClearAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_CLEAR),
  importarClienteIndustriaInfo: (info: string): ImportarClienteIndustriaInfoAction =>
    createAction(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INFO, info),

  listCompradoresClienteIndustria: (request: number[]): ListCompradoresClienteIndustriaAction =>
    createAction(ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST, request),
  listCompradoresClienteIndustriaSuccess: (response: IContato[]): ListCompradoresClienteIndustriaActionSuccessAction =>
    createAction(ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_SUCCESS, response),

  listClienteIndustriaAuxAuditoria: (request: number): ListClienteIndustriaAuditoriaAction =>
    createAction(ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST, request),
  listClienteIndustriaAuxAuditoriaSuccess: (response: IClienteIndustriaAuxAuditoria[]): ListClienteIndustriaAuditoriaSuccessAction =>
    createAction(ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_SUCCESS, response),

  defaultClienteFailure: (err: string): DefaultClienteFailureAction =>
    createAction(ClienteActionKeys.DEFAULT_FAILED, err),
};

export type ClienteActionUnion = ActionsUnion<typeof ClienteActions>;

export type ListClientesAction = Action<ClienteActionKeys.LIST_CLIENTES_REQUEST>;
export type ListClientesSuccessAction = Action<ClienteActionKeys.LIST_CLIENTES_SUCCESS, IClienteResponse[]>;

export type ListClientesIndustriasAction = Action<ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_REQUEST>;
export type ListClientesIndustriasSuccessAction = Action<ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_SUCCESS, IClienteIndustriaResponse[]>;

export type GetClienteIndustriaAction = Action<ClienteActionKeys.GET_CLIENTE_INDUSTRIA_REQUEST, number>;
export type GetClienteIndustriaSuccessAction = Action<ClienteActionKeys.GET_CLIENTE_INDUSTRIA_SUCCESS, IClienteIndustriaAuxForm>;

export type UpdateClienteIndustriaAuxAction = Action<ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST, IClienteIndustriaAuxForm>;
export type UpdateClienteIndustriaAuxSuccessAction = Action<ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_SUCCESS, IClienteIndustriaAuxForm>;

export type GetClienteIndustriaViewAction = Action<ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_REQUEST, number>;
export type GetClienteIndustriaViewSuccessAction = Action<ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_SUCCESS, IClienteIndustriaAuxView>;

export type ListCompradoresClienteIndustriaAction = Action<ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST, number[]>;
export type ListCompradoresClienteIndustriaActionSuccessAction = Action<ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_SUCCESS, IContato[]>;

export type ListCategoriasClienteAction = Action<ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_REQUEST>;
export type ListCategoriasClienteSuccessAction = Action<ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_SUCCESS, ICategoriaCliente[]>;

export type PersistCategoriaClienteAction = Action<ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_REQUEST, ICategoriaCliente>;
export type PersistCategoriaClienteSuccessAction = Action<ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_SUCCESS, ICategoriaCliente>;

export type DeleteCategoriaClienteAction = Action<ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_REQUEST, number>;
export type DeleteCategoriaClienteSuccessAction = Action<ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_SUCCESS, number>;

export type ImportarClienteIndustriaAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_REQUEST, File>;
export type ImportarClienteIndustriaSuccessAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_SUCCESS>;
export type ImportarClienteIndustriaFailureAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_FAILED, string>;
export type ImportarClienteIndustriaInvalidAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INVALID, IErroResponse[]>;
export type ImportarClienteIndustriaClearAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_CLEAR>;
export type ImportarClienteIndustriaInfoAction = Action<ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_INFO, string>;

export type ListClienteIndustriaAuditoriaAction = Action<ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST, number>;
export type ListClienteIndustriaAuditoriaSuccessAction = Action<ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_SUCCESS, IClienteIndustriaAuxAuditoria[]>;

export type DefaultClienteFailureAction = Action<ClienteActionKeys.DEFAULT_FAILED, string>;