import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { UserActions } from "../../store/user/User.actions";
import { getColumns } from "./Users.helper";
import UserForm from "./modals/form";
import UpdatePassword from './modals/updatePassword';
import styles from './Users.module.scss';
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";

const Users: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [userFormVisible, setUserFormVisible] = useState<boolean>(false);
    const [updatePasswordVisible, setUpdatePasswordVisible] = useState<boolean>(false);
    const [defaultValuesUserForm, setDefaultValuesUserForm] = useState<IUserResponse>({} as IUserResponse);
    const [userIdUpdatePassword, setUserIdUpdatePassword] = useState<string>('');

    useEffect(() => {
        dispatch(UserActions.listUsers());
    }, []);

    const openRegisterModal = (register?: IUserResponse) => {
        setDefaultValuesUserForm((register ?? {}) as IUserResponse);
        setUserFormVisible(true);
    }

    const deleteUser = (userId: string) => {
        dispatch(UserActions.deleteUser(userId));
    }

    const openUpdatePassword = (userId: string) => {
        setUserIdUpdatePassword(userId);
        setUpdatePasswordVisible(true);
    }

    return (
        <>
            <PageContent title='Usuários' className={styles['container']}>
                {props.isLoading || props.users?.length == 0 ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' onClick={() => openRegisterModal()}>Novo</Button>
                        </div>
                        <Table columns={getColumns(props.users, openRegisterModal, deleteUser, openUpdatePassword)} dataSource={props.users} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
            <UpdatePassword userId={userIdUpdatePassword} visible={updatePasswordVisible} onClose={() => setUpdatePasswordVisible(false)}></UpdatePassword>

            {userFormVisible ?
                <UserForm defaultValues={defaultValuesUserForm} visible={userFormVisible} onClose={() => setUserFormVisible(false)}></UserForm>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
    ...state.pedidoImportado
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Users);