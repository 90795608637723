import { IRolesResponse } from "../../data/interfaces/user/IRoles";
import { IUpdatePasswordRequest } from "../../data/interfaces/user/IUpdatePassword";
import { IUserData, IUserRequest } from "../../data/interfaces/user/IUserRequest";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum UserActionKeys {
  LIST_USERS_REQUEST = 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS',
  LIST_USERS_FAILED = 'LIST_USERS_FAILED',

  LIST_ROLES_REQUEST = 'LIST_ROLES_REQUEST',
  LIST_ROLES_SUCCESS = 'LIST_ROLES_SUCCESS',
  LIST_ROLES_FAILED = 'LIST_ROLES_FAILED',

  USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILED = 'USER_REGISTER_FAILED',

  USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILED = 'USER_UPDATE_FAILED',

  USER_DELETE_REQUEST = 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILED = 'USER_DELETE_FAILED',

  USER_UPDATE_PASSWORD_REQUEST = 'USER_UPDATE_PASSWORD_REQUEST',
  USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS',
  USER_UPDATE_PASSWORD_FAILED = 'USER_UPDATE_PASSWORD_FAILED',
}

export const UserActions = {
  listUsers: (): ListUsersAction =>
    createAction(UserActionKeys.LIST_USERS_REQUEST),
  listUsersSuccess: (response: IUserResponse[]): ListUsersSuccessAction =>
    createAction(UserActionKeys.LIST_USERS_SUCCESS, response),
  listUsersFailure: (err: string): ListUsersFailureAction =>
    createAction(UserActionKeys.LIST_USERS_FAILED, err),

  listRoles: (): ListRolesAction =>
    createAction(UserActionKeys.LIST_ROLES_REQUEST),
  listRolesSuccess: (response: IRolesResponse[]): ListRolesSuccessAction =>
    createAction(UserActionKeys.LIST_ROLES_SUCCESS, response),
  listRolesFailure: (err: string): ListRolesFailureAction =>
    createAction(UserActionKeys.LIST_ROLES_FAILED, err),

  registerUser: (request: IUserData): UserRegisterAction =>
    createAction(UserActionKeys.USER_REGISTER_REQUEST, request),
  registerUserSuccess: (response: IUserResponse): UserRegisterSuccessAction =>
    createAction(UserActionKeys.USER_REGISTER_SUCCESS, response),
  registerUserFailure: (err: string): UserRegisterFailureAction =>
    createAction(UserActionKeys.USER_REGISTER_FAILED, err),

  updateUser: (request: IUserData): UserUpdateAction =>
    createAction(UserActionKeys.USER_UPDATE_REQUEST, request),
  updateUserSuccess: (response: IUserResponse): UserUpdateSuccessAction =>
    createAction(UserActionKeys.USER_UPDATE_SUCCESS, response),
  updateUserFailure: (err: string): UserUpdateFailureAction =>
    createAction(UserActionKeys.USER_UPDATE_FAILED, err),

  deleteUser: (request: string): UserDeleteAction =>
    createAction(UserActionKeys.USER_DELETE_REQUEST, request),
  deleteUserSuccess: (response: string): UserDeleteSuccessAction =>
    createAction(UserActionKeys.USER_DELETE_SUCCESS, response),
  deleteUserFailure: (err: string): UserDeleteFailureAction =>
    createAction(UserActionKeys.USER_DELETE_FAILED, err),

  updatePassword: (request: IUpdatePasswordRequest): UserUpdatePasswordAction =>
    createAction(UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, request),
  updatePasswordSuccess: (): UserUpdatePasswordSuccessAction =>
    createAction(UserActionKeys.USER_UPDATE_PASSWORD_SUCCESS),
  updatePasswordFailure: (err: string): UserUpdatePasswordFailureAction =>
    createAction(UserActionKeys.USER_UPDATE_PASSWORD_FAILED, err),
};

export type UserActionUnion = ActionsUnion<typeof UserActions>;

export type ListUsersAction = Action<UserActionKeys.LIST_USERS_REQUEST>;
export type ListUsersSuccessAction = Action<UserActionKeys.LIST_USERS_SUCCESS, IUserResponse[]>;
export type ListUsersFailureAction = Action<UserActionKeys.LIST_USERS_FAILED, string>;

export type ListRolesAction = Action<UserActionKeys.LIST_ROLES_REQUEST>;
export type ListRolesSuccessAction = Action<UserActionKeys.LIST_ROLES_SUCCESS, IRolesResponse[]>;
export type ListRolesFailureAction = Action<UserActionKeys.LIST_ROLES_FAILED, string>;

export type UserRegisterAction = Action<UserActionKeys.USER_REGISTER_REQUEST, IUserData>;
export type UserRegisterSuccessAction = Action<UserActionKeys.USER_REGISTER_SUCCESS, IUserResponse>;
export type UserRegisterFailureAction = Action<UserActionKeys.USER_REGISTER_FAILED, string>;

export type UserUpdateAction = Action<UserActionKeys.USER_UPDATE_REQUEST, IUserData>;
export type UserUpdateSuccessAction = Action<UserActionKeys.USER_UPDATE_SUCCESS, IUserResponse>;
export type UserUpdateFailureAction = Action<UserActionKeys.USER_UPDATE_FAILED, string>;

export type UserDeleteAction = Action<UserActionKeys.USER_DELETE_REQUEST, string>;
export type UserDeleteSuccessAction = Action<UserActionKeys.USER_DELETE_SUCCESS, string>;
export type UserDeleteFailureAction = Action<UserActionKeys.USER_DELETE_FAILED, string>;

export type UserUpdatePasswordAction = Action<UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, IUpdatePasswordRequest>;
export type UserUpdatePasswordSuccessAction = Action<UserActionKeys.USER_UPDATE_PASSWORD_SUCCESS>;
export type UserUpdatePasswordFailureAction = Action<UserActionKeys.USER_UPDATE_PASSWORD_FAILED, string>;