import React, { FC, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import styles from './LeftBar.module.scss';
import { Menu, MenuProps } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link } from 'react-router-dom';

import {
    AppstoreOutlined,
    PieChartOutlined,
    FormOutlined,
    HddOutlined,
    UserOutlined,
    RiseOutlined,
    SettingOutlined,
    SmileOutlined,
    LogoutOutlined,
    LaptopOutlined,
    ClearOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined,
} from '@ant-design/icons';
import { AuthUtils, ILoggedUser, setVisibleAccessAdmin, setVisibleAccessCoordenador } from '../../../utils/AuthUtils';
type MenuItem = Required<MenuProps>['items'][number];

export interface ILeftBar {
    isCollapsed: boolean;
}

const rootSubmenuKeys = ['/pedidos', '/pedidoItens', '/produtos', '/clientes', '/dashboards', '/api'];

const LeftBar: FC<ILeftBar> = ({ isCollapsed }) => {

    var defaultOpenKeys: string[] = [];
    var defaultSelectedKeys: string[] = [];

    var pathName = window.location.pathname;

    if (pathName.split('/').length == 2) {
        defaultOpenKeys = [];
        defaultSelectedKeys = [pathName];
    }
    else {
        defaultOpenKeys = [pathName.substr(0, pathName.indexOf('/', 2))];
        defaultSelectedKeys = [`/${pathName.split('/')[1]}/${pathName.split('/')[2]}`];
    }

    const [openKeys, setOpenKeys] = useState(isCollapsed ? [] : defaultOpenKeys);

    const onOpenChange = (keys: any) => {
        const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onChange = (e: any) => {
        if (e.keyPath && e.keyPath.length == 1) {
            setOpenKeys([]);
        }
    }

    const onLogout = () => {
        AuthUtils.setLoggedUser({} as ILoggedUser);
        window.location.href = '/login';
    }

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        style?: React.CSSProperties,
        children?: MenuItem[],
        className?: string,
        onClick?: () => void,
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
            style,
            className,
            onClick
        } as MenuItem;
    }



    //<Menu.Item hidden={!isCollapsed} className={styles['parentMenuCollapsed']}>Dashboards</Menu.Item>

    const items: MenuItem[] = [

        getItem('Pedidos', '/pedidos', <FormOutlined />, undefined, [
            getItem('Pedidos', '/pedidos-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Importador <Link to='/pedidos/importador' /></>, '/pedidos/importador'),
            getItem(<>Não Importados <Link to='/pedidos/naoImportados' /></>, '/pedidos/naoImportados'),
        ]),
        getItem('Itens do Pedido', '/pedidoItens', <AppstoreOutlined />, undefined, [
            getItem('Itens do Pedido', '/pedidoItens-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Atualizar <Link to='/pedidoItens/atualizar' /></>, '/pedidoItens/atualizar', undefined, { ...setVisibleAccessAdmin }),
            getItem(<>Lista de Itens Atualizados <Link to='/pedidoItens/atualizarItens' /></>, '/pedidoItens/atualizarItens',),
        ]),
        getItem('Produtos', '/produtos', <HddOutlined />, { ...setVisibleAccessAdmin }, [
            getItem('Produtos', '/produtos-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Atualizar <Link to='/produtos/atualizar' /></>, '/produtos/atualizar',),
        ]),
        getItem('Clientes', '/clientes', <SmileOutlined />, { ...setVisibleAccessCoordenador }, [
            getItem('Clientes', '/clientes-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Clientes/Indústrias <Link to='/clientes' /></>, '/clientesXindustrias'),
            getItem(<>Categorias <Link to='/clientes/categorias' /></>, '/clientes/categorias',),
        ]),
        getItem(<> Contatos <Link to='/contatos' /></>, '/contatos', <SmileOutlined />, { ...setVisibleAccessCoordenador }),
        getItem(<> Metas <Link to='/metas' /></>, '/metas', <RiseOutlined />, { ...setVisibleAccessCoordenador }),
        getItem(<> Projeção de Venda <Link to='/projecao-venda' /></>, '/projecaoVenda', <RiseOutlined />, { ...setVisibleAccessAdmin }),
        getItem(<> Sell-Out <Link to='/sell-out' /></>, '/sell-out', <RiseOutlined />, { ...setVisibleAccessAdmin }),
        getItem(<> CRM <Link to='/crm' /></>, '/crm', <RiseOutlined />, { ...setVisibleAccessCoordenador }),
        getItem(<> Usuários <Link to='/usuarios' /></>, '/usuarios', <UserOutlined />, { ...setVisibleAccessAdmin }),
        getItem('Dashboards', '/dashboards', <PieChartOutlined />, undefined, [
            getItem('Dashboards', '/dashboards-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Vendas <Link to='/dashboards/vendas' /></>, '/dashboards/vendas'),
            getItem(<>Vendas %<Link to='/dashboards/vendascomparativo' /></>, '/dashboards/vendascomparativo'),
            getItem(<>Comparativo <Link to='/dashboards/comparativo' /></>, '/dashboards/comparativo',),
            getItem(<>Comparativo Produto<Link to='/dashboards/comparativoproduto' /></>, '/dashboards/comparativoproduto',),
            getItem(<>Comparativo Categorias-Produto<Link to='/dashboards/comparativocategoriaproduto' /></>, '/dashboards/comparativocategoriaproduto',),
            getItem(<>Projeção <Link to='/dashboards/projecao' /></>, '/dashboards/projecao',),
            getItem(<>Dias sem Comprar <Link to='/dashboards/diassemcomprar' /></>, '/dashboards/diassemcomprar',),
            getItem(<>Cliente Análise x Categoria x Produtos <Link to='/dashboards/analisecliente' /></>, '/dashboards/analisecliente',),
            getItem(<>Análise Vendas Mês  Trim  Sem  Ano <Link to='/dashboards/analisetemporal' /></>, '/dashboards/analisetemporal',),
            getItem(<>Analise Vendas Média Móvel <Link to='/dashboards/analisemensal' /></>, '/dashboards/analisemensal',),
            getItem(<>Clientes Novos <Link to='/dashboards/clientesnovos' /></>, '/dashboards/clientesnovos',),
            getItem(<>Curva ABC <Link to='/dashboards/curvaabc' /></>, '/dashboards/curvaabc',),
        ]),
        getItem('API', '/api', <LaptopOutlined />, { ...setVisibleAccessAdmin }, [
            getItem('API', '/api-1', undefined, !isCollapsed ? { display: 'none' } : undefined, undefined, styles['parentMenuCollapsed']),
            getItem(<>Campos API <Link to='/api/campos-api' /></>, '/api/campos-api'),
            getItem(<>Campos Integração <Link to='/api/campos-integracao' /></>, '/api/campos-integracao',),
        ]),
        getItem(<> Configurações <Link to='/config' /></>, '/config', <SettingOutlined />, { ...setVisibleAccessAdmin }),
        getItem(<> Limpar Cache</>, '/limpar-cache', <ClearOutlined />, undefined, undefined, undefined, () => window.location.href = `${process.env.REACT_APP_BASE_API_URL}/Configuracoes/LimparCache?urlReturn=${pathName}`),
        getItem(<> Sair</>, '/logout', <LogoutOutlined />, undefined, undefined, undefined, onLogout),
    ];

    return (
        <div className={`${styles['containerBar']} ${styles[isCollapsed ? 'containerBarCollapsed' : '']}`}>
            <Menu
                defaultSelectedKeys={defaultSelectedKeys}
                mode='inline'
                theme='dark'
                inlineCollapsed={isCollapsed}
                style={{ height: '100%' }}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onClick={onChange}
                items={items}
            />
        </div >
    );
};

export default LeftBar;