import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { IAcompanhamento } from "./interfaces/acompanhamento/IAcompanhamento";
import { IAcompanhamentoRequest, IImportAcompanhamentoRequest, IUpdateAcompanhamentoRequest } from "./interfaces/acompanhamento/IAcompanhamentoRequest";

export class AcompanhamentoApi {
    static async get(request: IAcompanhamentoRequest): Promise<AxiosResponse<IAcompanhamento[]>> {
        const axios = await Http.axios();
        return axios.get(`/Acompanhamento/list?industriaId=${request.industriaId}&mes=${request.mes}&ano=${request.ano}`);
    }

    static async update(request: IUpdateAcompanhamentoRequest): Promise<AxiosResponse> {
        const axios = await Http.axios();
        return axios.put(`/Acompanhamento/update`, request);
    }

    static async importarAcompanhamento(request: IImportAcompanhamentoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file ?? '');
        formData.append('mes', request.mes?.toString());
        formData.append('industriaId', request.industriaId?.toString());
        formData.append('ano', request.ano?.toString());

        return axios.post(`/Acompanhamento/importar`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}