import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Switch } from "antd";

import styles from './UserForm.module.scss';
import Form from "../../../../components/ui/form";
import FieldForm from "../../../../components/ui/field";
import schema from "./UserForm.schema";
import { IUserData, IUserRequest } from "../../../../data/interfaces/user/IUserRequest";
import { UserActions } from "../../../../store/user/User.actions";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "../../../../utils/cropImage";
import { IUserResponse } from "../../../../data/interfaces/user/IUserResponse";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export interface IUserModal {
    defaultValues: IUserResponse;
    visible: boolean;
    onClose: () => void;
}

interface IStateAvatar {
    src: any,
    crop: {
        x: number,
        y: number,
        width: number,
        height: number,
        unit?: 'px' | '%';
        aspect: number
    }
}

const initialStateAvatar = {
    crop: {
        unit: "%",
        width: 80,
        x: 10,
        y: 10,
        aspect: 1
    }
} as IStateAvatar;

const UserForm: FC<Props> = ({ defaultValues, visible, onClose, isLoading, roles }) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const [rolesOptions, setRolesOptions] = useState<IOptionData[]>([]);
    const isNew = defaultValues.id === undefined;
    const titleModal = isNew ? 'Cadastrar Usuário' : 'Atualizar Usuário';
    const [stateAvatar, setStateAvatar] = useState<IStateAvatar>(initialStateAvatar);
    const [avatarImageUrl, setAvatarImageUrl] = useState<any>(defaultValues.avatarFileName);
    const [image, setImage] = useState<any>();
    const [userActive, setUserActive] = useState<boolean>(defaultValues.isActive);
    const [allowEditImportadorPedido, setAllowEditImportadorPedido] = useState<boolean>(defaultValues.allowEditImportadorPedido);
    const [hasGoals, setHasGoals] = useState<boolean>(defaultValues.hasGoals);

    useEffect(() => {
        setAvatarImageUrl(defaultValues.avatarFileName ? process.env.REACT_APP_BASE_URL_STORAGE + defaultValues.avatarFileName : null);
    }, [defaultValues.avatarFileName])

    useEffect(() => {
        if (roles?.length) {
            setRolesOptions(
                roles.map((role) => ({
                    text: role.name,
                    value: role.name
                }) as IOptionData)
            );
        }
        else {
            dispatch(UserActions.listRoles());
        }
    }, [roles?.length])

    const onCropAvatar = async () => {
        var avatarUrl = await getCroppedImg(
            image,
            stateAvatar.crop,
            stateAvatar.src
        );

        setAvatarImageUrl(avatarUrl);
        setStateAvatar(initialStateAvatar);
    }

    const submit = async (values: IUserRequest) => {
        if (values) {

            var blob: Blob | undefined = undefined;

            if (avatarImageUrl)
                blob = await fetch(avatarImageUrl).then(r => r.blob());

            values.isActive = userActive;
            values.allowEditImportadorPedido = allowEditImportadorPedido;
            values.hasGoals = hasGoals;

            var data = {
                data: values,
                file: blob
            } as IUserData;

            dispatch(isNew ? UserActions.registerUser(data) : UserActions.updateUser(data));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    if (!isLoading && isSendingData) {
        setIsSendingData(false);
        onClose();
    }

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener(
                'load',
                () =>
                    setStateAvatar({
                        ...stateAvatar,
                        src: reader.result
                    }),
                false
            )

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onImageLoaded = (image: any) => {
        setImage(image);
    }

    const onCropChange = (crop: any) => {
        setStateAvatar({
            ...stateAvatar,
            crop
        });
    }

    const buttons =
        [
            <Button hidden={!stateAvatar.src} key="back-avatar" onClick={() => setStateAvatar(initialStateAvatar)}> Voltar</Button >,
            <Button hidden={!stateAvatar.src} key="crop-avatar" type="primary" onClick={onCropAvatar}>Aplicar Foto</Button>,
            <Button hidden={stateAvatar.src} key="back" onClick={onClose}>Cancelar</Button>,
            <Button hidden={stateAvatar.src} key="submit" type="primary" loading={isLoading} htmlType='submit' onClick={() => setIsSubmit(true)}>{isNew ? 'Cadastrar' : 'Salvar'}</Button>,
        ];

    return (
        <Form onSubmit={submit} schema={schema} initialValues={defaultValues} isSubmited={isSubmit}>
            <Modal title={titleModal} open={visible} footer={buttons} onCancel={onClose}>
                <div className={styles['container']}>
                    {
                        stateAvatar.src ? (
                            <ReactCrop
                                src={stateAvatar.src}
                                crop={stateAvatar.crop}
                                circularCrop={true}
                                onImageLoaded={onImageLoaded}
                                onChange={onCropChange}
                                keepSelection={true}
                            />
                        )
                            :
                            <>
                                <FieldForm type='hidden' name='id'></FieldForm>
                                <div style={{ display: 'inline-flex' }}>
                                    <FieldForm autoComplete='false' key='idSuasVendas' label='ID SuasVendas' name='idSuasVendas' className={styles['inputFormSm']}></FieldForm>
                                    <div className={styles['avatarInput']} onClick={() => document.getElementById('fileAvatar')?.click()}  >
                                        <input hidden type="file" id='fileAvatar' onChange={onSelectFile} />
                                        <img src={avatarImageUrl} />
                                    </div>
                                </div>
                                <FieldForm key='name' label='Nome' name='name' className={styles['inputForm']}></FieldForm>
                                <SelectForm placeholder='Selecione um tipo' name='roleName' label='Tipo de usuário' options={rolesOptions} />
                                <FieldForm key='email' label='Email' name='email' className={styles['inputForm']}></FieldForm>
                                <FieldForm key='goalsDashboardUrl' label='Url Dashboard de Metas' name='goalsDashboardUrl' className={styles['inputForm']}></FieldForm>
                                {
                                    isNew ?
                                        <FieldForm key='password' label='Senha' name='password' type='password' className={styles['inputForm']}></FieldForm>
                                        : null
                                }
                                <div className={styles['activeItem']}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked={userActive}
                                        size='small'
                                        onChange={(e) => setUserActive(e)}
                                    />
                                    <label>Usuário Ativo</label>
                                </div>
                                <div className={styles['activeItem']}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked={allowEditImportadorPedido}
                                        size='small'
                                        onChange={(e) => setAllowEditImportadorPedido(e)}
                                    />
                                    <label>Permissão Editar Importação de Pedido</label>
                                </div>
                                <div className={styles['activeItem']}>
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked={hasGoals}
                                        size='small'
                                        onChange={(e) => setHasGoals(e)}
                                    />
                                    <label>Possui Metas</label>
                                </div>
                            </>
                    }
                </div>
            </Modal>
        </Form>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IUserModal;

export default connector(UserForm);