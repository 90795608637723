import { INotification } from "../../data/interfaces/notification/INotification";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";


export enum NotificationActionKeys {
  LIST_NOTIFICATIONS_REQUEST = 'LIST_NOTIFICATIONS_REQUEST',
  LIST_NOTIFICATIONS_SUCCESS = 'LIST_NOTIFICATIONS_SUCCESS',

  ADD_NOTIFICATION = 'ADD_NOTIFICATION',

  READ_ALL_NOTIFICATIONS_REQUEST = 'READ_ALL_NOTIFICATIONS_REQUEST',

  DEFAULT_FAILED = 'DEFAULT_FAILED',
}

export const NotificationActions = {
  listNotifications: (): ListNotificationsAction => createAction(NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST),
  listNotificationsSuccess: (response: INotification[]): ListNotificationsSuccessAction => createAction(NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS, response),

  addNotificationSuccess: (response: INotification): AddNotificationAction => createAction(NotificationActionKeys.ADD_NOTIFICATION, response),

  readAllNotificationsRequest: (): ReadAllNotificationsAction => createAction(NotificationActionKeys.READ_ALL_NOTIFICATIONS_REQUEST),

  defaultFailure: (err: string): DefaultFailureAction => createAction(NotificationActionKeys.DEFAULT_FAILED, err),
};

export type NotificationActionUnion = ActionsUnion<typeof NotificationActions>;

export type ListNotificationsAction = Action<NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST>;
export type ListNotificationsSuccessAction = Action<NotificationActionKeys.LIST_NOTIFICATIONS_SUCCESS, INotification[]>;

export type AddNotificationAction = Action<NotificationActionKeys.ADD_NOTIFICATION, INotification>;

export type ReadAllNotificationsAction = Action<NotificationActionKeys.READ_ALL_NOTIFICATIONS_REQUEST>;

export type DefaultFailureAction = Action<NotificationActionKeys.DEFAULT_FAILED, string>;