import React from "react";
import { ColumnProps } from "antd/lib/table";
import { FaPen, FaTrash } from "react-icons/fa";
import { getDistinctFilterValues, sorter } from "../../utils/helpers";
import { history } from "../../store";

import styles from './ProjecaoVenda.module.scss';
import Confirm from "../../components/ui/confirm";
import { ICombinacao } from "../../data/interfaces/projecaoVenda/ICombinacao";
import { dateStringUStoPTBR } from "../../utils/dateHelper";

export const getColumns = (combinacoes: ICombinacao[], onEdit: (register: ICombinacao) => void, onDelete: (combinacaoId: number) => void) => {
    return [ 
        {
            title: 'Cliente',
            dataIndex: 'clienteNomeFantasia',
            filters: getDistinctFilterValues(combinacoes, 'clienteNomeFantasia'),
            sorter: (a, b) => sorter(a.clienteNomeFantasia, b.clienteNomeFantasia),
            onFilter: (value: string, record) => record.clienteNomeFantasia?.indexOf(value) === 0,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 200,
            render: v => v ?? '-'
        },
        {
            title: 'Indústria',
            dataIndex: 'industriaNomeFantasia',
            filters: getDistinctFilterValues(combinacoes, 'industriaNomeFantasia'),
            sorter: (a, b) => sorter(a.industriaNomeFantasia, b.industriaNomeFantasia),
            onFilter: (value: string, record) => record.industriaNomeFantasia?.indexOf(value) === 0,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 200,
            render: v => v ?? '-'
        },
        {
            title: 'Modelo',
            dataIndex: 'modeloNome',
            filters: getDistinctFilterValues(combinacoes, 'modeloNome'),
            sorter: (a, b) => sorter(a.modeloNome, b.modeloNome),
            onFilter: (value: string, record) => record.modeloNome?.indexOf(value) === 0,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 200,
            render: (value, register) => value ? <a onClick={() => history.push(`/projecao-venda/modelo/${register.combinacaoModeloId}`)}>{value}</a> : null
        },
        {
            title: 'Ativo',
            dataIndex: 'ativo',
            width: 100,
            render: (value) => value ? 'Sim' : 'Não'
        },
        {
            title: 'Data de Criação',
            dataIndex: 'dataCriacao',
            width: 100,
            render: (value) => dateStringUStoPTBR(value)

        },
        {
            width: 120,
            className: styles['columnAction'],
            render: (value, register) => {
                return (
                    <div>
                        <FaPen title='Editar' onClick={() => onEdit(register)} />
                        <FaTrash title='Excluir' display={register.combinacaoClienteId == null && register.combinacaoIndustriaId == null ? 'none' : 'inline'} onClick={() => Confirm('Excluir Combinação', 'Deseja realmente excluir?', () => onDelete(register.combinacaoId))} />
                    </div>
                )
            }
        },
    ] as Array<ColumnProps<ICombinacao>>;
}