import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { AuthApi } from '../../data/Auth.api';
import { ILoginResponse } from '../../data/interfaces/auth/ILoginResponse';
import { AuthUtils } from '../../utils/AuthUtils';
import { getParameterByName } from '../../utils/helpers';
import { AuthActions, UserLoginAction, UserLoginSuccessAction } from "./Auth.actions";
import { COOKIENAME_DEVICEID } from './Auth.constants';

export function* signIn({ payload }: UserLoginAction) {
  try {

    const cookies = new Cookies();
    var deviceId = cookies.get(COOKIENAME_DEVICEID);
    payload.deviceId = deviceId;

    const { data } = yield call(AuthApi.signIn, payload);

    const loginResponse =
      {
        tokenResponse: JSON.parse(data.data),
        user: data.info
      } as ILoginResponse;

    yield put(AuthActions.userLoginSuccess(loginResponse));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.userLoginFailure(error));
  }
}

export function* loginSuccess({ payload }: UserLoginSuccessAction) {
  AuthUtils.setLoggedUser({
    accessToken: payload.tokenResponse.access_token,
    refreshToken: payload.tokenResponse.refresh_token,
    expiresIn: payload.tokenResponse.expires_in,
    nome: payload.user.name,
    roleName: payload.user.roleName,
    email: payload.user.email,
    avatarFileName: payload.user.avatarFileName,
    allowEditImportadorPedido: payload.user.allowEditImportadorPedido,
    userId: payload.user.id
  });

  yield put(AuthActions.getUserInfoSuccess(payload.user));

  var redirectUrl = getParameterByName('redirectUrl');
  window.location.href = (redirectUrl ? redirectUrl : '/pedidos/importador');
}

export function* getUserInfo() {
  try {
    const { data } = yield call(AuthApi.getUserInfo);
    yield put(AuthActions.getUserInfoSuccess(data.data));
    yield put(AuthActions.getInfoSuccess(data.info));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AuthActions.getUserInfoFailure(error));
  }
}