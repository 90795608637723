import { ILoginRequest } from "../../data/interfaces/auth/ILoginRequest";
import { ILoginResponse } from "../../data/interfaces/auth/ILoginResponse";
import { IUserResponse } from "../../data/interfaces/user/IUserResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";

export enum AuthActionKeys {
  AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',

  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED',

  GET_INFO_SUCCESS = 'GET_INFO_SUCCESS'
}

export const AuthActions = {
  userLogin: (userData: ILoginRequest): UserLoginAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_REQUEST, userData),
  userLoginSuccess: (response: ILoginResponse): UserLoginSuccessAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_SUCCESS, response),
  userLoginFailure: (err: string): UserLoginFailureAction =>
    createAction(AuthActionKeys.AUTH_LOGIN_FAILED, err),

  getUserInfo: (): GetUserInfoAction =>
    createAction(AuthActionKeys.GET_USER_INFO_REQUEST),
  getUserInfoSuccess: (response: IUserResponse): GetUserInfoSuccessAction =>
    createAction(AuthActionKeys.GET_USER_INFO_SUCCESS, response),
  getUserInfoFailure: (err: string): GetUserInfoFailureAction =>
    createAction(AuthActionKeys.GET_USER_INFO_FAILED, err),

  getInfoSuccess: (response: string[]): GetInfoSuccessAction =>
    createAction(AuthActionKeys.GET_INFO_SUCCESS, response),
}

export type AuthActionUnion = ActionsUnion<typeof AuthActions>;

export type UserLoginAction = Action<AuthActionKeys.AUTH_LOGIN_REQUEST, ILoginRequest>;
export type UserLoginSuccessAction = Action<AuthActionKeys.AUTH_LOGIN_SUCCESS, ILoginResponse>;
export type UserLoginFailureAction = Action<AuthActionKeys.AUTH_LOGIN_FAILED, string>;

export type GetUserInfoAction = Action<AuthActionKeys.GET_USER_INFO_REQUEST>;
export type GetUserInfoSuccessAction = Action<AuthActionKeys.GET_USER_INFO_SUCCESS, IUserResponse>;
export type GetUserInfoFailureAction = Action<AuthActionKeys.GET_USER_INFO_FAILED, string>;

export type GetInfoSuccessAction = Action<AuthActionKeys.GET_INFO_SUCCESS, string[]>;