import { IMeta, IMetaBonus, IMetaBonusCoordenador, IMetaCliente } from "../../data/interfaces/meta/IMeta";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IMetaState } from "./interfaces/IMetaState";
import { MetaActionKeys, MetaActionUnion } from "./Meta.actions";

const initialState: IMetaState = {
  meta: {} as IMeta,
  isLoading: false,
  error: undefined,
  message: undefined,
  isLoadingMeta: false,
  isImporting: false
}

const metaReducer = (state = initialState, action: MetaActionUnion): IMetaState => {

  switch (action.type) {
    case MetaActionKeys.GET_META_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingMeta: true
      };
    case MetaActionKeys.GET_META_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingMeta: false,
        meta: action.payload,
      };

    case MetaActionKeys.CLEAR_META:

      var metaAux = { ...state.meta };
      metaAux.metaBonus = {} as IMetaBonus;
      metaAux.metaClientes = [] as IMetaCliente[];
      metaAux.metaBonusCoordenador = {} as IMetaBonusCoordenador;

      return {
        ...state,
        isLoading: false,
        isLoadingMeta: false,
        meta: metaAux,
      };

    case MetaActionKeys.UPDATE_META_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingMeta: true
      };

    case MetaActionKeys.UPDATE_META_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingMeta: false,
        meta: action.payload,
      };

    case MetaActionKeys.IMPORTAR_META_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined
      };
    case MetaActionKeys.IMPORTAR_META_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined
      };
    case MetaActionKeys.IMPORTAR_META_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case MetaActionKeys.IMPORTAR_META_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case MetaActionKeys.IMPORTAR_META_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case MetaActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoadingMeta: false,
        meta: {} as IMeta,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default metaReducer;
