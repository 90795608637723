import { Button, List, Progress } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import styles from './ImportadorMetas.module.scss';
import { toast } from "react-toastify";
import { anosOptions, secondsToMs } from "../../../../utils/dateHelper";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";
import { SvDbActions } from "../../../../store/svDb/SvDb.actions";
import { getOptionsDataFromObject } from "../../../../utils/helpers";
import { IImportarMetaRequest } from "../../../../data/interfaces/meta/IMeta";
import { MetaActions } from "../../../../store/meta/Meta.actions";
import { UserActions } from "../../../../store/user/User.actions";
import { ROLE_ADMINISTRADOR, ROLE_COORDENADOR } from "../../../../store/auth/Auth.constants";
import { IUserRequest } from "../../../../data/interfaces/user/IUserRequest";
import schema from "./ImportadorMetas.schema";

export interface ImportadorMetas {
    visible: boolean;
    onClose: () => void;
}

interface formValues {
    industriaId?: number
}

const ImportadorMetas: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeUpdating, setTimeUpdating] = useState(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [usuariosVendedoresOptions, setUsuariosVendedoresOptions] = useState<IOptionData[]>([]);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [userSelected, setUserSelected] = useState<IUserRequest>()

    useEffect(() => {
        dispatch(SvDbActions.listIndustrias());
        dispatch(UserActions.listUsers());
    }, []);

    useEffect(() => {
        if (props.industrias && props.industrias?.length > 0)
            setIndustriasOptions(getOptionsDataFromObject(props.industrias, 'industriaId', 'industriaNomeFantasia'));

        if (props.users && props.users.length > 0) {
            setUsuariosVendedoresOptions(getOptionsDataFromObject(props.users.filter(u => u.hasGoals), 'idSuasVendas', 'name'));
        }

    }, [props.users, props.industrias]);

    useEffect(() => {
        if (isUpdating) {
            setTimeout(() => {
                setTimeUpdating(timeUpdating + 1);
            }, 1000);
        }
    }, [timeUpdating, isUpdating])

    useEffect(() => {
        if (!props.isImporting && isUpdating) {
            if (!props.errosImportacao || props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsUpdating(false);
        }
    }, [props.isImporting])

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        dispatch(ClienteActions.importarClienteIndustriaClear());
        props.onClose();
    }

    const submit = (values: IImportarMetaRequest) => {
        if (values) {
            if (file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {

                values.isCoordenador = userSelected?.roleName == ROLE_COORDENADOR;
                values.file = file;
                dispatch(MetaActions.importarMeta(values));
                setIsUpdating(true);
                setShowForm(false);
                setValues(values);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(ClienteActions.importarClienteIndustriaClear());
        setFile(null);
    }

    const downloadArquivoPadrao = () => {
        window.open(`${process.env.REACT_APP_BASE_API_URL}/Meta/DownloadArquivoPadrao`);
    }

    const buttons =
        [
            <Button hidden={!showForm} key='downloadFile' type='link' style={{ position: 'absolute', left: 10 }} onClick={downloadArquivoPadrao}>Baixar arquivo padrão</Button>,
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosAtualizacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const form =
        <Form onSubmit={submit} isSubmited={isSubmit} initialValues={values} schema={schema} className={styles['form']}>
            <div>
                <SelectForm placeholder='Selecione um Usuário' onClear={() => setUserSelected(undefined)} onSelect={(e) => setUserSelected(props.users.find(x => x.idSuasVendas == e))} name='vendedorId' label='Usuário/Vendedor' options={usuariosVendedoresOptions} />
                <SelectForm placeholder='Selecione uma Indústria' hidden={userSelected?.roleName == ROLE_COORDENADOR} disabled={!userSelected} name='industriaId' label='Indústria' options={industriasOptions} />
                <SelectForm placeholder='Ano' name='ano' label='Ano' options={anosOptions} />
            </div>
            <div className={styles['groupSelect']} style={{ display: "flex" }}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportarClienteIndustria')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportarClienteIndustria' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    const infoAtualizacao = <div className={styles['infoUpdating']} id='infoUpdating'>
        <p>Importando Metas - <span>{secondsToMs(timeUpdating)}</span></p>
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={100}
            showInfo={false}
            status="active"
        />
    </div>;

    return (
        <Modal title={'Importação de Metas'} open={props.visible} footer={!isUpdating ? buttons : null} closable={false} destroyOnClose={true} width={!showForm && props.errosImportacao && props.errosImportacao.length > 0 ? 750 : 450}>
            <div className={styles['container']}>
                {
                    isUpdating ?
                        infoAtualizacao :
                        (
                            !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                errosAtualizacao
                                : form
                        )}
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.svDb,
    ...state.meta,
    users: { ...state }.user.users
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ImportadorMetas;

export default connector(ImportadorMetas);