import React from "react";
import { ColumnProps } from "antd/lib/table";
import { getDistinctFilterValues, getFilterDateProps, sorter } from "../../../utils/helpers";
import moment from "moment";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";
import { Button } from "antd";
import { IAtualizarPedidoItemResponse } from "../../../data/interfaces/atualizarPedido/IAtualizarPedidoItemResponse";
import { isAccessAdmin } from "../../../utils/AuthUtils";

export const getColumns = (atualizarPedidoItens: IAtualizarPedidoItemResponse[], restaurarItemPedido: (produtoIDAntigo: number, atualizarPedidoInfoID: number) => void) => {
    return [
        {
            title: 'Cod. Antigo',
            dataIndex: 'codigoAntigo',
            sorter: (a, b) => sorter(a.codigoAntigo, b.codigoAntigo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidoItens, 'codigoAntigo'),
            onFilter: (value: string, record) => { return record.codigoAntigo.indexOf(value) === 0 },
        },
        {
            title: 'Cod. Novo',
            dataIndex: 'codigoNovo',
            sorter: (a, b) => sorter(a.codigoNovo, b.codigoNovo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidoItens, 'codigoNovo'),
            onFilter: (value: string, record) => { return record.codigoNovo.indexOf(value) === 0 },
        },
        {
            title: 'Qtde',
            dataIndex: 'qtde',
            sorter: (a, b) => a.qtde - b.qtde,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: 'V. Unt.',
            dataIndex: 'precoUnit',
            sorter: (a, b) => a.precoUnit - b.precoUnit,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value: any, register: IAtualizarPedidoItemResponse) => {
                return (Math.round(value * 100) / 100).toFixed(2);
            }
        },
        {
            title: 'Pedido',
            dataIndex: 'pedidoID',
            sorter: (a, b) => a.pedidoID - b.pedidoID,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidoItens, 'codigoNovo'),
            onFilter: (value: string, record) => { return record.codigoNovo.indexOf(value) === 0 },
        },
        {
            title: 'Indústria',
            dataIndex: 'industriaNome',
            sorter: (a, b) => sorter(a.industriaNome, b.industriaNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidoItens, 'industriaNome'),
            onFilter: (value: string, record) => { return record.industriaNome.indexOf(value) === 0 },
        },
        {
            title: 'Cliente',
            dataIndex: 'clienteNome',
            sorter: (a, b) => sorter(a.clienteNome, b.clienteNome),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(atualizarPedidoItens, 'clienteNome'),
            onFilter: (value: string, record) => { return record.clienteNome.indexOf(value) === 0 },
        },
        {
            title: 'Data',
            dataIndex: 'dataHora',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.dataHora, b.dataHora),
            showSorterTooltip: false,
            ...getFilterDateProps('dataHora', [moment().add(-5, 'y'), moment()]),
            render: (value: any, register: IAtualizarPedidoItemResponse) => {
                return `${dateStringUStoPTBR(register.dataHora)}`;
            }
        },
        {
            title: 'Acessar no SV',
            dataIndex: 'pedidoID',
            width: 150,
            showSorterTooltip: false,
            render: (value: any) => {
                return <Button type='link' size='small' onClick={() => window.open('https://app4.suasvendas.com/Modulo/YourSales/PedidoFormV2.aspx?Op=UldScGRHRnk=&Id=' + btoa(btoa(value)))}>Acessar</Button>;
            }
        },
        isAccessAdmin() ?
            {
                title: 'Restaurar',
                dataIndex: null,
                showSorterTooltip: false,
                render: (value: any, register: IAtualizarPedidoItemResponse) => {
                    return <Button type='link' size='small' onClick={() => restaurarItemPedido(register.produtoIDAntigo, register.atualizarPedidoInfoID)}>Restaurar Item</Button>;
                }
            } : {},
    ] as Array<ColumnProps<IAtualizarPedidoItemResponse>>;
}