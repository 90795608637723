import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { ContatoApi } from '../../data/Contato.api';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { ContatoActions, ImportarContatoAction, UpdateContatoAction } from './Contato.actions';
import { SvApiActions } from "../svApi/SvApi.actions";

export function* listContatos() {
  try {
    const { data } = yield call(ContatoApi.listContatos);
    yield put(ContatoActions.listContatosSuccess(data.data));
    yield put(SvApiActions.listClientesSuccess(data.info.clientes));
    yield put(SvApiActions.listDepartamentosByClienteSuccess(data.info.departamentos));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ContatoActions.defaultFailure(error));
  }
}

export function* updateContato({ payload }: UpdateContatoAction) {
  try {
    const { data } = yield call(ContatoApi.update, payload);
    yield put(ContatoActions.updateContatoSuccess());
    toast.success('Contato atualizado com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(ContatoActions.defaultFailure(error));
  }
}

export function* importarContatos({ payload }: ImportarContatoAction) {
  try {
    yield call(ContatoApi.importarContatos, payload);
    yield put(ContatoActions.importarContatoSuccess());

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    console.log(e);

    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação está inválida!');
      yield put(ContatoActions.importarContatoInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(ContatoActions.importarContatoFailure(error));
    }
  }
}