import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { AddMessageAction, ChangeStatusAction, DeleteAttachmentAction, DeleteMessageAction, GetTicketAcompanhamentoAction, GetTicketAction, TicketActions, UploadAttachmentAction } from './Ticket.actions';
import { TicketApi } from '../../data/Ticket.api';

export function* getTicket({ payload }: GetTicketAction) {
  try {
    const { data } = yield call(TicketApi.get, payload);
    yield put(TicketActions.getTicketSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* getTicketByAcompanhamentoId({ payload }: GetTicketAcompanhamentoAction) {
  try {
    const { data } = yield call(TicketApi.getByAcompanhamentoId, payload);
    yield put(TicketActions.getTicketAcompanhamentoSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* changeTicketStatus({ payload }: ChangeStatusAction) {
  try {
    yield call(TicketApi.changeStatus, payload);
    yield put(TicketActions.changeStatusSuccess());
    toast.success("Status alterado com Sucesso!");
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* addTicketMessage({ payload }: AddMessageAction) {
  try {
    const { data } = yield call(TicketApi.addMessage, payload);
    yield put(TicketActions.addMessageSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* deleteTicketMessage({ payload }: DeleteMessageAction) {
  try {
    yield call(TicketApi.deleteMessage, payload);
    yield put(TicketActions.deleteMessageSuccess(payload));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* uploadAttachment({ payload }: UploadAttachmentAction) {
  try {
    const { data } = yield call(TicketApi.attachment, payload);
    yield put(TicketActions.uploadAttachmentSuccess(data.data));
  } catch (e) {
    console.log(e);
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao enviar arquivo';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}

export function* deleteTicketAttachment({ payload }: DeleteAttachmentAction) {
  try {
    yield call(TicketApi.deleteAttachment, payload);
    yield put(TicketActions.deleteAttachmentSuccess(payload));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(TicketActions.defaultFailure(error));
  }
}