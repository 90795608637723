import { AxiosResponse } from "axios";
import { Http } from "../utils/Http";
import { ICombinacao, INewCombinacaoRequest } from "./interfaces/projecaoVenda/ICombinacao";
import { IModeloCombinacao, INewModeloRequest } from "./interfaces/projecaoVenda/IModeloCombinacao";

export class ProjecaoVendaApi {
    static async listCombinacoes(): Promise<AxiosResponse<ICombinacao[]>> {
        const axios = await Http.axios();
        return axios.get(`/projecaoVenda/listCombinacoes`);
    }

    static async getCombinacao(request: number): Promise<AxiosResponse<ICombinacao>> {
        const axios = await Http.axios();
        return axios.get(`/projecaoVenda/getCombinacao/${request}`);
    }

    static async updateCombinacaoItens(request: ICombinacao): Promise<AxiosResponse<ICombinacao>> {
        const axios = await Http.axios();
        return axios.put(`/projecaoVenda/updateCombinacao/${request.combinacaoId}`, request.combinacaoItens);
    }

    static async importarCombinacao(request: INewCombinacaoRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file ?? '');
        formData.append('combinacaoId', request.combinacaoId?.toString());
        formData.append('modeloId', request.modeloId?.toString());

        let query = '?';

        if (request.clientesId?.length > 0) {
            query += `clientesId=${request.clientesId}&`;
        }

        if (request.industriasId?.length > 0) {
            query += `industriasId=${request.industriasId}`;
        }

        return axios.post(`/projecaoVenda/importarCombinacao${query}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async listModelos(): Promise<AxiosResponse<IModeloCombinacao[]>> {
        const axios = await Http.axios();
        return axios.get(`/projecaoVenda/listModelos`);
    }

    static async getModelo(request: number): Promise<AxiosResponse<IModeloCombinacao>> {
        const axios = await Http.axios();
        return axios.get(`/projecaoVenda/getModelo/${request}`);
    }

    static async deleteCombinacao(id: number): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.delete(`/projecaoVenda/deleteCombinacao/${id}`);
    }

    static async importarModelo(request: INewModeloRequest): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        const formData = new FormData();
        formData.append('file', request.file ?? '');
        formData.append('name', request.nome);
        formData.append('modeloId', request.modeloId?.toString());
        formData.append('combinacaoId', request.combinacaoId?.toString());

        return axios.post(`/projecaoVenda/importarModelo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }

    static async deleteModelo(id: number): Promise<AxiosResponse<any>> {
        const axios = await Http.axios();
        return axios.delete(`/projecaoVenda/deleteModelo/${id}`);
    }

    static async updateModeloItens(request: IModeloCombinacao): Promise<AxiosResponse<IModeloCombinacao>> {
        const axios = await Http.axios();
        return axios.put(`/projecaoVenda/updateModelo/${request.modeloId}`, request.modeloItens);
    }
}