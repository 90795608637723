import { IImportSellOutRequest, ISellOut } from "../../data/interfaces/sellOut/ISellOut";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { Action, ActionsUnion, createAction } from "../../utils/actionHelper";


export enum SellOutActionKeys {
    GET_SELL_OUT_REQUEST = 'GET_SELL_OUT_REQUEST',
    GET_SELL_OUT_SUCCESS = 'GET_SELL_OUT_SUCCESS',

    UPDATE_SELL_OUT_REQUEST = 'UPDATE_SELL_OUT_REQUEST',
    UPDATE_SELL_OUT_SUCCESS = 'UPDATE_SELL_OUT_SUCCESS',

    IMPORTAR_SELL_OUT_REQUEST = 'IMPORTAR_SELL_OUT_REQUEST',
    IMPORTAR_SELL_OUT_SUCCESS = 'IMPORTAR_SELL_OUT_SUCCESS',
    IMPORTAR_SELL_OUT_FAILED = 'IMPORTAR_SELL_OUT_FAILED',
    IMPORTAR_SELL_OUT_INVALID = 'IMPORTAR_SELL_OUT_INVALID',
    IMPORTAR_SELL_OUT_CLEAR = 'IMPORTAR_SELL_OUT_CLEAR',

    DEFAULT_FAILED = 'DEFAULT_FAILED',
    importarSellOut = "importarSellOut"
}

export const SellOutActions = {
  getSellOut: (request: ISellOut): GetSellOutAction => createAction(SellOutActionKeys.GET_SELL_OUT_REQUEST, request),
  getSellOutSuccess: (response: ISellOut): GetSellOutSuccessAction => createAction(SellOutActionKeys.GET_SELL_OUT_SUCCESS, response),

  updateSellOut: (request: ISellOut): UpdateSellOutAction => createAction(SellOutActionKeys.UPDATE_SELL_OUT_REQUEST, request),
  updateSellOutSuccess: (response: ISellOut): UpdateSellOutSuccessAction => createAction(SellOutActionKeys.UPDATE_SELL_OUT_SUCCESS, response),

  importarSellOut: (request: IImportSellOutRequest): ImportarSellOutAction => createAction(SellOutActionKeys.IMPORTAR_SELL_OUT_REQUEST, request),
  importarSellOutSuccess: (response: ISellOut): ImportarSellOutSuccessAction => createAction(SellOutActionKeys.IMPORTAR_SELL_OUT_SUCCESS, response),
  importarSellOutFailure: (err: string): ImportarSellOutFailureAction => createAction(SellOutActionKeys.IMPORTAR_SELL_OUT_FAILED, err),
  importarSellOutInvalid: (err: IErroResponse[]): ImportarSellOutInvalidAction => createAction(SellOutActionKeys.IMPORTAR_SELL_OUT_INVALID, err),
  importarSellOutClear: (): ImportarSellOutClearAction => createAction(SellOutActionKeys.IMPORTAR_SELL_OUT_CLEAR),

  defaultFailure: (err: string): DefaultFailureAction => createAction(SellOutActionKeys.DEFAULT_FAILED, err),
};

export type SellOutActionUnion = ActionsUnion<typeof SellOutActions>;

export type GetSellOutAction = Action<SellOutActionKeys.GET_SELL_OUT_REQUEST, ISellOut>;
export type GetSellOutSuccessAction = Action<SellOutActionKeys.GET_SELL_OUT_SUCCESS, ISellOut>;

export type UpdateSellOutAction = Action<SellOutActionKeys.UPDATE_SELL_OUT_REQUEST, ISellOut>;
export type UpdateSellOutSuccessAction = Action<SellOutActionKeys.UPDATE_SELL_OUT_SUCCESS, ISellOut>;

export type ImportarSellOutAction = Action<SellOutActionKeys.IMPORTAR_SELL_OUT_REQUEST, IImportSellOutRequest>;
export type ImportarSellOutSuccessAction = Action<SellOutActionKeys.IMPORTAR_SELL_OUT_SUCCESS, ISellOut>;
export type ImportarSellOutFailureAction = Action<SellOutActionKeys.IMPORTAR_SELL_OUT_FAILED, string>;
export type ImportarSellOutInvalidAction = Action<SellOutActionKeys.IMPORTAR_SELL_OUT_INVALID, IErroResponse[]>;
export type ImportarSellOutClearAction = Action<SellOutActionKeys.IMPORTAR_SELL_OUT_CLEAR>;

export type DefaultFailureAction = Action<SellOutActionKeys.DEFAULT_FAILED, string>;