import React, { FC, useEffect, useState } from "react";
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { AtualizarPedidoActions } from "../../../store/atualizarPedido/AtualizarPedido.actions";
import PageContent from "../../../components/ui/pageContent";

import styles from './AtualizarItensPedidos.module.scss';
import { Button, Modal, Skeleton, Table } from "antd";
import { getColumns } from "./AtualizarItensPedidos.helper";
import { IRestaurarItemPedido } from "../../../data/interfaces/atualizarPedido/IRestaurarItemPedido";

const AtualizarItensPedidos: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [produtoIDAntigo, setProdutoIDAntigo] = useState<number>();
    const [atualizarPedidoInfoID, setAtualizarPedidoInfoID] = useState<number>();
    const [isRequest, setIsRequest] = useState(false);

    useEffect(() => {
        dispatch(AtualizarPedidoActions.listAtualizarPedidoItems());
    }, []);

    useEffect(() => {
        if (isRequest && !props.isLoading) {
            setIsVisibleModal(false);
            setIsRequest(false);
        }
    }, [props.isLoading, isRequest])

    const openModalRestaurarItemPedido = (produtoIDAntigo: number, atualizarPedidoInfoID: number) => {
        setProdutoIDAntigo(produtoIDAntigo);
        setAtualizarPedidoInfoID(atualizarPedidoInfoID);
        setIsVisibleModal(true);
    }

    const restaurarItemPedido = (tipoRestauracao: 'unica' | 'todos') => {

        if (window.confirm('Está ação não poderá ser desfeita. Deseja continuar?')) {
            var request = {
                produtoIdAntigo: produtoIDAntigo,
                atualizacaoPedidoInfoId: tipoRestauracao == 'todos' ? 0 : atualizarPedidoInfoID
            } as IRestaurarItemPedido;

            dispatch(AtualizarPedidoActions.restaurarItemPedido(request));
            setIsRequest(true);
        }
    }

    const modalRestaurarItemPedido =
        <Modal
            open={isVisibleModal}
            title="Restauração de Itens"
            width={700}
            onCancel={() => setIsVisibleModal(false)}
            footer={[
                <Button key="back" loading={props.isLoading} onClick={() => setIsVisibleModal(false)}>Cancelar</Button>,
                <Button key="unica" style={{ background: "rgb(197 145 28)", borderColor: "rgb(197 145 28)", color: 'white' }} loading={props.isLoading} onClick={() => restaurarItemPedido('unica')}>Restauração Única</Button>,
                <Button key="todos" type="primary" loading={props.isLoading} onClick={() => restaurarItemPedido('todos')}>Restauração em Todos Pedidos</Button>,
            ]}
        >
            <p><b>Escolha uma das opções:</b></p>
            <p>Restauração Única = Restaura o produto apenas da linha escolhida</p>
            <p>Restauração em Todos Pedidos = Restaura o produto em todos Pedidos</p>
            <br />
            <p style={{ color: 'red' }}><b>Atenção: </b>Está ação não poderá ser desfeita!</p>
        </Modal >;

    return (
        <>
            <PageContent title='Lista de Itens - Atualizações de Pedidos' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <Table
                            columns={getColumns(props.atualizarPedidosItems, openModalRestaurarItemPedido)}
                            dataSource={props.atualizarPedidosItems}
                            style={{ overflowY: 'auto' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }} />
                    </>
                }
            </PageContent>
            {modalRestaurarItemPedido}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.atualizarPedido
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;


export default connector(AtualizarItensPedidos);