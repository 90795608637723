import { Button, Skeleton, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IGlobalReducerState } from "../../../../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./AtualizarProdutoDetail.helper";

export interface IAtualizarProduto {
    visible: boolean;
    onClose: () => void;
}

const AtualizarProdutoDetail: FC<Props> = (props) => {

    const buttons =
        [
            <Button loading={props.isLoading} key="cancel" onClick={props.onClose}>Cancelar</Button>,
        ];

    return (
        <Modal title={'Items - Atualização de Produtos'} open={props.visible} footer={buttons} destroyOnClose={true} width={1200}>
            {props.isLoading ?
                <Skeleton active />
                :
                <Table columns={getColumns(props.atualizarProduto.camposAtualizados)} dataSource={props.atualizarProduto.items} style={{ overflowY: 'auto' }} pagination={false} />
            }
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.svApi,
    ...state.produto
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IAtualizarProduto;

export default connector(AtualizarProdutoDetail);