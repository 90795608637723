import { IContatoAux } from "../../data/interfaces/contato/IContato";
import { IErroResponse } from "../../data/interfaces/shared/IErroResponse";
import { IContatoState } from "./interfaces/IContatoState";
import { ContatoActionKeys, ContatoActionUnion } from "./Contato.actions";

const initialState: IContatoState = {
  contatosAgrupados: [] as IContatoAux[],
  contatosIndividuais: [],
  isLoading: false,
  error: undefined,
  message: undefined,
  errosImportacao: [] as IErroResponse[],
  isImporting: false,
}

const contatoReducer = (state = initialState, action: ContatoActionUnion): IContatoState => {

  switch (action.type) {
    case ContatoActionKeys.GET_CONTATO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ContatoActionKeys.GET_CONTATO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contatosAgrupados: action.payload.contatosAgrupados,
        contatosIndividuais: action.payload.contatosIndividuais,
      };

    case ContatoActionKeys.UPDATE_CONTATO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ContatoActionKeys.UPDATE_CONTATO_SUCCESS:
      return {
        ...state,
        contatosAgrupados: [] as IContatoAux[],
        isLoading: false,
      };

    case ContatoActionKeys.IMPORTAR_CONTATO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImporting: true,
        errosImportacao: undefined,
      };
    case ContatoActionKeys.IMPORTAR_CONTATO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: undefined,
        contatosAgrupados: [] as IContatoAux[]
      };
    case ContatoActionKeys.IMPORTAR_CONTATO_FAILED:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        error: action.payload,
      };
    case ContatoActionKeys.IMPORTAR_CONTATO_INVALID:
      return {
        ...state,
        isLoading: false,
        isImporting: false,
        errosImportacao: action.payload,
      };
    case ContatoActionKeys.IMPORTAR_CONTATO_CLEAR:
      return {
        ...state,
        isLoading: false,
        errosImportacao: [] as IErroResponse[]
      };

    case ContatoActionKeys.DEFAULT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contatoReducer;
