import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./CRM.helper";
import styles from './CRM.module.scss';
import { ClienteActions } from "../../store/cliente/Cliente.actions";
import { TableLocale } from "antd/lib/table/interface";
import { history } from "../../store";
import { AcompanhamentoActions } from "../../store/acompanhamento/Acompanhamento.actions";
import { IAcompanhamento } from "../../data/interfaces/acompanhamento/IAcompanhamento";

const CRM: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [qtdeRows, setQtdeRows] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        dispatch(ClienteActions.listClientes());
        dispatch(AcompanhamentoActions.getAcompanhamentoSuccess([] as IAcompanhamento[]));
    }, []);

    useEffect(() => {
        setQtdeRows(props.clientes?.length ?? 0);
    }, [props.clientes?.length]);

    const tableChange = (qtdeRows: number) => {
        setQtdeRows(qtdeRows);
    }

    const onView = (id: number) => {
        history.push('/crm/' + id);
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeysAux: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeysAux);
        }
    };

    const localeTable = {
        emptyText: 'Nenhum dado encontrado',
        filterConfirm: 'Filtrar',
        filterReset: 'Limpar',
    } as TableLocale;

    return (
        <>
            <PageContent title='CRM' className={styles['container']}>
                {props.isLoadingClientesIndustrias ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' className='btn-green' onClick={() => history.push('/crm/acompanhamento')}>Acompanhamento Cliente/Indústria</Button>
                        </div>
                        <div className={styles['qtdeRows']}>
                            {qtdeRows} registros
                        </div>
                        <Table
                            rowSelection={{ type: 'checkbox', ...rowSelection }}
                            onChange={(p, f, s, extra) => tableChange(extra.currentDataSource?.length)}
                            columns={getColumns(props.clientes ?? [], onView)}
                            dataSource={props.clientes && props.clientes?.map((data, index) => ({ ...data, key: index }))}
                            style={{ overflowY: 'auto' }}
                            pagination={{ pageSize: 100, position: ['bottomRight'], showSizeChanger: false }}
                            locale={localeTable} />
                    </>
                }
            </PageContent>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(CRM);