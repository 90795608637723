import { Button, Modal, Skeleton, Space, Table, TablePaginationConfig } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import styles from './CombinacaoForm.module.scss';
import { history } from "../../../store";
import { ProjecaoVendaActions } from "../../../store/projecaoVenda/ProjecaoVenda.actions";
import { getColumns, removeAllEditingClass } from "./CombinacaoForm.helper";
import { ICombinacao, ICombinacaoItem } from "../../../data/interfaces/projecaoVenda/ICombinacao";
import { ProSidebar } from "react-pro-sidebar";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";

export interface ICombinacaoForm {
    match: any;
}

const CombinacaoForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isVisibleModalEditStatus, setIsVisibleModalEditStatus] = useState(false);
    const [isVisibleModalHistorico, setIsVisibleModalHistorico] = useState(false);
    const [chaveEditing, setChaveEditing] = useState<string | null>(null);
    const [combinacao, setCombinacao] = useState<ICombinacao>({ combinacaoItens: [] as ICombinacaoItem[] } as ICombinacao);
    const [title, setTitle] = useState('Projeção de Venda');

    useEffect(() => {
        setCombinacao({} as ICombinacao);
        dispatch(ProjecaoVendaActions.getCombinacao(props.match.params.id));
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.combinacao.combinacaoItens?.length > 0) {
            setCombinacao(props.combinacao);

            let title = (props.combinacao.combinacaoIndustriaId && props.combinacao.combinacaoClienteId ? `${props.combinacao.clienteNomeFantasia} x ${props.combinacao.industriaNomeFantasia}`
                : (props.combinacao.combinacaoIndustriaId ? props.combinacao.industriaNomeFantasia
                    : (props.combinacao.combinacaoClienteId ? props.combinacao.clienteNomeFantasia : 'Combinação Geral'))) + ' - Projeção de Venda';

            setTitle(title);
        }
    }, [props.combinacao]);

    const showModalEditStatus = (chave: string) => {
        setIsVisibleModalEditStatus(true);
        setChaveEditing(chave);
    }

    const handleEditStatus = (newStatus: string) => {
        var combinacaoAux = { ...combinacao };
        var index = combinacaoAux.combinacaoItens.findIndex(x => x.chave == chaveEditing);
        var registerUpdate = combinacaoAux.combinacaoItens[index];
        registerUpdate.status = newStatus;
        registerUpdate.editing = false;

        combinacaoAux.combinacaoItens.splice(index, 1, registerUpdate);

        setCombinacao(combinacaoAux);
        setIsVisibleModalEditStatus(false);
    }

    const closeModalEditStatus = () => {
        removeAllEditingClass();
        setIsVisibleModalEditStatus(false);
    }

    const handleSaveCombinacao = () => {
        dispatch(ProjecaoVendaActions.updateCombinacao(combinacao));
    }

    const tablePaginationConfig = {
        pageSize: 100,
        showSizeChanger: false,
    } as TablePaginationConfig;

    return (
        <>
            <PageContent title={title} className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Space>
                                <Button type='primary' onClick={handleSaveCombinacao}>Salvar</Button>
                                <Button type='default' onClick={() => setIsVisibleModalHistorico(true)}>Histórico de Alteração</Button>
                                <Button type='link' onClick={() => history.goBack()}>Voltar</Button>
                            </Space>
                        </div>
                        <Table size="small" rowKey={'chave'} columns={getColumns({ ...combinacao }.combinacaoItens, showModalEditStatus)} dataSource={props.combinacao.combinacaoItens} style={{ overflowY: 'auto' }} pagination={tablePaginationConfig} />
                    </>
                }
            </PageContent>

            <Modal title="Editar Status - Projeção de Venda" width={390} open={isVisibleModalEditStatus} onCancel={() => closeModalEditStatus()} footer={null}>
                <Space>
                    <Button type="default" onClick={() => handleEditStatus("Sim")}>Sim</Button>
                    <Button type="default" onClick={() => handleEditStatus("Não")}>Não</Button>
                    <Button type="default" onClick={() => handleEditStatus("Pós Venda")}>Pós Venda</Button>
                    <Button type="default" onClick={() => handleEditStatus("Verificar")}>Verificar</Button>
                </Space>
            </Modal>

            <Modal title="Histórico de Alterações" width={700} open={isVisibleModalHistorico} onCancel={() => setIsVisibleModalHistorico(false)} footer={null}>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                    <p style={{ width: 100 }}>Código</p>
                    <p style={{ width: 170 }}>Status Antigo</p>
                    <p style={{ width: 150 }}>Status Novo</p>
                    <p style={{ width: 130 }}>Data</p>
                    <p style={{ width: 200 }}>Usuário</p>
                </div>
                {
                    props.combinacao?.auditorias?.map(x =>
                        <div key={x.codigo} style={{ display: 'flex' }}>
                            <p style={{ width: 100 }}>{x.codigo}</p>
                            <p style={{ width: 170 }}>{x.oldStatus}</p>
                            <p style={{ width: 150 }}>{x.newStatus}</p>
                            <p style={{ width: 130 }}>{dateStringUStoPTBR(x.date)}</p>
                            <p style={{ width: 200 }}>{x.user.name}</p>
                        </div>
                    )
                }
            </Modal>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.projecaoVenda,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ICombinacaoForm;

export default connector(CombinacaoForm);