import { all, takeLatest } from "redux-saga/effects";
import { AtualizarPedidoActionKeys } from "./atualizarPedido/AtualizarPedido.actions";
import { AuthActionKeys } from "./auth/Auth.actions";
import { getUserInfo, loginSuccess, signIn } from "./auth/Auth.sagas";
import { ConfigActionKeys } from "./config/Config.actions";
import { getConfig, getStatusJobAtualizarBanco, getStatusFunctionAjustesTextosAPI, listCamposApi, listCamposIntegracao, startFunctionAtualizarBanco, startFunctionAjustesTextosAPI, updateConfig, getStatusFunctionAjustesTextosLancamentosAPI, startFunctionAjustesTextosLancamentosAPI, stopFunctionAjustesTextosLancamentosAPI, stopFunctionAjustesTextosAPI, startFunciontAtualizarBaseDadosPedido, getStatusFunctionAtualizarBaseDadosPedido, enableFunctionAtualizacaoBanco, disableFunctionAtualizacaoBanco } from "./config/Config.sagas";
import { PedidoImportadoActionKeys } from "./pedido/importador/PedidoImportado.actions";
import { getDataImportadorPedido, importarPedidoV2, limparPedido, list as listPedidosImportados, listPedidosNaoImportados, recuperarPedido, updatePedidoNaoImportado, listClienteIndustriaFull, importarPedido, analiseCliente, analiseGeral } from "./pedido/importador/PedidoImportado.sagas";
import { SvApiActionKeys } from "./svApi/SvApi.actions";
import { listClienteIndustria, listClientes as listClientesApi, listClienteTransportadora, listClienteVendedor, listContatosByCliente, listIndustrias as listIndustriasApi, listPedidoStatus as listPedidoStatusApi, listTabelasPreco, listTransportadoras, listTransportadorasByClienteId, listUsuarios, listUsuariosByCliente } from "./svApi/SvApi.sagas";
import { SvDbActionKeys } from "./svDb/SvDb.actions";
import { getUltimoPedido, listFormasPagamento, listPedidoStatus as listPedidoStatusDB, listIndustrias as listIndustriasDB, listUsuariosVendedores as listUsuariosVendedoresDB } from "./svDb/SvDb.sagas";
import { UserActionKeys } from "./user/User.actions";
import { deleteUser, listRoles, list as listUsers, registerUser, updatePassword, updateUser } from "./user/User.sagas";
import { detailAtualizacaoById, list as listAtualizarPedidos, listByItems, restaurarPedido, restaurarItemPedido, atualizarItensPedido, getAtualizarPedidoById } from "./atualizarPedido/AtualizarPedido.sagas";
import { ProdutoActionKeys } from "./produto/Produto.actions";
import { atualizarProduto, getAtualizarProduto, getAtualizarProdutoInDetail, listAtualizarProdutos, reiniciarAtualizarProduto } from "./produto/Produto.sagas";
import { ClienteActionKeys } from "./cliente/Cliente.actions";
import { listClientes, persistCategoriaCliente, listCategoriasCliente, deleteCategoriaCliente, listClientesIndustrias, getClienteIndustriaAuxFormById, updateClienteIndustriaAux, getClienteIndustriaAuxViewById, importarClienteIndustria, listCompradoresByClienteIndustriaId, listClienteIndustriaAuxAuditoria } from "./cliente/Cliente.sagas";
import { MetaActionKeys } from "./meta/Meta.actions";
import { getMeta, updateMeta, importarMeta } from './meta/Meta.sagas';
import { ProjecaoVendaActionKeys } from "./projecaoVenda/ProjecaoVenda.actions";
import { listCombinacoes, importarCombinacao, listModelos, deleteCombinacao, importarModelo, deleteModelo, getCombinacao, updateCombinacao, getModelo, updateModelo } from "./projecaoVenda/ProjecaoVenda.sagas";
import { SellOutActionKeys } from "./sellOut/SellOut.actions";
import { getSellOut, importarSellOut, updateSellOut } from "./sellOut/SellOut.sagas";
import { AcompanhamentoActionKeys } from "./acompanhamento/Acompanhamento.actions";
import { getAcompanhamento, importarAcompanhamento, updateAcompanhamento } from "./acompanhamento/Acompanhamento.sagas";
import { ContatoActionKeys } from "./contato/Contato.actions";
import { importarContatos, listContatos, updateContato } from "./contato/Contato.sagas";
import { ModuleActionKeys } from "./module/Module.actions";
import { listModules, updateModule } from "./module/Module.sagas";
import { TicketActionKeys } from "./ticket/Ticket.actions";
import { addTicketMessage, changeTicketStatus, deleteTicketAttachment, deleteTicketMessage, getTicket, getTicketByAcompanhamentoId, uploadAttachment } from "./ticket/Ticket.sagas";
import { NotificationActionKeys } from "./notification/Notification.actions";
import { listNotifications, readAllNotifications } from "./notification/Notification.sagas";

export function* rootSaga() {
    yield all([
        takeLatest(AuthActionKeys.AUTH_LOGIN_REQUEST, signIn),
        takeLatest(AuthActionKeys.AUTH_LOGIN_SUCCESS, loginSuccess),
        takeLatest(AuthActionKeys.GET_USER_INFO_REQUEST, getUserInfo),

        takeLatest(UserActionKeys.LIST_USERS_REQUEST, listUsers),
        takeLatest(UserActionKeys.USER_REGISTER_REQUEST, registerUser),
        takeLatest(UserActionKeys.USER_UPDATE_REQUEST, updateUser),
        takeLatest(UserActionKeys.USER_DELETE_REQUEST, deleteUser),
        takeLatest(UserActionKeys.USER_UPDATE_PASSWORD_REQUEST, updatePassword),
        takeLatest(UserActionKeys.LIST_ROLES_REQUEST, listRoles),

        takeLatest(PedidoImportadoActionKeys.LIST_REQUEST, listPedidosImportados),
        takeLatest(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_REQUEST, importarPedido),
        takeLatest(PedidoImportadoActionKeys.IMPORTAR_PEDIDO_V2_REQUEST, importarPedidoV2),
        takeLatest(PedidoImportadoActionKeys.LIMPAR_PEDIDO_REQUEST, limparPedido),
        takeLatest(PedidoImportadoActionKeys.RECUPERAR_PEDIDO_REQUEST, recuperarPedido),
        takeLatest(PedidoImportadoActionKeys.GET_DATA_IMPORTADOR_PEDIDO_REQUEST, getDataImportadorPedido),
        takeLatest(PedidoImportadoActionKeys.LIST_PEDIDOS_NAO_IMPORTADOS_REQUEST, listPedidosNaoImportados),
        takeLatest(PedidoImportadoActionKeys.UPDATE_PEDIDOS_NAO_IMPORTADOS_REQUEST, updatePedidoNaoImportado),
        takeLatest(PedidoImportadoActionKeys.LIST_CLIENTE_INDUSTRIA_FULL_REQUEST, listClienteIndustriaFull),
        takeLatest(PedidoImportadoActionKeys.GET_ANALISE_CLIENTE_REQUEST, analiseCliente),
        takeLatest(PedidoImportadoActionKeys.GET_ANALISE_GERAL_REQUEST, analiseGeral),

        takeLatest(SvApiActionKeys.LIST_INDUSTRIAS_REQUEST, listIndustriasApi),
        takeLatest(SvApiActionKeys.LIST_CLIENTES_REQUEST, listClientesApi),
        takeLatest(SvApiActionKeys.LIST_TABELAS_PRECO_REQUEST, listTabelasPreco),
        takeLatest(SvApiActionKeys.LIST_CLIENTE_INDUSTRIA_REQUEST, listClienteIndustria),
        takeLatest(SvApiActionKeys.LIST_CLIENTE_VENDEDOR_REQUEST, listClienteVendedor),
        takeLatest(SvApiActionKeys.LIST_TRANSPORTADORAS_REQUEST, listTransportadoras),
        takeLatest(SvApiActionKeys.LIST_CLIENTE_TRANSPORTADORA_REQUEST, listClienteTransportadora),
        takeLatest(SvApiActionKeys.LIST_PEDIDO_STATUS_REQUEST, listPedidoStatusApi),
        takeLatest(SvApiActionKeys.LIST_USUARIOS_REQUEST, listUsuarios),
        takeLatest(SvApiActionKeys.LIST_TRANSPORTADORAS_BY_CLIENTE_REQUEST, listTransportadorasByClienteId),
        takeLatest(SvApiActionKeys.LIST_USUARIOS_BY_CLIENTE_REQUEST, listUsuariosByCliente),
        takeLatest(SvApiActionKeys.LIST_CONTATOS_BY_CLIENTE_REQUEST, listContatosByCliente),

        takeLatest(SvDbActionKeys.LIST_FORMAS_PAGAMENTO_REQUEST, listFormasPagamento),
        takeLatest(SvDbActionKeys.LIST_PEDIDO_STATUS_REQUEST, listPedidoStatusDB),
        takeLatest(SvDbActionKeys.GET_ULTIMO_PEDIDO_REQUEST, getUltimoPedido),
        takeLatest(SvDbActionKeys.LIST_INDUSTRIAS_REQUEST, listIndustriasDB),
        takeLatest(SvDbActionKeys.LIST_USUARIOS_VENDEDORES_REQUEST, listUsuariosVendedoresDB),

        takeLatest(ConfigActionKeys.GET_CONFIG_REQUEST, getConfig),
        takeLatest(ConfigActionKeys.UPDATE_CONFIG_REQUEST, updateConfig),
        takeLatest(ConfigActionKeys.GET_JOB_ATUALIZAR_BANCO_REQUEST, getStatusJobAtualizarBanco),
        takeLatest(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_API_REQUEST, getStatusFunctionAjustesTextosAPI),
        takeLatest(ConfigActionKeys.START_ATUALIZAR_BANCO_REQUEST, startFunctionAtualizarBanco),
        takeLatest(ConfigActionKeys.START_ATUALIZAR_TEXTO_API_REQUEST, startFunctionAjustesTextosAPI),
        takeLatest(ConfigActionKeys.LIST_CAMPOS_API_REQUEST, listCamposApi),
        takeLatest(ConfigActionKeys.LIST_CAMPOS_INTEGRACAO_REQUEST, listCamposIntegracao),
        takeLatest(ConfigActionKeys.GET_JOB_ATUALIZAR_TEXTO_LANCAMENTOS_API_REQUEST, getStatusFunctionAjustesTextosLancamentosAPI),
        takeLatest(ConfigActionKeys.START_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST, startFunctionAjustesTextosLancamentosAPI),
        takeLatest(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_LANCAMENTO_API_REQUEST, stopFunctionAjustesTextosLancamentosAPI),
        takeLatest(ConfigActionKeys.STOP_ATUALIZAR_TEXTO_API_REQUEST, stopFunctionAjustesTextosAPI),
        takeLatest(ConfigActionKeys.START_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST, startFunciontAtualizarBaseDadosPedido),
        takeLatest(ConfigActionKeys.GET_JOB_ATUALIZAR_BASE_DADOS_PEDIDOS_REQUEST, getStatusFunctionAtualizarBaseDadosPedido),
        takeLatest(ConfigActionKeys.DISABLE_FUNCTION_REQUEST, disableFunctionAtualizacaoBanco),
        takeLatest(ConfigActionKeys.ENABLE_FUNCTION_REQUEST, enableFunctionAtualizacaoBanco),

        takeLatest(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_REQUEST, listAtualizarPedidos),
        takeLatest(AtualizarPedidoActionKeys.LIST_ATUALIZAR_PEDIDOS_ITEMS_REQUEST, listByItems),
        takeLatest(AtualizarPedidoActionKeys.DETAIL_ATUALIZACAO_PEDIDOS_REQUEST, detailAtualizacaoById),
        takeLatest(AtualizarPedidoActionKeys.RESTAURAR_PEDIDO_REQUEST, restaurarPedido),
        takeLatest(AtualizarPedidoActionKeys.RESTAURAR_ITEM_PEDIDO_REQUEST, restaurarItemPedido),
        takeLatest(AtualizarPedidoActionKeys.ATUALIZAR_ITENS_PEDIDO_REQUEST, atualizarItensPedido),
        takeLatest(AtualizarPedidoActionKeys.GET_ATUALIZAR_PEDIDOS_REQUEST, getAtualizarPedidoById),

        takeLatest(ProdutoActionKeys.ATUALIZAR_PRODUTO_REQUEST, atualizarProduto),
        takeLatest(ProdutoActionKeys.LIST_ATUALIZAR_PRODUTOS_REQUEST, listAtualizarProdutos),
        takeLatest(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_IN_DETAIL_REQUEST, getAtualizarProdutoInDetail),
        takeLatest(ProdutoActionKeys.GET_ATUALIZAR_PRODUTO_REQUEST, getAtualizarProduto),
        takeLatest(ProdutoActionKeys.REINICIAR_ATUALIZAR_PRODUTO_REQUEST, reiniciarAtualizarProduto),

        takeLatest(ClienteActionKeys.LIST_CLIENTES_INDUSTRIAS_REQUEST, listClientesIndustrias),
        takeLatest(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_REQUEST, getClienteIndustriaAuxFormById),
        takeLatest(ClienteActionKeys.LIST_CATEGORIAS_CLIENTE_REQUEST, listCategoriasCliente),
        takeLatest(ClienteActionKeys.PERSIST_CATEGORIA_CLIENTE_REQUEST, persistCategoriaCliente),
        takeLatest(ClienteActionKeys.DELETE_CATEGORIA_CLIENTE_REQUEST, deleteCategoriaCliente),
        takeLatest(ClienteActionKeys.UPDATE_CLIENTE_INDUSTRIA_AUX_REQUEST, updateClienteIndustriaAux),
        takeLatest(ClienteActionKeys.GET_CLIENTE_INDUSTRIA_VIEW_REQUEST, getClienteIndustriaAuxViewById),
        takeLatest(ClienteActionKeys.IMPORTAR_CLIENTE_INDUSTRIA_REQUEST, importarClienteIndustria),
        takeLatest(ClienteActionKeys.LIST_COMPRADORES_CLIENTE_INDUSTRIA_REQUEST, listCompradoresByClienteIndustriaId),
        takeLatest(ClienteActionKeys.LIST_CLIENTE_INDUSTRIA_AUX_AUDITORIA_REQUEST, listClienteIndustriaAuxAuditoria),
        takeLatest(ClienteActionKeys.LIST_CLIENTES_REQUEST, listClientes),

        takeLatest(MetaActionKeys.GET_META_REQUEST, getMeta),
        takeLatest(MetaActionKeys.UPDATE_META_REQUEST, updateMeta),
        takeLatest(MetaActionKeys.IMPORTAR_META_REQUEST, importarMeta),

        takeLatest(ProjecaoVendaActionKeys.LIST_COMBINACOES_REQUEST, listCombinacoes),
        takeLatest(ProjecaoVendaActionKeys.IMPORTAR_COMBINACAO_REQUEST, importarCombinacao),
        takeLatest(ProjecaoVendaActionKeys.LIST_MODELOS_REQUEST, listModelos),
        takeLatest(ProjecaoVendaActionKeys.COMBINACAO_DELETE_REQUEST, deleteCombinacao),
        takeLatest(ProjecaoVendaActionKeys.IMPORTAR_MODELO_REQUEST, importarModelo),
        takeLatest(ProjecaoVendaActionKeys.MODELO_DELETE_REQUEST, deleteModelo),
        takeLatest(ProjecaoVendaActionKeys.GET_COMBINACAO_REQUEST, getCombinacao),
        takeLatest(ProjecaoVendaActionKeys.UPDATE_COMBINACAO_REQUEST, updateCombinacao),
        takeLatest(ProjecaoVendaActionKeys.GET_MODELO_REQUEST, getModelo),
        takeLatest(ProjecaoVendaActionKeys.UPDATE_MODELO_REQUEST, updateModelo),

        takeLatest(SellOutActionKeys.GET_SELL_OUT_REQUEST, getSellOut),
        takeLatest(SellOutActionKeys.UPDATE_SELL_OUT_REQUEST, updateSellOut),
        takeLatest(SellOutActionKeys.IMPORTAR_SELL_OUT_REQUEST, importarSellOut),

        takeLatest(AcompanhamentoActionKeys.GET_ACOMPANHAMENTO_REQUEST, getAcompanhamento),
        takeLatest(AcompanhamentoActionKeys.UPDATE_ACOMPANHAMENTO_REQUEST, updateAcompanhamento),
        takeLatest(AcompanhamentoActionKeys.IMPORTAR_ACOMPANHAMENTO_REQUEST, importarAcompanhamento),

        takeLatest(ContatoActionKeys.GET_CONTATO_REQUEST, listContatos),
        takeLatest(ContatoActionKeys.UPDATE_CONTATO_REQUEST, updateContato),
        takeLatest(ContatoActionKeys.IMPORTAR_CONTATO_REQUEST, importarContatos),

        takeLatest(ModuleActionKeys.GET_MODULES_REQUEST, listModules),
        takeLatest(ModuleActionKeys.UPDATE_MODULES_REQUEST, updateModule),

        takeLatest(TicketActionKeys.GET_TICKET_REQUEST, getTicket),
        takeLatest(TicketActionKeys.GET_TICKET_ACOMPANHAMENTO_REQUEST, getTicketByAcompanhamentoId),
        takeLatest(TicketActionKeys.CHANGE_STATUS_REQUEST, changeTicketStatus),
        takeLatest(TicketActionKeys.ADD_MESSAGE_REQUEST, addTicketMessage),
        takeLatest(TicketActionKeys.DELETE_MESSAGE_REQUEST, deleteTicketMessage),
        takeLatest(TicketActionKeys.UPLOAD_ATTACHMENT_REQUEST, uploadAttachment),
        takeLatest(TicketActionKeys.DELETE_ATTACHMENT_REQUEST, deleteTicketAttachment),

        takeLatest(NotificationActionKeys.LIST_NOTIFICATIONS_REQUEST, listNotifications),
        takeLatest(NotificationActionKeys.READ_ALL_NOTIFICATIONS_REQUEST, readAllNotifications),
    ]);
}