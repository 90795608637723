import { Button, Skeleton } from "antd";
import React, { FC, useState } from "react";
import PageContent from '../../components/ui/pageContent';
import { connect, ConnectedProps } from 'react-redux';
import { IGlobalReducerState } from "../../store/base/interface/IGlobalReducerState";
import styles from './Metas.module.scss';
import { history } from "../../store";
import ImportadorMetas from "./modals/importadorMetas";

const Metas: FC<Props> = (props) => {
    const [isVisibleImportador, setIsVisibleImportador] = useState(false);

    return (
        <>
            <PageContent title='Metas' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' onClick={() => history.push('/metas/form')}>Atualizar Metas</Button>
                            <Button onClick={() => setIsVisibleImportador(true)} type='primary' className='btn-green'>Importar Metas</Button>
                        </div>
                        <div style={{ width: '100%', textAlign: 'center', paddingTop: 20 }}>
                            <iframe title="Metas - Meta Valor" style={{ width: 'calc(100vw - 350px)', height: 'calc(100vh - 200px)', border: 0 }} src="https://app.powerbi.com/view?r=eyJrIjoiN2EzMTI2ZTYtODQwMS00OTA4LTkwNmYtNGI1ODI3YjgyZThmIiwidCI6IjYxZGY0YTNlLTcwNTUtNDRkZS05NjNlLTc0Mzg5NTdhNTQyNyJ9&pageName=ReportSection0834cfffd4a6d423b3bb" allowFullScreen={true}></iframe>
                        </div>
                    </>
                }
            </PageContent>
            {isVisibleImportador ?
                <ImportadorMetas visible={isVisibleImportador} onClose={() => setIsVisibleImportador(false)}></ImportadorMetas>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(Metas);