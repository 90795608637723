import { Button, List, Progress, Skeleton } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import FieldForm from "../../../../../components/ui/field";
import SelectForm, { IOptionData } from "../../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../../store/base/interface/IGlobalReducerState";
import schema from "./ImportadorPedido.schema";
import styles from './ImportadorPedido.module.scss';
import { getOptionsDataFromObject } from "../../../../../utils/helpers";
import { toast } from "react-toastify";
import { IImportarPedidoRequest } from "../../../../../data/interfaces/pedido/importador/IImportarPedidoRequest";
import { PedidoImportadoActions } from "../../../../../store/pedido/importador/PedidoImportado.actions";
import { secondsToMs } from "../../../../../utils/dateHelper";
import { v4 as uuidv4 } from 'uuid';
import TextArea from "antd/lib/input/TextArea";

export interface IImportadorPedido {
    visible: boolean;
    onClose: () => void;
    isNovoPedido: boolean;
}

interface formValues {
    clienteId?: number,
    industriaId?: number,
    tabelaPrecoId?: number,
    condicaoPagamento?: string,
    transportadoraId?: number,
    freteId?: number,
    vendedorId?: number,
    statusId?: string,
    isNovoPedido: boolean,
    pedidoId?: number
}

const ImportadorPedido: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const titleModal = props.isNovoPedido ? 'Importar Pedido Novo' : 'Importar Pedido Existente';
    const [clientesOptions, setClientesOptions] = useState<IOptionData[]>([]);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);
    const [tabelasPrecoOptions, setTabelasPrecoOptions] = useState<IOptionData[]>([]);
    const [condicoesPagamentoOptions, setCondicoesPagamentoOptions] = useState<IOptionData[]>([]);
    const [transportadorasOptions, setTransportadorasOptions] = useState<IOptionData[]>([]);
    const [vendedorOptions, setVendedorOptions] = useState<IOptionData[]>([]);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeImportation, setTimeImportation] = useState(0);
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [pedidoId, setPedidoId] = useState<number>(0);
    const [statusLimparPedido, setStatusLimparPedido] = useState<'parado' | 'limpandoPedido' | 'recuperandoPedido' | 'concluido'>('parado');

    var pedidoStatusOptions = (getOptionsDataFromObject(props.pedidoStatusDB, 'pedidoStatusId', 'pedidoStatusNome'));
    var fretesOptions = ([{ value: 0, text: 'CIF' }, { value: 1, text: 'FOB' }] as IOptionData[]);

    useEffect(() => {
        if (props.clientes && props.clientes?.length > 0) {
            setClientesOptions(getOptionsDataFromObject(props.clientes, 'clienteId', 'clienteRazaoSocial'));
        }
    }, [props.clientes])

    useEffect(() => {
        if (isImporting) {
            setTimeout(() => {
                setTimeImportation(timeImportation + 1);
            }, 1000);
        }
    }, [timeImportation, isImporting])

    useEffect(() => {
        if (!props.isLoading && isImporting) {

            if (props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsImporting(false);
        }

        if (!props.isLoading && statusLimparPedido == 'limpandoPedido') {
            setStatusLimparPedido('concluido');
        }

        if (!props.isLoading && statusLimparPedido == 'recuperandoPedido') {
            setStatusLimparPedido('parado');
        }
    }, [props.isLoading]);

    const onSelectCliente = (clienteId: number) => {

        setValues(
            {
                ...values,
                pedidoId: pedidoId > 0 ? pedidoId : undefined,
                isNovoPedido: props.isNovoPedido,
                clienteId: clienteId,
                industriaId: undefined,
                transportadoraId: undefined,
                vendedorId: undefined,
                tabelaPrecoId: undefined,
                condicaoPagamento: undefined
            });

        setIndustriasOptions(getOptionsDataFromObject(
            props.config.confFiltrarClienteXIndustria ?
                props.industrias?.filter((industria) => {
                    var index = props?.clienteXIndustria?.findIndex(cXi => cXi?.clieIndustriaClienteId == clienteId && cXi?.clieIndustriaIndustriaId == industria?.industriaId);
                    return index === 0 || (index && index > -1);
                })
                :
                props.industrias,
            'industriaId', 'industriaNomeFantasia'));

        setTransportadorasOptions(getOptionsDataFromObject(
            props.config.confFiltrarClienteXTransportadora ?
                props.transportadoras?.filter((transportadora) => {
                    var index = props?.clienteXTransportadora?.findIndex(cXt => cXt?.clieTransportadoraClienteId == clienteId && cXt?.clieTransportadoraTransportadoraId == transportadora?.transportadoraId);
                    return index === 0 || (index && index > -1);
                })
                :
                props.transportadoras,
            'transportadoraId', 'transportadoraNomeFantasia'));

        setVendedorOptions(getOptionsDataFromObject(
            props.config.confFiltrarClienteXVendedor ?
                props.usuarios?.filter((usuario) => {
                    var index = props?.clienteXVendedor?.findIndex(cXv => cXv?.clieVendedorClienteId == clienteId && cXv?.clieVendedorUsuarioVendedorId == usuario?.idSuasVendas);
                    return index === 0 || (index && index > -1);
                })
                :
                props.usuarios,
            'idSuasVendas', 'name'));
    }

    const onSelectIndustria = (industriaId: number) => {

        setValues(
            {
                ...values,
                industriaId: industriaId,
                tabelaPrecoId: undefined,
                condicaoPagamento: undefined
            });

        setTabelasPrecoOptions(getOptionsDataFromObject(
            props.tabelasPreco?.filter((tabelaPreco) => tabelaPreco.tabelaPrecoIndustriaId == industriaId),
            'tabelaPrecoId', 'tabelaPrecoNome'));


        var formasPagamentoClientesOptions = getOptionsDataFromObject(
            props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == industriaId && formaPagamento.clientesId.includes(values.clienteId ?? -1)),
            'formaPagamentoNome', 'formaPagamentoNome');
        formasPagamentoClientesOptions.forEach(x => { x.text += ' (CLIENTE)'; x.style = { color: 'blue' }; });

        var formasPagamentoIndustriasOptions = getOptionsDataFromObject(
            props.formasPagamento?.filter((formaPagamento) => formaPagamento.formaPagamentoIndustriaId == industriaId && !formaPagamento.clientesId.includes(values.clienteId ?? -1)),
            'formaPagamentoNome', 'formaPagamentoNome');
        formasPagamentoIndustriasOptions.forEach(x => { x.text += ' (INDÚSTRIA)'; x.style = { color: '#d87724' }; });

        setCondicoesPagamentoOptions([...formasPagamentoClientesOptions, ...formasPagamentoIndustriasOptions]);
    }

    const onAddFormaPagamento = (e: any) => {

        if (condicoesPagamentoOptions.findIndex(x => x.value.toString().toLowerCase() == e.toLowerCase()) > -1) {
            toast.error('Condição de Pagamento já existente');
        }
        else {
            var newItem = {
                value: e,
                text: `${e} (NOVO)`,
                style: { color: 'green' },
                info: { isNovaFormaPagamento: true }
            } as IOptionData;

            setCondicoesPagamentoOptions([newItem, ...condicoesPagamentoOptions]);

            setValues({
                ...values,
                condicaoPagamento: e
            });
        }
    }

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        setStatusLimparPedido('parado');
        setPedidoId(0);
        dispatch(PedidoImportadoActions.importarPedidoClear());
        props.onClose();
    }

    const limparPedido = () => {
        if (pedidoId == 0) {
            toast.error('Informe o Número do Pedido');
        }
        else {
            dispatch(PedidoImportadoActions.limparPedido(pedidoId));
            setStatusLimparPedido('limpandoPedido');
        }
    }

    const recuperarPedido = () => {
        if (pedidoId == 0) {
            toast.error('Informe o Número do Pedido');
        }
        else {
            dispatch(PedidoImportadoActions.recuperarPedido(pedidoId));
            setStatusLimparPedido('recuperandoPedido');
        }
    }

    const submit = (values: any) => {
        if (values) {
            if (file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {
                var data = {
                    file: file,
                    data: values,
                    isNovaFormaPagamento: condicoesPagamentoOptions.find(x => x.value == values.condicaoPagamento)?.info?.isNovaFormaPagamento ?? false
                } as IImportarPedidoRequest;

                var pedidoGuid = uuidv4();
                data.data.GUIDImportacao = pedidoGuid;

                dispatch(PedidoImportadoActions.importarPedidoInfoCreate(pedidoGuid));
                dispatch(PedidoImportadoActions.importarPedido(data));
                setIsImporting(true);
                setShowForm(false);
                setValues(values);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(PedidoImportadoActions.importarPedidoClear());
        setFile(null);
    }

    const downloadArquivoPadrao = () => {
        window.open(`${process.env.REACT_APP_BASE_API_URL}/PedidoImportado/DownloadArquivoPadrao`);
    }

    const buttons =
        [
            <Button hidden={!showForm} key='downloadFile' type='link' style={{ position: 'absolute', left: 10 }} onClick={downloadArquivoPadrao}>Baixar arquivo padrão</Button>,
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} disabled={!props.isNovoPedido && statusLimparPedido != 'concluido'} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosImportacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const importForm =
        <Form onSubmit={submit} isSubmited={isSubmit} schema={schema} initialValues={values} className={styles['form']}>
            <FieldForm type='hidden' name='isNovoPedido'></FieldForm>
            <div className={styles['groupSelect']} style={{ display: "flex" }} hidden={props.isNovoPedido}>
                <FieldForm readonly={statusLimparPedido != 'parado'} type='number' name='pedidoId' label='Número do Pedido' placeholder={'Número do Pedido'} onInput={(e) => setPedidoId(e ? parseInt(e) : 0)} />
                <Button loading={props.isLoading} hidden={!(statusLimparPedido == 'parado' || statusLimparPedido == 'limpandoPedido')} type='primary' danger onClick={() => limparPedido()}>{'Limpar Pedido'}</Button>
                <Button loading={props.isLoading} hidden={!(statusLimparPedido == 'concluido' || statusLimparPedido == 'recuperandoPedido')} type='link' onClick={() => recuperarPedido()}>Recuperar Pedido</Button>
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='clienteId' label='Cliente' options={clientesOptions} placeholder={'Selecione um Cliente'} className={styles['select']} onSelect={onSelectCliente} />
                <SelectForm onSelect={onSelectIndustria} name='industriaId' label='Indústria' options={industriasOptions} placeholder={values.clienteId ? 'Selecione uma Indústria' : 'Selecione um Cliente'} disabled={!values.clienteId} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='tabelaPrecoId' label='Tabela de Preço' options={tabelasPrecoOptions} placeholder={values.industriaId ? 'Selecione uma Tabela de Preço' : 'Selecione uma Indústria'} disabled={!values.industriaId} className={styles['select']} />
                <SelectForm name='condicaoPagamento' showAddItem={true} onAddItem={onAddFormaPagamento} label='Condição de Pagamento' options={condicoesPagamentoOptions} placeholder={values.industriaId ? 'Selecione uma Condição de Pagamento' : 'Selecione um Indústria'} disabled={!values.industriaId} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='transportadoraId' label='Transportadora' options={transportadorasOptions} placeholder={values.clienteId ? 'Selecione uma Transportadora' : 'Selecione um Cliente'} disabled={!values.clienteId} className={styles['select']} />
                <SelectForm name='freteId' label='Frete' options={fretesOptions} placeholder={'Selecione um Frete'} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <SelectForm name='vendedorId' label='Vendedor' options={vendedorOptions} placeholder={values.clienteId ? 'Selecione um Vendedor' : 'Selecione um Cliente'} disabled={!values.clienteId} className={styles['select']} />
                <SelectForm name='statusId' label='Status' options={pedidoStatusOptions} placeholder={'Selecione um Status'} className={styles['select']} />
            </div>
            <div className={styles['groupSelect']}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportadorPedido')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportadorPedido' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    var percentProgress = 0;
    var messageImportacao = <p>Importando Pedido - (0%) <span>{secondsToMs(timeImportation)}</span></p>;

    if (!props.pedidoImportadoInfo?.qtdeTotalItens || props.pedidoImportadoInfo?.qtdeTotalItens == 0) {
        percentProgress = 5;
        messageImportacao = <p>Iniciando Importação - (5%) <span>{secondsToMs(timeImportation)}</span></p>;
    }
    else if (props.pedidoImportadoInfo?.qtdeTotalItens && props.pedidoImportadoInfo?.qtdeTotalItens > 0 && props.pedidoImportadoInfo?.qtdeItensImportado == props.pedidoImportadoInfo?.qtdeTotalItens) {
        percentProgress = 95;
        messageImportacao = <p>Finalizando Importação - (95%) <span>{secondsToMs(timeImportation)}</span></p>;
    }
    else if (props.pedidoImportadoInfo?.qtdeItensImportado && props.pedidoImportadoInfo?.qtdeTotalItens) {
        percentProgress = parseFloat((((props.pedidoImportadoInfo?.qtdeItensImportado / props.pedidoImportadoInfo?.qtdeTotalItens) * 90) + 5).toFixed(2));
        messageImportacao = <p>Importando Itens do Pedido - {props.pedidoImportadoInfo?.qtdeItensImportado}/{props.pedidoImportadoInfo?.qtdeTotalItens} ({percentProgress}%) - <span>{secondsToMs(timeImportation)}</span></p>;
    }

    var logImportacao = props.pedidoImportadoInfo?.infos?.map((info: string) => info).join('\r\n');

    const infoImportation = <div className={styles['infoImportation']} id='infoImportation'>
        {messageImportacao}
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={percentProgress}
            showInfo={false}
            status="active"
        />
        <div>
            <TextArea id='logImportacao' rows={6} value={logImportacao} disabled={true} style={{ marginTop: 20, color: '#483E41' }} />
        </div>
    </div>;

    var textarea = document.getElementById('logImportacao');

    if (textarea)
        textarea.scrollTop = textarea.scrollHeight;

    return (
        <Modal title={titleModal} open={props.visible} footer={!isImporting ? buttons : null} closable={false} destroyOnClose={true} width={858}>
            <div className={styles['container']}>
                {
                    props.isLoadingDataImportadorPedido ?
                        <Skeleton active />
                        :
                        <>
                            {isImporting ?
                                infoImportation :
                                (
                                    !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                        errosImportacao
                                        : importForm
                                )}
                        </>
                }
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.user,
    ...state.svApi,
    ...state.config,
    ...state.svDb,
    ...state.pedidoImportado
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IImportadorPedido;

export default connector(ImportadorPedido);