import { ColumnProps } from "antd/lib/table";
import { getDistinctFilterValues, sorter, getFilterDateProps, getFilterSearchProps } from "../../../utils/helpers";
import { ICampoApi } from "../../../data/interfaces/config/ICampoApi";
import moment from "moment";
import { dateStringUStoPTBR } from "../../../utils/dateHelper";

export const getColumns = (camposApi: ICampoApi[]) => {
    return [
        {
            title: 'Tabela',
            dataIndex: 'nomeTabela',
            sorter: (a, b) => sorter(a.nomeTabela, b.nomeTabela),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            filters: getDistinctFilterValues(camposApi, 'nomeTabela'),
            onFilter: (value: string, record) => record.nomeTabela.indexOf(value) === 0,
            defaultSortOrder: 'ascend',
            render: (value: string) => {
                return value.toUpperCase();
            }
        },
        {
            title: 'Nome',
            dataIndex: 'nomeCampo',
            sorter: (a, b) => sorter(a.nomeCampo, b.nomeCampo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('nomeCampo'),
            render: (value: string) => {
                return value.toUpperCase();
            }
        },
        {
            title: 'Data',
            dataIndex: 'dataCriacao',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.dataCriacao, b.dataCriacao),
            showSorterTooltip: false,
            ...getFilterDateProps('dataCriacao', [moment().add(-5, 'y'), moment()]),
            render: (value: any, register: ICampoApi) => {
                return `${dateStringUStoPTBR(register.dataCriacao)}`;
            }
        },
        {
            title: 'API Nova',
            dataIndex: 'apiNova',
            sorter: (a, b) => sorter(a.apiNova, b.apiNova),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            render: (value) => {
                return value == true ? 'Sim' : 'Não'
            }
        },
    ] as Array<ColumnProps<ICampoApi>>;
}