import React from "react";
import { ColumnProps } from "antd/lib/table";
import { getFilterSearchProps, getDistinctFilterValues, sorter, URL_PEDIDO_SV, getFilterDateProps } from "../../../utils/helpers";
import { IPedidoImportadoResponse } from "../../../data/interfaces/pedido/importador/IPedidoImportadoResponse";
import { dateTimeStringUStoPTBR } from "../../../utils/dateHelper";
import { Button } from "antd";
import moment from "moment";
import styles from './PedidosImportados.module.scss';
import { numberWithSeparators } from "../../../utils/numberHelper";
import { IModuleColumnUser } from "../../../data/interfaces/module/IModuleColumnUser";

const openPedidoSV = (id64: string) => {
    window.open(URL_PEDIDO_SV + id64);
}

export const getColumns = (columns: IModuleColumnUser[], pedidosImportados: IPedidoImportadoResponse[], qtdeItens: number, qtdePecas: number, analisarPedido: (pedidoNumero: number) => void) => {

    var dateCurrent = new Date();
    var dayFilter = dateCurrent.getDate() >= 10 ? dateCurrent.getDate() : '0' + dateCurrent.getDate();
    var monthFilter = dateCurrent.getMonth() == 0 ? 12 : (dateCurrent.getMonth() >= 10 ? dateCurrent.getMonth() : '0' + dateCurrent.getMonth());
    var yearFilter = dateCurrent.getMonth() == 0 ? dateCurrent.getFullYear() - 1 : dateCurrent.getFullYear();

    var monthWith30 = [4, 6, 9, 11];

    if (dateCurrent.getMonth() == 2 && dateCurrent.getDate() > 28) {
        dayFilter = '28';
    }
    else if (monthWith30.includes(dateCurrent.getMonth()) && dateCurrent.getDate() > 30) {
        dayFilter = '30';
    }

    /**********/

    var visibleColumns = [] as Array<ColumnProps<IPedidoImportadoResponse>>;

    var columnsAux = [
        {
            title: 'Número',
            dataIndex: 'pediNumero',
            sorter: (a, b) => sorter(a.pediNumero, b.pediNumero),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            width: 120,
            ...getFilterSearchProps('pediNumero', 'Pedido'),
            render: (text: string, record: IPedidoImportadoResponse) => (
                <>
                    {
                        record.isImportedNow ?
                            <div className={styles['isImportedNow']}>&nbsp;</div>
                            : null
                    }
                    <Button type='link' size='small' onClick={() => openPedidoSV(record.pediNumero64)}>{text}</Button>
                </>
            )
        },
        {
            title: 'Analisar',
            dataIndex: 'pediNumero',
            render: (text: string, record: IPedidoImportadoResponse) => (
                <Button type='link' size='small' onClick={() => analisarPedido(record.pediNumero ?? 0)}>Analisar</Button>
            )
        },
        {
            title: 'Cliente',
            dataIndex: 'pediNomeCliente',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediNomeCliente, b.pediNomeCliente),
            showSorterTooltip: false,
            ...getFilterSearchProps('pediNomeCliente', 'Pedido'),
        },
        {
            title: 'Indústria',
            dataIndex: 'pediNomeIndustria',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediNomeIndustria, b.pediNomeIndustria),
            showSorterTooltip: false,
            filters: getDistinctFilterValues(pedidosImportados, 'pediNomeIndustria'),
            onFilter: (value: string, record) => { return record.pediNomeIndustria.indexOf(value) === 0 },
        },
        {
            title: 'Data/Hora',
            dataIndex: 'pediData',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediData, b.pediData),
            defaultSortOrder: 'descend',
            showSorterTooltip: false,
            ...getFilterDateProps('pediData', [moment(`${yearFilter}-${monthFilter}-${dayFilter}T00:00:00`), moment()]),
            render: (value: any, register: IPedidoImportadoResponse) => {
                return `${dateTimeStringUStoPTBR(register.pediData)}`;
            }
        },
        {
            title: 'Tempo',
            dataIndex: 'pediTempoImportacao',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediTempoImportacao.replace(':', ''), b.pediTempoImportacao.replace(':', '')),
            showSorterTooltip: false
        },
        {
            title: 'Tipo',
            dataIndex: 'pediTipo',
            width: 100,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediTipo, b.pediTipo),
            showSorterTooltip: false,
            filters: getDistinctFilterValues(pedidosImportados, 'pediTipo'),
            onFilter: (value: string, record) => record.pediTipo?.indexOf(value) === 0
        },
        {
            title: `Q.Itens (${numberWithSeparators(qtdeItens)})`,
            dataIndex: 'pediQtdeItens',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediQtdeItens, b.pediQtdeItens),
            showSorterTooltip: false,
        },
        {
            title: `Q.Peças (${numberWithSeparators(qtdePecas)})`,
            dataIndex: 'pediQtdePecas',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => sorter(a.pediQtdePecas, b.pediQtdePecas),
            showSorterTooltip: false,
        },
        {
            title: 'Usuário',
            dataIndex: 'usuarioNome',
            filters: getDistinctFilterValues(pedidosImportados, 'usuarioNome'),
            onFilter: (value: string, record) => record.usuarioNome?.indexOf(value) === 0,
            sorter: (a, b) => sorter(a.usuarioNome, b.pediUsuaId),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
        },
        {
            title: 'Arquivo',
            className: styles['nomeArquivo'],
            dataIndex: 'pediNomeArquivo',
            sorter: (a, b) => sorter(a.pediNomeArquivo, b.pediNomeArquivo),
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false,
            ...getFilterSearchProps('pediNomeArquivo', 'Pedido'),
        },
    ] as Array<ColumnProps<IPedidoImportadoResponse>>;

    columns?.forEach(c => {
        if (c.display) {
            var item = columnsAux.find(x => x.title?.toString().startsWith(c.name));
            visibleColumns.push(item ?? {});
        }
    });

    return visibleColumns;
}