import { Button, List, Progress, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import Form from "../../../../components/ui/form";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import SelectForm, { IOptionData } from "../../../../components/ui/selectForm";
import { IGlobalReducerState } from "../../../../store/base/interface/IGlobalReducerState";
import styles from './ImportadorClienteIndustria.module.scss';
import { toast } from "react-toastify";
import { secondsToMs } from "../../../../utils/dateHelper";
import { SvApiActions } from "../../../../store/svApi/SvApi.actions";
import { ClienteActions } from "../../../../store/cliente/Cliente.actions";

export interface ImportadorClienteIndustria {
    visible: boolean;
    onClose: () => void;
}

interface formValues {
    industriaId?: number
}

const ImportadorClienteIndustria: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [values, setValues] = useState({} as formValues);
    const [file, setFile] = useState<File | null>(null);
    const [timeUpdating, setTimeUpdating] = useState(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(true);
    const [industriasOptions, setIndustriasOptions] = useState<IOptionData[]>([]);

    useEffect(() => {
        dispatch(SvApiActions.listIndustrias());
    }, []);

    useEffect(() => {
        console.log('signalr', props.clienteIndustriaImportadorInfo);
    }, [props.clienteIndustriaImportadorInfo]);

    useEffect(() => {
        if (isUpdating) {
            setTimeout(() => {
                setTimeUpdating(timeUpdating + 1);
            }, 1000);
        }
    }, [timeUpdating, isUpdating])

    useEffect(() => {
        if (!props.isLoading && isUpdating) {
            if (!props.errosImportacao || props.errosImportacao?.length == 0) {
                onClose();
                setShowForm(true);
            }

            setIsUpdating(false);
        }
    }, [props.isLoading])

    const onSelectFile = (e: FileList | null) => {
        if (e) {
            setFile(e[0]);
        }
    }

    const onClose = () => {
        setValues({} as formValues);
        setFile(null);
        dispatch(ClienteActions.importarClienteIndustriaClear());
        props.onClose();
    }

    const submit = (values: any) => {
        if (values) {
            if (file == null) {
                toast.error('Selecione um arquivo para importar');
            }
            else {
                dispatch(ClienteActions.importarClienteIndustria(file));
                setIsUpdating(true);
                setShowForm(false);
                setValues(values);
            }
        }
        setIsSubmit(false);
    }

    const removeFile = () => {
        dispatch(ClienteActions.importarClienteIndustriaClear());
        setFile(null);
    }

    const downloadArquivoPadrao = () => {
        window.open(`${process.env.REACT_APP_BASE_API_URL}/Cliente/DownloadArquivoPadrao`);
    }

    const buttons =
        [
            <Button hidden={!showForm} key='downloadFile' type='link' style={{ position: 'absolute', left: 10 }} onClick={downloadArquivoPadrao}>Baixar arquivo padrão</Button>,
            <Button hidden={!showForm} loading={props.isLoading} key="cancel" onClick={onClose}>Cancelar</Button>,
            <Button hidden={!(showForm && props.errosImportacao && props.errosImportacao?.length > 0)} key="erros" danger onClick={() => setShowForm(false)}>Ver Erros</Button>,
            <Button hidden={!showForm} key="submit" type="primary" loading={props.isLoading} onClick={() => setIsSubmit(true)}>{'Importar'}</Button>,
            <Button hidden={showForm} key="back" type="primary" loading={props.isLoading} onClick={() => setShowForm(true)}>{'Voltar para Importação'}</Button>
        ];

    const errosAtualizacao = <List
        header={<b>Erros da Importação</b>}
        bordered
        dataSource={props.errosImportacao}
        renderItem={item => (
            <List.Item>
                {item.message}
            </List.Item>
        )}
    />;

    const updateForm =
        <Form onSubmit={submit} isSubmited={isSubmit} initialValues={values} className={styles['form']}>
            <div className={styles['groupSelect']} style={{ display: "flex" }}>
                <div className={styles['upload']}>
                    {
                        file ?
                            <div className={styles['fileSelected']}>
                                <div className={styles['fileName']}>{file.name}</div>
                                <div title='Remover arquivo' className={styles['clearFile']} onClick={removeFile} >X</div>
                            </div>
                            :
                            <div className={styles['selectFile']} onClick={() => document.getElementById('fileImportarClienteIndustria')?.click()}>Selecionar Arquivo</div>
                    }
                    <input type='file' id='fileImportarClienteIndustria' hidden onChange={(e) => onSelectFile(e.currentTarget.files)} />
                </div>
            </div>
        </Form>;

    const infoAtualizacao = <div className={styles['infoUpdating']} id='infoUpdating'>
        <p>Importando Cliente/Indústria</p>
        <span>{props.clienteIndustriaImportadorInfo}</span>
        <Progress
            strokeColor={{
                from: '#108ee9',
                to: '#87d068',
            }}
            percent={100}
            showInfo={false}
            status="active"
        />
    </div>;

    return (
        <Modal title={'Importação de Cliente/Indústria'} open={props.visible} footer={!isUpdating ? buttons : null} closable={false} destroyOnClose={true} width={(!showForm && props.errosImportacao && props.errosImportacao.length > 0) || isUpdating ? 750 : 450}>
            <div className={styles['container']}>
                {
                    isUpdating ?
                        infoAtualizacao :
                        (
                            !showForm && props.errosImportacao && props.errosImportacao.length > 0 ?
                                errosAtualizacao
                                : updateForm
                        )}
            </div>
        </Modal>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & ImportadorClienteIndustria;

export default connector(ImportadorClienteIndustria);