import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { SVAPIApi } from "../../data/SVAPI.api";
import { ListContatosByClienteAction, ListTransportadorasByClienteAction, ListUsuariosByClienteAction, SvApiActions } from "./SvApi.actions";

export function* listIndustrias() {
    try {
        const { data } = yield call(SVAPIApi.listIndustrias);
        yield put(SvApiActions.listIndustriasSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listIndustriasFailure(error));
    }
}

export function* listClientes() {
    try {
        const { data } = yield call(SVAPIApi.listClientes);
        yield put(SvApiActions.listClientesSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listClientesFailure(error));
    }
}

export function* listTabelasPreco() {
    try {
        const { data } = yield call(SVAPIApi.listTabelasPreco);
        yield put(SvApiActions.listTabelasPrecoSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listTabelasPrecoFailure(error));
    }
}

export function* listClienteIndustria() {
    try {
        const { data } = yield call(SVAPIApi.listClienteXIndustria);
        yield put(SvApiActions.listClienteIndustriaSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listClienteIndustriaFailure(error));
    }
}

export function* listClienteVendedor() {
    try {
        const { data } = yield call(SVAPIApi.listClienteXVendedor);
        yield put(SvApiActions.listClienteVendedorSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listClienteVendedorFailure(error));
    }
}

export function* listTransportadoras() {
    try {
        const { data } = yield call(SVAPIApi.listTransportadoras);
        yield put(SvApiActions.listTransportadorasSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listTransportadorasFailure(error));
    }
}

export function* listTransportadorasByClienteId({ payload }: ListTransportadorasByClienteAction) {
    try {
        const { data } = yield call(SVAPIApi.listTransportadorasByClienteId, payload);
        yield put(SvApiActions.listTransportadorasByClienteSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.defaultSvApiFailure(error));
    }
}

export function* listClienteTransportadora() {
    try {
        const { data } = yield call(SVAPIApi.listClienteXTransportadora);
        yield put(SvApiActions.listClienteTransportadoraSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listClienteTransportadoraFailure(error));
    }
}

export function* listPedidoStatus() {
    try {
        const { data } = yield call(SVAPIApi.listPedidoStatus);
        yield put(SvApiActions.listPedidoStatusSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listPedidoStatusFailure(error));
    }
}

export function* listUsuarios() {
    try {
        const { data } = yield call(SVAPIApi.listUsuarios);
        yield put(SvApiActions.listUsuariosSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.listUsuariosFailure(error));
    }
}

export function* listUsuariosByCliente({ payload }: ListUsuariosByClienteAction) {
    try {
        const { data } = yield call(SVAPIApi.listUsuariosByClienteId, payload);
        yield put(SvApiActions.listUsuariosByClienteSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.defaultSvApiFailure(error));
    }
}

export function* listContatosByCliente({ payload }: ListContatosByClienteAction) {
    try {
        const { data } = yield call(SVAPIApi.listContatosByClienteId, payload);
        yield put(SvApiActions.listContatosByClienteSuccess(data.data));
    } catch (e) {
        const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
        toast.error(error);
        yield put(SvApiActions.defaultSvApiFailure(error));
    }
}