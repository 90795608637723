import React, { useEffect, useState } from 'react';
import { dateTimeStringUStoPTBR } from '../../../utils/dateHelper';
import { IGlobalReducerState } from '../../../store/base/interface/IGlobalReducerState';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { isAccessCoordenador, setVisibleAccessCoordenador } from '../../../utils/AuthUtils';
import { NotificationActions } from '../../../store/notification/Notification.actions';
import { Badge, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import styles from './NotificationBox.module.scss';

const NotificationBox: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [showNotifications, setShowNotifications] = useState(false);

    useEffect(() => {
        if (isAccessCoordenador())
            dispatch(NotificationActions.listNotifications());
    }, []);

    const handleButtonClick = () => {
        setShowNotifications(!showNotifications);
        dispatch(NotificationActions.readAllNotificationsRequest())
    };

    return (
        <>
            <label style={{ ...setVisibleAccessCoordenador }}>
                <Button type="text" onClick={handleButtonClick}>
                    <Badge count={props.notifications.filter(x => !x.hasRead).length} showZero>
                        <BellOutlined style={{ fontSize: 24, color: props.notifications.length > 0 ? 'red' : 'inherit' }} />
                    </Badge>
                </Button>
                {showNotifications ?
                    <div style={{ width: 400, position: 'absolute', top: '100%', right: 0, padding: '5px 10px', borderRadius: 4, background: 'white', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', zIndex: 1000 }}>
                        {props.notifications.length > 0 ? (
                            <>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {props.notifications.slice(0, 10).map((notification) => (
                                        <li className={styles[!notification.hasView ? 'notRead' : '']} key={notification.notificationId} style={{ marginTop: 5, padding: '5px 5px 15px 5px', borderBottom: '1px solid #cdcdcd' }}>
                                            <div style={{ fontSize: 14, textAlign: 'left' }}>{notification.title}</div>
                                            <div style={{ textAlign: 'left', display: 'flex' }}>
                                                <label style={{ position: 'relative', display: 'block', top: 0, width: 130 }}>
                                                    <small style={{ color: '#a5a5a5' }}>
                                                        {dateTimeStringUStoPTBR(notification.dataHoraCriacao)}
                                                    </small>
                                                </label>
                                                <small style={{ marginLeft: 136, position: 'relative', display: 'block', top: 3 }}>
                                                    <a type='link' style={{ textDecoration: 'none' }} href={notification.link}>Ver Mais</a>
                                                </small>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <p style={{ textAlign: 'center', paddingTop: 10, color: 'gray' }}>Sem Notificações</p>
                        )}
                    </div>
                    : null
                }
            </label>
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.auth,
    ...state.notification
});

const connector = connect(
    mapState,
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(NotificationBox);