import { SvDbActionKeys, SvDbActionUnion } from "./SvDb.actions";
import { IFormaPagamentoResponse } from "../../data/interfaces/svData/IFormaPagamentoResponse";
import { ISvDbState } from "./interface/ISvDbState";

const initialState: ISvDbState = {
    formasPagamento: [] as IFormaPagamentoResponse[],
    isLoading: false,
    error: undefined,
    message: undefined,
}

const svDbReducer = (state = initialState, action: SvDbActionUnion): ISvDbState => {

    switch (action.type) {
        case SvDbActionKeys.LIST_FORMAS_PAGAMENTO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvDbActionKeys.LIST_FORMAS_PAGAMENTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                formasPagamento: action.payload,
            };
        case SvDbActionKeys.LIST_FORMAS_PAGAMENTO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SvDbActionKeys.LIST_PEDIDO_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvDbActionKeys.LIST_PEDIDO_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pedidoStatusDB: action.payload,
            };

        case SvDbActionKeys.GET_ULTIMO_PEDIDO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvDbActionKeys.GET_ULTIMO_PEDIDO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ultimoPedido: action.payload,
            };

        case SvDbActionKeys.LIST_INDUSTRIAS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvDbActionKeys.LIST_INDUSTRIAS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                industrias: action.payload,
            };

        case SvDbActionKeys.LIST_USUARIOS_VENDEDORES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SvDbActionKeys.LIST_USUARIOS_VENDEDORES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                usuariosVendedores: action.payload,
            };

        case SvDbActionKeys.DEFAULT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export default svDbReducer;