import React, { FC, useEffect, useState } from 'react';
import { Button, List, message, Modal, Select, Avatar, Mentions, Skeleton, Upload, UploadFile, UploadProps } from 'antd';
import { IGlobalReducerState } from '../../../../store/base/interface/IGlobalReducerState';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { AuthUtils } from '../../../../utils/AuthUtils';
import { TicketActions } from '../../../../store/ticket/Ticket.actions';
import { ITicketChangeStatusRequest, ITicketMessage, ITicketMessageRequest, IUploadTicketAttachmentRequest, TicketStatusEnum } from '../../../../data/interfaces/ticket/ITicket';
import { FaTrash } from 'react-icons/fa';
import { dateTimeStringUStoPTBR } from '../../../../utils/dateHelper';
import { ListLocale } from 'antd/es/list';
import { MentionsOptionProps } from 'antd/es/mentions';
import { AuthActions } from '../../../../store/auth/Auth.actions';
import { toast } from 'react-toastify';
const { Option } = Select;

export interface IConversation {
    acompanhamentoId?: number;
    ticketId?: number;
    visible: boolean;
    onClose: (reload?: boolean) => void;
}

const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const Conversation: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState<ITicketMessage[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [status, setStatus] = useState<number>(TicketStatusEnum.Aberto);
    const [user, setUser] = useState(AuthUtils.getLoggedUser());
    const [userNames, setUserNames] = useState<MentionsOptionProps[]>([]);

    useEffect(() => {
        if (props.ticketId) {
            dispatch(TicketActions.getTicket(props.ticketId));
        }
        else if (props.acompanhamentoId) {
            dispatch(TicketActions.getTicketAcompanhamento(props.acompanhamentoId));
        }
        else {
            console.log('Ocorreu um erro ao carregar mensagens!');
        }
    }, []);

    useEffect(() => {
        if (props.userNames) {
            setUserNames(props.userNames.map(x => ({
                value: x,
                label: x
            }) as MentionsOptionProps));
        }
        else {
            dispatch(AuthActions.getUserInfo());
        }
    }, [props.userNames])

    useEffect(() => {
        if (props.ticket && props.ticket.messages) {
            setMessages(props.ticket.messages);
            setStatus(props.ticket.status);

        }
    }, [props.ticket])

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom('list-messages');
        }, 50);
    }, [props.ticket?.ticketId, props.ticket?.messages]);

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    const handleSend = () => {
        if (inputValue.trim() === '') {
            message.error('Digite uma mensagem para enviar!');
            return;
        }

        const newMessage = {
            message: inputValue,
            ticketId: props.ticket?.ticketId
        } as ITicketMessageRequest;

        dispatch(TicketActions.addMessage(newMessage));
        setInputValue('');
    };

    const handleDeleteMessage = (id: number) => {
        dispatch(TicketActions.deleteMessage(id));
    };

    const handleStatusChange = (value: TicketStatusEnum) => {
        setStatus(value);

        var changeStatus = {
            ticketId: props.ticket?.ticketId,
            status: value
        } as ITicketChangeStatusRequest;

        dispatch(TicketActions.changeStatus(changeStatus));
    };

    const handleClose = () => {
        dispatch(TicketActions.clearTicket());
        props.onClose();
    }

    function scrollToBottom(id: any) {
        var elementAux = document.getElementById(id);
        elementAux?.scroll({ top: elementAux?.scrollHeight, behavior: 'smooth' });
    }

    const buttons =
        [
            <Button key="cancel" onClick={handleClose}>Fechar</Button>,
        ];

    const localeTable = {
        emptyText: 'Nenhuma Mensagem!',
    } as ListLocale;


    //*********
    //****Upload
    const handleUploadAttachment = (e: any) => {

        if (e.file.size > 50000000) {
            toast.warning('O arquivo deve ter menos de 50MB')
        }
        else {
            var attachment =
                {
                    ticketId: props.ticket?.ticketId,
                    file: e.file
                } as IUploadTicketAttachmentRequest;

            dispatch(TicketActions.uploadAttachment(attachment));
        }
    }

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleDeleteAttachment = (e: any) => {
        if (window.confirm("Deseja realmente deletar o arquivo?")) {
            dispatch(TicketActions.deleteAttachment(e.uid));
        }
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <Modal title={'Mensagens'} open={props.visible} footer={buttons} closable={false} destroyOnClose={true} width={650} style={{ marginTop: -50 }}>
            {!props.ticket?.ticketId ?
                <>
                    <Skeleton active />
                </>
                :
                <div>
                    <div>
                        <Select
                            value={status}
                            style={{ width: 120, marginBottom: 16 }}
                            onChange={handleStatusChange}
                        >
                            <Option value={0}>Aberto</Option>
                            <Option value={1}>Fechado</Option>
                        </Select>
                    </div>
                    <div style={{ border: '1px solid #cdcdcd', paddingLeft: 5, borderRadius: 5 }}>
                        <List
                            dataSource={messages}
                            style={{ height: 250, overflowY: 'scroll' }}
                            loading={props.isLoading}
                            id='list-messages'
                            locale={localeTable}
                            renderItem={(item) => (
                                <List.Item
                                    actions={[
                                        <FaTrash key={item.ticketMessageId} onClick={() => handleDeleteMessage(item.ticketMessageId)} style={{ cursor: 'pointer', display: item.userFk != user.userId ? 'none' : 'block' }} />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar src={item.user?.avatarFileName ? `${process.env.REACT_APP_BASE_URL_STORAGE}${item.user.avatarFileName}` : null} icon={<UserOutlined />} shape="circle" />
                                        }
                                        title={<><label>{item.user?.name}</label> - <small style={{ color: 'gray' }}>{dateTimeStringUStoPTBR(item.dataHoraEnvio)}</small></>}
                                        description={item.message}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Upload
                            customRequest={handleUploadAttachment}
                            listType="picture-card"
                            fileList={props.ticket?.attachments.map((file) => ({
                                uid: file.ticketAttachmentId?.toString(),
                                name: file.attachment?.name,
                                status: file.status,
                                url: `https://storageaccountrecurb94c.blob.core.windows.net/attachments/tickets/${file.attachment?.name}`,
                                percent: file.progress
                            }) as UploadFile)
                            }
                            onPreview={handlePreview}
                            onDownload={(e) => window.open(e.url)}
                            onRemove={handleDeleteAttachment}
                            showUploadList={{ showDownloadIcon: true }}
                        >
                            {!props.isUploading && uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Mentions
                            style={{ width: '100%', height: 80 }}
                            onChange={handleInputChange}
                            value={inputValue}
                            options={userNames}
                            rows={2}
                        />
                        <div style={{ display: 'flex', textAlign: 'right', paddingTop: 10 }}>
                            <Button type="primary" disabled={props.isLoading} onClick={handleSend} style={{ position: 'relative', left: 460 }}>
                                {props.isLoading ? 'Enviando...' : 'Enviar Mensagem'}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </Modal >
    );
};

const mapState = (state: IGlobalReducerState) => ({
    ...state.acompanhamento,
    ...state.ticket,
    ...state.auth
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IConversation;

export default connector(Conversation);