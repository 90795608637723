import React, { memo, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { AuthUtils } from '../utils/AuthUtils';
import { useDispatch } from 'react-redux';
import { ClienteActions } from '../store/cliente/Cliente.actions';

const ClienteSignalR = () => {
    const [connection, setConnection] = useState<HubConnection>();
    var user = AuthUtils.getLoggedUser();
    var dispatch = useDispatch();

    useEffect(() => {

        if (connection) {
            connection.stop();
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(
                `${process.env.REACT_APP_BASE_API_URL?.substring(0, process.env.REACT_APP_BASE_API_URL?.length - 4)}/hubs/cliente`,
                { accessTokenFactory: () => user.accessToken ?? "" }
            )
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        newConnection.serverTimeoutInMilliseconds = 100000;
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    connection.on('newNotificationImportador', (message) => {
                        var result = JSON.parse(message);
                        dispatch(ClienteActions.importarClienteIndustriaInfo(result.Text));
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    return <></>;
};

export default memo(ClienteSignalR);