import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { AcompanhamentoApi } from '../../data/Acompanhamento.api';
import { AcompanhamentoActions, GetAcompanhamentoAction, ImportarAcompanhamentoAction, UpdateAcompanhamentoAction } from './Acompanhamento.actions';

export function* getAcompanhamento({ payload }: GetAcompanhamentoAction) {
  try {
    const { data } = yield call(AcompanhamentoApi.get, payload);
    yield put(AcompanhamentoActions.getAcompanhamentoSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AcompanhamentoActions.defaultFailure(error));
  }
}

export function* updateAcompanhamento({ payload }: UpdateAcompanhamentoAction) {
  try {
    const { data } = yield call(AcompanhamentoApi.update, payload);
    yield put(AcompanhamentoActions.updateAcompanhamentoSuccess());
    toast.success('Acompanhamento atualizado com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(AcompanhamentoActions.defaultFailure(error));
  }
}

export function* importarAcompanhamento({ payload }: ImportarAcompanhamentoAction) {
  try {
    yield call(AcompanhamentoApi.importarAcompanhamento, payload);
    yield put(AcompanhamentoActions.importarAcompanhamentoSuccess());
    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    console.log(e);

    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação está inválida!');
      yield put(AcompanhamentoActions.importarAcompanhamentoInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(AcompanhamentoActions.importarAcompanhamentoFailure(error));
    }
  }
}