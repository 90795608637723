import { Button, Skeleton, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageContent from '../../../components/ui/pageContent';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { IGlobalReducerState } from "../../../store/base/interface/IGlobalReducerState";
import { getColumns } from "./CategoriasCliente.helper";
import styles from './CategoriasCliente.module.scss';
import { ClienteActions } from "../../../store/cliente/Cliente.actions";
import CategoriaClienteForm from '../modals/formCategoria';
import { ICategoriaCliente } from "../../../data/interfaces/cliente/ICategoriaCliente";

const CategoriasCliente: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [defaultValuesUserForm, setDefaultValuesUserForm] = useState<ICategoriaCliente>({} as ICategoriaCliente);
    const [formVisible, setFormVisible] = useState<boolean>(false);

    useEffect(() => {
        dispatch(ClienteActions.listCategoriasCliente());
    }, []);

    const openModalForm = (register?: ICategoriaCliente) => {
        setDefaultValuesUserForm((register ?? {}) as ICategoriaCliente);
        setFormVisible(true);
    }

    const deleteUser = (categoriaClienteId: number) => {
        dispatch(ClienteActions.deleteCategoriaCliente(categoriaClienteId));
    }

    return (
        <>
            <PageContent title='Categorias de Cliente' className={styles['container']}>
                {props.isLoading ?
                    <Skeleton active />
                    :
                    <>
                        <div className={styles['toolBar']}>
                            <Button type='primary' onClick={() => openModalForm()}>Nova Categoria</Button>
                        </div>
                        <Table columns={getColumns(props.categoriasCliente, openModalForm, deleteUser)} dataSource={props.categoriasCliente} style={{ overflowY: 'auto' }} pagination={false} />
                    </>
                }
            </PageContent>
            {formVisible
                ? <CategoriaClienteForm defaultValues={defaultValuesUserForm} visible={formVisible} onClose={() => setFormVisible(false)}></CategoriaClienteForm>
                : null}
        </>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(CategoriasCliente);