import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { IErroResponse } from '../../data/interfaces/shared/IErroResponse';
import { SellOutApi } from '../../data/SellOut.api';
import { GetSellOutAction, ImportarSellOutAction, SellOutActions, UpdateSellOutAction } from './SellOut.actions';

export function* getSellOut({ payload }: GetSellOutAction) {
  try {
    const { data } = yield call(SellOutApi.get, payload);
    yield put(SellOutActions.getSellOutSuccess(data.data));
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(SellOutActions.defaultFailure(error));
  }
}

export function* updateSellOut({ payload }: UpdateSellOutAction) {
  try {
    const { data } = yield call(SellOutApi.update, payload);
    yield put(SellOutActions.updateSellOutSuccess(data.data));
    toast.success('Sell-Out atualizado com sucesso!');
  } catch (e) {
    const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
    toast.error(error);
    yield put(SellOutActions.defaultFailure(error));
  }
}

export function* importarSellOut({ payload }: ImportarSellOutAction) {
  try {
    const { data } = yield call(SellOutApi.importarSellOut, payload);

    yield put(SellOutActions.importarSellOutSuccess(data.data));

    toast.success('Importação realizada com sucesso!');
  } catch (e) {
    console.log(e);

    if (e.errors && e.errors.length) {
      const errors = e.errors as IErroResponse[];
      toast.error('A importação está inválida!');
      yield put(SellOutActions.importarSellOutInvalid(errors));
    }
    else {
      const error = e.errors && e.errors.length ? e.errors[0].message : 'Erro ao realizar ação';
      toast.error(error);
      yield put(SellOutActions.importarSellOutFailure(error));
    }
  }
}