import React, { FC, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { IGlobalReducerState } from "../../../../../store/base/interface/IGlobalReducerState";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Switch } from "antd";

import styles from './PedidoNaoImportadoForm.module.scss';
import Form from "../../../../../components/ui/form";
import FieldForm from "../../../../../components/ui/field";
import { IPedidosNaoImportado } from "../../../../../data/interfaces/pedido/IPedidosNaoImportado";
import { PedidoImportadoActions } from "../../../../../store/pedido/importador/PedidoImportado.actions";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TextArea from "../../../../../components/ui/textArea";
import { IPedidosNaoImportadoRequest } from "../../../../../data/interfaces/pedido/IPedidosNaoImportadoRequest";

export interface IPedidoNaoImportadoFormModal {
    defaultValues: IPedidosNaoImportadoRequest;
    visible: boolean;
    onClose: () => void;
}

const PedidoNaoImportadoForm: FC<Props> = ({ defaultValues, visible, onClose, isLoading }) => {
    const dispatch = useDispatch();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSendingData, setIsSendingData] = useState<boolean>(false);
    const [isVerificado, setIsVerificado] = useState<boolean>(defaultValues.verificado);

    const submit = (values: IPedidosNaoImportadoRequest) => {
        if (values) {
            values.verificado = isVerificado;
            dispatch(PedidoImportadoActions.updatePedidoNaoImportado(values));
            setIsSendingData(true);
        }

        setIsSubmit(false);
    }

    if (!isLoading && isSendingData) {
        setIsSendingData(false);
        onClose();
    }

    const buttons =
        [
            <Button key="back" onClick={onClose}>Cancelar</Button>,
            <Button key="submit" type="primary" loading={isLoading} htmlType='submit' onClick={() => setIsSubmit(true)}>{'Salvar'}</Button>,
        ];

    return (
        <Form onSubmit={submit} initialValues={defaultValues} isSubmited={isSubmit}>
            <Modal title={'Pedido Não Importado - Info'} open={visible} footer={buttons} onCancel={onClose}>
                <div className={styles['container']}>
                    <FieldForm type='hidden' name='pedidoNumero'></FieldForm>
                    <h3>Pedido - Nº {defaultValues.pedidosNumero?.join(',')}</h3>
                    <div className={styles['activeItem']}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={isVerificado}
                            size='small'
                            onChange={(e) => setIsVerificado(e)}
                        />
                        <label>Verificado</label>
                    </div>
                    <br></br>
                    <TextArea rows={3} autoComplete='false' key='observacao' label='Obeservação' name='observacao' className={styles['inputForm']}></TextArea>
                </div>
            </Modal>
        </Form>
    );
}

const mapState = (state: IGlobalReducerState) => ({
    ...state.cliente,
});

const connector = connect(
    mapState,
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IPedidoNaoImportadoFormModal;

export default connector(PedidoNaoImportadoForm);